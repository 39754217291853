import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    titleContainer: {
      '& .headingContainer': {
        flexDirection: "column",
        '& .titleHeading': {
          fontSize: 18,
          fontWeight: 500,
          '& .content': {
            fontWeight: 600
          }
        },
        '& .titleContent': {
          fontSize: 12,
          fontWeight: 600,
          '&.link': {
            cursor: "pointer"
          }
        }
      },
      '& .tagContainer': {
        marginLeft: 8,
        textAlign: 'center',
        '& .tag': {
          color: COLORS.WHITE,
          borderRadius: 100,
          padding: theme.spacing(0.5, 1),
          backgroundColor: COLORS.BLACK,
          fontWeight: 700,
          textTransform: "uppercase",
          fontSize: 12,
          '&.yellow': {
            backgroundColor: COLORS.GOLDEN_YELLOW
          },
          '&.blue': {
            backgroundColor: COLORS.AZURE_BLUE
          },
          '&.green': {
            backgroundColor: COLORS.PERSIAN_GREEN
          },
          '&.red': {
            backgroundColor: COLORS.DARK_RED
          },
          '&.black': {
            backgroundColor: COLORS.BLACK
          }
        }
      },
      '& .pencilIconStyle' : {
        cursor: 'pointer',
        margin: 'auto 8px',
        fill: "none",
      },
    },
    contentContainer: {
      flexDirection: "column",
      flexWrap: "nowrap",
      '& .snackBarErrorMessage': {
        marginBottom: 16,
        backgroundColor: COLORS.DARK_RED,
        height: 35,
        borderRadius: 8,
        '& .errorTag': {
          color: COLORS.WHITE,
          padding: theme.spacing(1, 2, 0, 0.5),
          fontSize: 12,
          width: "95%",
          float: "left"
        },
      },
      '& .snackBarSuccessMessage': {
        marginBottom: 16,
        backgroundColor: COLORS.PERSIAN_GREEN,
        height: 35,
        borderRadius: 8,
        '& .successTag': {
          color: COLORS.WHITE,
          padding: theme.spacing(1, 2),
          fontSize: 12,
          width: "95%",
          float: "left"
        },
      },
      '& .snackBarDetails': {
        marginBottom: 16,
        backgroundColor: COLORS.GOLDEN_YELLOW,
        height: 35,
        borderRadius: 100,
        '& .tag': {
          color: COLORS.WHITE,
          padding: theme.spacing(1, 2),
          fontSize: 12,
          width: "70%",
          float: "left"
        },
        '& .action': {
          color: COLORS.WHITE,
          padding: theme.spacing(1, 2),
          fontSize: 12,
          fontWeight: 600,
          width: "30%",
          float: "right",
          textAlign: "right",
          cursor: 'pointer'
        }
      },
      '& .detailsHeader': {
        flexDirection: "column",
        padding: theme.spacing(2, 0, 2, 2),
        borderRadius: theme.spacing(1),
        '& .detailsHeaderItem': {
          paddingBottom: theme.spacing(1),
          width: "100%",
          '& .addressinfo':{
            fontWeight: 550
          },
          '& .geocoded':{
            color: COLORS.DARK_RED
          },
          '&:last-child': {
            paddingBottom: 0
          },
          '& p': {
            lineHeight: "22px",
            fontSize: 12
          },
          '& .addressTitle': {
            fontSize: 14
          },
          '& .name': {
            fontSize: 14,
            fontWeight: 600
          },
          '&.geocodedAddress': {
            paddingTop: theme.spacing(2),
            width: "97%",
            '& .addressColItem': {
              alignSelf: "flex-start"
            },
            '& .geoLocAddressContainer': {
              cursor: "pointer",
              '& .address': {
                fontSize: 14,
                fontWeight: 600,
                color: theme.palette.primary.main,
                lineHeight: "14px",
                paddingRight: 3
              },
              '& .addressLogo': {
                width: 12,
                height: 12,
                transform: "rotate(0deg)",
                transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                '&.open': {
                  transform: "rotate(180deg)",
                }
              }
            },
            '& .addressMapContainer': {
              height: 243,
              border: `1px solid ${COLORS.SILVER}`,
              borderRadius: theme.spacing(1),
              marginTop: theme.spacing(1),
              backgroundColor: COLORS.SILVER
            }
          },
          '& .contactMobile': {
            marginRight: theme.spacing(5),
            marginBottom: theme.spacing(1.2),
            width: '100%'
          },
          '& .logo': {
            width: 24,
            height: 24
          },
          '& .contact': {
            '& a': {
              lineHeight: "17px",
              fontSize: 12,
              fontWeight: 600,
              textDecoration: "none",
              color: theme.palette.text.primary
            },
            paddingLeft: theme.spacing(1),
            '&.mobile': {
              paddingLeft: 2
            }
          }
        }
      },
      '& .addressContainer':{
        height: 360,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '0.3em',
          height: '0.3em'
        },
        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'grey',
          borderRadius: 8
        }
      },
      '& .detailsHeaderLeftSection': {
        float: "left",
        width: "49%",
        borderRadius: "8px",
        boxShadow: "0px 0px 4px rgb(0 0 0 / 15%)",
      },
      '& .detailsHeaderRightSection': {
        float: "right",
        width: "49%",
        borderRadius: "8px",
        boxShadow: "0px 0px 4px rgb(0 0 0 / 15%)",
      },
      '& .itemRowMargin':{
        paddingBottom: theme.spacing(3)
      },
      '& .itemPaddingLeft':{
        paddingLeft: theme.spacing(3)
      },
      '& .itemValue':{
        fontWeight: 600
      },
      '& .detailsContent': {
        flexDirection: "column",
        flexWrap: "nowrap",
        padding: theme.spacing(3, 0, 2, 0),
        '& .contentRow': {
          paddingBottom: theme.spacing(3),
          '&:last-child': {
            paddingBottom: 0
          },
          '& .contentColumn': {
            flex: "1 1 0",
            '&:last-child': {
              flex: "0 1 auto"
            },
            '& p': {
              fontSize: 14,
              lineHeight: "17px"
            },
            '& .label': {
              paddingBottom: theme.spacing(1)
            },
            '& .content': {
              fontWeight: 600
            }
          }
        }
      },
      '& .sellerDetails': {
        paddingTop: theme.spacing(1.75),
        '& .detailsHeader': {
          backgroundColor: theme.palette.secondary.main
        }
      },
      '& .customerAddressTop': {
        paddingTop: theme.spacing(2)
      },
      '& .customerAddress': {
        paddingBottom: theme.spacing(2),
        '& .detailsHeaderItem': {
          paddingBottom: theme.spacing(1),
          '& .addressinfo':{
            fontWeight: 550
          },
          '&:last-child': {
            paddingBottom: 0
          },
          '& p': {
            lineHeight: "22px",
            fontSize: 12
          },
          '& .addressTitle': {
            fontSize: 14
          },
          '& .name': {
            fontSize: 14,
            fontWeight: 600
          }
        }
      },
      '& .consignmentHistory': {
        paddingBottom: theme.spacing(2)
      },
      '& .itemsSection': {
        '& .content': {
          width: "100%",
          '& .itemsContainer': {
            flexWrap: "nowrap",
            '& .substituteItem':{
              //backgroundColor: "#F8F8F8",
            },
            '& .itemDivider': {
              backgroundColor: "#E6E6E6",
              margin: "0 16px"
            },
            '& .item': {
              padding: theme.spacing(2, 2),
              "&:first-child": {
                padding: theme.spacing(0, 2, 2, 2),
                borderBottom: `1px solid ${theme.palette.secondary.dark}`,
              },
              '&:last-child': {
                borderBottom: `1px solid ${theme.palette.secondary.dark}`,
              },
              '& .itemContainer': {
                '& p': {
                  fontSize: 14,
                  lineHeight: "17px",
                  fontWeight: 600
                },
                '&.header p': {
                  color: COLORS.DOVE_GRAY,
                  fontWeight: 400
                },
                '& .itemColumn': {
                  '&.item1': {
                    "& p": {
                      fontWeight: 400,
                      textTransform: "capitalize"
                    }
                  },
                  '&.item2': {
                    '& p': {
                      textAlign: "right",
                      '& .green': {
                        color: COLORS.PERSIAN_GREEN
                      }
                    }
                  },
                  '&.item3': {
                    '& p': {
                      textAlign: "right",
                      '& .green': {
                        color: COLORS.PERSIAN_GREEN
                      }
                    }
                  },
                  '&.item4': {
                    '& p': {
                      textAlign: "right",
                      '& .red': {
                        color: COLORS.DARK_RED
                      }
                    }
                  },
                  '&.item5': {
                    '& p': {
                      textAlign: "right"
                    }
                  }
                }
              },
              '& .substitutedLine': {
                '& .itemColumn': {
                  '&.item1': {
                    "& p": {
                      textDecoration: "line-through",
                      color: COLORS.DOVE_GRAY
                    }
                  },
                  '&.item2': {
                    '& p': {
                      textAlign: "right",
                      '& .green': {
                        color: COLORS.PERSIAN_GREEN
                      }
                    }
                  },
                  '&.item3': {
                    '& p': {
                      textAlign: "right",
                      '& .green': {
                        color: COLORS.PERSIAN_GREEN
                      }
                    }
                  },
                  '&.item4': {
                    '& p': {
                      textAlign: "right",
                      '& .red': {
                        color: COLORS.DARK_RED
                      }
                    }
                  },
                  '&.item5': {
                    '& p': {
                      textAlign: "right"
                    }
                  }
                }
              },
              '& .substitutedContent': {
                '& .substitutedTxt': {
                  fontSize: 12,
                  color: COLORS.GOLDEN_YELLOW,
                  padding: "20px 0 10px 0"
                },
                '& .itemContainer': {
                  paddingBottom: 10
                }
              },
            }
          },
          '& .paymentContainer': {
            paddingTop: 13,
            paddingLeft: 16,
            paddingRight: 16,
            '& .totalDivider': {
              margin: "11px 0px"
            },
            '& .paymentItem': {
              '&:last-child p': {
                fontWeight: 600
              },
              padding: "5px 0",
              '& .paymentItemColumn': {
                '& p': {
                  fontSize: 14,
                  lineHeight: "17px"
                },
                '& .vat': {
                  fontWeight: 400,
                  paddingLeft: 2
                },
                '&.right': {
                  marginLeft: "auto"
                }
              }
            }
          }
        }
      },
      '& .repushButton': {
        paddingTop:20,
        textAlign: "center"
      },
      '& .itemSummary': {
        '& .MuiAccordionDetails-root': {
          padding: "16px 0 !important"
        }
      },
      '& .contentItem': {
        '& .itemContainer': {
          display: 'inline',
          '& .itemLabel': {
            '& p': {
              fontSize: 14,
              paddingBottom: 4
            }
          }
        }
      }
    },
    accordionSummary: {
      '& .header': {
        fontSize: 14,
        lineHeight: "17px",
        //fontWeight: 600
      },
      '& .content': {
        flexGrow: 1
      }
    },
    timeline: {
      padding: "0 !important",
      margin: 0,
      '& .timelineItem': {
        minHeight: 50,
        '&:last-child': {
          minHeight: "auto"
        },
        '&:before': {
          content: "none"
        },
        '& .timelineDot': {
          padding: 0,
          margin: 0,
          border: "none",
          boxShadow: "none",
          backgroundColor: "transparent",
          '& .dotIcon': {
            width: 18,
            height: 18,
            padding: '4px 2px 2px 4px',
            '&.active': {
              padding: 0
            }
          },
        },
        '& .primaryConnector': {
          backgroundColor: COLORS.TRANSPARENT,
          borderLeft: `1px dashed ${COLORS.SILVER}`
        },
        '& .timelineContent': {
          padding: 0,
          paddingLeft: theme.spacing(1),
          '& .heading': {
            paddingTop: 1,
            fontSize: 14,
            lineHeight: "14px",
            fontWeight: 600,
            color: theme.palette.primary.main,
            '&.error': {
              color: COLORS.DARK_RED,
              '&.active': {
                paddingBottom: 17
              }
            },
            '&.active': {
              paddingBottom: 17
            },
            '& .dateTime': {
              fontSize: 12,
              lineHeight: "15px",
              fontWeight: 500,
              color: COLORS.DOVE_GRAY,
              paddingLeft: 4,
              verticalAlign: "bottom",
            }
          },
          '& .metadataContainer': {
            paddingBottom: theme.spacing(1.5),
            '& .title': {
              fontSize: 12,
              lineHeight: "10px",
              paddingBottom: theme.spacing(1),
              fontWeight: 500,
              color: COLORS.COPY
            },
            '& .description': {
              fontSize: 10,
              lineHeight: "10px",
              color: COLORS.DOVE_GRAY_LIGHT
            }
          }
        }
      }
    },
    buttons: {
      width: "100%",
      '& .buttonsContainer': {
        '& .userBtn': {
          '&.primary': {
            marginRight: theme.spacing(2)
          },
          '&.secondary': {
            marginRight: theme.spacing(2)
          },
          flexGrow: 1
        }
      }
    },
    statusMessageIcon: {
      width: "2.3%",
      float: "left",
      paddingLeft: 2,
      paddingTop: 11,
      '& .iconStyle':{
        width: 150
      }
    },
    statusMessageImg: {
      width: "4.3%",
      float: "left",
      paddingLeft: 4,
      paddingTop: 8,
      '& .iconStyle':{
        width: 150
      }
    },
    iconStyle:{
      width: 150
    },
    dialogPaperPropsRoot: {
      margin: 0,
      marginLeft: "auto",
      maxHeight: "none",
      height: "100vh",
      width: 600,
      '& .MuiDialogActions-root': {
        boxShadow: '0px 0px 4px rgb(0 0 0 / 10%)'
      },
      '& .MuiDialogTitle-root':{
        paddingBottom: 0
      },
    },
    customDialog: {
      '& .MuiDialogActions-root': {
        padding: "20px",
        marginTop: 20,
        marginBottom: 10,
        '& .customBtn':{
          minWidth: 134,
          height: 40
        }
      },
      '& .MuiDialog-paper':{
        height: "calc(100vh - 200px)",
        minWidth: 555
      },
      '& .MuiDialogTitle-root': {
        padding: '4px 24px',
        '& .MuiTypography-root':{
          fontSize: 18,
          fontWeight: 600,
          color: "#252525",
        }
      },
      '& .MuiDialogContent-root':{
        padding: '8px 24px 12px 30px',
        '& .MuiCollapse-container':{
          width: '100%'
        }
      },
      '& .MuiDialogContentText-root': {
        fontSize: 18,
        fontWeight: 600,
        color: "#252525",
        '& .MuiCollapse-container':{
          width: '100%'
        }
      },
      '& .titleStatus':{
        height: 21,
        fontWeight: 600,
        color: '#4D4D4D',
        marginBottom: 12,
        paddingLeft: 22,
        '&.trackingStatus':{
          fontSize: 20,
        },
        '&.carrier':{
          fontSize: 14,
          textAlign: 'right'
        }
      },
      '& .orderNumber':{
        height: 21,
        fontWeight: 600,
        color: '#4D4D4D',
        marginBottom: 20,
        '&.orderLabel':{
          fontSize: 14,
          paddingLeft: 22,
        },
        '& .orderValue':{
          color: '#0E5AA7',
        },
        '&.logo':{
          textAlign: 'right'
        }
      },
      '& .orderDate':{
        height: 21,
        fontWeight: 600,
        color: '#4D4D4D',
        marginBottom: 20,
        '&.orderDateLabel':{
          fontSize: 14,
          textDecorationLine: 'underline',
          color: '#000000',
        },
      },
      '& .StatusProgressMain':{
        '& .timelineItem': {
          minHeight: '36px !important',
          '& .marginLeft':{
            marginLeft: -3
          }
        },
        '& .timelineContent':{
          paddingLeft: '12px !important'
        },
        '& .primaryConnector':{
          margin: '0px',
          backgroundImage: 'repeating-linear-gradient(10deg, #DCDCDC, #DCDCDC 7px, transparent 7px, transparent 15px)',
          border: 'none'
        },
        '& .lastConnector':{
          flexGrow: '0.7'
        },
        '& .timer':{
          fontSize: 12,
          lineHeight: "15px",
          color: COLORS.DOVE_GRAY_LIGHT,
          padding: '0px 10px 0px 0px',
          '&.first':{
            minWidth: 22,
          },
          '&.notFirst':{
            minWidth: 25,
          }
        },
        '& .heading':{
          lineHeight: '17px !important'
        },
        '& .metadataContainer':{
          paddingBottom: '20px !important',
          width: '80%',
          '&.viewMore':{
            paddingBottom: '10px !important'
          },
          '& .border':{
            height: 44,
            border: '0.5px solid #C3C3C3',
            boxSizing: 'border-box',
            borderRadius: 4,
            padding: '3px 0 0px 8px',
            marginLeft: '-8px'
          }
        },
        '& .title':{
          lineHeight: '17px !important',
          '&.failed':{
            color: '#3C1010 !important'
          }
        },
        '& .notFound':{
          fontSize: 14,
        },
        '& .arrow':{
          marginRight: 8,
          '& .addressLogo': {
            width: 14,
            height: 14,
            transform: "rotate(270deg)",
            transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            '&.open': {
              transform: "rotate(0deg)",
            }
          }
        },
        '& .viewMore':{
          color: `${theme.palette.primary.main} !important`,
          fontWeight: '600 !important',
          //background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 50.52%)'
        }
      },
      '& .orderTimeLine':{
        height: 15,
        fontWeight: 500,
        color: '#4D4D4D',
        marginBottom: 20,
        '&.orderTime':{
          fontSize: 12,
          color: '#4D4D4D',
        },
        '&.smallDotIcon':{
          paddingTop: 2
        },
        '&.dotIconDiv':{
          paddingLeft: 12,
        },
        '&.smallDotIconDiv':{
          paddingLeft: 18,
          paddingTop: 7,
        },
        '& .status':{
          fontSize: 14,
          color: '#0B4C8E',
          fontWeight: 600,
        },
        '& .date':{
          fontSize: 12,
          color: '#4D4D4D',
          fontWeight: 500,
        },
        '& .arrow':{
          marginLeft: 8,
          '& .addressLogo': {
            width: 14,
            height: 14,
            transform: "rotate(0deg)",
            transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            '&.open': {
              transform: "rotate(180deg)",
            }
          }
        },
        '&.summary':{
          '& .title':{
            fontSize: 12,
          },
          '& .date':{
            fontSize: 10,
          },
          '&.border':{
            border: '0.5px solid #C3C3C3',
            boxSizing: 'border-box',
            borderRadius: 4,
            height:'auto',
            padding: theme.spacing(0.5, 0.5, 0.5,0.25),
            '& .failedColor':{
                color: '#3C1010'
              }
            }
        },
        '& .primaryConnector': {
          backgroundColor: COLORS.TRANSPARENT,
          borderLeft: `1px dashed ${COLORS.SILVER}`,
          marginLeft: 24
        },
      },
      '& .summaryRow':{
        marginBottom: 20,
        '&.failedColor':{
          marginBottom: 0,
        }
      }
      
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    /** RenderConsignmentEdit */
    contentAreaContainer: {
      flexDirection: "column",
      flexWrap: "nowrap",
    },
    contentAreaItem: {
      padding: theme.spacing(0, 0, 2, 0),
      flexGrow: 1,
    },
    itemContainerStyle : {
      flexDirection: "column",
    },
    itemLabel: {
      fontSize: 14,
      fontWeight: 400,
      color: COLORS.TEXT_PRIMARY,
      padding: theme.spacing(0, 0, 1, 0),
      alignSelf: "flex-start",
    },
    itemInputStyle: {
      '& .MuiOutlinedInput-input': {
        fontSize: 14,
        fontWeight: 400
      }
    },
    itemTextBoxInputStyle: {
      '& .MuiOutlinedInput-root':{
        height: 'auto',
        fontSize: 14,
        fontWeight: 400
      }
    },
    warningContainer: {
      backgroundColor: COLORS.AMBER,
      padding: theme.spacing(2),
      borderRadius: '8px',
    },
    warningSpanStyle: {
      margin: 'auto 8px',
      fontSize: 14,
      fontWeight: 500,
      color: COLORS.WHITE,
    },
    warningIconStyle : {
      margin: 'auto 0px'
    },
    titleHeadingGridContainer: {
      margin: 'auto 0px'
    },
    titleDivGridContainer: {
      maxWidth: '540px',
    }
  })
);