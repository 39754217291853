import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      '& .MuiOutlinedInput-input': {
        paddingTop: theme.spacing(2),
        paddingBottom: 7,
        fontWeight: 600,
        borderWidth: 1,
        borderRadius: theme.spacing(1),
        cursor: "pointer",
        fontFamily: "inherit",
        '&:focus': {
          outline: "none"
        }
      }
    }
  })
);