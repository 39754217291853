import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    mainContainer: {
      overflowY: "auto"
    },
    filterSection: {
      padding: theme.spacing(1, 2, 0, 0),
      flexGrow: 1,
      height: 75,
      minWidth: 1024,
      marginLeft: 24,
      '& .filterHeading': {
        alignSelf: "flex-start",
        '& .headingContainer': {
          cursor: "pointer",
          '& .heading': {
            fontSize: 16,
            fontWeight: 600,
            color: theme.palette.primary.main,
          },
          '& .logo': {
            transform: "rotate(0deg)",
            transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            '&.open': {
              transform: "rotate(180deg)",
            }
          }
        }
      },
      '& .filtersContent': {
        flexWrap: "nowrap",
        '& .buttons': {
          marginLeft: "auto",
          alignSelf: "center",
          '& .MuiButton-root':{
            margin: "0px 2px 8px 8px"
          }
        },
        '& .MuiGrid-item':{
          width: "20%",
          margin: "8px 8px 8px 8px",
          [theme.breakpoints.down(1100)]: {
            margin: "8px 1px 8px 1px",
          },
          '& .MuiFormControl-root':{
            width: "100%"
          }
        },
        '& .MuiOutlinedInput-root': {
          //width: 310
          width: "100%"
        }
      }
    },
    button: {
      margin: theme.spacing(0.5),
      '&.reset-btn': {
        marginRight: theme.spacing(0)
      }
    },
    charArea: {
      width: "98%",
      marginLeft: "24px",
    },
    paraText: {
      padding: "15px 0 0 20px",
      fontSize: "18px",
      fontWeight: 600
    },
    chartTitle: {
      padding: "15px 0 0 20px",
      fontSize: "16px",
      fontWeight: 600
    },
    charArea1: {
      width: "66%",
      height: "320px",
      float: "left",
      margin: "0 20px 20px 0",
      borderRadius: "8px",
      WebkitBoxShadow: "0px 0px 3px 0px rgba(207, 207, 207, 1)",
      [theme.breakpoints.between(0, 1280)]: {
        width: "53%",
      },
      [theme.breakpoints.between(1280, 1550)]: {
        width: "61%",
      },
      '& canvas': {
        padding: "10px 20px 0 10px",
        width: "100% !important",
        height: "auto !important"
      }
    },
    charArea2: {
      width: "31.7%",
      [theme.breakpoints.between(0, 1280)]: {
        width: "43.7%",
      },
      [theme.breakpoints.between(1280, 1550)]: {
        width: "36.7%",
      },
      height: "320px",
      float: "left",
      margin: "0 0 20px 0",
      borderRadius: "8px",
      WebkitBoxShadow: "0px 0px 3px 0px rgba(207, 207, 207, 1)",
    },
    carriersLogo:{
      height: 30,
      width: 68
    },
    carriersLogoLarge:{
      height: 64,
      width: 227
    },
    tableHead: {
      '& tr': {
        backgroundColor: "#fff",
      },
      '& th': {
        backgroundColor: "#F5F5F5",
        "&:first-child": {
          borderRadius: "8px 0 0 8px"
        },
        "&:last-child": {
          borderRadius: "0 8px 8px 0"
        }
      },
    },
    tableContainer: {
      height: "100%",
      padding: "0px 20px 0px 20px",
    },
    tableSection: {
      width: '97%',
      marginLeft: "24px",
      height: "470px",
      float: "left",
      borderRadius: "8px",
      WebkitBoxShadow: "0px 0px 3px 0px rgba(207, 207, 207, 1)",
      '& table': {
        '& tr': {
          height: 59,
          "&:last-child": {
            '& th': {
              border: "none"
            },
            '& td': {
              border: "none"
            }
          },
          "&:hover": {
            backgroundColor: "#fff"
          }
        },
        '& th': {
          lineHeight: "normal",
          fontSize: 14
        },
        '& td': {
          fontWeight: "600",
          fontSize: 16,
          "&:first-child": {
            minWidth: 100
          },
          '& p': {
            height: "25px",
            paddingTop: "2px"
          }
        }
      }
    },
    tableHeading: {
      padding: "15px 0 15px 20px",
      fontSize: "18px",
      fontWeight: 600
    },
    MuiTableCellRoot: {
      border: "2px sold #000"
    },
    dashboardContainer: {
      //width: 1664
      width: "100%",
      minWidth: 1024
    },
    carrierName: {
      borderRadius: "15px",
      fontSize: "14px",
      color: "#fff",
      fontWeight: 600,
    },
    topInfoArea: {
      height: "100%",
      padding: "0px 20px 0px 20px",
      '& p': {
        fontSize: 12,
        lineHeight: "1"
      },
      '& table': {
        '& tr': {
          height: 56,
          '& td': {
            fontSize: 12,
            paddingBottom: 0,
            '& sup': {
              fontSize: 9
            }
          },
          "&:last-child": {
            '& td': {
              border: "none"
            }
          },
          "&:hover": {
            backgroundColor: "#fff"
          }
        }
      }
    },
    topOneTable: {
      height: "100%",
      padding: "0px 20px 0px 20px",
      '& table': {
        '& tr': {
          //height: 100,
          '& td': {
            fontSize: 14,
            paddingTop: 30,
            border: "none",
            '& p': {
              fontSize: 20
            }
          },
          "&:hover": {
            backgroundColor: "#fff"
          }
        }
      }
    },
    topOneTag: {
      fontSize: 9,
      padding: '0 0 0 20px'
    },
    smalltext: {
      fontSize: 10
    },
    noRecordContainer: {
      height: "100%",
      padding: "25% 0 0 0",
      textAlign: "center"
    },
    noRecord: {
      fontSize: 14,
      fontWeight: 500
    }
  })
);