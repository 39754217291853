const DashboardConstants = {
  DASHBOARD_CONSTANTS: {
    DASHBOARD_WRAPPER_DATA: {
      title: "Dashboard",
      FilterFormData: [
        {
          type: "dateRange",
          fieldName: "dateRange",
          key: `dateRange-filter-${Math.floor(Math.random() * 1000)}`,
          placeholderText: 'Delivery Date',
          label: 'Date Range',
          autoUpdateInput: false,
          autoApply: true,
          showDropdowns: true,
          linkedCalendars: true,
          maxSpan: {
            day: "6"
          },
          locale: {}
        },
        {
          type: "selectBox",
          fieldName: "carriers",
          multiple: true,
          key: `selectBox-carriers-${Math.floor(Math.random() * 1000)}`,
          label: "Carriers",
          items: []
        },
        {
          type: "selectBox",
          fieldName: "hubs",
          multiple: true,
          key: `selectBox-hub-${Math.floor(Math.random() * 1000)}`,
          label: "Hub",
          items: []
        },
        {
          type: "selectBox",
          fieldName: "deliveryProposition",
          multiple: true,
          key: `selectBox-proposition-${Math.floor(Math.random() * 1000)}`,
          label: "Delivery Proposition",
          items: []
        }
      ],
      defaultFiltersObj: {
        hubs: ["all"],
        carriers: ["all"],
        deliveryProposition: ["all"],
        dateRange: {
          startDate: new Date(),
          endDate: new Date()
        }
      },
      carrierColorMap: {
        "aramex": "#EE2628",
        "fetchr": "#E38710",
        "qpost": "#6F1D61",
        "jeebly": "#1EB0E6",
        "imile": "#F3BA40",
        "shipa": "#F48265",
        "oneclick": "#202323",
        "urbantz": "#00B6CE",
        "Seller Shipped": "#DAC6D7",
        "Supplier Delivery": "#CDCDE5",
        "trukker": "#1987CD",
        "wings": "#105AA7",
        "lyve": "#BB062D",
      },
      BAR_DISABLED_COLOR: "#E6E6E6",
      carrierStatues:[
        {label:"Carrier Assigned",field:"carrier_asigned"},
        {label:"Creation Failed",field:"creation_failed"},
        {label:"Picked up",field:"picked_up"},
        {label:"Dispatched",field:"dispatched"},
        {label:"Not Delivered - Reattempt",field:"not_delivered_reattempt"},
        {label:"Not Delivered - RTO",field:"not_delivered_rto"},
        {label:"Rejected - Carrier",field:"rejected_carrier"},
        {label:"Delivered",field:"delivered"},
        {label:"Lost in Transit - FWD",field:"lost_in_trannsit"},
        {label:"Partially Delivered",field:"partially_delivered"}
      ]
    },
    CHARTOPTIONS:{
      chart: {
        height: 275,
        type: 'column'
      },
      title: {
        text: null
      },
      xAxis: {
        categories: [],
        lineWidth: 1,
        lineColor: '#e0e0e0',
        gridLineWidth: 0,
        labels:{
          style: {
            fontSize: "14px"
          }
        }
      },
      yAxis: {
        min: 0,
        gridLineWidth: 0,
        title: {
          text: null
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            fontSize: "12px"
          }
        },
        labels: {
          enabled: false
        }
      },
      tooltip: {
        enabled: false
      },
      plotOptions: {
        series: {
          stacking: 'normal',
          pointWidth: 50,
          states: {
            hover: {
                enabled: false
            }
          }
        }
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false
      },
      series: [{
        showInLegend: false,
        data: [],
        borderRadiusTopLeft: '5px',
        borderRadiusTopRight: '5px',
      }]
    }
  }
}

export default DashboardConstants;