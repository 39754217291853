import React, { forwardRef, ReactElement, Ref, useCallback, useEffect, useState } from 'react';
import CustomDialog from '../common/Dialog';
import { useStyles } from './ExceptionsDeliveryEditDetails.styles';
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import { Button, Grid, Typography, Link, Accordion, AccordionSummary, AccordionDetails, SvgIcon } from '@material-ui/core';
import { ReactComponent as downArrowIcon } from './../assets/icons/down-arrow.svg';
import { COLORS } from '../config/theme/baseTheme';
import AppConstants from '../constants/index';
import { useFormik } from 'formik';
import TextBox from '../common/TextBox';
//import SelectBox from '../common/SelectBox';
import CustomTooltip from '../common/Tooltip';

interface ExceptionsDeliveryEditDetailsProps {
  open: boolean;
  consignment: any;
  closePopup: () => void;
  handleDialogAction?: (params: any) => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ExceptionsDeliveryEditDetails = (props: ExceptionsDeliveryEditDetailsProps) => {

  const classes = useStyles();
  const { open, consignment, closePopup, handleDialogAction } = props;
  const [expandedCustomerDetailsFlag, setExpandedCustomerDetailsFlag] = useState(true);
  const [expandedSellerDetailsFlag, setExpandedSellerDetailsFlag] = useState(true);
  //const [resetFormFlag, setResetFormFlag] = useState(false);

  let deliveryInformation: any = {};
  let metadata: any = {};
  let custInfo: any = {
    address: {}
  };
  let custGeoLocation: any = {
    name: "",
    location: {
      lat: 0,
      lng: 0
    }
  };
  let sellerInfo: any = {
    address: {}
  };
  let sellerGeoLocation: any = {
    name: "",
    location: {
      lat: 0,
      lng: 0
    }
  };
  // let posInfo: any = {
  //   address: {}
  // };
  let tableRecord: any = {};

  if (consignment) {
    deliveryInformation = consignment.deliveryInformation;
    metadata = consignment.metadata;
    custInfo = deliveryInformation && deliveryInformation.customerContactInfo;
    if (custInfo && custInfo.address) {
      custGeoLocation.name = custInfo.name;
      custGeoLocation.location.lat = parseFloat(custInfo.address.latitude) || 0;
      custGeoLocation.location.lng = parseFloat(custInfo.address.longitude) || 0;
    }
    sellerInfo = consignment.pickUpInformation && consignment.pickUpInformation.pickUpContactInfo;
    if (sellerInfo && sellerInfo.address) {
      sellerGeoLocation.name = sellerInfo.name;
      sellerGeoLocation.location.lat = parseFloat(sellerInfo.address.latitude) || 0;
      sellerGeoLocation.location.lng = parseFloat(sellerInfo.address.longitude) || 0;
    }
    //posInfo = consignment.posInformation && consignment.posInformation.contactInfo;
    tableRecord = consignment.tableRecord;
  }

  const dialogPaperProps = {
    classes: {
      root: classes.dialogPaperPropsRoot
    },
    square: true
  }

  const getStatusField = (fieldName: string, statusCode: string) => {
    const statuses: any = AppConstants.CONSIGNMENT_CONSTANTS.STATUSES;
    const statusName = statusCode && statusCode.toLowerCase().replace(/\s/gm, '_');
    return statusCode && statuses[statusName] ? statuses[statusName][fieldName] : "";
  }

  const handleTrackingLink = () => {
    window.open(deliveryInformation.carrierTrackingUrl, '_blank');
  }

  const getInitialValues = () => {
    return tableRecord ? {
      custName: custInfo.name ? custInfo.name : '',
      custCity: custInfo.address ? custInfo.address.city : '',
      custArea: custInfo.address ? custInfo.address.area : '',
      custStreetName: custInfo.address ? custInfo.address.streetName : '',
      custStreetNo: custInfo.address ? custInfo.address.streetNumber : '',
      custBuilding: custInfo.address ? custInfo.address.building : '',
      custApartmentNo: custInfo.address ? custInfo.address.apartment : '',
      custLandmark: custInfo.address ? custInfo.address.landmark : '',
      custPostalCode: custInfo.address ? custInfo.address.postalCode : '',
      sellerName: sellerInfo.companyName ? sellerInfo.companyName : '',
      sellerCity: sellerInfo.address ? sellerInfo.address.city : '',
      sellerArea: sellerInfo.address ? sellerInfo.address.area : '',
      sellerStreetName: sellerInfo.address ? sellerInfo.address.streetName : '',
      sellerStreetNo: sellerInfo.address ? sellerInfo.address.streetNumber : '',
      sellerBuilding: sellerInfo.address ? sellerInfo.address.building : '',
      sellerApartmentNo: sellerInfo.address ? sellerInfo.address.apartment : '',
      sellerLandmark: sellerInfo.address ? sellerInfo.address.landmark : '',
      sellerPostalCode: sellerInfo.address ? sellerInfo.address.postalCode : '',
    } : {
      custName: '',
      custCity: '',
      custArea: '',
      custStreetName: '',
      custBuilding: '',
      custApartmentNo: '',
      custLandmark: '',
      sellerName: '',
      sellerCity: '',
      sellerArea: '',
      sellerStreetName: '',
      sellerBuilding: '',
      sellerApartmentNo: '',
      sellerLandmark: '',
    } 
  }

  const inputFormik = useFormik({
    initialValues: getInitialValues(),
    validate: () => {
      //setResetFormFlag(false);
    },
    onSubmit: (values) => {
      dialogActionHandler('submit', values);
    }
  });

  const handleCustomerDetailsShowHide = () => () => {
    setExpandedCustomerDetailsFlag(expandedCustomerDetailsFlag ? false : true);
  };

  const handleSellerDetailsShowHide = () => () => {
    setExpandedSellerDetailsFlag(expandedSellerDetailsFlag ? false : true);
  };

  const getTitleContent = () => {
    return (
      <Grid className={classes.titleContainer} container>
        <Grid item>
          <Grid className="headingContainer" container>
            <Grid item>
              <Grid container>
                <Grid item>
                  <Typography className="titleHeading">
                    <span className="label"> CONSIGNMENT ID : </span>
                    <span className="content"> {consignment.consignmentCode} </span>
                  </Typography>
                </Grid>
                <CustomTooltip title={getStatusField('hintText', metadata && metadata.displayStatus)}>
                  <Grid className="tagContainer" item>
                    <Typography className={['tag', getStatusField('color', metadata && metadata.displayStatus)].join(" ")}>{metadata ? metadata.displayStatus ? metadata.displayStatus : metadata.status : ""}</Typography>
                  </Grid>
                </CustomTooltip>
              </Grid>
            </Grid>
            {
              (deliveryInformation && deliveryInformation.carrierTrackingId) || consignment.orderReference ? (
                <Grid item>
                  {
                    deliveryInformation && deliveryInformation.carrierTrackingUrl ?
                      <Link className="titleContent link" onClick={handleTrackingLink}>{`Track Consignment (#${deliveryInformation && deliveryInformation.carrierTrackingId ? deliveryInformation.carrierTrackingId : consignment.orderReference ? consignment.orderReference : ""})`}</Link>
                      :
                      <Typography className="titleContent">{`Track Consignment (#${deliveryInformation && deliveryInformation.carrierTrackingId ? deliveryInformation.carrierTrackingId : consignment.orderReference ? consignment.orderReference : ""})`}</Typography>
                  }
                </Grid>
              ) : ""
            }
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const getDetailsContent = () => {
    return (
      <form onSubmit={inputFormik.handleSubmit}>
        <Grid className={classes.contentContainer} container>

          <Grid className="customerEditDetails"  item>
            <Accordion expanded={expandedCustomerDetailsFlag} onChange={handleCustomerDetailsShowHide()}>
              <AccordionSummary
                expandIcon={<SvgIcon component={downArrowIcon} htmlColor={COLORS.COPY} />}
                aria-controls="consignment-history-content"
                id="consignment-history-header"
                className={classes.accordionSummary}
              >
                <Typography className="header">Customer Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Typography component="div" className="content">
                <Grid className="itemsContainer" container direction="column">
                  
                  <Grid className="item" item>
                    <Grid className="itemContainer header" container>
                      <Grid className="itemColumn item1" item xs={12}>
                        <Grid className="itemLabel" item>
                          <Typography>Customer Name</Typography>
                        </Grid>
                        <Grid className="itemInput" item>
                          <TextBox
                            fullWidth
                            variant="outlined"
                            handleChange={inputFormik.handleChange}
                            value={inputFormik.values.custName}
                            error={inputFormik.touched.custName && Boolean(inputFormik.errors.custName)}
                            helperText={inputFormik.touched.custName && inputFormik.errors.custName}
                            textBoxId="custName"
                            name="custName"
                            type="text"
                            disabled={true}
                            placeholderText="Customer Name"
                          ></TextBox>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid className="item" item>
                    <Grid className="itemContainer header" container>
                      <Grid className="itemColumn item1" item xs={12}>
                        <Grid className="itemLabel" item>
                          <Typography>City</Typography>
                        </Grid>
                        <Grid className="itemInput" item>
                          <TextBox
                            fullWidth
                            variant="outlined"
                            handleChange={inputFormik.handleChange}
                            value={inputFormik.values.custCity}
                            error={inputFormik.touched.custCity && Boolean(inputFormik.errors.custCity)}
                            helperText={inputFormik.touched.custCity && inputFormik.errors.custCity}
                            textBoxId="custCity"
                            name="custCity"
                            type="text"
                            placeholderText="City"
                          ></TextBox>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid className="item" item>
                    <Grid className="itemContainer header" container>
                      <Grid className="itemColumn item1" item xs={12}>
                        <Grid className="itemLabel" item>
                          <Typography>Area</Typography>
                        </Grid>
                        <Grid className="itemInput" item>
                          <TextBox
                            fullWidth
                            variant="outlined"
                            handleChange={inputFormik.handleChange}
                            value={inputFormik.values.custArea}
                            error={inputFormik.touched.custArea && Boolean(inputFormik.errors.custArea)}
                            helperText={inputFormik.touched.custArea && inputFormik.errors.custArea}
                            textBoxId="custArea"
                            name="custArea"
                            type="text"
                            placeholderText="Area"
                          ></TextBox>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid className="item" item>
                    <Grid className="itemContainer header" container>
                      <Grid className="itemColumn item1" item xs={12}>
                        <Grid className="itemLabel" item>
                          <Typography>Street Name</Typography>
                        </Grid>
                        <Grid className="itemInput" item>
                          <TextBox
                            fullWidth
                            variant="outlined"
                            handleChange={inputFormik.handleChange}
                            value={inputFormik.values.custStreetName}
                            error={inputFormik.touched.custStreetName && Boolean(inputFormik.errors.custStreetName)}
                            helperText={inputFormik.touched.custStreetName && inputFormik.errors.custStreetName}
                            textBoxId="custStreetName"
                            name="custStreetName"
                            type="text"
                            placeholderText="Street Name"
                          ></TextBox>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid className="item" item>
                    <Grid className="itemContainer header" container>
                      <Grid className="itemColumn item1" item xs={12}>
                        <Grid className="itemLabel" item>
                          <Typography>Street No.</Typography>
                        </Grid>
                        <Grid className="itemInput" item>
                          <TextBox
                            fullWidth
                            variant="outlined"
                            handleChange={inputFormik.handleChange}
                            value={inputFormik.values.custStreetNo}
                            error={inputFormik.touched.custStreetNo && Boolean(inputFormik.errors.custStreetNo)}
                            helperText={inputFormik.touched.custStreetNo && inputFormik.errors.custStreetNo}
                            textBoxId="custStreetNo"
                            name="custStreetNo"
                            type="text"
                            placeholderText="Street No."
                          ></TextBox>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid className="item" item>
                    <Grid className="itemContainer header" container>
                      <Grid className="itemColumn item1" item xs={12}>
                        <Grid className="itemLabel" item>
                          <Typography>Building Name/Villa No.</Typography>
                        </Grid>
                        <Grid className="itemInput" item>
                          <TextBox
                            fullWidth
                            variant="outlined"
                            handleChange={inputFormik.handleChange}
                            value={inputFormik.values.custBuilding}
                            error={inputFormik.touched.custBuilding && Boolean(inputFormik.errors.custBuilding)}
                            helperText={inputFormik.touched.custBuilding && inputFormik.errors.custBuilding}
                            textBoxId="custBuilding"
                            name="custBuilding"
                            type="text"
                            placeholderText="Building Name/Villa No."
                          ></TextBox>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid className="item" item>
                    <Grid className="itemContainer header" container>
                      <Grid className="itemColumn item1" item xs={12}>
                        <Grid className="itemLabel" item>
                          <Typography>Floor No./Apartment No.</Typography>
                        </Grid>
                        <Grid className="itemInput" item>
                          <TextBox
                            fullWidth
                            variant="outlined"
                            handleChange={inputFormik.handleChange}
                            value={inputFormik.values.custApartmentNo}
                            error={inputFormik.touched.custApartmentNo && Boolean(inputFormik.errors.custApartmentNo)}
                            helperText={inputFormik.touched.custApartmentNo && inputFormik.errors.custApartmentNo}
                            textBoxId="custApartmentNo"
                            name="custApartmentNo"
                            type="text"
                            placeholderText="Floor No./Apartment No."
                          ></TextBox>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid className="item" item>
                    <Grid className="itemContainer header" container>
                      <Grid className="itemColumn item1" item xs={12}>
                        <Grid className="itemLabel" item>
                          <Typography>Postal Code</Typography>
                        </Grid>
                        <Grid className="itemInput" item>
                          <TextBox
                            fullWidth
                            variant="outlined"
                            handleChange={inputFormik.handleChange}
                            value={inputFormik.values.custPostalCode}
                            error={inputFormik.touched.custPostalCode && Boolean(inputFormik.errors.custPostalCode)}
                            helperText={inputFormik.touched.custPostalCode && inputFormik.errors.custPostalCode}
                            textBoxId="custPostalCode"
                            name="custPostalCode"
                            type="text"
                            placeholderText="Postal Code"
                          ></TextBox>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid className="item" item>
                    <Grid className="itemContainer header" container>
                      <Grid className="itemColumn item1" item xs={12}>
                        <Grid className="itemLabel" item>
                          <Typography>Landmark</Typography>
                        </Grid>
                        <Grid className="itemInput" item>
                          <TextBox
                            fullWidth
                            variant="outlined"
                            handleChange={inputFormik.handleChange}
                            value={inputFormik.values.custLandmark}
                            error={inputFormik.touched.custLandmark && Boolean(inputFormik.errors.custLandmark)}
                            helperText={inputFormik.touched.custLandmark && inputFormik.errors.custLandmark}
                            textBoxId="custLandmark"
                            name="custLandmark"
                            type="text"
                            placeholderText="Landmark"
                          ></TextBox>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
              </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid className="sellerEditDetails" item>
            <Accordion expanded={expandedSellerDetailsFlag} onChange={handleSellerDetailsShowHide()}>
              <AccordionSummary
                expandIcon={<SvgIcon component={downArrowIcon} htmlColor={COLORS.COPY} />}
                aria-controls="consignment-history-content"
                id="consignment-history-header"
                className={classes.accordionSummary}
              >
                <Typography className="header">Seller Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Typography component="div" className="content">
                <Grid className="itemsContainer" container direction="column">
                  
                <Grid className="item" item>
                    <Grid className="itemContainer header" container>
                      <Grid className="itemColumn item1" item xs={12}>
                        <Grid className="itemLabel" item>
                          <Typography>Store Name</Typography>
                        </Grid>
                        <Grid className="itemInput" item>
                          <TextBox
                            fullWidth
                            variant="outlined"
                            handleChange={inputFormik.handleChange}
                            value={inputFormik.values.sellerName}
                            error={inputFormik.touched.sellerName && Boolean(inputFormik.errors.sellerName)}
                            helperText={inputFormik.touched.sellerName && inputFormik.errors.sellerName}
                            textBoxId="sellerName"
                            name="sellerName"
                            type="text"
                            disabled={true}
                            placeholderText="Store Name"
                          ></TextBox>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid className="item" item>
                    <Grid className="itemContainer header" container>
                      <Grid className="itemColumn item1" item xs={12}>
                        <Grid className="itemLabel" item>
                          <Typography>City</Typography>
                        </Grid>
                        <Grid className="itemInput" item>
                          <TextBox
                            fullWidth
                            variant="outlined"
                            handleChange={inputFormik.handleChange}
                            value={inputFormik.values.sellerCity}
                            error={inputFormik.touched.sellerCity && Boolean(inputFormik.errors.sellerCity)}
                            helperText={inputFormik.touched.sellerCity && inputFormik.errors.sellerCity}
                            textBoxId="sellerCity"
                            name="sellerCity"
                            type="text"
                            placeholderText="City"
                          ></TextBox>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid className="item" item>
                    <Grid className="itemContainer header" container>
                      <Grid className="itemColumn item1" item xs={12}>
                        <Grid className="itemLabel" item>
                          <Typography>Area</Typography>
                        </Grid>
                        <Grid className="itemInput" item>
                          <TextBox
                            fullWidth
                            variant="outlined"
                            handleChange={inputFormik.handleChange}
                            value={inputFormik.values.sellerArea}
                            error={inputFormik.touched.sellerArea && Boolean(inputFormik.errors.sellerArea)}
                            helperText={inputFormik.touched.sellerArea && inputFormik.errors.sellerArea}
                            textBoxId="sellerArea"
                            name="sellerArea"
                            type="text"
                            placeholderText="Area"
                          ></TextBox>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid className="item" item>
                    <Grid className="itemContainer header" container>
                      <Grid className="itemColumn item1" item xs={12}>
                        <Grid className="itemLabel" item>
                          <Typography>Street Name</Typography>
                        </Grid>
                        <Grid className="itemInput" item>
                          <TextBox
                            fullWidth
                            variant="outlined"
                            handleChange={inputFormik.handleChange}
                            value={inputFormik.values.sellerStreetName}
                            error={inputFormik.touched.sellerStreetName && Boolean(inputFormik.errors.sellerStreetName)}
                            helperText={inputFormik.touched.sellerStreetName && inputFormik.errors.sellerStreetName}
                            textBoxId="sellerStreetName"
                            name="sellerStreetName"
                            type="text"
                            placeholderText="Street Name"
                          ></TextBox>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid className="item" item>
                    <Grid className="itemContainer header" container>
                      <Grid className="itemColumn item1" item xs={12}>
                        <Grid className="itemLabel" item>
                          <Typography>Street No.</Typography>
                        </Grid>
                        <Grid className="itemInput" item>
                          <TextBox
                            fullWidth
                            variant="outlined"
                            handleChange={inputFormik.handleChange}
                            value={inputFormik.values.sellerStreetNo}
                            error={inputFormik.touched.sellerStreetNo && Boolean(inputFormik.errors.sellerStreetNo)}
                            helperText={inputFormik.touched.sellerStreetNo && inputFormik.errors.sellerStreetNo}
                            textBoxId="sellerStreetNo"
                            name="sellerStreetNo"
                            type="text"
                            placeholderText="Street Name"
                          ></TextBox>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid className="item" item>
                    <Grid className="itemContainer header" container>
                      <Grid className="itemColumn item1" item xs={12}>
                        <Grid className="itemLabel" item>
                          <Typography>Building Name/Villa No.</Typography>
                        </Grid>
                        <Grid className="itemInput" item>
                          <TextBox
                            fullWidth
                            variant="outlined"
                            handleChange={inputFormik.handleChange}
                            value={inputFormik.values.sellerBuilding}
                            error={inputFormik.touched.sellerBuilding && Boolean(inputFormik.errors.sellerBuilding)}
                            helperText={inputFormik.touched.sellerBuilding && inputFormik.errors.sellerBuilding}
                            textBoxId="sellerBuilding"
                            name="sellerBuilding"
                            type="text"
                            placeholderText="Building Name/Villa No."
                          ></TextBox>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid className="item" item>
                    <Grid className="itemContainer header" container>
                      <Grid className="itemColumn item1" item xs={12}>
                        <Grid className="itemLabel" item>
                          <Typography>Floor No./Apartment No.</Typography>
                        </Grid>
                        <Grid className="itemInput" item>
                          <TextBox
                            fullWidth
                            variant="outlined"
                            handleChange={inputFormik.handleChange}
                            value={inputFormik.values.sellerApartmentNo}
                            error={inputFormik.touched.sellerApartmentNo && Boolean(inputFormik.errors.sellerApartmentNo)}
                            helperText={inputFormik.touched.sellerApartmentNo && inputFormik.errors.sellerApartmentNo}
                            textBoxId="sellerApartmentNo"
                            name="sellerApartmentNo"
                            type="text"
                            placeholderText="Floor No./Apartment No."
                          ></TextBox>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid className="item" item>
                    <Grid className="itemContainer header" container>
                      <Grid className="itemColumn item1" item xs={12}>
                        <Grid className="itemLabel" item>
                          <Typography>Postal Code</Typography>
                        </Grid>
                        <Grid className="itemInput" item>
                          <TextBox
                            fullWidth
                            variant="outlined"
                            handleChange={inputFormik.handleChange}
                            value={inputFormik.values.sellerPostalCode}
                            error={inputFormik.touched.sellerPostalCode && Boolean(inputFormik.errors.sellerPostalCode)}
                            helperText={inputFormik.touched.sellerPostalCode && inputFormik.errors.sellerPostalCode}
                            textBoxId="sellerPostalCode"
                            name="sellerPostalCode"
                            type="text"
                            placeholderText="Postal Code"
                          ></TextBox>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid className="item" item>
                    <Grid className="itemContainer header" container>
                      <Grid className="itemColumn item1" item xs={12}>
                        <Grid className="itemLabel" item>
                          <Typography>Landmark</Typography>
                        </Grid>
                        <Grid className="itemInput" item>
                          <TextBox
                            fullWidth
                            variant="outlined"
                            handleChange={inputFormik.handleChange}
                            value={inputFormik.values.sellerLandmark}
                            error={inputFormik.touched.sellerLandmark && Boolean(inputFormik.errors.sellerLandmark)}
                            helperText={inputFormik.touched.sellerLandmark && inputFormik.errors.sellerLandmark}
                            textBoxId="sellerLandmark"
                            name="sellerLandmark"
                            type="text"
                            placeholderText="Landmark"
                          ></TextBox>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
              </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>

        </Grid>
      </form>
    )
  }

  const handleDialogClose = useCallback(
    () => {
      closePopup();
    },
    [closePopup],
  );

  const dialogActionHandler = useCallback(
    (type: string, values?: any) => {
      handleDialogAction && handleDialogAction({
        type: type,
        values: values
      });
    },
    [handleDialogAction],
  )

  const getDialogActions = () => {
    return (
      <form className={classes.buttons} onSubmit={inputFormik.handleSubmit}>
        <Grid className="buttonsContainer" container>
          <Button className="userBtn secondary" variant="contained" onClick={() => { dialogActionHandler('cancel') }}>{AppConstants.BUTTONS.CANCEL}</Button>
          <Button className="userBtn primary" variant="contained" type="submit">{AppConstants.BUTTONS.UPDATE}</Button>
        </Grid>
      </form>
    );
  };

  useEffect(() => {
    if (open) {
      inputFormik.resetForm();
      //setResetFormFlag(true);
      inputFormik.setValues(getInitialValues());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <CustomDialog
      open={open}
      TransitionComponent={Transition}
      PaperProps={dialogPaperProps}
      title={getTitleContent()}
      content={getDetailsContent()}
      actions={getDialogActions()}
      handleClose={handleDialogClose}
    ></CustomDialog>
  )
}

export default ExceptionsDeliveryEditDetails;