import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: 500,
      marginBottom: 20,
      '&:last-child': {
        marginBottom: 0
      },
      '& .descLabel': {
        fontWeight: 500
      },
      '& .descContent': {
        marginLeft: 5
      },
    },
    logoSection: {
      height: 60,
      border: `1px solid ${theme.palette.primary.main}`,
      '& .logo': {
        height: 58,
        padding: 10
      }
    },
    fromSection: {
      display: "flex",
      flexDirection: "column",
      height: 175,
      padding: 10,
      border: `1px solid ${theme.palette.primary.main}`,
    },
    heading: {
      fontSize: '1.3rem',
      fontWeight: 900,
      marginBottom: 2
    },
    address: {
      '& .addHeading,.addContent,.addFooter': {
        fontSize: '16px',
        lineHeight: 1.2,
        overflow: "hidden",
        textOverflow: "ellipsis"
      }
    },
    phnContainer: {
      marginTop: "auto",
      '& img': {
        width: 25
      },
      '& span': {
        fontSize: '1.2rem',
        padding: '4px 0 0 5px'
      }
    },
    barcode1Section: {
      border: `1px solid ${theme.palette.primary.main}`,
      '& img': {
        maxWidth: 220,
        height: 110,
        transform: 'rotate(-90deg) translate(-62px, -50px)',
      }
    },
    toSection: {
      display: "flex",
      flexDirection: "column",
      height: 202,
      padding: 10,
      border: `1px solid ${theme.palette.primary.main}`,
      "& .custName": {
        fontSize: '16px'
      }
    },
    descSection: {
      display: "flex",
      flexDirection: "column",
      padding: 10,
      height: 140,
      border: `1px solid ${theme.palette.primary.main}`,
      '& .descItem': {
        margin: '5px 0px',
        '& .item': {
          marginLeft: "auto",
          marginRight: "auto"
        },
        '&:last-child': {
          margin: 0,
          marginTop: "auto"
        }
      }
    },
    modeSection: {
      display: "flex",
      flexDirection: "column",
      padding: 10,
      border: `1px solid ${theme.palette.primary.main}`,
      "& .mode": {
        margin: 'auto 0'
      },
      "& .modeText": {
        fontSize: "1.7rem",
        fontWeight: 600,
        textTransform: "uppercase",
        textAlign: "center"
      },
      "& .amtContainer": {
        textAlign: "center",
        fontSize: 14
      }
    },
    barcode2Section: {
      display: "flex",
      flexDirection: "column",
      height: 170,
      padding: 10,
      border: `1px solid ${theme.palette.primary.main}`,
      "& img": {
        margin: "0 50px"
      },
      '& .carrierContainer': {
        marginLeft: "auto"
      },
      "& .extraInfoContainer":{
        textAlign: "center",
        fontWeight: 500,
        fontSize: '1.3rem'
      }
    },
    extraInfo:{
      height: '200px !important',
    }
  })
);