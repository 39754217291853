const LoginConstants = {
  LOGIN_CONSTANTS: {
    TYPES: [
      {
        name: "Staff",
        value: "Staff"
      },
      {
        name: "Outsourced",
        value: "Outsourced"
      }
    ],
    INCORRECT_UNAME_PWD_ERROR_MSG: "Incorrect Username or Password. Please try again",
    PWD_CHANGE_SUCCESS_MSG: "We have sent a reset password link to your email. Please check.",
    USER_CREATION_SUCCESS_MSG: "Account is created successfully , please check your email for account verification",
    USER_REQUEST_SUBMITTED: "Your request has been submitted successfully.",
    RESPONSE_CONSTANTS: {
      ERROR_CODES: {
        CONFIRM_PWD: "UM102",
        ACCOUNT_DISABLED: "UM103",
        INVALID_UNAME: "UM104",
        PWD_EXP: "UM105",
        INVALID_PWD: "UM106",
        PWD_MISMATCH: "UM107"
      }
    }
  }
}

export default LoginConstants;