import { Grid, Typography, Tabs, Tab, Divider } from '@material-ui/core';
import React from 'react';
import { useStyles } from './ExceptionsReturns.styles';
import { Link as RouterLink } from 'react-router-dom';
import AppConstants from "../constants";

const ExceptionsReturns = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid className={classes.container} container>
                <Grid className="planningItem heading" item>
                    <Typography>Exceptions</Typography>
                </Grid>
                <Grid className="planningItem content" item>
                    <Tabs className="exceptionsRoutes" value='Returns' aria-label="Navigation Tabs">
                        <Tab label="Delivery" value='Delivery' component={RouterLink} to={AppConstants.ROUTES.EXCEPTIONS_DELIVERIES} />
                        <Tab label="Returns" value='Returns' component={RouterLink} to={AppConstants.ROUTES.EXCEPTIONS_RETURNS} />
                    </Tabs>
                    <Divider className="tabDivider"/>
                    <Grid container className={classes.tabPanelContainer}>
                        <Grid item className="panelItem chipFilter">
                            <Grid className={classes.root} container>
                                <Grid className={classes.comingSoonMain}>
                                    <Typography variant="h3" component="h3">COMING SOON</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>

    );
}

export default ExceptionsReturns;