import { makeStyles, createStyles } from '@material-ui/core/styles';
import { COLORS } from './../../config/theme/baseTheme';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: "absolute",
      top: "90%",
      left: 0,
      right: 0,
      bottom: 0,
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 1200,
      backgroundColor: COLORS.LOADER_BACKGROUND
    },
    notLoggedIn: {
      top: 0,
      zIndex: 9999,
    }
  })
)