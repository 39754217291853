import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import networkHandler from "../../network/networkHandler";
import { EApiMiddlewareMethods } from "../../network/networkHandler.types";
import { repushOrderAuthToken } from "../../config/Api.config";
import { saveFile, transformSelectValues, transformSelectValuesWithCode } from "../../utils/helpers.utils";
import { ExceptionsDeliveryState } from "./exceptionsDeliverySlice.types";
import AppConstants from "../../constants";

export const fetchExceptionsDelivery = createAsyncThunk(
  "ExceptionsDelivery/FetchByCountry",
  async (
    {
      requestContinuationToken,
      page,
      searchBy,
      searchValue,
    }: {
      requestContinuationToken?: string;
      page: number;
      searchBy: string;
      searchValue: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `orders/${searchBy}/${searchValue}?page=${page}`,
        method: EApiMiddlewareMethods.GET,
      } as any;

      if (requestContinuationToken && page) {
        request.headers = {
          "x-requestcontinuation-token": requestContinuationToken,
        };
      }
      const { data } = await networkHandler(request, false, true);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchFiltersDropdownValues = createAsyncThunk(
  "ExceptionsDelivery/FetchFiltersDropdownValues",
  async (params, { rejectWithValue }) => {
    try {
      const request = {
        url: `filters/filter/all/ORDER`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, true);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchExceptionsDeliveryByFilter = createAsyncThunk(
  "ExceptionsDelivery/FetchByFilter",
  async (
    {
      filters
    }: {
      filters: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'exception/all',
        method: EApiMiddlewareMethods.POST,
        data: filters
      } as any;
      const { data } = await networkHandler(request, false, true);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchExceptionsDeliveryByCount = createAsyncThunk(
  "ExceptionsDelivery/FetchByCount",
  async (
    {
      filters,
    }: {
      filters: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'exception/count',
        method: EApiMiddlewareMethods.POST,
        data: filters
      } as any;
      const { data } = await networkHandler(request, false, true);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateExportToExcelPath = createAsyncThunk(
  "ExceptionsDelivery/ExportToExcel",
  async (
    {
      payload,
    }: {
      payload: object;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'export/exceptions/excel',
        method: EApiMiddlewareMethods.POST,
        data: payload,
        responseType: "blob"
      } as any;
      const { data } = await networkHandler(request, false, true);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const clearList = createAsyncThunk("ExceptionsDelivery/ClearContents", () => {
  return [];
});

export const resetExceptionsDeliveryError = createAsyncThunk("ExceptionsDelivery/ResetError", () => {
  return "";
});

export const resetBulkRepushOrder = createAsyncThunk("ExceptionsDelivery/resetBulkRepushOrder", () => {
  return "";
});

export const repushBulkOrder = createAsyncThunk(
  "ExceptionsDelivery/repushBulkOrder",
  async (
    {
      payload
    }: {
      payload: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `repush/orders`,
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      request.headers = {
        'Authorization': repushOrderAuthToken,
      }
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const resetTableSelection = createAsyncThunk(
  "ExceptionsDelivery/ResetTableSelection", 
  async (
    {
      tableSelection
    }: {
      tableSelection: any;
    }
  ) => {
    return tableSelection;
});

export const resetFilterOrderBySearch = createAsyncThunk("ExceptionsDelivery/resetFilterOrderBySearch", () => {
  return false;
});

export const resetRepushEnabledFlag = createAsyncThunk("ExceptionsDelivery/resetRepushEnabledFlag", (isEnabled: boolean) => {
  return isEnabled;
});

export const saveColumnSettings = createAsyncThunk("ExceptionsDelivery/SaveColumnSettings", (settingsInfo: any) => {
  return settingsInfo;
});

export const updateColumnSettings = createAsyncThunk("ExceptionsDelivery/UpdateColumnSettings", (settingsInfo: any) => {
  return settingsInfo;
});

export const resetViewCreationStatus = createAsyncThunk("ExceptionsDelivery/ResetViewCreationStatus", () => {
  return false;
});

export const resetViewUpdationStatus = createAsyncThunk("ExceptionsDelivery/ResetViewUpdationStatus", () => {
  return false;
});

const initialColumnList = () => {
  let deliveriesSavedViews = localStorage.getItem('EceptionsDeliverySavedViews') ? JSON.parse(localStorage.getItem('EceptionsDeliverySavedViews') || "[]") : [];
  if(!deliveriesSavedViews.length){
    let saveView = {viewName : "Default View", colList: AppConstants.EXCEPTION_DELIVERY_CONSTANTS.TABLE_WRAPPER_DATA.HeaderData, isActive: true}
    deliveriesSavedViews.push(saveView);
    localStorage.setItem('EceptionsDeliverySavedViews', JSON.stringify(deliveriesSavedViews));
  }
  deliveriesSavedViews = localStorage.getItem('EceptionsDeliverySavedViews') ? JSON.parse(localStorage.getItem('EceptionsDeliverySavedViews') || "[]") : [];
  let activeViewObj = deliveriesSavedViews.findIndex(((obj: any) => obj.isActive === true));
  const activeView = deliveriesSavedViews[activeViewObj];
  return activeView ? activeView.colList : AppConstants.EXCEPTION_DELIVERY_CONSTANTS.TABLE_WRAPPER_DATA.HeaderData;
}

const deliveriesSavedViews = () => {
  return localStorage.getItem('EceptionsDeliverySavedViews') ? JSON.parse(localStorage.getItem('EceptionsDeliverySavedViews') || "[]") : [];
}

export const fetchInitialColumnList = createAsyncThunk("ExceptionsDelivery/FetchInitialColumnList", () => {
  let deliveriesSavedViews = localStorage.getItem('EceptionsDeliverySavedViews') ? JSON.parse(localStorage.getItem('EceptionsDeliverySavedViews') || "[]") : [];
  if(!deliveriesSavedViews.length){
    let saveView = {viewName : "Default View", colList: AppConstants.EXCEPTION_DELIVERY_CONSTANTS.TABLE_WRAPPER_DATA.HeaderData, isActive: true}
    deliveriesSavedViews.push(saveView);
    localStorage.setItem('EceptionsDeliverySavedViews', JSON.stringify(deliveriesSavedViews));
  }
  deliveriesSavedViews = localStorage.getItem('EceptionsDeliverySavedViews') ? JSON.parse(localStorage.getItem('EceptionsDeliverySavedViews') || "[]") : [];
  let activeViewObj = deliveriesSavedViews.findIndex(((obj: any) => obj.isActive === true));
  const activeView = deliveriesSavedViews[activeViewObj];
  return activeView ? activeView.colList : AppConstants.EXCEPTION_DELIVERY_CONSTANTS.TABLE_WRAPPER_DATA.HeaderData;
});

export const fetchDeliveriesSavedViews = createAsyncThunk("ExceptionsDelivery/FetchDeliveriesSavedViews", () => {
  return localStorage.getItem('EceptionsDeliverySavedViews') ? JSON.parse(localStorage.getItem('EceptionsDeliverySavedViews') || "[]") : [];
});

const initialState: ExceptionsDeliveryState = {
  loading: true,
  tableLoading: true,
  filtersDropdowns: {
    countriesList: [],
    hubList: [],
    carrierList: [],
    statusList: [],
    delPropositionList: []
  },
  // chipsCount: {
  //   totalException: 0,
  //   creationFailed: 0,
  //   pickupCreationFailed: 0,
  //   updationFailed: 0,
  //   cancellationFailed: 0,
  //   statusDelay: {}
  // },
  chipsCount: {
    deliveryChipsCount: {
      totalException: 0,
      creationFailed: 0,
      pickupCreationFailed: 0,
      updationFailed: 0,
      cancellationFailed: 0,
      statusDelay: 0
    }
  },
  exportToExcelPath: "",
  data: {
    content: [],
    pageNumber: 0,
    totalElements: 0
  },
  columnList: initialColumnList(),
  favoriteViews: deliveriesSavedViews(),
  error: "",
  bulkRepushFlag: false,
  bulkRepushMsg: "",
  tableSelectionReset: false,
  filterOrderBySearch: false,
  viewUpdateStatus: false,
  viewCreationStatus: false,
  viewCreationMsg: "",
  viewUpdateMsg: "",
  repushEnabled: false,
};

const exceptionsDeliverySlice = createSlice({
  name: "OrderList",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(fetchExceptionsDelivery.pending, (state) => {
        state.tableLoading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchExceptionsDelivery.fulfilled, (state, action) => {
        const {
          payload,
          meta: {
            arg: { page, searchValue, searchBy },
          },
        } = action;
        state.tableLoading = false;
        state.filterOrderBySearch = true;
        state.errorCode = "";
        state.error = "";
        state.data = {
          ...payload,
          totalElements: payload.totalElements,
          content:
            page === 0
              ? [...payload.elements]
              : [...state.data.content, ...payload.elements],
        };
        state.prevCountry =
          searchBy === "country" ? searchValue : state.prevCountry;
      })
      .addCase(fetchExceptionsDelivery.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.tableLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchFiltersDropdownValues.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchFiltersDropdownValues.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        let hubList: any = {};
        let carrierList: any = {};
        let statusList: any = {};
        let delPropositionList: any = {};
        if (payload.hubMap) {
          Object.keys(payload.hubMap).filter((country: any) => {
            hubList[country] = transformSelectValuesWithCode(payload.hubMap[country]);
            return true;
          });
        }
        if (payload.carrierMap) {
          Object.keys(payload.carrierMap).filter((country: any) => {
            carrierList[country] = transformSelectValues(payload.carrierMap[country]);
            return true;
          });
        }
        if (payload.statusMap) {
          Object.keys(payload.statusMap).filter((country: any) => {
            statusList[country] = transformSelectValues(payload.statusMap[country]);
            return true;
          });
        }
        if (payload.deliveryPropositionMap) {
          Object.keys(payload.deliveryPropositionMap).filter((country: any) => {
            delPropositionList[country] = transformSelectValues(payload.deliveryPropositionMap[country]);
            return true;
          });
        }
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.filtersDropdowns = {
          countriesList: transformSelectValues(payload.countries),
          hubList: hubList,
          carrierList: carrierList,
          statusList: statusList,
          delPropositionList: delPropositionList
        };
      })
      .addCase(fetchFiltersDropdownValues.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(clearList.fulfilled, (state, action) => {
        const { payload } = action;
        state.tableLoading = false;
        state.errorCode = "";
        state.error = "";
        state.data = {
          ...payload,
          content: action.payload,
        };
      })
      .addCase(resetExceptionsDeliveryError.fulfilled, (state, action) => {
        const { payload } = action;
        state.errorCode = payload;
        state.error = payload;
      })
      .addCase(resetTableSelection.fulfilled, (state, action) => {
        const { payload } = action;
        state.tableSelectionReset = payload ? true : false;
      })
      .addCase(fetchExceptionsDeliveryByFilter.pending, (state) => {
        state.tableLoading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchExceptionsDeliveryByFilter.fulfilled, (state, action) => {
        const {
          payload,
          meta: {
            arg: { filters }
          }
        } = action;
        state.tableLoading = false;
        state.filterOrderBySearch = false;
        state.errorCode = "";
        state.error = "";
        state.data = {
          ...payload,
          totalElements: payload.totalElements,
          content:
            filters.page === 0
              ? [...payload.elements]
              : [...state.data.content, ...payload.elements],
        };
      })
      .addCase(fetchExceptionsDeliveryByFilter.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.tableLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchExceptionsDeliveryByCount.pending, (state) => {
        state.tableLoading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchExceptionsDeliveryByCount.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.tableLoading = false;
        state.filterOrderBySearch = false;
        state.errorCode = "";
        state.error = "";
        state.chipsCount.deliveryChipsCount = payload;
      })
      .addCase(fetchExceptionsDeliveryByCount.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.tableLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(updateExportToExcelPath.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(updateExportToExcelPath.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        if (payload) {
          saveFile(payload, AppConstants.EXCEPTION_DELIVERY_CONSTANTS.EXCEL_FILE_NAME);
        }
      })
      .addCase(updateExportToExcelPath.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(repushBulkOrder.pending, (state) => {
        state.bulkRepushFlag = false;
        state.bulkRepushMsg = "";
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(repushBulkOrder.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        if (!payload) {
          state.errorCode = "";
          state.error = AppConstants.EXCEPTION_DELIVERY_CONSTANTS.REPUSHED_ORDER_CONTENT_NOT_FOUND;
        }else if (payload.error) {
          state.errorCode = payload.error || AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
          state.error = payload
            ? payload.message
            : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        } else {
          state.errorCode = "";
          state.error = "";
          state.bulkRepushFlag = true;
          state.bulkRepushMsg = AppConstants.EXCEPTION_DELIVERY_CONSTANTS.BULK_ORDER_REPUSHED_MSG;
        }
      })
      .addCase(repushBulkOrder.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.bulkRepushFlag = false;
        state.bulkRepushMsg = "";
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(resetBulkRepushOrder.fulfilled, (state, action) => {
        state.bulkRepushFlag = false;
        state.bulkRepushMsg = "";
      })
      .addCase(resetFilterOrderBySearch.fulfilled, (state, action) => {
        const { payload } = action;
        state.filterOrderBySearch = payload;
      })
      .addCase(resetRepushEnabledFlag.fulfilled, (state, action) => {
        const { payload } = action;
        state.repushEnabled = payload;
      })
      .addCase(saveColumnSettings.fulfilled, (state, action) => {
        const { payload } = action;
        let saveView = {viewName : payload.viewName, colList: payload.itemList, isActive: true}
        const deliveriesSavedViews = localStorage.getItem('EceptionsDeliverySavedViews') ? JSON.parse(localStorage.getItem('EceptionsDeliverySavedViews') || "[]") : [];
        let isAlreadySavedView = false;
        if(deliveriesSavedViews){
          for (let i = 0; i < deliveriesSavedViews.length; i++) {
            if (deliveriesSavedViews[i].isActive === true) {
              deliveriesSavedViews[i].isActive = false;
            }
            if (deliveriesSavedViews[i].viewName === payload.viewName) {
              isAlreadySavedView = true;
              deliveriesSavedViews[i].colList = payload.itemList;
              deliveriesSavedViews[i].isActive = true;
            }
          }
          if(!isAlreadySavedView){
            deliveriesSavedViews.push(saveView);
            state.viewCreationStatus = true;
            state.viewCreationMsg = `'${payload.viewName}' view created successfully and selected as default, you can change it from Saved Views`;
          }else{
            state.viewUpdateStatus = true;
            state.viewUpdateMsg = `'${payload.viewName}' view updated successfully and selected as default, you can change it from Saved Views`;
          }
        }
        localStorage.setItem('EceptionsDeliverySavedViews', JSON.stringify(deliveriesSavedViews));
        state.favoriteViews = deliveriesSavedViews;
        state.columnList = payload ? payload.itemList : state.columnList;
      })
      .addCase(updateColumnSettings.fulfilled, (state, action) => {
        const { payload } = action;
        const deliveriesSavedViews = localStorage.getItem('EceptionsDeliverySavedViews') ? JSON.parse(localStorage.getItem('EceptionsDeliverySavedViews') || "[]") : [];
        if(deliveriesSavedViews){
          for (let i = 0; i < deliveriesSavedViews.length; i++) {
            if (deliveriesSavedViews[i].isActive === true && deliveriesSavedViews[i].viewName !== payload.viewName) {
              deliveriesSavedViews[i].isActive = false;
            }
            if (deliveriesSavedViews[i].viewName === payload.viewName) {
              deliveriesSavedViews[i].isActive = true;
            }
          }
        }
        localStorage.setItem('EceptionsDeliverySavedViews', JSON.stringify(deliveriesSavedViews));
        state.favoriteViews = deliveriesSavedViews;
        state.columnList = payload ? payload.itemList : state.columnList;
      })
      .addCase(fetchInitialColumnList.fulfilled, (state, action) => {
        const { payload } = action;
        state.columnList = payload;
      })
      .addCase(fetchDeliveriesSavedViews.fulfilled, (state, action) => {
        const { payload } = action;
        state.favoriteViews = payload;
      })
      .addCase(resetViewCreationStatus.fulfilled, (state, action) => {
        const { payload } = action;
        state.viewCreationStatus = payload;
      })
      .addCase(resetViewUpdationStatus.fulfilled, (state, action) => {
        const { payload } = action;
        state.viewUpdateStatus = payload;
      });
  },
});

export default exceptionsDeliverySlice.reducer;
