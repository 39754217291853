import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AppConstants from "../../../constants";
import { CommonState } from "./commonSlice.types";


export const countryCodeUpdate = createAsyncThunk("Common/CountryCodeUpdate", (countryCode: string) => {
  return countryCode;
});

export const resetMenuOpenFlag = createAsyncThunk("Common/ResetMenuOpenFlag", (isMenuOpen: string) => {
  return isMenuOpen;
});

const initialState: CommonState = {
  countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE) || AppConstants.COUNTRY_OBJ.value,
  isMenuOpen: ''
}

const commonSlice = createSlice({
  name: "Common",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(countryCodeUpdate.fulfilled, (state, action) => {
        const { payload } = action;
        sessionStorage.setItem(AppConstants.COUNTRY_CODE, payload);
        state.countryCode = payload;
      })
      .addCase(resetMenuOpenFlag.fulfilled, (state, action) => {
        const { payload } = action;
        state.isMenuOpen = payload;
      });
  }
});

export default commonSlice.reducer;