import { CircularProgress, Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useStyles } from './Loader.styles';
import { useSelector } from 'react-redux';
import { AppState } from '../../config/redux/reducers';

interface LoaderProps { }

const Loader = (props: LoaderProps) => {
  const classes = useStyles();
  const { isLoggedIn } = useSelector((state: AppState) => state.userLogin);
  const [loaderClass, setLoaderClass] = useState(classes.root);
  
  useEffect(() => {
    if (isLoggedIn) {
      setLoaderClass(classes.root);
    }else{
      setLoaderClass(classes.root+" "+classes.notLoggedIn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <Grid className={loaderClass} container>
      <CircularProgress />
    </Grid>
  )
}

export default Loader;