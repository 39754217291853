import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import theme from './config/theme/baseTheme';
import { Provider } from 'react-redux';
import store from './config/redux/store';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <Provider store={store}>
        <CssBaseline />
        <App />
      </Provider>
    </BrowserRouter>
  </ThemeProvider>,
  document.getElementById('root')
);
