import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1),
      '& .MuiOutlinedInput-root': {
        width: 200,
        minWidth: 180
      }
    }
  })
);