import { Checkbox, TableCell, TableRow, TableRowProps } from '@material-ui/core';
import React, { useCallback } from 'react';
import { ColDef, RowData, RowId } from '.';
import DataTableCellElement from './DataTableCellElement';
import { useStyles } from './DataTableRowElement.styles';

export interface DataTableRowElementProps extends TableRowProps {
  rowData: RowData;
  columns: ColDef[];
  checkboxSelection: boolean;
  disableSelectionOnClick?: boolean;
  isItemSelected?: boolean;
  rowClickCallback?: (rowData: RowData) => void;
  rowSelectionCallback?: (rowId: RowId) => void;
  cellClickCallback?: (column: ColDef, value: any) => void;
}

const DataTableRowElement = (props: DataTableRowElementProps) => {
  const classes = useStyles();
  const { rowData, columns, checkboxSelection, disableSelectionOnClick, isItemSelected, rowClickCallback, rowSelectionCallback, cellClickCallback, ...rest } = props;

  const handleRowSelection = useCallback(
    () => {
      rowSelectionCallback && rowSelectionCallback(rowData.id);
    },
    [rowData, rowSelectionCallback],
  )

  const handleRowClick = useCallback(
    (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
      if (event && event.target && event.target instanceof HTMLInputElement) {
        return;
      }
      if (checkboxSelection) {
        if (disableSelectionOnClick) {
          rowClickCallback && rowClickCallback(rowData);
          return;
        }
        handleRowSelection();
      } else {
        rowClickCallback && rowClickCallback(rowData);
      }
    },
    [checkboxSelection, disableSelectionOnClick, handleRowSelection, rowClickCallback, rowData],
  );

  return (
    <TableRow
      {...rest}
      className={classes.root}
      style={{ cursor: disableSelectionOnClick ? 'pointer' : 'normal' }}
      selected={isItemSelected}
      onClick={handleRowClick}
    >
      {
        checkboxSelection &&
        <TableCell>
          <Checkbox
            checked={isItemSelected}
            size="small"
            color="primary"
            onChange={handleRowSelection}
          />
        </TableCell>
      }
      {columns.map((col: ColDef, index: number) => (
        <DataTableCellElement key={`${col.field}${index}`} column={col} value={rowData[col.field]} cellClickCallback={cellClickCallback} />
      ))}
    </TableRow>
  )
}

export default DataTableRowElement;