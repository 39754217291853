import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import AppConstants from '../../constants';
import DatePickerComponent from '../DatePickerComponent';

interface DateRangePickerComponentProps {
  filter: any;
  value: any;
  autoUpdateInput?: boolean;
  locale?: any;
  maxSpan?: any,
  showDropdowns?: boolean,
  linkedCalendars?: boolean,
  autoApply?: boolean;
  timePicker?: boolean;
  updateDateRange: (dateRange: any) => void;
}

const DateRangePickerComponent = (props: DateRangePickerComponentProps) => {

  const { filter, value, autoUpdateInput, locale, maxSpan, showDropdowns, linkedCalendars, autoApply, timePicker, updateDateRange } = props;

  const [initialSettings, setInitialSettings] = useState({
    autoUpdateInput: autoUpdateInput,
    startDate: value.startDate,
    endDate: value.endDate,
    locale: locale,
    maxSpan: maxSpan,
    showDropdowns: showDropdowns,
    linkedCalendars: linkedCalendars,
    autoApply: autoApply,
    timePicker: timePicker
  });

  const getDatePickerValue = useCallback(
    (picker: any) => {
      return `${picker.startDate.format(timePicker ? AppConstants.DATE_TIME_FORMAT_UI : AppConstants.DATE_FORMAT_UI)} - ${picker.endDate.format(timePicker ? AppConstants.DATE_TIME_FORMAT_UI : AppConstants.DATE_FORMAT_UI)}`;
    },
    [timePicker],
  );
  const [datePickerValue, setDatePickerValue] = useState(getDatePickerValue({
    startDate: moment(value.startDate, timePicker ? AppConstants.DATE_TIME_FORMAT_UI : AppConstants.DATE_FORMAT),
    endDate: moment(value.endDate, timePicker ? AppConstants.DATE_TIME_FORMAT_UI : AppConstants.DATE_FORMAT)
  }));

  const handleDateRangeChange = useCallback(
    (event: any, picker: any) => {
      updateDateRange({
        startDate: picker.startDate.format(timePicker ? AppConstants.DATE_TIME_FORMAT_UI : AppConstants.DATE_FORMAT),
        endDate: picker.endDate.format(timePicker ? AppConstants.DATE_TIME_FORMAT_UI : AppConstants.DATE_FORMAT)
      });
    },
    [timePicker, updateDateRange]
  )

  useEffect(() => {
    setInitialSettings(i => ({ ...i, startDate: value.startDate, endDate: value.endDate }));
    setDatePickerValue(getDatePickerValue({
      startDate: moment(value.startDate, timePicker ? AppConstants.DATE_TIME_FORMAT_UI : AppConstants.DATE_FORMAT),
      endDate: moment(value.endDate, timePicker ? AppConstants.DATE_TIME_FORMAT_UI : AppConstants.DATE_FORMAT)
    }));

  }, [value, timePicker, getDatePickerValue]);

  return (
    <>
      <DatePickerComponent
        id={filter.key}
        datePickerValue={datePickerValue}
        placeholderText={filter.placeholderText}
        label={filter.label}
        initialSettings={initialSettings}
        onChange={handleDateRangeChange}
      />
    </>
  )
}

export default DateRangePickerComponent;