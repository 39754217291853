import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
      marginLeft: 24,
      marginRight: 24
    },
    pageTitle:{
      padding:"16px 16px 0 0",
      fontSize:20
    },
    statusHeader: {
      "& .statusMsgMore": {
        fontWeight:"bold"
      }
    }
  }),
);
