import { isExisty } from "./helpers.utils";

export const replaceUrlVariables = (
  url: string,
  params: { [key: string]: string }
): string => {
  const regex = /[^{{}]+(?=}})/g;
  const matches = url.match(regex);

  let modifiedURL = url;

  if (matches) {
    matches.forEach((item: string) => {
      const value = params[item];
      modifiedURL = modifiedURL.replace(
        new RegExp("{{" + item + "}}"),
        isExisty(value) || value === "" ? value : `{{${item}}}`
      );
    });
  }

  return modifiedURL;
};
