import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    statusMessageMain: {
      height: 64,
      marginTop:15,
      paddingRight: 16,
      paddingLeft: 16

    },
    statusMessageSuccess: {
      backgroundColor: "#009688",
      width: "100%",
      borderRadius: 8,
      height: "100%",
      color: "#fff"
    },
    statusMessageError: {
      backgroundColor: "#ff1744",
      width: "100%",
      borderRadius: 8,
      height: "100%",
      color: "#fff"
    },
    statusMessageIcon: {
      width: "2.3%",
      float: "left",
      paddingLeft: 12,
      paddingTop: 16
    },
    statusMessageText: {
      width: "94.7%",
      float: "left",
      paddingTop: 22,
      paddingLeft: 16
    },
    statusMessageClose: {
      width: "3%",
      float: "right",
      paddingTop: 20,
      paddingLeft: 10
    },
    firstIconCustomWidth: {
      width: "3.3%"
    },
    textMessageCustomWidth: {
      width: "92.7%"
    },
    closeIconCustomWidth: {
      width: "4%"
    },
    firstIconCustomWidthMd: {
      width: "4.3%"
    },
    textMessageCustomWidthMd: {
      width: "90.7%"
    },
    closeIconCustomWidthMd: {
      width: "5%"
    },
    firstIconCustomWidthSm: {
      width: "5.3%"
    },
    textMessageCustomWidthSm: {
      width: "88.7%"
    },
    closeIconCustomWidthSm: {
      width: "6%"
    },
    statusMessageDivider:{
      marginTop:10
    }
  }),
);
