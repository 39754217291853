import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import networkHandler from "../../network/networkHandler";
import { EApiMiddlewareMethods } from "../../network/networkHandler.types";
import { repushOrderAuthToken } from "../../config/Api.config";
import { saveFile, transformSelectValues, transformSelectValuesWithCode } from "../../utils/helpers.utils";
import { OrderListState } from "./orderListSlice.types";
import AppConstants from "../../constants";

export const fetchOrderList = createAsyncThunk(
  "OrderList/FetchByCountry",
  async (
    {
      payload,
      page,
      searchBy,
      searchValue,
    }: {
      payload: any;
      page: number;
      searchBy: string;
      searchValue: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `orders/${searchBy}`,
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      const { data } = await networkHandler(request, false, true);
      return data;
    } catch (error : any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchFiltersDropdownValues = createAsyncThunk(
  "OrderList/FetchFiltersDropdownValues",
  async (params, { rejectWithValue }) => {
    try {
      const request = {
        url: `filters/filter/all/ORDER`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, true);
      return data;
    } catch (error : any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchOrderListByFilter = createAsyncThunk(
  "OrderList/FetchByFilter",
  async (
    {
      filters
    }: {
      filters: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'orders/order/filter',
        method: EApiMiddlewareMethods.POST,
        data: filters
      } as any;
      const { data } = await networkHandler(request, false, true);
      return data;
    } catch (error : any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateExportToExcelPath = createAsyncThunk(
  "OrderList/ExportToExcel",
  async (
    {
      payload,
    }: {
      payload: object;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'export/excel',
        method: EApiMiddlewareMethods.POST,
        data: payload,
        responseType: "blob"
      } as any;
      const { data } = await networkHandler(request, false, true);
      return data;
    } catch (error : any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const clearList = createAsyncThunk("OrderList/ClearContents", () => {
  return [];
});

export const resetOrderListError = createAsyncThunk("OrderList/ResetError", () => {
  return "";
});

export const resetRepushOrder = createAsyncThunk("OrderList/resetRepushOrder", () => {
  return "";
});

export const resetOpenHatch = createAsyncThunk("OrderList/resetOpenHatch", () => {
  return "";
});

export const resetReassignCarrier = createAsyncThunk("OrderList/resetReassignCarrier", () => {
  return "";
});

export const repushOrder = createAsyncThunk(
  "OrderList/repushOrder",
  async (
    {
      payload
    }: {
      payload: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `repush/orders`,
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      request.headers = {
        'Authorization': repushOrderAuthToken,
      }
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error : any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const openTheHatch = createAsyncThunk(
  "OrderList/openTheHatch",
  async (
    {
      localizedLang,
      consignmentCode,
      vehicleId
    }: {
      localizedLang: any,
      consignmentCode: any,
      vehicleId: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `rover/open-hatch`,
        method: EApiMiddlewareMethods.POST,
        data: {localizedLang : localizedLang, consignmentCode: consignmentCode, vehicleId: vehicleId}
      } as any;
      request.headers = {
        'Authorization': repushOrderAuthToken,
      }
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error : any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const reassignCarrier = createAsyncThunk(
  "OrderList/reassignCarrier",
  async (
    {
      toCarrier,
      consignmentCode,
      vehicleType
    }: {
      toCarrier: any;
      consignmentCode: any;
      vehicleType: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `carrier/reassign/consignment`,
        method: EApiMiddlewareMethods.POST,
        data: {toCarrier : toCarrier, consignmentCode: consignmentCode, vehicleType: vehicleType}
      } as any;
      request.headers = {
        'Authorization': repushOrderAuthToken,
      }
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error : any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCustomerGeoCodedAddress = createAsyncThunk(
  "OrderList/getCustomerGeoCodedAddress",
  async (
    {
      latLng
    }: {
      latLng: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'geocoded/address',
        method: EApiMiddlewareMethods.POST,
        data: latLng
      } as any;
      const { data } = await networkHandler(request, false, true, false, true);
      return data;
    } catch (error : any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const resetTableSelection = createAsyncThunk("OrderList/ResetTableSelection", async ({tableSelection} : {tableSelection: any}) => {
    return tableSelection;
});

export const resetFilterOrderBySearch = createAsyncThunk("OrderList/resetFilterOrderBySearch", () => {
  return false;
});

export const saveColumnSettings = createAsyncThunk("OrderList/SaveColumnSettings", (settingsInfo: any) => {
  return settingsInfo;
});

export const updateColumnSettings = createAsyncThunk("OrderList/UpdateColumnSettings", (settingsInfo: any) => {
  return settingsInfo;
});

export const resetViewCreationStatus = createAsyncThunk("OrderList/ResetViewCreationStatus", () => {
  return false;
});

export const resetViewUpdationStatus = createAsyncThunk("OrderList/ResetViewUpdationStatus", () => {
  return false;
});

const initialColumnList = () => {
  let deliveriesSavedViews = localStorage.getItem('deliveriesSavedViews') ? JSON.parse(localStorage.getItem('deliveriesSavedViews') || "[]") : [];
  if(!deliveriesSavedViews.length){
    let saveView = {viewName : "Default View", colList: AppConstants.CONSIGNMENT_CONSTANTS.TABLE_WRAPPER_DATA.HeaderData, isActive: true}
    deliveriesSavedViews.push(saveView);
    localStorage.setItem('deliveriesSavedViews', JSON.stringify(deliveriesSavedViews));
  }
  deliveriesSavedViews = localStorage.getItem('deliveriesSavedViews') ? JSON.parse(localStorage.getItem('deliveriesSavedViews') || "[]") : [];
  let activeViewObj = deliveriesSavedViews.findIndex(((obj: any) => obj.isActive === true));
  const activeView = deliveriesSavedViews[activeViewObj];
  return activeView ? activeView.colList : AppConstants.CONSIGNMENT_CONSTANTS.TABLE_WRAPPER_DATA.HeaderData;
}

const deliveriesSavedViews = () => {
  return localStorage.getItem('deliveriesSavedViews') ? JSON.parse(localStorage.getItem('deliveriesSavedViews') || "[]") : [];
}

export const fetchInitialColumnList = createAsyncThunk("OrderList/FetchInitialColumnList", () => {
  let deliveriesSavedViews = localStorage.getItem('deliveriesSavedViews') ? JSON.parse(localStorage.getItem('deliveriesSavedViews') || "[]") : [];
  if(!deliveriesSavedViews.length){
    let saveView = {viewName : "Default View", colList: AppConstants.CONSIGNMENT_CONSTANTS.TABLE_WRAPPER_DATA.HeaderData, isActive: true}
    deliveriesSavedViews.push(saveView);
    localStorage.setItem('deliveriesSavedViews', JSON.stringify(deliveriesSavedViews));
  }
  deliveriesSavedViews = localStorage.getItem('deliveriesSavedViews') ? JSON.parse(localStorage.getItem('deliveriesSavedViews') || "[]") : [];
  let activeViewObj = deliveriesSavedViews.findIndex(((obj: any) => obj.isActive === true));
  const activeView = deliveriesSavedViews[activeViewObj];
  return activeView ? activeView.colList : AppConstants.CONSIGNMENT_CONSTANTS.TABLE_WRAPPER_DATA.HeaderData;
});

export const fetchDeliveriesSavedViews = createAsyncThunk("OrderList/FetchDeliveriesSavedViews", () => {
  return localStorage.getItem('deliveriesSavedViews') ? JSON.parse(localStorage.getItem('deliveriesSavedViews') || "[]") : [];
});

export const fetchDeliveryReasons = createAsyncThunk("OrderList/fetchDeliveryReasons", async (params, { rejectWithValue }) => {
  try {
    const request = { url: `delivery-reasons/get`, method: EApiMiddlewareMethods.GET } as any;
    const { data } = await networkHandler(request, false, true);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchOrderDeliveryStatuses = createAsyncThunk("OrderList/fetchOrderDeliveryStatuses", () => {
  const statuses = [ {code : "UNDELIVERED", value : "Un Delivered"} ];
  return statuses;
});

export const resetOrderDeliveryStatuses = createAsyncThunk("OrderList/resetOrderDeliveryStatuses", () => {
  return [];
});

export const resetReasonsDropdownList = createAsyncThunk("OrderList/resetReasonsDropdownList", () => {
  return [];
});

export const updateOrderStatus = createAsyncThunk("OrderList/updateOrderStatus", async ({ params }: { params: any }, { rejectWithValue }) => {
  try {
    const request = { url: 'imile/update-order-status', method: EApiMiddlewareMethods.POST, data: params } as any;
    const { data } = await networkHandler(request, false, true, false, false, false, true);
    return data;
  } catch (error : any) {
    return rejectWithValue(error.response.data);
  }
});

const initialState: OrderListState = {
  loading: true,
  tableLoading: true,
  filtersDropdowns: {
    countriesList: [],
    hubList: [],
    carrierList: [],
    statusList: [],
    delPropositionList: []
  },
  exportToExcelPath: "",
  data: {
    content: [],
    pageNumber: 0,
    totalElements: 0
  },
  columnList: initialColumnList(),
  favoriteViews: deliveriesSavedViews(),
  error: "",
  repushOrderFlag: false,
  repushOrderMsg: "",
  reassignCarrierFlag: false,
  reassignCarrierMsg: "",
  openHatchSuccess: false,
  openHatchError: false,
  openHatchMsg: "",
  tableSelectionReset: false,
  filterOrderBySearch: false,
  viewUpdateStatus: false,
  viewCreationStatus: false,
  viewCreationMsg: "",
  viewUpdateMsg: "",
  geocodedaddress: {},
  //
  deliveryError: "",
  reasonsDropdownList: [],
  deliveryReasons: {},
  orderDeliveryStatuses: []
};

const orderListSlice = createSlice({
  name: "OrderList",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(fetchOrderList.pending, (state) => {
        state.tableLoading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchOrderList.fulfilled, (state, action) => {
        const {
          payload,
          meta: {
            arg: { page, searchValue, searchBy },
          },
        } = action;
        state.tableLoading = false;
        state.filterOrderBySearch = true;
        state.errorCode = "";
        state.error = "";
        state.data = {
          ...payload,
          totalElements: payload.totalElements,
          content:
            page === 0
              ? [...payload.elements]
              : [...state.data.content, ...payload.elements],
        };
        state.prevCountry =
          searchBy === "country" ? searchValue : state.prevCountry;
      })
      .addCase(fetchOrderList.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.tableLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchFiltersDropdownValues.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchFiltersDropdownValues.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        let hubList: any = {};
        let carrierList: any = {};
        let statusList: any = {};
        let delPropositionList: any = {};
        if (payload.hubMap) {
          Object.keys(payload.hubMap).filter((country: any) => {
            hubList[country] = transformSelectValuesWithCode(payload.hubMap[country]);
            return true;
          });
        }
        if (payload.carrierMap) {
          Object.keys(payload.carrierMap).filter((country: any) => {
            carrierList[country] = transformSelectValues(payload.carrierMap[country]);
            return true;
          });
        }
        if (payload.statusMap) {
          Object.keys(payload.statusMap).filter((country: any) => {
            statusList[country] = transformSelectValues(payload.statusMap[country]);
            return true;
          });
        }
        if (payload.deliveryPropositionMap) {
          Object.keys(payload.deliveryPropositionMap).filter((country: any) => {
            delPropositionList[country] = transformSelectValues(payload.deliveryPropositionMap[country]);
            return true;
          });
        }
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.filtersDropdowns = {
          countriesList: transformSelectValues(payload.countries),
          hubList: hubList,
          carrierList: carrierList,
          statusList: statusList,
          delPropositionList: delPropositionList
        };
      })
      .addCase(fetchFiltersDropdownValues.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(clearList.fulfilled, (state, action) => {
        const { payload } = action;
        state.tableLoading = false;
        state.errorCode = "";
        state.error = "";
        state.data = {
          ...payload,
          content: action.payload,
        };
      })
      .addCase(resetOrderListError.fulfilled, (state, action) => {
        const { payload } = action;
        state.errorCode = payload;
        state.error = payload;
      })
      .addCase(resetTableSelection.fulfilled, (state, action) => {
        const { payload } = action;
        state.tableSelectionReset = payload ? true : false;
      })
      .addCase(fetchOrderListByFilter.pending, (state) => {
        state.tableLoading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchOrderListByFilter.fulfilled, (state, action) => {
        const {
          payload,
          meta: {
            arg: { filters }
          }
        } = action;
        state.tableLoading = false;
        state.filterOrderBySearch = false;
        state.errorCode = "";
        state.error = "";
        state.data = {
          ...payload,
          totalElements: payload.totalElements,
          content:
            filters.page === 0
            ? [...payload.elements]
            : [...state.data.content, ...payload.elements],
        };
      })
      .addCase(fetchOrderListByFilter.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.tableLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(updateExportToExcelPath.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(updateExportToExcelPath.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        if (payload) {
          saveFile(payload, AppConstants.CONSIGNMENT_CONSTANTS.EXCEL_FILE_NAME);
        }
      })
      .addCase(updateExportToExcelPath.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(repushOrder.pending, (state) => {
        state.repushOrderFlag = false;
        state.repushOrderMsg = "";
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(repushOrder.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        if (!payload) {
          state.errorCode = "";
          state.error = AppConstants.CONSIGNMENT_CONSTANTS.REPUSHED_ORDER_CONTENT_NOT_FOUND;
        }else if (payload.error) {
          state.errorCode = payload.error || AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
          state.error = payload
            ? payload.message
            : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        } else {
          state.errorCode = "";
          state.error = "";
          state.repushOrderFlag = true;
          state.repushOrderMsg = AppConstants.CONSIGNMENT_CONSTANTS.ORDER_REPUSHED_MSG;
        }
      })
      .addCase(repushOrder.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.repushOrderFlag = false;
        state.repushOrderMsg = "";
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(resetRepushOrder.fulfilled, (state, action) => {
        state.repushOrderFlag = false;
        state.repushOrderMsg = "";
      })
      .addCase(openTheHatch.pending, (state) => {
        state.openHatchSuccess = false;
        state.openHatchError = false;
        state.openHatchMsg = "";
        state.loading = true;
      })
      .addCase(openTheHatch.fulfilled, (state, action) => {
        state.loading = false;
        state.openHatchSuccess = true;
        state.openHatchError = false;
        state.openHatchMsg = AppConstants.CONSIGNMENT_CONSTANTS.OPEN_HATCH_SUCCESS_MSG;
      })
      .addCase(openTheHatch.rejected, (state, action) => {
        state.loading = false;
        state.openHatchSuccess = false;
        state.openHatchError = true;
        state.openHatchMsg = AppConstants.CONSIGNMENT_CONSTANTS.OPEN_HATCH_ERROR_MSG;
      })
      .addCase(resetOpenHatch.fulfilled, (state, action) => {
        state.openHatchSuccess = false;
        state.openHatchError = false;
        state.openHatchMsg = "";
      })
      .addCase(reassignCarrier.pending, (state) => {
        state.reassignCarrierFlag = false;
        state.reassignCarrierMsg = "";
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(reassignCarrier.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        if (!payload) {
          state.errorCode = "";
          state.error = AppConstants.CONSIGNMENT_CONSTANTS.REPUSHED_ORDER_CONTENT_NOT_FOUND;
        }else if (payload.error) {
          state.errorCode = payload.error || AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
          state.error = payload
            ? payload.message
            : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        } else {
          state.errorCode = "";
          state.error = "";
          state.reassignCarrierFlag = true;
          state.reassignCarrierMsg = AppConstants.CONSIGNMENT_CONSTANTS.REASSIGN_CARRIER_MSG;
        }
      })
      .addCase(reassignCarrier.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.reassignCarrierFlag = false;
        state.reassignCarrierMsg = "";
        if(errorPayload && errorPayload.status &&  errorPayload.status === 404){
          state.openHatchSuccess = false;
          state.openHatchError = true;
          state.openHatchMsg = AppConstants.CONSIGNMENT_CONSTANTS.REASSIGN_CARRIER_NOT_FOUND_MSG;
        }else{
          state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
          state.error = errorPayload
            ? errorPayload.message
            : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        }
      })
      .addCase(resetReassignCarrier.fulfilled, (state, action) => {
        state.reassignCarrierFlag = false;
        state.reassignCarrierMsg = "";
      })
      .addCase(resetFilterOrderBySearch.fulfilled, (state, action) => {
        const { payload } = action;
        state.filterOrderBySearch = payload;
      })
      .addCase(saveColumnSettings.fulfilled, (state, action) => {
        const { payload } = action;
        let saveView = {viewName : payload.viewName, colList: payload.itemList, isActive: true}
        const deliveriesSavedViews = localStorage.getItem('deliveriesSavedViews') ? JSON.parse(localStorage.getItem('deliveriesSavedViews') || "[]") : [];
        let isAlreadySavedView = false;
        if(deliveriesSavedViews){
          for (let i = 0; i < deliveriesSavedViews.length; i++) {
            if (deliveriesSavedViews[i].isActive === true) {
              deliveriesSavedViews[i].isActive = false;
            }
            if (deliveriesSavedViews[i].viewName === payload.viewName) {
              isAlreadySavedView = true;
              deliveriesSavedViews[i].colList = payload.itemList;
              deliveriesSavedViews[i].isActive = true;
            }
          }
          if(!isAlreadySavedView){
            deliveriesSavedViews.push(saveView);
            state.viewCreationStatus = true;
            state.viewCreationMsg = `'${payload.viewName}' view created successfully and selected as default, you can change it from Saved Views`;
          }else{
            state.viewUpdateStatus = true;
            state.viewUpdateMsg = `'${payload.viewName}' view updated successfully and selected as default, you can change it from Saved Views`;
          }
        }
        localStorage.setItem('deliveriesSavedViews', JSON.stringify(deliveriesSavedViews));
        state.favoriteViews = deliveriesSavedViews;
        state.columnList = payload ? payload.itemList : state.columnList;
      })
      .addCase(updateColumnSettings.fulfilled, (state, action) => {
        const { payload } = action;
        const deliveriesSavedViews = localStorage.getItem('deliveriesSavedViews') ? JSON.parse(localStorage.getItem('deliveriesSavedViews') || "[]") : [];
        if(deliveriesSavedViews){
          for (let i = 0; i < deliveriesSavedViews.length; i++) {
            if (deliveriesSavedViews[i].isActive === true && deliveriesSavedViews[i].viewName !== payload.viewName) {
              deliveriesSavedViews[i].isActive = false;
            }
            if (deliveriesSavedViews[i].viewName === payload.viewName) {
              deliveriesSavedViews[i].isActive = true;
            }
          }
        }
        localStorage.setItem('deliveriesSavedViews', JSON.stringify(deliveriesSavedViews));
        state.favoriteViews = deliveriesSavedViews;
        state.columnList = payload ? payload.itemList : state.columnList;
      })
      .addCase(fetchInitialColumnList.fulfilled, (state, action) => {
        const { payload } = action;
        state.columnList = payload;
      })
      .addCase(fetchDeliveriesSavedViews.fulfilled, (state, action) => {
        const { payload } = action;
        state.favoriteViews = payload;
      })
      .addCase(resetViewCreationStatus.fulfilled, (state, action) => {
        const { payload } = action;
        state.viewCreationStatus = payload;
      })
      .addCase(resetViewUpdationStatus.fulfilled, (state, action) => {
        const { payload } = action;
        state.viewUpdateStatus = payload;
      })
      .addCase(getCustomerGeoCodedAddress.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
        state.geocodedaddress = {};
      })
      .addCase(getCustomerGeoCodedAddress.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.geocodedaddress = payload;
      })
      .addCase(getCustomerGeoCodedAddress.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.geocodedaddress = {}
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      /** fetchDeliveryReasons */
      .addCase(fetchDeliveryReasons.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
        state.deliveryError = "";
        state.deliveryErrorCode = "";
        state.reasonsDropdownList = [];
        state.deliveryReasons = {};
      })
      .addCase(fetchDeliveryReasons.fulfilled, (state, action) => {
        const {payload} = action;
        state.loading = false;
        let deliveryReasonsList: any = [];
        if (payload?.reasons?.length > 0) {
          deliveryReasonsList = payload.reasons.map((reason: any) => {
            return { code: reason.reasonCode, value: reason.reasonDescription };
          });
          state.deliveryReasons = payload?.reasons;
        }
        state.reasonsDropdownList = transformSelectValues(deliveryReasonsList);
      })
      .addCase(fetchDeliveryReasons.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.deliveryErrorCode = errorPayload && errorPayload?.error ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.deliveryError = errorPayload && errorPayload?.message ? errorPayload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      /** fetchOrderDeliveryStatuses */
      .addCase(fetchOrderDeliveryStatuses.fulfilled, (state, action) => {
        const { payload } = action;      
        state.loading = false;
        state.orderDeliveryStatuses = transformSelectValues(payload?.length  > 0 ? payload : []);
      })
      /** resetOrderDeliveryStatuses */
      .addCase(resetOrderDeliveryStatuses.fulfilled, (state, action) => {
        state.orderDeliveryStatuses = [];
      })
      /** resetReasonsDropdownList */
      .addCase(resetReasonsDropdownList.fulfilled, (state, action) => {
        state.reasonsDropdownList = [];
      })
      /** updateOrderStatus */
      .addCase(updateOrderStatus.pending, (state) => {
        state.loading = true;
        state.deliveryError = "";
        state.deliveryErrorCode = "";
      })
      .addCase(updateOrderStatus.fulfilled, (state, action) => {
        const {payload} = action; 
        state.loading = false;
      })
      .addCase(updateOrderStatus.rejected, (state, action) => {
        const errorPayload: any = action?.payload;
        state.loading = false;
        state.deliveryErrorCode = errorPayload && errorPayload?.error ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.deliveryError = errorPayload && errorPayload?.message ? errorPayload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
  },
});

export default orderListSlice.reducer;
