import React, { useCallback, useEffect, useState } from 'react';
import SelectBox from '../SelectBox';
import DateRangePickerComponent from '../DateRangeComponent';
import { Grid } from '@material-ui/core';
import AppConstants from '../../constants';
import { useStyles } from './Filter.styles';

interface FilterProps {
  filter: any,
  updateFilter: (filter: any) => void,
  reset: boolean,
  defaultValues: any,
  initialValues?: any;
}

const Filter = (props: FilterProps) => {
  const classes = useStyles();
  const { filter, defaultValues, reset, updateFilter, initialValues } = props;
  const [filterValue, setFilterValue] = useState(defaultValues[filter.fieldName] || "");

  const handleDateRangeChange = useCallback(
    (range: any) => {
      setFilterValue(range);
    },
    [],
  )
  const handleFilterChange = useCallback(
    (value: any) => {
      setFilterValue(value);
    },
    []
  );

  useEffect(() => {
    updateFilter({
      key: filter.fieldName,
      value: filterValue
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue])

  useEffect(() => {
    if (reset) {
      setFilterValue(defaultValues[filter.fieldName]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  const getFilter = () => {
    switch (filter.type) {
      case 'selectBox':
        return (
          <SelectBox
            handleChange={handleFilterChange}
            value={filterValue}
            reset={reset}
            id={filter.key}
            multiple={filter.multiple}
            label={filter.label}
            initialValues={initialValues}
            items={filter.items || []}
          ></SelectBox>
        );
      case 'dateRange':
        return (
          <DateRangePickerComponent
            filter={filter}
            value={filterValue}
            autoUpdateInput={filter.autoUpdateInput}
            autoApply={filter.autoApply}
            maxSpan={filter.maxSpan}
            showDropdowns={filter.showDropdowns}
            linkedCalendars={filter.linkedCalendars}
            timePicker={filter.timePicker}
            locale={{ ...filter.locale, format: (filter.timePicker ? AppConstants.DATE_TIME_FORMAT_UI : AppConstants.DATE_FORMAT) }}
            updateDateRange={handleDateRangeChange}
          />
        )
      default: return null;
    }
  }
  return (
    <Grid className={classes.root} item>
      { filter && filter.type ? getFilter() : null}
    </Grid>
  );
}

export default Filter;