import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    titleContainer: {
      '& .headingContainer': {
        flexDirection: "column",
        '& .titleHeading': {
          fontSize: 18,
          fontWeight: 500,
          '& .content': {
            fontWeight: 600
          }
        },
        '& .titleContent': {
          fontSize: 12,
          fontWeight: 600,
          '&.link': {
            cursor: "pointer"
          }
        }
      },
      '& .tagContainer': {
        marginLeft: 8,
        '& .tag': {
          color: COLORS.WHITE,
          borderRadius: 100,
          padding: theme.spacing(0.5, 1),
          backgroundColor: COLORS.BLACK,
          fontWeight: 700,
          textTransform: "uppercase",
          fontSize: 12,
          '&.yellow': {
            backgroundColor: COLORS.GOLDEN_YELLOW
          },
          '&.blue': {
            backgroundColor: COLORS.AZURE_BLUE
          },
          '&.green': {
            backgroundColor: COLORS.PERSIAN_GREEN
          },
          '&.red': {
            backgroundColor: COLORS.DARK_RED
          },
          '&.black': {
            backgroundColor: COLORS.BLACK
          }
        }
      }
    },
    contentContainer: {
      flexDirection: "column",
      flexWrap: "nowrap",
      '& .detailsHeader': {
        flexDirection: "column",
        padding: theme.spacing(2, 0, 2, 2),
        borderRadius: theme.spacing(1),
        '& .detailsHeaderItem': {
          paddingBottom: theme.spacing(1),
          width: "100%",
          '& .addressinfo':{
            fontWeight: 550
          },
          '& .geocoded':{
            color: COLORS.DARK_RED
          },
          '&:last-child': {
            paddingBottom: 0
          },
          '& p': {
            lineHeight: "22px",
            fontSize: 12
          },
          '& .addressTitle': {
            fontSize: 14
          },
          '& .name': {
            fontSize: 14,
            fontWeight: 600
          },
          '&.geocodedAddress': {
            paddingTop: theme.spacing(2),
            width: "97%",
            '& .addressColItem': {
              alignSelf: "flex-start"
            },
            '& .geoLocAddressContainer': {
              cursor: "pointer",
              '& .address': {
                fontSize: 14,
                fontWeight: 600,
                color: theme.palette.primary.main,
                lineHeight: "14px",
                paddingRight: 3
              },
              '& .addressLogo': {
                width: 12,
                height: 12,
                transform: "rotate(0deg)",
                transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                '&.open': {
                  transform: "rotate(180deg)",
                }
              }
            },
            '& .addressMapContainer': {
              height: 243,
              border: `1px solid ${COLORS.SILVER}`,
              borderRadius: theme.spacing(1),
              marginTop: theme.spacing(1),
              backgroundColor: COLORS.SILVER
            }
          },
          '& .contactMobile': {
            marginRight: theme.spacing(5),
            marginBottom: theme.spacing(1.2),
            width: '100%'
          },
          '& .logo': {
            width: 24,
            height: 24
          },
          '& .contact': {
            '& a': {
              lineHeight: "17px",
              fontSize: 14,
              fontWeight: 600,
              textDecoration: "none",
              color: theme.palette.text.primary
            },
            paddingLeft: theme.spacing(1),
            '&.mobile': {
              paddingLeft: 2
            }
          }
        }
      },
      '& .addressContainer':{
        height: 330,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '0.3em',
          height: '0.3em'
        },
        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'grey',
          borderRadius: 8
        }
      },
      '& .detailsHeaderLeftSection': {
        float: "left",
        width: "49%",
        borderRadius: "8px",
        boxShadow: "0px 0px 4px rgb(0 0 0 / 15%)",
      },
      '& .detailsHeaderRightSection': {
        float: "right",
        width: "49%",
        borderRadius: "8px",
        boxShadow: "0px 0px 4px rgb(0 0 0 / 15%)",
      },
      '& .itemRowMargin':{
        paddingBottom: theme.spacing(3)
      },
      '& .itemPaddingLeft':{
        paddingLeft: theme.spacing(3)
      },
      '& .itemValue':{
        fontWeight: 600
      },
      '& .detailsContent': {
        flexDirection: "column",
        flexWrap: "nowrap",
        padding: theme.spacing(3, 0, 2, 0),
        '& .contentRow': {
          paddingBottom: theme.spacing(3),
          '&:last-child': {
            paddingBottom: 0
          },
          '& .contentColumn': {
            flex: "1 1 0",
            '&:last-child': {
              flex: "0 1 auto"
            },
            '& p': {
              fontSize: 14,
              lineHeight: "17px"
            },
            '& .label': {
              paddingBottom: theme.spacing(1)
            },
            '& .content': {
              fontWeight: 600
            }
          }
        }
      },
      '& .sellerDetails': {
        paddingTop: theme.spacing(1.75),
        '& .detailsHeader': {
          backgroundColor: theme.palette.secondary.main
        }
      },
      '& .customerAddressTop': {
        paddingTop: theme.spacing(2)
      },
      '& .customerAddress': {
        paddingBottom: theme.spacing(2),
        '& .detailsHeaderItem': {
          paddingBottom: theme.spacing(1),
          '& .addressinfo':{
            fontWeight: 550
          },
          '&:last-child': {
            paddingBottom: 0
          },
          '& p': {
            lineHeight: "22px",
            fontSize: 12
          },
          '& .addressTitle': {
            fontSize: 14
          },
          '& .name': {
            fontSize: 14,
            fontWeight: 600
          }
        }
      },
      '& .consignmentHistory': {
        paddingBottom: theme.spacing(2)
      },
      '& .itemsSection': {
        '& .content': {
          width: "100%",
          '& .itemsContainer': {
            flexWrap: "nowrap",
            '& .item': {
              padding: theme.spacing(2, 0),
              borderBottom: `1px solid ${theme.palette.secondary.dark}`,
              "&:first-child": {
                padding: 0,
                paddingBottom: theme.spacing(2)
              },
              '&:last-child': {
                borderBottom: "none"
              },
              '& .itemContainer': {
                '& p': {
                  fontSize: 14,
                  lineHeight: "17px",
                  fontWeight: 600
                },
                '&.header p': {
                  color: COLORS.DOVE_GRAY,
                  fontWeight: 400
                },
                '& .itemColumn': {
                  '&.item1': {
                    "& p": {
                      fontWeight: 400,
                      textTransform: "capitalize"
                    }
                  },
                  '&.item2': {
                    '& p': {
                      textAlign: "right",
                      '& .green': {
                        color: COLORS.PERSIAN_GREEN
                      }
                    }
                  },
                  '&.item3': {
                    '& p': {
                      textAlign: "right"
                    }
                  }
                }
              }
            }
          },
          '& .paymentContainer': {
            paddingTop: 13,
            '& .totalDivider': {
              margin: "11px 0px"
            },
            '& .paymentItem': {
              '&:last-child p': {
                fontWeight: 600
              },
              padding: "5px 0",
              '& .paymentItemColumn': {
                '& p': {
                  fontSize: 14,
                  lineHeight: "17px"
                },
                '& .vat': {
                  fontWeight: 400,
                  paddingLeft: 2
                },
                '&.right': {
                  marginLeft: "auto"
                }
              }
            }
          }
        }
      },
      '& .repushButton': {
        paddingTop:20,
        textAlign: "center"
      }
    },
    accordionSummary: {
      '& .header': {
        fontSize: 14,
        lineHeight: "17px",
        fontWeight: 600
      },
      '& .content': {
        flexGrow: 1
      }
    },
    timeline: {
      padding: "0 !important",
      margin: 0,
      '& .timelineItem': {
        minHeight: 50,
        '&:last-child': {
          minHeight: "auto"
        },
        '&:before': {
          content: "none"
        },
        '& .timelineDot': {
          padding: 0,
          margin: 0,
          border: "none",
          boxShadow: "none",
          backgroundColor: "transparent",
          '& .dotIcon': {
            width: 18,
            height: 18,
            padding: 5,
            '&.active': {
              padding: 0
            }
          },
        },
        '& .primaryConnector': {
          backgroundColor: COLORS.TRANSPARENT,
          borderLeft: `1px dashed ${COLORS.SILVER}`
        },
        '& .timelineContent': {
          padding: 0,
          paddingLeft: theme.spacing(1),
          '& .heading': {
            paddingTop: 1,
            fontSize: 14,
            lineHeight: "14px",
            fontWeight: 600,
            color: theme.palette.primary.main,
            '&.error': {
              color: COLORS.DARK_RED,
              '&.active': {
                paddingBottom: 17
              }
            },
            '&.active': {
              // paddingBottom: 17
            },
            '& .dateTime': {
              fontSize: 12,
              lineHeight: "15px",
              fontWeight: 500,
              color: COLORS.DOVE_GRAY,
              paddingLeft: 4,
              verticalAlign: "bottom",
            }
          },
          '& .metadataContainer': {
            paddingBottom: theme.spacing(1.5),
            '& .title': {
              fontSize: 12,
              lineHeight: "10px",
              paddingBottom: theme.spacing(1),
              fontWeight: 500,
              color: COLORS.COPY
            },
            '& .description': {
              fontSize: 10,
              lineHeight: "10px",
              color: COLORS.DOVE_GRAY_LIGHT
            }
          }
        }
      }
    },
    dialogPaperPropsRoot: {
      margin: 0,
      marginLeft: "auto",
      maxHeight: "none",
      height: "100vh",
      width: 580
    }
  }),
);