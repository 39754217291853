import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    titleContainer: {
      '& .headingContainer': {
        flexDirection: "column",
        '& .titleHeading': {
          fontSize: 24,
          fontWeight: 600,
        },
        '& .titleContent': {
          fontSize: 12,
          fontWeight: 600,
          '&.link': {
            cursor: "pointer"
          }
        },
        "& .statusContainer": {
          "& .statusIcon": {
            width: 12,
            height: 12,
            borderRadius: "50%",
            '&.yellow': {
              backgroundColor: COLORS.STATUS_YELLOW
            },
            '&.black': {
              backgroundColor: COLORS.TEXT_PRIMARY
            },
            '&.green': {
              backgroundColor: COLORS.PERSIAN_GREEN
            },
            '&.red': {
              backgroundColor: COLORS.DARK_RED
            },
            '&.gray': {
              backgroundColor: COLORS.BLACK_BORDER
            },
          },
          '& .statusMsg': {
            paddingLeft: theme.spacing(1),
            '& .status': {
              fontSize: 12
            }
          }
        }
      },
      '& .tagContainer': {
        cursor: "pointer",
        marginLeft: theme.spacing(2),
        alignSelf: "center"
      }
    },
    contentContainer: {
      flexDirection: "column",
      flexWrap: "nowrap",
      '& .detailsHeader': {
        flexDirection: "column",
        backgroundColor: COLORS.TRANSPARENT_BLUE,
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1),
        '& .detailsHeaderItem': {
          paddingBottom: theme.spacing(1),
          '&:last-child': {
            paddingBottom: 0
          },
          '& p': {
            lineHeight: "17px",
            fontSize: 14
          },
          '& .name': {
            fontWeight: 600
          },
          '& .contactMobile': {
            marginRight: theme.spacing(5)
          },
          '& .logo': {
            width: 24,
            height: 24
          },
          '& .contact': {
            '& a': {
              lineHeight: "17px",
              fontSize: 14,
              fontWeight: 600,
              textDecoration: "none",
              color: theme.palette.text.primary
            },
            paddingLeft: theme.spacing(1),
            '&.mobile': {
              paddingLeft: 8
            }
          }
        }
      },
      '& .detailsContent': {
        flexDirection: "column",
        flexWrap: "nowrap",
        padding: theme.spacing(3, 0, 2, 0),
        '& .contentRow': {
          paddingBottom: theme.spacing(3),
          '& .contentRowContainer': {
            justifyContent: "space-between"
          },
          '&:last-child': {
            paddingBottom: 0
          },
          '& .contentColumn': {
            '&.virtual': {
              opacity: 0
            },
            '& p': {
              fontSize: 14,
              lineHeight: "17px"
            },
            '& .label': {
              paddingBottom: theme.spacing(1)
            },
            '& .content': {
              fontWeight: 600
            }
          }
        }
      },
    },
    buttons: {
      width: "100%",
      '& .userBtn': {
        '&.secondary': {
          marginRight: theme.spacing(2)
        },
        flexGrow: 1
      }
    },
    dialogPaperPropsRoot: {
      margin: 0,
      marginLeft: "auto",
      maxHeight: "none",
      height: "100vh",
      width: 580
    }
  }),
);