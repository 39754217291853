import React, { useEffect } from "react";

import { isInIframe } from "./utils/helpers.utils";
import { wingsPortalUrl } from "./config/Api.config";

const RedirectIfNotIframe = () => {
  useEffect(() => {
    if (!isInIframe()) {
      window.location.href = wingsPortalUrl || "https://cwingsfe.mafrservices.com"; // If not in an iframe, redirect to the different domain
    }
  }, []);

  return null; // No need to render anything
};

export default RedirectIfNotIframe;
