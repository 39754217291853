import moment from "moment";
import AppConstants from "../../constants";
import { localTimeString } from "../../utils/helpers.utils";

export interface MetaData {
  paymentType: string;
  totalAmount: string;
  transactionDate: Date;
  amountToCollect: string;
  currency: string;
  displayStatus: string;
  category: string;
  deliveryType: string;
  modeOfCollection: string;
  invoiceNumber: string;
  internalExternal: string;
  channel: string;
  omsStatus: string;
  origin: string;
  status: string;
}

export interface Name {
  ar: string;
  en: string;
}

export interface Product {
  isSubstitution: boolean;
  sku: string;
  entryNumber: string;
  hasRejectedProducts: boolean;
  name: Name;
  quantity: string;
  unitPrice: string;
  toleranceMinWeight: string;
  toleranceMaxWeight: string;
  imgUrl: string;
  vatPercentage: string;
  uom: string;
  orderedBarcode: string;
  associatedBarcodes: string[];
}

export interface Address {
  area: string;
  landmark: string;
  building: string;
  apartment: string;
  zoneNumber: string;
  makani: string;
  streetNumber: string;
  streetName: string;
  city: string;
  postalCode: string;
  countryName: string;
  countryIsoCode: string;
  longitude: string;
  latitude: string;
}

export interface ContactInfo {
  name: string;
  phone: string;
  altphone: string;
  email: string;
  address: Address;
}

export interface PosInformation {
  storeName: string;
  store: string;
  contactInfo: ContactInfo;
}

export interface DeliverySlot {
  startTime: Date;
  endTime: Date;
}

export interface Address2 {
  area: string;
  landmark: string;
  building: string;
  apartment: string;
  zoneNumber: string;
  streetNumber: string;
  streetName: string;
  city: string;
  postalCode: string;
  countryName: string;
  countryIsoCode: string;
  longitude: string;
  latitude: string;
}

export interface CustomerContactInfo {
  name: string;
  phone: string;
  email: string;
  address: Address2;
}

export interface DeliveryInformation {
  deliveryInstructions: string;
  deliveryCost: string;
  carrier: string;
  carrierTrackingId: string;
  totalWeight: string;
  driverName: string;
  driverPhone: string;
  preferredVehicle: string;
  deliverySlot: DeliverySlot;
  carrierDeliverySlot: DeliverySlot;
  customerContactInfo: CustomerContactInfo;
}

export interface Address3 {
  area: string;
  landmark: string;
  building: string;
  apartment: string;
  zoneNumber: string;
  streetNumber: string;
  streetName: string;
  city: string;
  postalCode: string;
  countryName: string;
  countryIsoCode: string;
  longitude: string;
  latitude: string;
}

export interface PickUpContactInfo {
  companyName: string;
  name: string;
  phone: string;
  email: string;
  address: Address3;
}

export interface PickupSlot {
  startTime: Date;
  endTime: Date;
}

export interface PickUpInformation {
  pickUpContactInfo: PickUpContactInfo;
  pickupSlot: PickupSlot;
  pickupRefID: string;
  pickupGUID: string;
  carrierPickUpSlot: DeliverySlot;
}

export interface StatusError {
  errorCode: string;
  message: string;
  date: string;
}

export interface Content {
  orderCode: string;
  consignmentCode: string;
  orderReference: string;
  requestType: string;
  hasRejectedProducts: boolean;
  metadata: MetaData;
  products: Product[];
  errors?: StatusError[];
  posInformation: PosInformation;
  deliveryInformation: DeliveryInformation;
  pickUpInformation: PickUpInformation;
  lastModifiedTime: Date;
}

export interface Sort {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export interface Pageable {
  sort: Sort;
  offset: number;
  requestContinuation: string;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;
}

export interface Sort2 {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export interface CountryDataResponse {
  content: Content[];
  totalElements: number;
  totalPages: number;
  pageNumber: number;
  continuationToken: string;
}

const lastMileCountryDataTransform = (response: CountryDataResponse): any[] => {
  const transformedArray: any[] = [];
  response.content.forEach((content: Content) => {
    const {
      metadata,
      posInformation: { store, storeName = '' },
      deliveryInformation,
      errors,
      pickUpInformation,
      orderCode,
      orderReference,
      consignmentCode,
      lastModifiedTime
    } = content;
    // TODO: map/add actual objects too for drilling down content or store in state
    const deliveryDate = new Date(deliveryInformation.deliverySlot.startTime);
    const transactionDate = new Date(metadata.transactionDate);
    const startTime = new Date(deliveryInformation.deliverySlot.startTime);
    const endTime = new Date(deliveryInformation.deliverySlot.endTime);
    const lastModifiedDate = new Date(lastModifiedTime);
    const carrierDeliveryDate= deliveryInformation.carrierDeliverySlot && new Date(deliveryInformation.carrierDeliverySlot.startTime);
    const carrierDeliverySlotStart = deliveryInformation.carrierDeliverySlot && new Date(deliveryInformation.carrierDeliverySlot.startTime);
    const carrierDeliverySlotEnd = deliveryInformation.carrierDeliverySlot && new Date(deliveryInformation.carrierDeliverySlot.endTime);
    const pickUpDate= pickUpInformation.pickupSlot && new Date(pickUpInformation.pickupSlot.startTime);
    const pickUpDateStart = pickUpInformation.pickupSlot && new Date(pickUpInformation.pickupSlot.startTime);
    const pickUpDateEnd = pickUpInformation.pickupSlot && new Date(pickUpInformation.pickupSlot.endTime);
    const carrierPickUpDate= pickUpInformation.carrierPickUpSlot && new Date(pickUpInformation.carrierPickUpSlot.startTime);
    const carrierPickUpDateStart = pickUpInformation.carrierPickUpSlot && new Date(pickUpInformation.carrierPickUpSlot.startTime);
    const carrierPickUpDateEnd = pickUpInformation.carrierPickUpSlot && new Date(pickUpInformation.carrierPickUpSlot.endTime);
    transformedArray.push({
      id: `${consignmentCode}-${orderCode}`,
      hubCode: store ? store : '',
      hubName: storeName.length > 0 ? storeName : store,
      consignmentId: consignmentCode,
      trackingId: deliveryInformation.carrierTrackingId || orderReference,
      displayStatus: metadata.displayStatus ? metadata.displayStatus : metadata.status,
      deliveryStatus: metadata.status,
      deliveryDate: deliveryDate.toLocaleDateString('en-GB'),
      startEndTime: `${localTimeString(startTime)} - ${localTimeString(endTime)}`,
      deliveryTime: lastModifiedTime && `${lastModifiedDate.toLocaleDateString('en-GB')} - ${localTimeString(lastModifiedDate)}`,
      carrierDeliveryDate: carrierDeliveryDate && carrierDeliveryDate.toLocaleDateString('en-GB'),
      carrierDeliverySlot: carrierDeliverySlotStart && carrierDeliverySlotEnd && `${localTimeString(carrierDeliverySlotStart)} - ${localTimeString(carrierDeliverySlotEnd)}`,
      pickUpDate: pickUpDate && pickUpDate.toLocaleDateString('en-GB'),
      pickUpSlot: pickUpDateStart && pickUpDateEnd && `${localTimeString(pickUpDateStart)} - ${localTimeString(pickUpDateEnd)}`,
      carrierPickUpDate: carrierPickUpDate && carrierPickUpDate.toLocaleDateString('en-GB'),
      carrierPickUpSlot: carrierPickUpDateStart && carrierPickUpDateStart && `${localTimeString(carrierPickUpDateStart)} - ${localTimeString(carrierPickUpDateEnd)}`,
      orderId: orderCode,
      customerName: deliveryInformation.customerContactInfo.name,
      deliveryZone: deliveryInformation.customerContactInfo.address.zoneNumber,
      deliveryArea: deliveryInformation.customerContactInfo.address.area,
      deliveryStreetAddress: `${deliveryInformation.customerContactInfo.address.streetName}`,
      customerMobileNumber: deliveryInformation.customerContactInfo.phone,
      customerEmail: deliveryInformation.customerContactInfo.email,
      transactionDate: transactionDate.toLocaleDateString('en-GB'),
      paymentType: metadata.paymentType,
      totalAmount: metadata.totalAmount,
      totalWeight: deliveryInformation.totalWeight,
      driverName: deliveryInformation.driverName,
      driverPhone: deliveryInformation.driverPhone,
      codAmount: metadata.amountToCollect,
      paymentStatus: '',
      carrier: deliveryInformation.carrier,
      vehicleType: deliveryInformation.preferredVehicle,
      omsStatus: metadata.omsStatus,
      statusErrors: errors,
      sellerName: pickUpInformation.pickUpContactInfo.companyName,
      deliveryProposition: metadata.deliveryType,
      pickupRefID: pickUpInformation.pickupRefID ? pickUpInformation.pickupRefID : '',
      pickupGUID: pickUpInformation.pickupGUID ? pickUpInformation.pickupGUID : '',
      sellerLat: pickUpInformation.pickUpContactInfo.address ? pickUpInformation.pickUpContactInfo.address.latitude : '',
      sellerLong: pickUpInformation.pickUpContactInfo.address ? pickUpInformation.pickUpContactInfo.address.longitude : '',
    });
  });
  return [...transformedArray];
};

export { lastMileCountryDataTransform };

export const sortedCountryData = (
  columnName: string,
  isDescending: boolean
) => (a: any, b: any) => {
  const aVal = isDescending ? a : b;
  const bVal = isDescending ? b : a;
  if (
    aVal[columnName].match(/^[0-9]+$/) &&
    bVal[columnName].match(/^[0-9]+$/)
  ) {
    return parseFloat(aVal[columnName]) - parseFloat(bVal[columnName]);
  } else {
    if (aVal[columnName] < bVal[columnName]) {
      return -1;
    } else if (aVal[columnName] > bVal[columnName]) {
      return 1;
    } else {
      return 0;
    }
  }
};

export const createFilterPayload = (payload: any, data?: any, isLoadMore?: boolean, isExcel?: boolean) => {
  if (payload.carriers) {

    const selectAllIdx = payload.carriers.indexOf(AppConstants.SELECT_ALL.value);
    const selectNoneIdx = payload.carriers.indexOf(AppConstants.SELECT_NONE.value);
    if (selectAllIdx > -1) {
      payload.carriers = [];
    }
    if (selectNoneIdx > -1) {
      payload.carriers = [];
    }
  }
  if (payload.carrierStatus) {
    const selectAllIdx = payload.carrierStatus.indexOf(AppConstants.SELECT_ALL.value);
    const selectNoneIdx = payload.carrierStatus.indexOf(AppConstants.SELECT_NONE.value);
    if (selectAllIdx > -1) {
      payload.carrierStatus = [];
    }
    if (selectNoneIdx > -1) {
      payload.carrierStatus = [];
    }
  }
  if (payload.requestType) {
    payload.requestType = [payload.requestType];
    const selectAllIdx = payload.requestType.indexOf(AppConstants.SELECT_ALL.value);
    const selectNoneIdx = payload.requestType.indexOf(AppConstants.SELECT_NONE.value);
    if (selectAllIdx > -1) {
      payload.requestType.splice(selectAllIdx, 1);
    }
    if (selectNoneIdx > -1) {
      payload.requestType.splice(selectNoneIdx, 1);
    }
  }
  if (payload.deliveryDateRange) {
    payload.deliveryDateFrom = moment(payload.deliveryDateRange.startDate, AppConstants.DATE_FORMAT).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_BACKEND);
    payload.deliveryDateTo = moment(payload.deliveryDateRange.endDate, AppConstants.DATE_FORMAT).hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT_BACKEND);
    delete payload.deliveryDateRange;
  }
  if (payload.transactionDateRange) {
    //payload.createdDateFrom = moment(payload.transactionDateRange.startDate, AppConstants.DATE_FORMAT).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_BACKEND);
    //payload.createdDateTo = moment(payload.transactionDateRange.endDate, AppConstants.DATE_FORMAT).hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT_BACKEND);
    delete payload.transactionDateRange;
  }
  if (payload.deliveryProposition) {
    payload.deliveryProposition = [payload.deliveryProposition];
    const selectAllIdx = payload.deliveryProposition.indexOf(AppConstants.SELECT_ALL.value);
    const selectNoneIdx = payload.deliveryProposition.indexOf(AppConstants.SELECT_NONE.value);
    if (selectAllIdx > -1) {
      payload.deliveryProposition.splice(selectAllIdx, 1);
    }
    if (selectNoneIdx > -1) {
      payload.deliveryProposition.splice(selectNoneIdx, 1);
    }
  }
  if (isLoadMore && data) {
    payload.page = data.pageNumber + 1 <= data.totalPages ? data.pageNumber + 1 : payload.page;
  }
 
  return payload;
};

export const getErrorStatusMsg = (errors: any) => {
  let errorStatusMsg = '';
  if(errors && errors.length){
    errorStatusMsg = `${errors[0].errorCode} - ${errors[0].message}`
  }
  return errorStatusMsg;
};

export const getErrorStatusFieldReadMore = (errors: any) => {
  let statusErrorsReadMore = '';
  if(errors && errors.length > 1){
      statusErrorsReadMore =  `${AppConstants.CONSIGNMENT_CONSTANTS.MORE_ERRORS}`
  }
  return statusErrorsReadMore;
};