import React from 'react';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import { useStyles } from './Tooltip.styles';

const CustomTooltip = (props: TooltipProps) => {
  const classes = useStyles();

  return <Tooltip arrow classes={classes} {...props} />;
}

export default CustomTooltip;