import React from "react";

import { Grid, SvgIcon, Typography } from "@material-ui/core";

import SelectBox, { SelectItemProps } from "../common/SelectBox";

import { ConsignmentDetailsStateInterface } from "./redux/orderListSlice.types";

import { ReactComponent as warningIcon } from "../assets/icons/warningIcon.svg";

import AppConstants from "../constants";
import TextBox from "../common/TextBox";

interface RenderConsignmentEditInterface {
  classes: any;
  loading: boolean;
  consignmentDetailsState: ConsignmentDetailsStateInterface;
  orderDeliveryStatuses: SelectItemProps[];
  reasonsDropdownList: SelectItemProps[];
  handleStatusChange: any;
  handleReasonChange: any;
  handleCommentsTextChange: any;
}

const RenderConsignmentEdit = (props: RenderConsignmentEditInterface) => {
  const { classes, loading, consignmentDetailsState, orderDeliveryStatuses, reasonsDropdownList, handleStatusChange, handleReasonChange, handleCommentsTextChange } = props;

  return (
    <Grid container className={classes.contentAreaContainer}>
      <Grid item xs={12} className={classes.contentAreaItem}>
        <Grid container className={classes.itemContainerStyle}>
          <Grid item xs={12}>
            <Typography className={classes.itemLabel}>{`${AppConstants.CONSIGNMENT_CONSTANTS.EDIT_CONSIGNMENT_DELIVERY.UPDATE_STATUS}`}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.itemInputStyle}>
            <SelectBox
              fullWidth
              handleChange={handleStatusChange}
              value={consignmentDetailsState.selectedStatus}
              id="selected-status-select-box"
              items={orderDeliveryStatuses}
              initialValues={{ name: `Please Select ${AppConstants.CONSIGNMENT_CONSTANTS.EDIT_CONSIGNMENT_DELIVERY.STATUS}`, value: AppConstants.SELECT_NONE.value }}
              inputProps={{ name: "selectedStatus", id: "selected-status-select-box" }}
            />
          </Grid>
        </Grid>
      </Grid>
      {consignmentDetailsState.selectedStatus === AppConstants.CONSIGNMENT_CONSTANTS.EDIT_CONSIGNMENT_DELIVERY.DEFAULT_DELIVERY_EDIT_SELECTION && (
        <Grid container>
          <Grid item xs={12} className={classes.contentAreaItem}>
            <Grid container className={classes.itemContainerStyle}>
              <Grid item xs={12}>
                <Typography className={classes.itemLabel}>{`${AppConstants.CONSIGNMENT_CONSTANTS.EDIT_CONSIGNMENT_DELIVERY.REASON}`}</Typography>
              </Grid>
              <Grid item xs={12} className={classes.itemInputStyle}>
                <SelectBox
                  fullWidth
                  handleChange={handleReasonChange}
                  value={consignmentDetailsState.selectedReason}
                  id="selected-reason-select-box"
                  items={reasonsDropdownList}
                  error={!!consignmentDetailsState.selectedReasonError}
                  helperText={consignmentDetailsState.selectedReasonError}
                  initialValues={{ name: `Please Select ${AppConstants.CONSIGNMENT_CONSTANTS.EDIT_CONSIGNMENT_DELIVERY.REASON}`, value: AppConstants.SELECT_NONE.value }}
                  inputProps={{ name: "selectedReason", id: "selected-reason-select-box" }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.contentAreaItem}>
            <Grid container className={classes.itemContainerStyle}>
              <Grid item xs={12}>
                <Typography className={classes.itemLabel}>{`${AppConstants.CONSIGNMENT_CONSTANTS.EDIT_CONSIGNMENT_DELIVERY.COMMENTS}`}</Typography>
              </Grid>
              <Grid item xs={12} className={classes.itemTextBoxInputStyle}>
                <TextBox
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={4}
                  handleChange={handleCommentsTextChange}
                  value={consignmentDetailsState.commentsValue}
                  error={!!consignmentDetailsState.commentsValueError}
                  helperText={consignmentDetailsState.commentsValueError}
                  textBoxId={"comments-value-select-box"}
                  name="commentsValue"
                  type="text"
                  placeholderText={`${AppConstants.CONSIGNMENT_CONSTANTS.EDIT_CONSIGNMENT_DELIVERY.ENTER_COMMENTS}`}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container className={classes.warningContainer}>
              <SvgIcon className={classes.warningIconStyle} viewBox="0 0 24 24" component={warningIcon} />
              <Typography className={classes.warningSpanStyle}>{`${AppConstants.CONSIGNMENT_CONSTANTS.EDIT_CONSIGNMENT_DELIVERY.NO_LONGER_DELIVERED}`}</Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(RenderConsignmentEdit);
