import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AppConstants from "../../constants";
import networkHandler from "../../network/networkHandler";
import { EApiMiddlewareMethods } from "../../network/networkHandler.types";
import { UserLoginState } from "./loginSlice.types";

export const userLogin = createAsyncThunk(
  "UserLogin/Login",
  async (
    {
      userdata
    }: {
      userdata: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'authenticate',
        method: EApiMiddlewareMethods.POST,
        data: userdata
      } as any;
      const { data } = await networkHandler(request, true);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userResetPwd = createAsyncThunk(
  "UserLogin/ResetPassword",
  async (
    {
      userdata
    }: {
      userdata: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'user/forgotPassword',
        method: EApiMiddlewareMethods.POST,
        data: userdata
      } as any;
      const { data } = await networkHandler(request, true);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userRegister = createAsyncThunk(
  "UserLogin/Register",
  async (
    {
      userdata
    }: {
      userdata: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'enduser/signup',
        method: EApiMiddlewareMethods.POST,
        data: userdata
      } as any;
      const { data } = await networkHandler(request, true);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userLogout = createAsyncThunk("UserLogin/Logout", () => {
  return false;
});

export const resetUserResetPwd = createAsyncThunk("UserLogin/ResetUserResetPassword", () => {
  return false;
});

export const resetUserCreationSuccess = createAsyncThunk("UserLogin/ResetUserCreationSuccess", () => {
  return false;
});

export const resetUserError = createAsyncThunk("UserLogin/ResetUserError", () => {
  return "";
});

const initialState: UserLoginState = {
  isLoggedIn: sessionStorage.getItem('token') ? true : false,
  userCreationSuccess: false,
  loading: false,
  errorCode: "",
  countryCode: "",
  error: "",
  username: "",
  resetPwdSuccess: false
}

const userLoginSlice = createSlice({
  name: "UserLogin",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        const {
          payload,
          meta: {
            arg: { userdata }
          }
        } = action;
        state.loading = false;
        if (payload.error) {
          sessionStorage.setItem('token', "");
          sessionStorage.setItem('username', "");
          sessionStorage.setItem('name', "");
          sessionStorage.setItem('role', "[]");
          sessionStorage.setItem(AppConstants.COUNTRY_CODE, "");
          state.countryCode = "";
          state.token = "";
          state.errorCode = payload.error || AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
          if (state.errorCode === AppConstants.LOGIN_CONSTANTS.RESPONSE_CONSTANTS.ERROR_CODES.PWD_EXP) {
            sessionStorage.setItem('username', userdata.username);
          }
          state.error = payload
            ? payload.message
            : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
          state.isLoggedIn = false;
        } else {
          const token = `Bearer ${payload.jwtToken}`;
          sessionStorage.setItem('token', token);
          sessionStorage.setItem('username', userdata.username);
          sessionStorage.setItem('name', payload.name);
          sessionStorage.setItem('role', JSON.stringify(payload.roles));
          if(payload.countryCode === AppConstants.COUNTRY_ALL){
            payload.countryCode = AppConstants.DEFAULT_COUNTRY_CODE
          }
          sessionStorage.setItem(AppConstants.COUNTRY_CODE, payload.countryCode);
          state.countryCode = payload.countryCode;
          state.errorCode = "";
          state.error = "";
          state.token = token;
          state.username = userdata.username;
          state.isLoggedIn = true;
        }
      })
      .addCase(userLogin.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        sessionStorage.setItem('token', "");
        sessionStorage.setItem('username', "");
        sessionStorage.setItem('name', "");
        sessionStorage.setItem('role', "[]");
        sessionStorage.setItem(AppConstants.COUNTRY_CODE, "");
        state.countryCode = "";
        state.loading = false;
        state.token = "";
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        state.isLoggedIn = false;
      })
      .addCase(userRegister.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(userRegister.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        if (payload.error) {
          state.errorCode = payload.error || AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
          state.error = payload
            ? payload.message
            : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
          state.userCreationSuccess = false;
        } else {
          state.errorCode = "";
          state.error = "";
          state.userCreationSuccess = true;
        }
      })
      .addCase(userRegister.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        state.userCreationSuccess = false;
      })
      .addCase(userResetPwd.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(userResetPwd.fulfilled, (state, action) => {
        const payload: any = action.payload;
        state.loading = false;
        if (payload.error) {
          state.resetPwdSuccess = false;
          state.errorCode = payload.error || AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
          state.error = payload
            ? payload.message
            : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        } else {
          sessionStorage.setItem('token', '');
          sessionStorage.setItem('username', '');
          sessionStorage.setItem('name', "");
          sessionStorage.setItem('role', "[]");
          sessionStorage.setItem(AppConstants.COUNTRY_CODE, "");
          state.countryCode = "";
          state.errorCode = "";
          state.error = "";
          state.userCreationSuccess = false;
          state.resetPwdSuccess = true;
        }
      })
      .addCase(userResetPwd.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.resetPwdSuccess = false;
        state.userCreationSuccess = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(resetUserResetPwd.fulfilled, (state, action) => {
        const { payload } = action;
        state.resetPwdSuccess = payload;
      })
      .addCase(resetUserCreationSuccess.fulfilled, (state, action) => {
        const { payload } = action;
        state.userCreationSuccess = payload;
      })
      .addCase(resetUserError.fulfilled, (state, action) => {
        const { payload } = action;
        state.errorCode = payload;
        state.error = payload;
      })
      .addCase(userLogout.fulfilled, (state, action) => {
        const { payload } = action;
        sessionStorage.setItem('token', "");
        sessionStorage.setItem('username', "");
        sessionStorage.setItem('name', "");
        sessionStorage.setItem('role', "[]");
        localStorage.setItem('deliveriesSavedViews', "[]");
        localStorage.setItem('EceptionsDeliverySavedViews', "[]");
        sessionStorage.setItem(AppConstants.COUNTRY_CODE, "");
        state.countryCode = "";
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.token = "";
        state.username = "";
        state.isLoggedIn = payload;
      });
  }
});

export default userLoginSlice.reducer;