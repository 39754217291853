import { combineReducers } from "@reduxjs/toolkit";
import { UserLoginState } from "../../Login/redux/loginSlice.types";
import orderListSlice from "../../orderList/redux/orderListSlice";
import returnsListSlice from "../../returnsList/redux/returnsListSlice";
import usersSlice from "../../Users/redux/usersSlice";
import userLoginSlice from "../../Login/redux/loginSlice";
import commonSlice from "./common/commonSlice";
import { OrderListState } from "../../orderList/redux/orderListSlice.types";
import { ReturnsListState } from "../../returnsList/redux/returnsListSlice.types";
import { CommonState } from "./common/commonSlice.types";
import { UsersState } from "../../Users/redux/usersSlice.types";
import { DashboardState } from "../../Dashboard/redux/dashboardSlice.types";
import dashboardSlice from "../../Dashboard/redux/dashboardSlice";
import exceptionsDeliverySlice from "../../ExceptionsDelivery/redux/exceptionsDeliverySlice";
import { ExceptionsDeliveryState } from "../../ExceptionsDelivery/redux/exceptionsDeliverySlice.types";

const reducers = {
  orderList: orderListSlice,
  returnsList: returnsListSlice,
  userLogin: userLoginSlice,
  users: usersSlice,
  common: commonSlice,
  dashboard: dashboardSlice,
  exceptionsDelivery: exceptionsDeliverySlice
};

export interface AppState {
  orderList: OrderListState;
  returnsList: ReturnsListState;
  userLogin: UserLoginState;
  users: UsersState;
  common: CommonState;
  dashboard: DashboardState;
  exceptionsDelivery: ExceptionsDeliveryState;
}

export default combineReducers(reducers);
