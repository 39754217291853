import axios from "axios";
import { NetworkCallPayload } from "./networkHandler.types";
import { reportBaseURL, userBaseURL, repushOrderBaseURL, geoCodedAddressUrl, basicGeocodeAuthorization, yandexAuthToken, yandexApiUrl, iMileApiUrl, iMileAuthorization } from "../config/Api.config";
import { replaceUrlVariables } from "../utils/updateUrlParams";

const networkDefaultSetting = (isUser?: boolean, isAuth?: boolean, isRepushOrdeer?: boolean, isGeoCode?: boolean, isYandex?: boolean, isIMile?: boolean) => {
  axios.defaults.baseURL = reportBaseURL;
  if (isAuth) {
    if (isGeoCode) {
      axios.defaults.headers.common = { "Authorization": basicGeocodeAuthorization };
    } else if (isYandex) {
      axios.defaults.headers.common = { "Authorization": yandexAuthToken };
    } else if (isIMile) {
      axios.defaults.headers.common = { "Authorization": iMileAuthorization };
    } else{
      axios.defaults.headers.common = { "Authorization": sessionStorage.getItem("token") };
    }
  } else {
    axios.defaults.headers.common = {};
  }
  if (isUser) {
    axios.defaults.baseURL = userBaseURL;
    axios.defaults.headers.common['system-id'] = "CAMS"
  }
  if (isRepushOrdeer) {
    axios.defaults.baseURL = repushOrderBaseURL;
  }
  if (isGeoCode) {
    axios.defaults.baseURL = geoCodedAddressUrl;
  }
  if (isYandex) {
    axios.defaults.baseURL = yandexApiUrl;
  }
  if(isIMile){
    axios.defaults.baseURL = iMileApiUrl;
  }
};

const networkHandler = async (params: NetworkCallPayload, isUser?: boolean, isAuth?: boolean, isRepushOrdeer?: boolean, isGeoCode?: boolean, isYandex?: boolean, isIMile ?: boolean) => {
  const { url, urlParams, ...restApiOptions } = params;
  let updateUrl = url;
  if (urlParams) {
    updateUrl = replaceUrlVariables(url, urlParams);
  }
  // axios default configs
  networkDefaultSetting(isUser, isAuth, isRepushOrdeer, isGeoCode, isYandex, isIMile);
  return await axios.request({ url: updateUrl, ...restApiOptions });
};

export default networkHandler;