import React, { useCallback, forwardRef, ReactElement, Ref, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import CustomDialog from '../../common/Dialog';
import { useStyles } from './ColumnSettings.styles';
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import { Grid, Typography, Button } from '@material-ui/core';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import Switch from '@material-ui/core/Switch';
import MenuIcon from '@material-ui/icons/Menu';
import * as yup from 'yup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface ColumnSettingsProps {
  open: boolean,
  savedViews: any,
  columnList: any,
  closePopup: () => void,
  handleDialogAction?: (params: any) => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ColumnSettings = (props: ColumnSettingsProps) => {

  const classes = useStyles();
  const { open, columnList, savedViews, closePopup, handleDialogAction } = props;
  const [itemsList, setItemsList] = useState(columnList);

  const dialogPaperProps = {
    classes: {
      root: classes.dialogPaperPropsRoot
    },
    square: true
  }

  const [openDialog, setOpenDialog] = React.useState(false);
  
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const getInitialValues = () => {
    return {itemsList}
      
  }

  const inputFormik = useFormik({
    initialValues: getInitialValues(),
    onSubmit: (values, {resetForm}) => {
      resetForm();
      handleDialogClose();
      handleClickOpenDialog();
    }
  })

  const inputValidationSchema = yup.object({
    viewName: yup
      .string()
      .required("View name is required")
      .max(20, 'View name length should not be more than 20 characters')
  });

  const getInitialValues1 = () => {
    return {
        viewName: '',
      }
  }

  const inputFormik1 = useFormik({
    initialValues: getInitialValues1(),
    validationSchema: inputValidationSchema,
    validate: () => {
      //handleClickOpenDialog();
    },
    onSubmit: (values, {resetForm}) => {
      resetForm();
      handleCloseDialog();
      dialogActionHandler('save', values);
    }
  })

  const handleViewNameChange = useCallback(
    (e: any) => {
      inputFormik1.setFieldValue('viewName', e.target.value)
    },
    [inputFormik1]
  )

  const handleViewNameSelect = useCallback(
    (value: any) => {
      inputFormik1.setFieldValue('viewName', value)
    },
    [inputFormik1]
  )

  
  
  const onItemSortEnd = (indexInfo: any) => {
    const newItemsList = arrayMove(itemsList, indexInfo.oldIndex, indexInfo.newIndex);
    setItemsList(newItemsList);
  };

  const handleChange = (event: any, itemList: any) => {
    let cloneItemList = JSON.parse(JSON.stringify(itemList));
    let newItemList = cloneItemList.map((item: any) => {
      if (item.field === event.target.name) {
        item.hide = event.target.checked ? false : true
      }
      return item;
    })
    setItemsList(newItemList);
  }

  useEffect(() => {
    setItemsList(columnList);
  }, [columnList])

  const DragHandle = SortableHandle(() => <MenuIcon className={classes.menuIcon} />); // This can be any component you want

  const SortableItem = SortableElement((itemInfo : any) => {
    return (
      <Grid container className={classes.sortableItem} direction="column" item>
      <li>
        <Grid container alignItems="center">
          <Grid className="itemColumn item1" item xs={1}>
            <DragHandle />
          </Grid>
          <Grid className="itemColumn item2" item xs={8}>
            {itemInfo.value.item.headerName}
          </Grid>
          <Grid className="itemColumn item3" item xs={3}>
            <Switch
              checked={itemInfo.value.item.hide ? false : true}
              onChange={(e) => handleChange(e,  itemsList)}
              color="primary"
              name={itemInfo.value.item.field}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Grid>
        </Grid>
      </li>
      </Grid>
    );
  });

  const SortableList = SortableContainer(( itemsList : any ) => {
    return (
      <ul>
      <Grid container direction="column">
          {itemsList && itemsList.items.list.map((item: any, index: any) => (
              <SortableItem key={`item-${index}`} index={index} value={{item:item}} />
          ))}
        </Grid>
      </ul>
    );
  });

  const getTitleContent = () => {
    return (
      <Grid className={classes.titleContainer} container>
        <Grid item>
          <Grid className="headingContainer" container>
            <Grid item>
              <Grid container>
                <Grid item>
                  <Typography className="titleHeading">COLUMN SETTINGS</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const getDetailsContent = () => {
    let colItemsList = {list: itemsList}
    return (
      <form onSubmit={inputFormik.handleSubmit}>
      <Grid className={classes.contentContainer} container>
        <Grid item>
          <Grid className="detailsContent" container>
          <SortableList items={colItemsList} onSortEnd={onItemSortEnd} />
          </Grid>
        </Grid>
      </Grid>
      </form>
    )
  }

  const handleDialogClose = () => {
    closePopup();
  }

  const dialogActionHandler = useCallback(
    (type: string, values?: any,) => {
      handleDialogAction && handleDialogAction({
        type: type,
        values: values,
        itemsList: itemsList,
      });
    },
    [handleDialogAction, itemsList],
  )

  const getDialogActions = () => {
    return (
      <form className={classes.buttons} onSubmit={inputFormik.handleSubmit}>
        <Grid className="buttonsContainer" container>
          <Button className="userBtn secondary" variant="contained" onClick={() => { dialogActionHandler('cancel', []) }}>CANCEL</Button>
          <Button className="userBtn primary" variant="contained" type="submit">SAVE VIEW</Button>
        </Grid>
      </form>
    );
  };

  return (
    <>
    <CustomDialog
      open={open}
      TransitionComponent={Transition}
      PaperProps={dialogPaperProps}
      title={getTitleContent()}
      content={getDetailsContent()}
      actions={getDialogActions()}
      handleClose={handleDialogClose}
    ></CustomDialog>
    <Dialog className={classes.customDialog} open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
      <form className={classes.buttons} onSubmit={inputFormik1.handleSubmit}>
        <DialogTitle id="form-dialog-title">
          Please provide name of View
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseDialog}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <Autocomplete
            freeSolo
            fullWidth
            id="free-solo-2-demo"
            disableClearable
            options={savedViews.map((option: any) => option.viewName)}
            onChange={(event, newValue) => {
              handleViewNameSelect(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                onChange={handleViewNameChange}
                error={inputFormik1.touched.viewName && Boolean(inputFormik1.errors.viewName)}
                helperText={inputFormik1.touched.viewName && inputFormik1.errors.viewName}
                InputProps={{ ...params.InputProps, type: 'search', className: 'autoSearchViewName', name: 'viewName', placeholder: 'Enter View Name' }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button className="userBtn customBtn secondary" variant="contained" onClick={handleCloseDialog} color="primary">Cancel</Button>
          <Button className="userBtn customBtn primary" variant="contained" type="submit" color="primary">Save</Button>
        </DialogActions>
        </form>
      </Dialog>
      </>
  )
}

export default ColumnSettings;