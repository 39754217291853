import { Dialog, WithStyles, withStyles, Theme, Grid } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React, { ForwardRefExoticComponent } from 'react';
import { styles } from './Dialog.styles';

interface CustomDialogProps {
  className?: '';
  PaperProps?: {};
  TransitionComponent?: ForwardRefExoticComponent<any>;
  open: boolean;
  title: any;
  content: any;
  actions?: any;
  handleClose: () => void;
}

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={[classes.root, "do-not-print"].join(" ")} {...other}>
      <Grid>{children}</Grid>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    overflowX: "hidden"
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions);


const CustomDialog = (props: CustomDialogProps) => {

  const { className, PaperProps, TransitionComponent, open, title, content, actions, handleClose } = props;
  return (
    <>
      <Dialog
        className={className}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={PaperProps}
        TransitionComponent={TransitionComponent}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {title}
        </DialogTitle>
        <DialogContent id="customized-dialog-content" className="do-not-print">
          {content}
        </DialogContent>
        {actions ? <DialogActions className="do-not-print">
          {actions}
        </DialogActions> : null}
      </Dialog>
    </>
  );
}

export default CustomDialog;