import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createAddUserPayload,
  createEditUserPayload,
  createFilterPayload,
  getStatusField,
  lastMileUserDataTransform
} from "../mocks/users/response.transforms";
import { AppState } from "../config/redux/reducers";
import { useStyles } from "./Users.styles";
import AppConstants from "../constants";
import GridWrapper from "../common/GridWrapper";
import Loader from "../common/Loader";
import CustomStatusBar from "../common/CustomStatusBar";
import { userLogout } from "../Login/redux/loginSlice";
import _ from "lodash";
import { fetchUsersByFilter, fetchAddUserDropdownValues, updateUser, createUserViaSA, resetUserCreationStatus, resetUserUpdationStatus, resetUsersError } from "./redux/usersSlice";
import UserDetails from "./UserDetails";
import { fetchFiltersDropdownValues } from "../orderList/redux/orderListSlice";
import { Grid, Typography, Tooltip } from "@material-ui/core";
import { RowData, RowParams, SelectionChangeParams, SortModel } from "../common/GridWrapper/DataTable";
import UserAddOrEditDetails from "./UserAddOrEditDetails";

const Users = () => {
  const componentClasses = useStyles();
  const dispatch = useDispatch();
  const { data, tableLoading, loading, userCreationStatus, userCreationMsg, userUpdateStatus, addUserDropdowns, userUpdateMsg, error, errorCode } = useSelector(
    (state: AppState) => state.users
  );
  const { filtersDropdowns } = useSelector(
    (state: AppState) => state.orderList
  );

  const { countryCode } = useSelector(
    (state: AppState) => state.common
  );
  const [listToShow, setListToShow] = useState([]);
  const [userData, setUserData] = useState({});

  const [openUserDetailsPopup, setOpenUserDetailsPopup] = useState(false);
  const [openUserAddPopup, setOpenUserAddPopup] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([] as RowData[]);

  const [tableWrapperData, setTableWrapperData] = useState({
    ...AppConstants.USERS_CONSTANTS.TABLE_WRAPPER_DATA, HeaderData: AppConstants.USERS_CONSTANTS.TABLE_WRAPPER_DATA.HeaderData.map((header: any) => {
      let newHeader = { ...header };
      if (newHeader.field === 'status') {
        newHeader.renderCell = (params: any) => (
          <Grid className={componentClasses.statusHeader} container alignItems="center">
            <Grid item className={`statusIcon ${getStatusField('color', params.value)}`}></Grid>
            <Grid item className="statusMsg">
            <Tooltip arrow placement="top" classes={{tooltip: componentClasses.customTooltip,arrow: componentClasses.customArrow}} title={getStatusField('tooltip', params.value)}>
              <Typography className="status">{getStatusField('name', params.value)}</Typography>
            </Tooltip>
            </Grid>
          </Grid>
        );
      }
      return newHeader;
    })
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const snackbarType = useRef(AppConstants.SNACKBAR.TYPES.SUCCESS);
  const snackbarMessage = useRef("");

  const fetchAllFilters = useCallback(
    () => {
      dispatch(
        fetchFiltersDropdownValues()
      );
      dispatch(
        fetchAddUserDropdownValues()
      );
    },
    [dispatch]
  );

  const handleHeaderButtonsDisableState = useCallback(
    () => {
      let headerButtons = tableWrapperData.headerButtons.map((btn: any) => {
        switch (btn.name) {
          case 'addUser':
            btn.disabled = false;
            break;
          default:
            break;
        }
        return btn;
      });
      setTableWrapperData({ ...tableWrapperData, headerButtons: headerButtons });
    },
    [tableWrapperData]
  );
  const handleLoadMoreDisableState = useCallback(
    () => {
      let loadMoreState = tableWrapperData.loadMore;
      loadMoreState.disabled = data.totalPages ? data.pageable.pageNumber + 1 >= data.totalPages : true;
      setTableWrapperData({ ...tableWrapperData, loadMore: loadMoreState });
    },
    [tableWrapperData, data]
  );

  const filterCallback = (filters: any, isLoadMore?: boolean) => {
    if (filters) {
      const requestContinuationToken = data.pageable.requestContinuation;
      let payloadFilters = _.cloneDeep(filters);
      payloadFilters[AppConstants.COUNTRY_CODE] = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;
      const payload = createFilterPayload(payloadFilters, data, isLoadMore);
      dispatch(
        fetchUsersByFilter({
          filters: payload,
          requestContinuationToken: requestContinuationToken
        })
      );
    }
  }

  const searchFilterHandler = (dataFilter: { [key: string]: string }, filters: any, isLoadMore: boolean) => {
    const searchKeys = Object.keys(dataFilter);
    for (const searchKey of searchKeys) {
      dataFilter[searchKey] = _.trim(dataFilter[searchKey]);
      if (dataFilter[searchKey] && dataFilter[searchKey].length >= AppConstants.SEARCH_BOX_LIMIT) {
        let searchPayload = _.cloneDeep(filters);
        searchPayload[AppConstants.COUNTRY_CODE] = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;
        const payload = createFilterPayload(searchPayload, data, isLoadMore);
        payload.search = dataFilter;
        filterCallback(payload);
        return;
      }else{
        openSnackbarPopup(AppConstants.SEARCH_TEXT_LIMIT, AppConstants.SNACKBAR.TYPES.ERROR);
        return;
      }
    }
  };

  const handleRowClick = (params: RowParams) => {
    if (data && params.data) {
      const selectedRow = params.data;
      const selectedUser = data.content.filter((user: any) => user.email === selectedRow.email);
      const user = selectedUser.length ? { ...selectedUser[0], tableRecord: selectedRow } : { tableRecord: selectedRow };
      setUserData(user);
      setOpenUserDetailsPopup(true);
    }
  }

  const updateSelectedRows = useCallback(
    (params: SelectionChangeParams) => {
      setSelectedUsers(params.rows);
    },
    []
  );

  const handleHeaderButtonClick = useCallback(
    (btnObj: any) => {
      if (btnObj && btnObj.type) {
        switch (btnObj.type) {
          case 'addUser':
            openUserUpdatePopup(false);
            break;
          default:
            break;
        }
      }
    },
    []
  );

  const closeUserDetailsPopup = () => {
    setOpenUserDetailsPopup(false);
  }

  const closeUserAddPopup = () => {
    setOpenUserAddPopup(false);
  }
  
  const openSnackbarPopup = (msg: string, type: string) => {
    snackbarMessage.current = msg;
    snackbarType.current = type;
    setOpenSnackbar(true);
  }

  const handleSessionExpired = useCallback(
    () => {
      if (errorCode === AppConstants.CONSIGNMENT_CONSTANTS.RESPONSE_CONSTANTS.ERROR_CODES.SESSION_TIMEOUT) {
        dispatch(
          userLogout()
        );
      }
    },
    [dispatch, errorCode],
  )

  const updateUserdata = useCallback(
    (params: any) => {
      const payload = createEditUserPayload(_.cloneDeep(params), addUserDropdowns);
      if (payload) {
        dispatch(
          updateUser({
            payload: payload
          })
        )
      }
    },
    [addUserDropdowns, dispatch],
  )

  const openUserUpdatePopup = (isEdit: boolean) => {
    setIsEdit(isEdit);
    setOpenUserAddPopup(true);
  }

  const handleUserDetailsActions = useCallback(
    (params: any) => {
      if (params.type === 'update') {
        if (params.isStatusUpdate) {
          updateUserdata(params.values);
        } else {
          closeUserDetailsPopup();
          openUserUpdatePopup(true);
        }
      } else {
        if (params.isStatusUpdate) {
          updateUserdata(params.values);
        } else {
          closeUserDetailsPopup();
        }
      }
    },
    [updateUserdata],
  )

  const handleAddUser = useCallback(
    (params: any) => {
      const payload = createAddUserPayload(_.cloneDeep(params), addUserDropdowns);
      dispatch(
        createUserViaSA({
          payload: payload
        })
      )
    },
    [addUserDropdowns, dispatch]
  )

  const handleUserUpdateActions = useCallback(
    (params: any) => {
      if (params.type === 'submit') {
        if (params.isEdit) {
          updateUserdata(params.values);
        } else {
          handleAddUser(params.values);
        }
      } else {
        closeUserAddPopup();
      }
    },
    [handleAddUser, updateUserdata],
  )

  const handleSnackbarOnExited = useCallback(
    () => {
      handleSessionExpired();
      if (snackbarType.current === AppConstants.SNACKBAR.TYPES.SUCCESS) {
        if (userCreationStatus) {
          dispatch(
            resetUserCreationStatus()
          )
        }
        if (userUpdateStatus) {
          dispatch(
            resetUserUpdationStatus()
          )
        }
      }
      if (snackbarType.current === AppConstants.SNACKBAR.TYPES.ERROR) {
        dispatch(
          resetUsersError()
        )
      }
    },
    [dispatch, handleSessionExpired, userCreationStatus, userUpdateStatus],
  )

  const handleSnackbarClose = useCallback(() =>{
      setOpenSnackbar(false);
      handleSnackbarOnExited();
    },
    [handleSnackbarOnExited]
  )

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpenSnackbar(false);
      handleSnackbarOnExited();
    }, AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT);
    return () => clearTimeout(timer);
  })

  useEffect(() => {
    if (error) {
      openSnackbarPopup(error, AppConstants.SNACKBAR.TYPES.ERROR);
    }
  }, [error])

  useEffect(() => {
    if (userCreationStatus) {
      closeUserAddPopup();
      openSnackbarPopup(userCreationMsg, AppConstants.SNACKBAR.TYPES.SUCCESS);
      filterCallback(tableWrapperData.defaultFiltersObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCreationStatus, userCreationMsg])

  useEffect(() => {
    if (userUpdateStatus) {
      closeUserDetailsPopup();
      closeUserAddPopup();
      openSnackbarPopup(userUpdateMsg, AppConstants.SNACKBAR.TYPES.SUCCESS);
      filterCallback(tableWrapperData.defaultFiltersObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userUpdateStatus])

  useEffect(() => {
    setListToShow(lastMileUserDataTransform(data, countryCode) as any);
    handleLoadMoreDisableState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    handleHeaderButtonsDisableState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listToShow, selectedUsers]);

  useEffect(() => {
    let filterFormData = tableWrapperData.FilterFormData.map((filter: any) => {
      if (filter.fieldName === 'hub') {
        filter.items = filtersDropdowns.hubList[countryCode] || [];
      }
      if (filter.fieldName === 'role') {
        filter.items = addUserDropdowns.rolesList || [];
      }
      if (filter.fieldName === 'status') {
        filter.items = addUserDropdowns.statusList || [];
      }
      if (filter.fieldName === 'type') {
        filter.items = addUserDropdowns.userTypeList || [];
      }
      return filter;
    });
    setTableWrapperData({ ...tableWrapperData, FilterFormData: filterFormData });
    //filterCallback(tableWrapperData.defaultFiltersObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode])

  useEffect(() => {
    if (tableWrapperData && tableWrapperData.FilterFormData) {
      let filterFormData = tableWrapperData.FilterFormData.map((filter: any) => {
        if (filter.fieldName === 'hub') {
          filter.items = filtersDropdowns.hubList[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode || AppConstants.COUNTRY_OBJ.value] || [];
        }
        if (filter.fieldName === 'role') {
          filter.items = addUserDropdowns.rolesList || [];
        }
        if (filter.fieldName === 'status') {
          filter.items = addUserDropdowns.statusList || [];
        }
        if (filter.fieldName === 'type') {
          filter.items = addUserDropdowns.userTypeList || [];
        }
        return filter;
      });
      setTableWrapperData({ ...tableWrapperData, FilterFormData: filterFormData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersDropdowns, addUserDropdowns])

  return (
    <div className={componentClasses.root}>
      {loading && <Loader></Loader>}
      <CustomStatusBar open={openSnackbar} handleClose={handleSnackbarClose} message={snackbarMessage.current} type={snackbarType.current} />
      <UserDetails open={openUserDetailsPopup} user={userData} handleDialogAction={handleUserDetailsActions} closePopup={closeUserDetailsPopup}></UserDetails>
      <UserAddOrEditDetails open={openUserAddPopup} user={userData} isEdit={isEdit} handleDialogAction={handleUserUpdateActions} closePopup={closeUserAddPopup}></UserAddOrEditDetails>
      <Grid className={componentClasses.pageTitle}>User Management</Grid>
      <GridWrapper
        headerData={tableWrapperData.HeaderData}
        rowData={listToShow}
        loading={loading ? undefined : tableLoading}
        searchTypes={tableWrapperData.SEARCH_TYPES}
        filterForm={tableWrapperData.FilterFormData}
        defaultFilter={tableWrapperData.defaultFiltersObj}
        headerButtons={tableWrapperData.headerButtons}
        title={tableWrapperData.title}
        sortModel={tableWrapperData.sortModel as SortModel}
        loadMore={{ ...tableWrapperData.loadMore, rowCount: data.totalElements || 0 }}
        onRowClick={handleRowClick}
        onSelectionChange={updateSelectedRows}
        headerBtnClick={handleHeaderButtonClick}
        searchCallback={searchFilterHandler}
        filterGrid={filterCallback}
        fetchAllFilters={fetchAllFilters}
      />
      {/* <CustomSnackbar open={openSnackbar} handleClose={handleSnackbarClose} onExited={handleSnackbarOnExited} autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT} message={snackbarMessage.current} type={snackbarType.current} /> */}
    </div>
  );
};

export default Users;
