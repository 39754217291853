export enum EApiMiddlewareMethods {
  DELETE = "DELETE",
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
}
export interface NetworkCallPayload {
  data?: object | string; // Usually used with "POST|PUT" methods
  headers?: object; // Override headers
  isAbsUrl?: boolean; // Set this to true for absolute URLs
  method?: EApiMiddlewareMethods;
  urlParams?: { [key: string]: string }; // Usually used with "GET|DELETE" methods
  url: string;
}
