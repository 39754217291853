import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%"
    },
    headerSection: {},
    tableContainer: {
      borderRadius: "1px 1px 8px 8px",
      boxShadow: "0px 2px 4px rgb(0 0 0 / 10%)",
      height: "calc(100% - 130px)"
    },
    exceptionTableContainer: {
      height: "calc(100% - 310px)"
    }
  })
);
