import { Button, Typography } from '@material-ui/core';
import React from 'react';
import CustomDialog from '../common/Dialog';
import ShippingLabel from './ShippingLabel';

interface PrintShippingLabelProps {
  open: boolean,
  selectedRows: any,
  closePopup: () => void
}

const PrintShippingLabel = (props: PrintShippingLabelProps) => {

  const { open, selectedRows, closePopup } = props;

  const getTitleContent = () => {
    return (
      <Typography variant="h6">Shipping Label</Typography>
    )
  }

  const getDialogContent = () => {
    return (
      selectedRows.length ? selectedRows.map((item: any) => (
        <ShippingLabel key={item.id} record={item}></ShippingLabel>
      )) : null
    )
  }

  const printDialogContent = () => {
    window.print();
  }

  const getDialogActions = () => {
    return (
      <Button className="primary" variant="contained" onClick={printDialogContent}>Print</Button>
    );
  };

  return (
    <CustomDialog
      open={open}
      title={getTitleContent()}
      content={getDialogContent()}
      actions={getDialogActions()}
      handleClose={closePopup}
    />
  );
}

export default PrintShippingLabel;