import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import networkHandler from "../../network/networkHandler";
import { EApiMiddlewareMethods } from "../../network/networkHandler.types";
import { transformSelectValues } from "../../utils/helpers.utils";
import { UsersState } from "./usersSlice.types";
import AppConstants from "../../constants";
import { checkUserAsAdmin, checkUserAsSuperAdmin } from "../../mocks/users/response.transforms";

export const fetchFiltersDropdownValues = createAsyncThunk(
  "Users/FetchFiltersDropdownValues",
  async (params, { rejectWithValue }) => {
    try {
      const request = {
        url: `filters/filter/all`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, true, true);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchAddUserDropdownValues = createAsyncThunk(
  "Users/FetchAddUserDropdownValues",
  async (params, { rejectWithValue }) => {
    try {
      const request = {
        url: `user/populate`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, true, true);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUsersByFilter = createAsyncThunk(
  "Users/FetchByFilter",
  async (
    {
      filters,
      requestContinuationToken
    }: {
      requestContinuationToken?: string;
      filters: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'users/search/filter',
        method: EApiMiddlewareMethods.POST,
        data: filters
      } as any;
      if (requestContinuationToken && filters.page) {
        request.headers = {
          'x-requestcontinuation-token': requestContinuationToken
        }
      }
      const { data } = await networkHandler(request, true, true);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createUserViaSA = createAsyncThunk(
  "Users/CreateUserViaSA",
  async (
    {
      payload
    }: {
      payload: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'user/create',
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      const { data } = await networkHandler(request, true, true);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const bulkUpdateUsers = createAsyncThunk(
  "Users/BulkUpdateUsers",
  async (
    {
      payload
    }: {
      payload: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'user/bulkapproval',
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      const { data } = await networkHandler(request, true, true);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  "Users/updateUser",
  async (
    {
      payload
    }: {
      payload: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'user/update',
        method: EApiMiddlewareMethods.PUT,
        data: payload
      } as any;
      const { data } = await networkHandler(request, true, true);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const clearList = createAsyncThunk("Users/ClearContents", () => {
  return [];
});

export const resetUsersError = createAsyncThunk("Users/ResetError", () => {
  return "";
});

export const resetUserCreationStatus = createAsyncThunk("Users/ResetUserCreationStatus", () => {
  return false;
});

export const resetUserUpdationStatus = createAsyncThunk("Users/ResetUserUpdationStatus", () => {
  return false;
});

export const setLoggedInUser = createAsyncThunk("Users/SetLoggedInUser", () => {
  return false;
});

export const resetFilterUserBySearch = createAsyncThunk("Users/resetFilterUserBySearch", () => {
  return false;
});

const initialState: UsersState = {
  loading: false,
  tableLoading: false,
  userUpdateStatus: false,
  userCreationStatus: false,
  userCreationMsg: "",
  userUpdateMsg: "",
  isAdmin: sessionStorage.getItem('role') ? checkUserAsAdmin(JSON.parse(sessionStorage.getItem('role') || '[]')) : false,
  isSuperAdmin: sessionStorage.getItem('role') ? checkUserAsSuperAdmin(JSON.parse(sessionStorage.getItem('role') || '[]')) : false,
  filtersDropdowns: {
    hubList: [],
    rolesList: [],
    userTypeList: [],
    statusList: []
  },
  addUserDropdowns: {
    countriesList: [],
    hubList: [],
    rolesList: [],
    userTypeList: [],
    statusList: []
  },
  data: {
    content: [],
    pageable: {
      pageNumber: 0,
    },
  },
  error: "",
  filterUserBySearch: false
};

const UsersSlice = createSlice({
  name: "Users",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(fetchFiltersDropdownValues.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchFiltersDropdownValues.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        if (payload.error) {
          state.errorCode = payload.error || AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
          state.error = payload
            ? payload.message
            : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        } else {
          let hubList: any = {};
          if (payload.hubMap) {
            Object.keys(payload.hubMap).filter((country: any) => {
              hubList[country] = transformSelectValues(payload.hubMap[country]);
              return true;
            });
          }
          state.errorCode = "";
          state.error = "";
          state.filtersDropdowns = {
            rolesList: transformSelectValues(payload.roles),
            userTypeList: transformSelectValues(payload.userTypes),
            statusList: transformSelectValues(payload.userStatus),
            hubList: hubList
          };
        }
      })
      .addCase(fetchFiltersDropdownValues.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchAddUserDropdownValues.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchAddUserDropdownValues.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        if (payload.error) {
          state.errorCode = payload.error || AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
          state.error = payload
            ? payload.message
            : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        } else {
          let countriesList: any = {};
          let hubList: any = {};
          if (payload.roleCountryMap) {
            Object.keys(payload.roleCountryMap).filter((role: any) => {
              countriesList[role] = transformSelectValues(payload.roleCountryMap[role]);
              return true;
            });
          }
          if (payload.countryHubMap) {
            Object.keys(payload.countryHubMap).filter((country: any) => {
              hubList[country] = transformSelectValues(payload.countryHubMap[country]);
              return true;
            });
          }
          state.errorCode = "";
          state.error = "";
          state.addUserDropdowns = {
            countriesList: countriesList,
            rolesList: transformSelectValues(payload.roles),
            userTypeList: transformSelectValues(payload.userTypes),
            statusList: transformSelectValues(payload.userStatus),
            hubList: hubList
          };
        }
      })
      .addCase(fetchAddUserDropdownValues.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.addUserDropdowns = {
          countriesList: {},
          rolesList: [],
          userTypeList: [],
          statusList: [],
          hubList: {}
        };
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(clearList.fulfilled, (state, action) => {
        const { payload } = action;
        state.tableLoading = false;
        state.errorCode = "";
        state.error = "";
        state.data = {
          ...payload,
          content: action.payload,
        };
      })
      .addCase(resetUsersError.fulfilled, (state, action) => {
        const { payload } = action;
        state.errorCode = payload;
        state.error = payload;
      })
      .addCase(resetUserCreationStatus.fulfilled, (state, action) => {
        const { payload } = action;
        state.userCreationStatus = payload;
      })
      .addCase(resetUserUpdationStatus.fulfilled, (state, action) => {
        const { payload } = action;
        state.userUpdateStatus = payload;
      })
      .addCase(setLoggedInUser.fulfilled, (state) => {
        state.isAdmin = checkUserAsAdmin(JSON.parse(sessionStorage.getItem('role') || '[]'));
        state.isSuperAdmin = checkUserAsSuperAdmin(JSON.parse(sessionStorage.getItem('role') || '[]'));
      })
      .addCase(fetchUsersByFilter.pending, (state) => {
        state.tableLoading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchUsersByFilter.fulfilled, (state, action) => {
        const {
          payload,
          meta: {
            arg: { filters }
          }
        } = action;
        state.tableLoading = false;
        if(filters.search){
          state.filterUserBySearch = true;
        }
        if (payload.error) {
          state.errorCode = payload.error || AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
          state.error = payload
            ? payload.message
            : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        } else {
          state.errorCode = "";
          state.error = "";
          state.data = {
            ...payload,
            content:
              filters.page === 0
                ? [...payload.content]
                : [...state.data.content, ...payload.content],
          };
        }
      })
      .addCase(fetchUsersByFilter.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.tableLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(createUserViaSA.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(createUserViaSA.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        if (payload.error) {
          state.errorCode = payload.error || AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
          state.error = payload
            ? payload.message
            : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        } else {
          state.errorCode = "";
          state.error = "";
          state.userCreationStatus = true;
          state.userCreationMsg = `User ${payload.firstName} ${payload.lastName} has been added successfully`;
        }
      })
      .addCase(createUserViaSA.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.userCreationStatus = false;
        state.userCreationMsg = "";
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(bulkUpdateUsers.pending, (state) => {
        state.tableLoading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(bulkUpdateUsers.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.tableLoading = false;
        if (payload.error) {
          state.errorCode = payload.error || AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
          state.error = payload
            ? payload.message
            : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        } else {
          state.errorCode = "";
          state.error = "";
          state.userUpdateStatus = true;
          state.userUpdateMsg = payload;
        }
      })
      .addCase(bulkUpdateUsers.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.tableLoading = false;
        state.userUpdateStatus = false;
        state.userUpdateMsg = "";
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        if (payload.error) {
          state.errorCode = payload.error || AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
          state.error = payload
            ? payload.message
            : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        } else {
          state.errorCode = "";
          state.error = "";
          state.userUpdateStatus = true;
          state.userUpdateMsg = payload;
        }
      })
      .addCase(updateUser.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.userUpdateStatus = false;
        state.userUpdateMsg = "";
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(resetFilterUserBySearch.fulfilled, (state, action) => {
        const { payload } = action;
        state.filterUserBySearch = payload;
      })
  },
});

export default UsersSlice.reducer;
