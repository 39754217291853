import { Checkbox, Chip, FormControl, FormHelperText, InputLabel, ListItemText, MenuItem, PopoverOrigin, Select, SelectProps } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useStyles, anchorOriginDefault } from './SelectBox.styles';
import { ReactComponent as downArrowIcon } from './../../assets/icons/down-arrow.svg';
import AppConstants from '../../constants';

export interface SelectItemProps {
  name?: string;
  value: any;
}

interface SelectBoxProps extends SelectProps {
  handleChange?: (value: any) => void;
  handleFormikChange?: (e: React.ChangeEvent<any>) => void;
  items: Array<SelectItemProps>;
  initialValues?: SelectItemProps;
  helperText?: any;
  fullWidth?: boolean;
  hideIconComponent?: boolean;
  menuPaperProps?: string;
  anchorOrigin?: PopoverOrigin | undefined;
  reset?: boolean;
}
const SelectBox = (props: SelectBoxProps) => {

  const classes = useStyles();

  const { handleChange, handleFormikChange, value, initialValues, multiple, id, label, items, hideIconComponent, menuPaperProps, anchorOrigin, className, reset, error, helperText, fullWidth, ...rest } = props;
  const [itemsDdn, setItemsDdn] = useState(items.map(item => item.value));
  const [ddnValue, setDdnValue] = useState<any>(value || AppConstants.SELECT_NONE.value);
  const [defaultValue, setDefaultValue] = useState<any>(value || []);
  const anchorEl = useRef(null);
  const [isOpen, setIsOpen] = useState(false);



  const getMenuItems = () => {
    return items.length ? items.map((item, index) => (
      <MenuItem key={`${item.value}-${index}`} value={item.value}>
        {multiple &&
          <Checkbox size="small" color="primary" checked={ddnValue.indexOf(item.value) > -1} />
        }
        <ListItemText primary={item.name} />
      </MenuItem>
    )) : null;
  }

  const handleDdnChange = useCallback(
    (event: React.ChangeEvent<SelectItemProps>, child: React.ReactNode) => {
      let selected = event.target.value;
      if (multiple) {
        const selectAllIdx = selected.indexOf(AppConstants.SELECT_ALL.value);
        const selectAllPreviousIdx = ddnValue.indexOf(AppConstants.SELECT_ALL.value);
        const selectNoneIdx = selected.indexOf(AppConstants.SELECT_NONE.value);
        if (selectNoneIdx > -1) {
          selected = selected.filter((item: any) => item !== AppConstants.SELECT_NONE.value);
        }
        if (selectAllIdx > -1) {
          if (selected.length < itemsDdn.length) {
            if (selectAllPreviousIdx > -1) {
              selected = selected.filter((item: any) => item !== AppConstants.SELECT_ALL.value);
            } else {
              selected = itemsDdn.slice();
            }
          }
        } else {
          if (selected.length < itemsDdn.length) {
            if (selectAllPreviousIdx > -1) {
              selected = [];
            } else if (selected.length === itemsDdn.length - 1) {
              selected = itemsDdn.slice();
            }
          }
        }
        if (!selected.length) {
          selected = [AppConstants.SELECT_NONE.value];
        }
      }
      setDdnValue(selected);
      handleFormikChange && handleFormikChange(event);
    },
    [ddnValue, itemsDdn, multiple, handleFormikChange],
  );

  useEffect(() => {
    if (multiple) {
      if(!defaultValue.includes("all")){
        setItemsDdn([AppConstants.SELECT_ALL.value, ...items.map(item => item.value)]);
        setDdnValue(defaultValue);
      }else{
        setItemsDdn([AppConstants.SELECT_ALL.value, ...items.map(item => item.value)]);
        setDdnValue([AppConstants.SELECT_ALL.value, ...items.map(item => item.value)]);
      }
    } else {
      setDdnValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    if (reset) {
      if (multiple) {
        if(!defaultValue.includes("all")){
          setDdnValue(defaultValue);
        }else{
          setDdnValue(itemsDdn);
        }
      } else {
        setDdnValue(value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    handleChange && handleChange(ddnValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ddnValue])

  useEffect(() => {
    setDefaultValue(defaultValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getChips = useCallback(
    (selected: any) => {
      if (multiple) {
        if (selected.indexOf(AppConstants.SELECT_ALL.value) > -1) {
          return AppConstants.SELECT_ALL.name;
        }
        if (selected.indexOf(AppConstants.SELECT_NONE.value) > -1) {
          return initialValues ? initialValues.name : AppConstants.SELECT_NONE.name;
        }
        return (
          <div className={classes.chips}>
            {(selected as string[]).map((value) => {
              const item: SelectItemProps = value === AppConstants.SELECT_ALL.value ? AppConstants.SELECT_ALL : items.filter(i => i.value === value).length ? items.filter(i => i.value === value)[0] : {
                name: "",
                value: ""
              };
              return (
                <Chip key={value} label={item.name} size="small" className={classes.chip} />
              )
            })}
          </div>
        )
      } else {
        const item: SelectItemProps = items.filter(i => i.value === selected).length ? items.filter(i => i.value === selected)[0] : initialValues ? initialValues : AppConstants.SELECT_NONE;
        return item.name;
      }
    },
    [multiple, items, classes, initialValues],
  )

  return (
    <>
      <FormControl variant="outlined" className={classes.formControl} error={error} fullWidth={fullWidth}>
        {label && <InputLabel id={`${id}-${label}`}>{label}</InputLabel>}
        <Select
          {...rest}
          displayEmpty
          className={`${className || ''} ${isOpen ? 'open ' : ''}${label ? 'hasLabel ' : ''}`}
          ref={anchorEl}
          labelId={label ? `${id}-${label}` : ""}
          multiple={multiple}
          value={ddnValue}
          onOpen={() => { setIsOpen(true) }}
          onClose={() => { setIsOpen(false) }}
          onChange={handleDdnChange}
          renderValue={getChips}
          IconComponent={hideIconComponent ? () => (<></>) : downArrowIcon}
          MenuProps={{
            classes: {
              list: classes.menuPropsList,
              paper: menuPaperProps || classes.menuPropsPaper
            },
            anchorEl: anchorEl.current,
            getContentAnchorEl: null,
            anchorOrigin: anchorOrigin || anchorOriginDefault
          }}
        >
          {
            multiple &&
            <MenuItem className="selectAll" key={AppConstants.SELECT_ALL.value} value={AppConstants.SELECT_ALL.value}>
              <Checkbox size="small" color="primary" checked={ddnValue.indexOf(AppConstants.SELECT_ALL.value) > -1} />
              <ListItemText primary={AppConstants.SELECT_ALL.name} />
            </MenuItem>
          }
          {getMenuItems()}
        </Select>
        {
          helperText &&
          <FormHelperText>{helperText}</FormHelperText>
        }
      </FormControl>
    </>
  );
}

export default SelectBox;