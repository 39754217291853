import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import networkHandler from "../../network/networkHandler";
import { EApiMiddlewareMethods } from "../../network/networkHandler.types";
import { transformSelectValues } from "../../utils/helpers.utils";
import { DashboardState } from "./dashboardSlice.types";
import AppConstants from "../../constants";
import _ from "lodash";

export const fetchFiltersDropdownValues = createAsyncThunk(
  "Dashboard/FetchFiltersDropdownValues",
  async (params, { rejectWithValue }) => {
    try {
      const request = {
        url: `filters/filter/all/ORDER`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, true);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDashboardByFilter = createAsyncThunk(
  "Dashboard/FetchByFilter",
  async (
    {
      filters
    }: {
      filters: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'orders/order/carrier/performance',
        method: EApiMiddlewareMethods.POST,
        data: filters
      } as any;
      let { data } = await networkHandler(request, false, true);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDashboardByFilterOnTime = createAsyncThunk(
  "Dashboard/FetchByFilterOnTime",
  async (
    {
      filters
    }: {
      filters: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'orders/order/carrier/ontime',
        method: EApiMiddlewareMethods.POST,
        data: filters
      } as any;
      let { data } = await networkHandler(request, false, true);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const resetDashboardListError = createAsyncThunk("Dashboard/ResetError", () => {
  return "";
});

const initialState: DashboardState = {
  loading: true,
  filtersDropdowns: {
    countriesList: [],
    carrierList: [],
    hubList: [],
    delPropositionList: []
  },
  data: {
    allRows: [],
    top5rows: [],
  },
  error: "",
};

const dashboardSlice = createSlice({
  name: "Dashboard",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(fetchFiltersDropdownValues.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchFiltersDropdownValues.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        let carrierList: any = {};
        let hubList: any = {};
        let delPropositionList: any = {};
        if (payload.hubMap) {
          Object.keys(payload.hubMap).filter((country: any) => {
            hubList[country] = transformSelectValues(payload.hubMap[country]);
            return true;
          });
        }
        if (payload.carrierMap) {
          Object.keys(payload.carrierMap).filter((country: any) => {
            carrierList[country] = transformSelectValues(payload.carrierMap[country]);
            return true;
          });
        }
        if (payload.deliveryPropositionMap) {
          Object.keys(payload.deliveryPropositionMap).filter((country: any) => {
            delPropositionList[country] = transformSelectValues(payload.deliveryPropositionMap[country]);
            return true;
          });
        }
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.filtersDropdowns = {
          countriesList: transformSelectValues(payload.countries),
          carrierList: carrierList,
          hubList: hubList,
          delPropositionList: delPropositionList
        };
      })
      .addCase(fetchFiltersDropdownValues.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchDashboardByFilter.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchDashboardByFilter.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        const allDataRows = _.orderBy(payload, ['totalConsignments'], ['desc']);
        state.data = {
          allRows:  [...allDataRows],
          top5rows : [...state.data.top5rows]
        };
      })
      .addCase(fetchDashboardByFilter.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchDashboardByFilterOnTime.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchDashboardByFilterOnTime.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        let top5Performer = payload.length ? _.take(payload, 5) : payload;
        state.data = {
          top5rows:  [...top5Performer],
          allRows : [...state.data.allRows]
        };
      })
      .addCase(fetchDashboardByFilterOnTime.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(resetDashboardListError.fulfilled, (state, action) => {
        const { payload } = action;
        state.errorCode = payload;
        state.error = payload;
      });
  },
});

export default dashboardSlice.reducer;
