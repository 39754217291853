import { Grid, Table, TableBody, TableContainer, TableProps } from '@material-ui/core';
import React, { ElementType, ReactElement, useCallback, useEffect, useState } from 'react';
import Loader from '../../Loader';
import { useStyles } from './DataTable.styles';
import DataTableHeader from './DataTableHeader';
import { AppState } from "../../../config/redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import DataTableRowElement from './DataTableRowElement';
import { resetTableSelection } from "../../../orderList/redux/orderListSlice";

declare type NativeColTypes = 'string' | 'number';

declare type CellValue = string | number;

export type RowId = string | number;

export type SortDirection = 'asc' | 'desc' | 'default';

export type ColType = NativeColTypes | string;

export interface CellParams {
  field: string;
  value: CellValue;
  colDef?: ColDef;
}

export interface ColParams {
  field: string;
  colDef: any;
  colIndex: number;
}

export interface ColDef {
  field: string;
  headerName: string;
  hide?: boolean;
  width?: number;
  type?: ColType;
  renderCell?: (params: CellParams) => ReactElement;
  renderHeader?: (params: ColParams) => ReactElement;
}

export interface RowData extends ObjectWithId {
  [key: string]: any;
}

export interface ComponentProps {
  rows: RowData[];
  columns: ColDef[];
  options: DataTableOptions;
}

export interface SortModel {
  field: string;
  direction: SortDirection;
}

export interface RowParams {
  data: RowData;
}

export interface SelectionChangeParams {
  rows: RowData[];
}

interface ObjectWithId {
  id: RowId;
}

interface DataTableComponentOverridesProp {
  header?: ElementType<ComponentProps>;
}

interface DataTableOptions {
  rowCount: number;
  selected?: number;
}

export interface DataTableProps extends TableProps {
  rows: RowData[];
  columns: ColDef[];
  isColumnSettingsDisabled?: boolean | undefined;
  ariaLabel?: string;
  loading?: boolean | undefined;
  exceptions?: boolean | undefined;
  sortModel?: SortModel;
  rowHeight?: number;
  headerHeight?: number;
  checkboxSelection: boolean | false;
  disableSelectionOnClick?: boolean;
  onRowClick?: ((params: RowParams) => void) | undefined;
  onCellClick?: ((params: CellParams) => void) | undefined;
  onSelectionChange?: ((params: SelectionChangeParams) => void) | undefined;
  onSort?: ((params: SortModel) => void) | undefined;
  hideFooter?: boolean;
  rowCount?: number;
  components?: DataTableComponentOverridesProp;
}

const DataTable = (props: DataTableProps) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const { tableSelectionReset } = useSelector(
    (state: AppState) => state.orderList
  );
  const { rows, columns, isColumnSettingsDisabled, components, sortModel, rowCount, rowHeight, loading, exceptions, ariaLabel, checkboxSelection, disableSelectionOnClick, headerHeight, onRowClick, onCellClick, onSelectionChange, onSort, ...rest } = props;

  const [selected, setSelected] = useState<RowId[]>([]);
  const [order, setOrder] = useState<SortDirection>(sortModel ? sortModel.direction : 'asc');
  const [orderBy, setOrderBy] = useState<keyof RowData>(sortModel ? sortModel.field : 'id');

  const isSelected = (id: RowId) => selected.indexOf(id) !== -1;

  const handleSort = (event: React.MouseEvent<unknown>, property: keyof RowData) => {
    const isAsc = order === 'asc';
    const isDefault = order === 'default';
    const newOrder = orderBy === property ? (isAsc ? 'desc' : isDefault ? 'asc' : 'default') : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    onSort && onSort({
      field: property as string,
      direction: newOrder
    });
  };

  const handleSelectAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const newSelectedRows: RowId[] = rows.map((row: RowData) => row.id);
        setSelected(newSelectedRows);
        return;
      }
      setSelected([]);
    },
    [rows]
  );

  const handleRowClick = useCallback(
    (rowData: RowData) => {
      onRowClick && onRowClick({
        data: rowData
      });
    },
    [onRowClick],
  )
  const handleCellClick = useCallback(
    (column: ColDef, value: any) => {
      onCellClick && onCellClick({
        field: column.field,
        value: value,
        colDef: column,
      });
    },
    [onCellClick],
  )

  const handleSelectionChange = useCallback(
    (rowId: RowId) => {
      const selectedIndex = selected.indexOf(rowId);
      let newSelected: RowId[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, rowId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
      setSelected(newSelected);
    },
    [selected]
  );

  useEffect(() => {
    onSelectionChange && onSelectionChange({
      rows: rows.filter((row: RowData) => selected.indexOf(row.id) !== -1)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  useEffect(() => {
    if(tableSelectionReset){
      setSelected([]);
      dispatch(
        resetTableSelection({tableSelection:false})
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableSelectionReset])

  return (
    <Grid className={classes.root}>
      {
        components && components.header &&
        <Grid className={classes.headerSection}>
          <components.header rows={rows} columns={columns} options={{ rowCount: rowCount || rows.length, selected: selected.length }}></components.header>
        </Grid>
      }
      <TableContainer className={`data-table-scrollable-area ${exceptions ? classes.exceptionTableContainer : classes.tableContainer}`}>
        {loading && <Loader></Loader>}
        <Table
          className="DataTable"
          stickyHeader
          aria-label={ariaLabel ? ariaLabel : "Data Table"}
          {...rest}
        >
          <DataTableHeader
            checkboxSelection={checkboxSelection}
            columns={columns}
            headerHeight={headerHeight || 48}
            selected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAll={handleSelectAll}
            onSort={handleSort}
            rowCount={rows.length}
          />
          <TableBody>
            {
              rows.map((row: RowData) => (
                <DataTableRowElement
                  key={row.id}
                  rowData={row}
                  columns={columns}
                  checkboxSelection={checkboxSelection}
                  disableSelectionOnClick={disableSelectionOnClick}
                  isItemSelected={isSelected(row.id)}
                  rowClickCallback={handleRowClick}
                  rowSelectionCallback={handleSelectionChange}
                  cellClickCallback={handleCellClick}
                />
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

export default DataTable;