import moment from "moment";
import AppConstants from "../../constants";
import aramexLogoIcon from '../../assets/icons/aramex.svg';
import fetchrLogoIcon from '../../assets/icons/fetchr.svg';
import qpostLogoIcon from '../../assets/icons/qpost.svg';
import jeeblyLogoIcon from '../../assets/icons/jeebly.svg';
import imileLogoIcon from '../../assets/icons/imile.svg';
import shipaLogoIcon from '../../assets/icons/shipa.svg';
import oneClickLogoIcon from '../../assets/icons/oneclick.svg';
import lyveLogoIcon from '../../assets/icons/lyve.svg';
import urbantzLogoIcon from '../../assets/icons/urbantz.svg';
import trukkerLogoIcon from '../../assets/icons/trukker.svg';
import wingsLogoIcon from '../../assets/icons/wings.svg';
import sellerShippedIcon from '../../assets/icons/seller-shipped.svg';
import sellerDeliveryIcon from '../../assets/icons/supplier-delivery.svg';
import aramexLogoIconBg from '../../assets/icons/aramex-bg.svg';
import fetchrLogoIconBg from '../../assets/icons/fetchr-bg.svg';
import qpostLogoIconBg from '../../assets/icons/qpost-bg.svg';
import jeeblyLogoIconBg from '../../assets/icons/jeebly-bg.svg';
import imileLogoIconBg from '../../assets/icons/imile-bg.svg';
import shipaLogoIconBg from '../../assets/icons/shipa-bg.svg';
import oneClickLogoIconBg from '../../assets/icons/oneclick-bg.svg';
import urbantzLogoIconBg from '../../assets/icons/urbantz-bg.svg';
import trukkerLogoIconBg from '../../assets/icons/trukker-bg.svg';
import wingsLogoIconBg from '../../assets/icons/wings-bg.svg';
import sellerShippedIconBg from '../../assets/icons/seller-shipped-bg.svg';
import sellerDeliveryIconBg from '../../assets/icons/supplier-delivery-bg.svg';
import lyveLogoIconBg from '../../assets/icons/lyve-bg.svg';

const lastMileCountryDataTransform = (response:any):any[]=> {
  const transformedArray: any[] = [];
  return [...transformedArray];
};

export { lastMileCountryDataTransform };

export const createFilterPayload = (payload: any, data?: any) => {
  if (payload.hubs) {
    const selectAllIdx = payload.hubs.indexOf(AppConstants.SELECT_ALL.value);
    const selectNoneIdx = payload.hubs.indexOf(AppConstants.SELECT_NONE.value);
    if (selectAllIdx > -1) {
      payload.hubs = [];
    }
    if (selectNoneIdx > -1) {
      payload.hubs = [];
    }
  }
  if (payload.carriers) {
    const selectAllIdx = payload.carriers.indexOf(AppConstants.SELECT_ALL.value);
    const selectNoneIdx = payload.carriers.indexOf(AppConstants.SELECT_NONE.value);
    if (selectAllIdx > -1) {
      payload.carriers = [];
    }
    if (selectNoneIdx > -1) {
      payload.carriers = [];
    }
    //to convert the payload array values in lower case
    payload.carriers = payload.carriers.map((name:any) => {return name.toLowerCase()});
  }
  if (payload.deliveryProposition) {
    const selectAllIdx = payload.deliveryProposition.indexOf(AppConstants.SELECT_ALL.value);
    const selectNoneIdx = payload.deliveryProposition.indexOf(AppConstants.SELECT_NONE.value);
    if (selectAllIdx > -1) {
      payload.deliveryProposition = [];
      
    }
    if (selectNoneIdx > -1) {
      payload.deliveryProposition = [];
    }
  }
  if (payload.dateRange) {
    payload.dateFrom = moment(payload.dateRange.startDate, AppConstants.DATE_FORMAT).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_BACKEND);
    payload.dateTo = moment(payload.dateRange.endDate, AppConstants.DATE_FORMAT).hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT_BACKEND);
    delete payload.dateRange;
  }
  return payload;
};

export const getRankSup = (rank: any) => {
  let rankSupValue = "th";
  if (rank === "1") {
    rankSupValue = "st";
  } else if (rank === "2") {
    rankSupValue = "nd";
  } else if (rank === "3") {
    rankSupValue = "rd";
  }
  return rankSupValue;
}

export const getCarrierLogo = (name: any, type: string) => {
  let carrierLogoName = "";
  if (name === "aramex") {
    carrierLogoName = type === "small" ? aramexLogoIcon : aramexLogoIconBg;
  } else if (name === "fetchr") {
    carrierLogoName = type === "small" ? fetchrLogoIcon : fetchrLogoIconBg;
  } else if (name === "qpost") {
    carrierLogoName = type === "small" ? qpostLogoIcon : qpostLogoIconBg;
  } else if (name === "jeebly") {
    carrierLogoName = type === "small" ? jeeblyLogoIcon : jeeblyLogoIconBg;
  } else if (name === "imile") {
    carrierLogoName = type === "small" ? imileLogoIcon : imileLogoIconBg;
  } else if (name === "shipa") {
    carrierLogoName = type === "small" ? shipaLogoIcon : shipaLogoIconBg;
  } else if (name === "oneclick") {
    carrierLogoName = type === "small" ? oneClickLogoIcon : oneClickLogoIconBg;
  }else if (name === "lyve") {
    carrierLogoName = type === "small" ? lyveLogoIcon : lyveLogoIconBg;
  } else if (name === "urbantz") {
    carrierLogoName = type === "small" ? urbantzLogoIcon : urbantzLogoIconBg;
  } else if (name === "trukker") {
    carrierLogoName = type === "small" ? trukkerLogoIcon : trukkerLogoIconBg;
  } else if (name === "wings") {
    carrierLogoName = type === "small" ? wingsLogoIcon : wingsLogoIconBg;
  } else if (name === "seller shipped") {
    carrierLogoName = type === "small" ? sellerShippedIcon : sellerShippedIconBg;
  } else if (name === "supplier delivery") {
    carrierLogoName = type === "small" ? sellerDeliveryIcon : sellerDeliveryIconBg;
  }
  return carrierLogoName;
}

export const getCarrierColor = (name: any, dashboardWrapperData:any) => {
  return dashboardWrapperData.carrierColorMap[name] ? dashboardWrapperData.carrierColorMap[name]  :  '';
}