import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AppConstants from '../../constants';
import { useStyles } from "./CustomStatusBar.styles";
import { useSelector } from 'react-redux';
import { AppState } from '../../config/redux/reducers';


interface CustomStatusBarProps {
  open: boolean;
  mainClassName?: string;
  iconClassName?: string;
  textClassName?: string;
  closeClassName?: string;
  message: string;
  type?: any;
  handleClose: (event: React.SyntheticEvent<Element, Event>) => void;
}

const CustomStatusBar = (props: CustomStatusBarProps) => {

  const { isLoggedIn } = useSelector((state: AppState) => state.userLogin);
  const { open, mainClassName, iconClassName, textClassName, closeClassName, type, message, handleClose } = props;
  const componentClasses = useStyles();
  const [firstIconCustomWidth, setFirstIconWidth] = useState("");
  const [textMessageCustomWidth, setTextMessageWidth] = useState("");
  const [closeIconCustomWidth, setCloseIconWidth] = useState("");

  useEffect(() => {
    function handleResize() {
      setFirstIconWidth("");
      setTextMessageWidth("");
      setCloseIconWidth("");
      if (window.innerWidth < 700) {
        setFirstIconWidth(componentClasses.firstIconCustomWidthSm);
        setTextMessageWidth(componentClasses.textMessageCustomWidthSm);
        setCloseIconWidth(componentClasses.closeIconCustomWidthSm);
      } else if (window.innerWidth >= 700 && window.innerWidth < 1000) {
        setFirstIconWidth(componentClasses.firstIconCustomWidthMd);
        setTextMessageWidth(componentClasses.textMessageCustomWidthMd);
        setCloseIconWidth(componentClasses.closeIconCustomWidthMd);
      } else if (window.innerWidth >= 1000 && window.innerWidth < 1300) {
        setFirstIconWidth(componentClasses.firstIconCustomWidth);
        setTextMessageWidth(componentClasses.textMessageCustomWidth);
        setCloseIconWidth(componentClasses.closeIconCustomWidth);
      }
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [componentClasses])

  return <>
    {open &&
      <Grid className={`${componentClasses.statusMessageMain} ${mainClassName}`} >
        {type.toLowerCase() === AppConstants.SNACKBAR.TYPES.SUCCESS &&
          <Grid className={componentClasses.statusMessageSuccess}>
            <Grid className={`${componentClasses.statusMessageIcon} ${iconClassName} ${firstIconCustomWidth}`}>
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="32" height="32" fill="#E5E5E5" />
                <rect width="1440" height="1024" transform="translate(-416 -367)" fill="#0E5AA7" />
                <g filter="url(#filter0_d)">
                  <rect x="-64" y="-162" width="736" height="621" rx="8" fill="white" />
                </g>
                <g filter="url(#filter1_d)">
                  <rect x="-16" y="-16" width="640" height="64" rx="8" fill="#009688" />
                  <rect x="-15.5" y="-15.5" width="639" height="63" rx="7.5" stroke="#009688" />
                </g>
                <circle cx="16" cy="16" r="16" fill="white" />
                <path d="M15.9748 25.9483C12.8429 25.9386 9.89533 24.4668 8.00577 21.9692L8.80433 21.3677C10.6614 23.8272 13.648 25.1652 16.7195 24.9138C19.7911 24.6623 22.5203 22.8564 23.9526 20.1276C25.9158 16.388 25.0136 11.7863 21.7837 9.06482C18.5538 6.34338 13.8657 6.23488 10.5133 8.804C7.16094 11.3731 6.04683 15.9282 7.83493 19.7546L6.92893 20.1764C5.06155 16.1568 6.07077 11.387 9.40626 8.4682C12.7417 5.54939 17.6032 5.18181 21.3397 7.56592C25.0761 9.95003 26.791 14.5138 25.5493 18.7686C24.3075 23.0233 20.407 25.9482 15.9748 25.9483ZM14.0776 20.9577L9.98165 16.8127L10.7286 16.0147L14.0666 19.3947L21.2246 11.9577L21.9816 12.7447L14.0776 20.9577Z" fill="#009688" />
                <defs>
                  <filter id="filter0_d" x="-76" y="-174" width="760" height="645" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                    <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow" />
                    <feOffset />
                    <feGaussianBlur stdDeviation="5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.054902 0 0 0 0 0.352941 0 0 0 0 0.654902 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                  </filter>
                  <filter id="filter1_d" x="-27" y="-27" width="662" height="86" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                    <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow" />
                    <feOffset />
                    <feGaussianBlur stdDeviation="5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                  </filter>
                </defs>
              </svg>
            </Grid>
            <Grid className={`${componentClasses.statusMessageText} ${textClassName} ${textMessageCustomWidth}`}>{message}</Grid>
            {
              isLoggedIn &&
              <Grid className={`${componentClasses.statusMessageClose} ${closeClassName} ${closeIconCustomWidth}`} onClick={handleClose}>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.3334 16.679L9.65442 9.00002L17.3306 1.32288L16.6763 0.668538L9.00008 8.34568L1.32109 0.666687L0.666748 1.32103L8.34574 9.00002L0.666748 16.679L1.32109 17.3334L9.00008 9.65436L16.6791 17.3334L17.3334 16.679Z" fill="white" />
                </svg>
              </Grid>
            }
          </Grid>
        }
        {type.toLowerCase() === AppConstants.SNACKBAR.TYPES.ERROR &&
          <Grid className={componentClasses.statusMessageError}>
            <Grid className={`${componentClasses.statusMessageIcon} ${iconClassName} ${firstIconCustomWidth}`}>
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="32" height="32" fill="#E5E5E5" />
                <rect width="1440" height="1024" transform="translate(-416 -378)" fill="#0E5AA7" />
                <g filter="url(#filter0_d)">
                  <rect x="-64" y="-161" width="736" height="590" rx="8" fill="white" />
                  <rect x="-64.5" y="-161.5" width="737" height="591" rx="8.5" stroke="#0A437C" />
                </g>
                <g filter="url(#filter1_d)">
                  <rect x="-16" y="-16" width="640" height="64" rx="8" fill="#F34041" />
                  <rect x="-15.5" y="-15.5" width="639" height="63" rx="7.5" stroke="#F34041" />
                </g>
                <circle cx="16" cy="16" r="16" fill="white" />
                <path d="M14.927 25.9445L14.7591 25.9251C9.51514 25.2685 5.68709 20.6453 6.02018 15.3709C6.35327 10.0965 10.7325 5.99163 16.0174 6.00001C21.3023 6.00839 25.6686 10.1271 25.9849 15.4026C26.3013 20.678 22.4586 25.2891 17.2126 25.929L17.0777 25.9445L16.9826 24.9522L17.0942 24.9406C21.8193 24.3647 25.281 20.2121 24.9967 15.4605C24.7124 10.7088 20.7804 6.9985 16.0203 6.99012C11.2602 6.98175 7.31518 10.6782 7.0142 15.4288C6.71322 20.1794 10.1602 24.3442 14.8833 24.9367L15.0356 24.9542L14.927 25.9445Z" fill="#F34041" />
                <path d="M20 19.6859L16.3141 16L19.9987 12.315L19.6846 12.0009L16 15.6859L12.3141 12L12 12.3141L15.6859 16L12 19.6859L12.3141 20L16 16.3141L19.6859 20L20 19.6859Z" fill="#F34041" />
                <defs>
                  <filter id="filter0_d" x="-77" y="-174" width="762" height="616" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                    <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow" />
                    <feOffset />
                    <feGaussianBlur stdDeviation="5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.054902 0 0 0 0 0.352941 0 0 0 0 0.654902 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                  </filter>
                  <filter id="filter1_d" x="-27" y="-27" width="662" height="86" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                    <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow" />
                    <feOffset />
                    <feGaussianBlur stdDeviation="5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                  </filter>
                </defs>
              </svg>
            </Grid>
            <Grid className={`${componentClasses.statusMessageText} ${textClassName} ${textMessageCustomWidth}`}>{message}</Grid>
            {
              isLoggedIn &&
              <Grid className={`${componentClasses.statusMessageClose} ${closeClassName} ${closeIconCustomWidth}`} onClick={handleClose}>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.3334 16.679L9.65442 9.00002L17.3306 1.32288L16.6763 0.668538L9.00008 8.34568L1.32109 0.666687L0.666748 1.32103L8.34574 9.00002L0.666748 16.679L1.32109 17.3334L9.00008 9.65436L16.6791 17.3334L17.3334 16.679Z" fill="white" />
                </svg>
              </Grid>
            }
          </Grid>
        }
      </Grid>
    }
  </>
}

export default CustomStatusBar;