import React, { useCallback, forwardRef, ReactElement, Ref, useState } from 'react';
import CustomDialog from '../common/Dialog';
import mobileLogoIcon from './../assets/icons/mobileLogo.svg';
import emailLogoIcon from './../assets/icons/emailLogo.svg';
import { ReactComponent as activeTimelineDot } from './../assets/icons/active-timeline-dot.svg';
import { ReactComponent as errorTimelineDot } from './../assets/icons/timeline-dot-error.svg';
import { ReactComponent as timelineDot } from './../assets/icons/timeline-dot.svg';
import { ReactComponent as downArrowIcon } from './../assets/icons/down-arrow.svg';
import { useStyles } from './ConsignmentDetails.styles';
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails, SvgIcon, Collapse, Link, Button } from '@material-ui/core';
import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from '@material-ui/lab';
import AppConstants from '../constants';
import { COLORS } from '../config/theme/baseTheme';
import CustomTooltip from '../common/Tooltip';
import MapContainer from '../common/GoogleMapContainer';
import { repushReturnOrder } from "./redux/returnsListSlice";
import { useDispatch } from "react-redux";
import { getDateStringFromUTC, getTimeStringFromUTC, isPostPaidOrder } from "../utils/helpers.utils";

interface ConsignmentDetailsProps {
  open: boolean,
  consignment: any,
  closePopup: () => void
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ConsignmentDetails = (props: ConsignmentDetailsProps) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const { open, consignment, closePopup } = props;

  const [toggleCustMap, setToggleCustMap] = useState(false);
  const [toggleSellerMap, setToggleSellerMap] = useState(false);

  let deliveryInformation: any = {};
  let metadata: any = {};
  let sellerInfo: any = {
    address: {}
  };
  let statusErrors: any = [];
  let sellerGeoLocation: any = {
    name: "",
    location: {
      lat: 0,
      lng: 0
    }
  };
  let pickupInfo: any = {
    address: {}
  };
  let pickupGeoLocation: any = {
    name: "",
    location: {
      lat: 0,
      lng: 0
    }
  };
  // let posInfo: any = {
  //   address: {}
  // };
  let tableRecord: any = {};
  let items: any = [];
  let historyItems: any = [];
  let addressGeoCoded: boolean = false;
  let geocodedaddress: any = {};
  
  if (consignment) {
    deliveryInformation = consignment.deliveryInformation;
    metadata = consignment.metadata;
    sellerInfo = deliveryInformation && deliveryInformation.customerContactInfo;
    statusErrors = consignment.errors || [];
    pickupInfo = consignment.pickUpInformation && consignment.pickUpInformation.pickUpContactInfo;
    if (sellerInfo && sellerInfo.address) {
      sellerGeoLocation.name = sellerInfo.name;
      sellerGeoLocation.location.lat = parseFloat(sellerInfo.address.latitude) || 0;
      sellerGeoLocation.location.lng = parseFloat(sellerInfo.address.longitude) || 0;
    }
    addressGeoCoded = consignment.addressGeoCoded;
    geocodedaddress = consignment.geocodedAddress;
    if (pickupInfo && pickupInfo.address) {
      pickupGeoLocation.name = pickupInfo.name;
      pickupGeoLocation.location.lat = parseFloat(pickupInfo.address.latitude) || 0;
      pickupGeoLocation.location.lng = parseFloat(pickupInfo.address.longitude) || 0;
    }
    //posInfo = consignment.posInformation && consignment.posInformation.contactInfo;
    tableRecord = consignment.tableRecord;
    items = consignment.products;
    if (consignment.statuses && consignment.statuses.length) {
      historyItems = consignment.statuses.slice();
    }
  }
  const dialogPaperProps = {
    classes: {
      root: classes.dialogPaperPropsRoot
    },
    square: true
  }

  const handleTrackingLink = () => {
    window.open(deliveryInformation.carrierTrackingUrl, '_blank');
  }

  const handleRepushReturn = useCallback(
    () => {
      dispatch(
        repushReturnOrder({
          returnId: consignment.rmaNumber
        })
      );
    },
    [dispatch, consignment]
  )

  const getStatusField = (fieldName: string, statusCode: string) => {
    const statuses: any = AppConstants.RETURN_CONSTANTS.STATUSES;
    const statusName = statusCode && statusCode.toLowerCase().replace(/\s/gm, '_');
    return statusCode && statuses[statusName] ? statuses[statusName][fieldName] : "";
  }

  const hasRepushAccess = (statusCode: string) => {
    const statuses: any = AppConstants.RETURN_CONSTANTS.REPUSH_STATUSES;
    //const usersHasAccess: any = AppConstants.CONSIGNMENT_CONSTANTS.REPUSH_ACESSS_USERS;
    let isStatusExists = statuses.includes(statusCode);
    //let loggedInUser = sessionStorage.getItem('username') ? sessionStorage.getItem('username') : '';
    //let isLoggedInUserHasAccess = usersHasAccess.includes(loggedInUser);
    if(isStatusExists){
      return true;
    }
    return false;
  }

  const isFailedStatus = (statusCode: string) => {
    const statuses: any = AppConstants.RETURN_CONSTANTS.FAILED_STATUSES;
    let isStatusExists = statuses.includes(statusCode);
    if(isStatusExists){
      return true;
    }
    return false;
  }

  const getStatusType = (errors: any, statusCode: string, index: number) => {
    if(errors && errors.length && isFailedStatus(statusCode)){
      return errorTimelineDot;
    }else if(index === 0){
      return activeTimelineDot
    }else{
      return timelineDot
    }
  }

  const getTimelineViewBox = (errors: any, statusCode: string, index: number) => {
    if(errors && errors.length && isFailedStatus(statusCode)){
      return "0 0 18 18";
    }else if(index === 0){
      return "0 0 18 18"
    }else{
      return "0 0 8 8"
    }
  }

  const getItems = () => {
    return items && items.length ? items.map((item: any, index: number) => (
      <Grid key={`${item?.name?.en} - ${index}`} className="item" item>
        <Grid className="itemContainer" container>
          <Grid className="itemColumn item1" item xs={5}>
            <Typography>{item?.name?.en.toLowerCase()}</Typography>
          </Grid>
          <Grid className="itemColumn item2" item xs={4}>
            <Typography><span className="green">{item.shippedQty || ""}</span>/{`${item.quantity || ""} ${item.uom && item.uom === 'Grams' ? item.uom : ""}`}</Typography>
          </Grid>
          <Grid className="itemColumn item3" item xs={3}>
            <Typography>{`${item.unitPrice} ${metadata.currency}`}</Typography>
          </Grid>
        </Grid>
      </Grid>
    )) : (<Typography>No Items to display</Typography>)
  }

  const getHistoryTimelineItems = () => {
    return <>
      {
      historyItems && historyItems.length ? historyItems.map((item: any, index: number) => (
        <TimelineItem key={`${item.statusCode} - ${index}`} className="timelineItem">
          <TimelineSeparator>
            {index === 0 ?
              <TimelineDot className="timelineDot">
                <SvgIcon className="dotIcon active" component={getStatusType(statusErrors, item.statusCode, index)} viewBox="0 0 18 18" />
              </TimelineDot>
              :
              <TimelineDot className="timelineDot">
                <SvgIcon className="dotIcon" component={getStatusType(statusErrors, item.statusCode, index)} viewBox={getTimelineViewBox(statusErrors, item.statusCode, index)} />
              </TimelineDot>
            }
            {index !== historyItems.length - 1 && <TimelineConnector className="primaryConnector" />}
          </TimelineSeparator>
          {
          isFailedStatus(item.statusCode) ?
          <TimelineContent className="timelineContent">
          <Typography className="heading error active"><span className="status">{`${item.statusCode} - ${item.statusName} (${statusErrors.length})`}</span><span className="dateTime">{`(${getDateStringFromUTC(item.statusDate, metadata.timeZone)} at ${getTimeStringFromUTC(item.statusDate, metadata.timeZone)})`}</span></Typography>
          {
            statusErrors.map((errorItem: any, index: number) => (
              <Typography key={`errorMetadata-${index}`} component="div" className="metadataContainer">
                <Typography className="title">{`${errorItem.errorCode} - ${errorItem.message}`}</Typography>
                <Typography className="description">{`By System on (${getDateStringFromUTC(errorItem.date, metadata.timeZone)} at ${getTimeStringFromUTC(errorItem.date, metadata.timeZone)})`}</Typography>
              </Typography>
            ))
          }
        </TimelineContent>
        :
          <TimelineContent className="timelineContent">
            <Typography className={["heading", index === 0 ? "active" : ""].join(" ")}><span className="status">{item.statusCode} - {item.statusName}</span><span className="dateTime">{`(${getDateStringFromUTC(item.statusDate, metadata.timeZone)} at ${getTimeStringFromUTC(item.statusDate, metadata.timeZone)})`}</span></Typography>
          </TimelineContent>
        }
        </TimelineItem>
      )) : (<Typography>No Items to display</Typography>)
      }
      </>
  }

  const getTitleContent = () => {
    return (
      <Grid className={classes.titleContainer} container>
        <Grid item>
          <Grid className="headingContainer" container>
            <Grid item>
              <Grid container>
                <Grid item>
                  <Typography className="titleHeading">
                    <span className="label"> RETURN ID : </span>
                    <span className="content"> {tableRecord && tableRecord.returnId} </span>
                  </Typography>
                </Grid>
                <CustomTooltip title={getStatusField('hintText', metadata && metadata.displayStatus)}>
                  <Grid className="tagContainer" item>
                    <Typography className={['tag', getStatusField('color', metadata && metadata.displayStatus)].join(" ")}>{metadata ? metadata.displayStatus ? metadata.displayStatus : metadata.status : ""}</Typography>
                  </Grid>
                </CustomTooltip>
              </Grid>
            </Grid>
            {
              (deliveryInformation && deliveryInformation.carrierTrackingId) || consignment.orderReference ? (
                <Grid item>
                  {
                    deliveryInformation && deliveryInformation.carrierTrackingUrl ?
                      <Link className="titleContent link" onClick={handleTrackingLink}>{`Track Return (#${deliveryInformation && deliveryInformation.carrierTrackingId ? deliveryInformation.carrierTrackingId : consignment.orderReference ? consignment.orderReference : ""})`}</Link>
                      :
                      <Typography className="titleContent">{`Track Return (#${deliveryInformation && deliveryInformation.carrierTrackingId ? deliveryInformation.carrierTrackingId : consignment.orderReference ? consignment.orderReference : ""})`}</Typography>
                  }
                </Grid>
              ) : ""
            }
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const getDetailsContent = () => {
    return (
      <Grid className={classes.contentContainer} container>
        <Grid className="customerDetails" item>
          <Grid className="detailsHeader detailsHeaderLeftSection" container>
            <Grid className="addressContainer" container>
              <Grid className="detailsHeaderItem" item>
                <Typography className="addressTitle">Pickup Details {addressGeoCoded ? <span className="geocoded">(GeoCoded)</span> : ''}</Typography>
              </Grid>
              <Grid className="detailsHeaderItem" item>
                <Typography className="name">{pickupInfo && pickupInfo.name}</Typography>
                <Typography><span className="addressinfo">Flat/Villa no</span>: {pickupInfo && pickupInfo.address ? `${pickupInfo.address.apartment ? pickupInfo.address.apartment : ""}` : ""} </Typography>
                <Typography><span className="addressinfo">Building name/no</span>: {pickupInfo && pickupInfo.address ? `${pickupInfo.address.building ? pickupInfo.address.building : ""}` : ""} </Typography>
                <Typography><span className="addressinfo">Street Name/no</span>: {pickupInfo && pickupInfo.address ? `${pickupInfo.address.streetName ? (pickupInfo.address.streetNumber ? pickupInfo.address.streetName + "/" : pickupInfo.address.streetName) : ""}${pickupInfo.address.streetNumber ? pickupInfo.address.streetNumber : ""}` : ""} </Typography>
                {
                  !addressGeoCoded ? 
                  <>
                    <Typography><span className="addressinfo">Area</span>: {pickupInfo && pickupInfo.address ? `${pickupInfo.address.area ? pickupInfo.address.area : ""}` : ""} </Typography>
                    <Typography><span className="addressinfo">City</span>: {pickupInfo && pickupInfo.address ? `${pickupInfo.address.city ? pickupInfo.address.city : ""}` : ""} </Typography>
                  </>
                  :
                  <>
                    <Typography className="geocoded"><span className="addressinfo">Area</span>: {geocodedaddress && geocodedaddress.geocodedArea ? `${geocodedaddress.geocodedArea}` : ""} </Typography>
                    <Typography className="geocoded"><span className="addressinfo">City</span>: {geocodedaddress && geocodedaddress.geocodedCity ? `${geocodedaddress.geocodedCity}` : ""} </Typography>
                  </>
                }
                <Typography><span className="addressinfo">Landmark</span>: {pickupInfo && pickupInfo.address ? `${pickupInfo.address.landmark ? pickupInfo.address.landmark : ""}` : ""} </Typography>
                <Typography><span className="addressinfo">Country</span>: {pickupInfo && pickupInfo.address ? `${pickupInfo.address.countryName ? pickupInfo.address.countryName : ""}` : ""} </Typography>
                <Typography><span className="addressinfo">Lat/Long</span>: {pickupInfo && pickupInfo.address ? ` ${pickupInfo.address.latitude ? pickupInfo.address.latitude + ', ' : ""}${pickupInfo.address.longitude ? pickupInfo.address.longitude : ""}` : ""}</Typography>
              </Grid>
              <Grid className="detailsHeaderItem" item>
                <Grid container alignItems="center">
                  <Grid className="contactMobile" item>
                    <Grid container alignItems="center">
                      <img className="logo" alt="Mobile" src={mobileLogoIcon} />
                      <span className="contact mobile">{pickupInfo ? <a href={`tel: ${pickupInfo.phone}`}>{pickupInfo.phone || ""}</a> : ""}</span>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container alignItems="center">
                      <img className="logo" alt="Email" src={emailLogoIcon} />
                      <span className="contact email">{pickupInfo ? <a href={`mailto: ${pickupInfo.email}`}>{pickupInfo.email || ""}</a> : ""}</span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className="detailsHeaderItem geocodedAddress" item>
                <Grid container direction="column">
                  <Grid className="addressColItem" item>
                    <Grid container className="geoLocAddressContainer" alignItems="center" onClick={() => setToggleCustMap(!toggleCustMap)}>
                      <span className="address">View Map</span>
                      <SvgIcon className={["addressLogo", toggleCustMap ? "open" : ""].join(" ")} component={downArrowIcon} />
                    </Grid>
                  </Grid>
                  <Collapse in={toggleCustMap}>
                    <Grid className="addressMapContainer" item>
                      {
                        pickupGeoLocation ? (
                          <MapContainer center={pickupGeoLocation.location} zoom={12} markers={[pickupGeoLocation]}></MapContainer>
                        ) : ""
                      }
                    </Grid>
                  </Collapse>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="detailsHeader detailsHeaderRightSection" container>
            <Grid className="addressContainer" container>
              <Grid className="detailsHeaderItem" item>
                <Typography className="addressTitle">Delivery Details</Typography>
              </Grid>
              <Grid className="detailsHeaderItem" item>
                <Typography className="name">{sellerInfo && (sellerInfo.companyName ? sellerInfo.companyName : sellerInfo.name ? sellerInfo.name : '' )}</Typography>
                <Typography><span className="addressinfo">Flat/Villa no</span>: {sellerInfo && sellerInfo.address ? `${sellerInfo.address.apartment ? sellerInfo.address.apartment : ""}` : ""} </Typography>
                <Typography><span className="addressinfo">Building name/no</span>: {sellerInfo && sellerInfo.address ? `${sellerInfo.address.building ? sellerInfo.address.building : ""}` : ""} </Typography>
                <Typography><span className="addressinfo">Street Name/no</span>: {sellerInfo && sellerInfo.address ? `${sellerInfo.address.streetName ? (sellerInfo.address.streetNumber ? sellerInfo.address.streetName + "/" : sellerInfo.address.streetName) : ""}${sellerInfo.address.streetNumber ? sellerInfo.address.streetNumber : ""}` : ""} </Typography>
                <Typography><span className="addressinfo">Area</span>: {sellerInfo && sellerInfo.address ? `${sellerInfo.address.area ? sellerInfo.address.area : ""}` : ""} </Typography>
                <Typography><span className="addressinfo">City</span>: {sellerInfo && sellerInfo.address ? `${sellerInfo.address.city ? sellerInfo.address.city : ""}` : ""} </Typography>
                <Typography><span className="addressinfo">Landmark</span>: {sellerInfo && sellerInfo.address ? `${sellerInfo.address.landmark ? sellerInfo.address.landmark : ""}` : ""} </Typography>
                <Typography><span className="addressinfo">Country</span>: {sellerInfo && sellerInfo.address ? `${sellerInfo.address.countryName ? sellerInfo.address.countryName : ""}` : ""} </Typography>
                <Typography><span className="addressinfo">Lat/Long</span>: {sellerInfo && sellerInfo.address ? ` ${sellerInfo.address.latitude ? sellerInfo.address.latitude + ', ' : ""}${sellerInfo.address.longitude ? sellerInfo.address.longitude : ""}` : ""}</Typography>
              </Grid>
              <Grid className="detailsHeaderItem" item>
                <Grid container alignItems="center">
                  <Grid className="contactMobile" item>
                    <Grid container alignItems="center">
                      <img className="logo" alt="Mobile" src={mobileLogoIcon} />
                      <span className="contact mobile">{sellerInfo ? <a href={`tel: ${sellerInfo.phone}`}>{sellerInfo.phone || ""}</a> : ""}</span>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container alignItems="center">
                      <img className="logo" alt="Email" src={emailLogoIcon} />
                      <span className="contact email">{sellerInfo ? <a href={`mailto: ${sellerInfo.email}`}>{sellerInfo.email || ""}</a> : ""}</span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className="detailsHeaderItem geocodedAddress" item>
                  <Grid container direction="column">
                    <Grid className="addressColItem" item>
                      <Grid container className="geoLocAddressContainer" alignItems="center" onClick={() => setToggleSellerMap(!toggleSellerMap)}>
                        <span className="address">View Map</span>
                        <SvgIcon className={["addressLogo", toggleSellerMap ? "open" : ""].join(" ")} component={downArrowIcon} />
                      </Grid>
                    </Grid>
                    <Collapse in={toggleSellerMap}>
                      <Grid className="addressMapContainer" item>
                        {
                          sellerGeoLocation ? (
                            <MapContainer center={sellerGeoLocation.location} zoom={12} markers={[sellerGeoLocation]}></MapContainer>
                          ) : ""
                        }
                      </Grid>
                    </Collapse>
                  </Grid>
                </Grid>
            </Grid>
          </Grid>
        </Grid>
        {addressGeoCoded ? 
        <Grid className="customerAddress customerAddressTop" item>
          <Accordion>
            <AccordionSummary
              expandIcon={<SvgIcon component={downArrowIcon} htmlColor={COLORS.COPY} />}
              aria-controls="customer-address-content"
              id="customer-address-header"
              className={classes.accordionSummary}
            >
              <Typography className="header">Address entered by customer</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="div" className="content">
                <Timeline className={classes.timeline} align="left">
                  <Grid className="detailsHeaderItem" item>
                    <Typography className="name">{pickupInfo && pickupInfo.name}</Typography>
                    <Typography>
                      <span className="addressinfo">Flat/Villa no</span>: {pickupInfo && pickupInfo.address ? `${pickupInfo.address.apartment ? pickupInfo.address.apartment + ', ' : ""}` : ""} 
                      <span className="addressinfo">Building name/no</span>: {pickupInfo && pickupInfo.address ? `${pickupInfo.address.building ? pickupInfo.address.building : ""}` : ""} 
                    </Typography>
                    <Typography>
                      <span className="addressinfo">Street Name/no</span>: {pickupInfo && pickupInfo.address ? `${pickupInfo.address.streetName ? (pickupInfo.address.streetNumber ? pickupInfo.address.streetName + "/" : pickupInfo.address.streetName + ', ') : ""}${pickupInfo.address.streetNumber ? pickupInfo.address.streetNumber + ', ' : ""}` : ""} 
                      <span className="addressinfo">Area</span>: {pickupInfo && pickupInfo.address ? `${pickupInfo.address.area ? pickupInfo.address.area : ""}` : ""} 
                    </Typography>
                    <Typography>
                      <span className="addressinfo">City</span>: {pickupInfo && pickupInfo.address ? `${pickupInfo.address.city ? pickupInfo.address.city + ", " : ""}` : ""} 
                      <span className="addressinfo">Landmark</span>: {pickupInfo && pickupInfo.address ? `${pickupInfo.address.landmark ? pickupInfo.address.landmark : ""}` : ""} 
                    </Typography>
                    <Typography><span className="addressinfo">Country</span>: {pickupInfo && pickupInfo.address ? `${pickupInfo.address.countryName ? pickupInfo.address.countryName : ""}` : ""} </Typography>
                    <Typography><span className="addressinfo">Lat/Long</span>: {pickupInfo && pickupInfo.address ? ` ${pickupInfo.address.latitude ? pickupInfo.address.latitude + ', ' : ""}${pickupInfo.address.longitude ? pickupInfo.address.longitude : ""}` : ""}</Typography>
                  </Grid>
                </Timeline>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        :null  
        }
        <Grid item>
        <Grid className="detailsContent" container>
            <Grid className="item" item>
              <Grid container>
                <Grid className="itemlabel" item xs={4}>Carrier Name</Grid>
                <Grid className="itemlabel" item xs={4}>Pickup Date</Grid>
                <Grid className="itemlabel itemPaddingLeft" item xs={4}>Pickup Slot</Grid>
              </Grid>
              <Grid className="itemRowMargin" container>
                <Grid className="itemValue" item xs={4}>{deliveryInformation && deliveryInformation.carrier}</Grid>
                <Grid className="itemValue" item xs={4}>{tableRecord && tableRecord.pickupDate}</Grid>
                <Grid className="itemValue itemPaddingLeft" item xs={4}>{tableRecord && tableRecord.pickupSlotTime}</Grid>
              </Grid>
              <Grid container>
                <Grid className="itemlabel" item xs={4}>Delivery Proposition</Grid>
                <Grid className="itemlabel" item xs={4}>Delivery Date</Grid>
                <Grid className="itemlabel itemPaddingLeft" item xs={4}>Delivery Slot</Grid>
              </Grid>
              <Grid className="itemRowMargin" container>
                <Grid className="itemValue" item xs={4}>{tableRecord && tableRecord.deliveryProposition}</Grid>
                <Grid className="itemValue" item xs={4}>{tableRecord && tableRecord.deliveryDate}</Grid>
                <Grid className="itemValue itemPaddingLeft" item xs={4}>{tableRecord && tableRecord.startEndTime}</Grid>
              </Grid>
              <Grid container>
                <Grid className="itemlabel" item xs={12}>Original Consignment ID</Grid>
              </Grid>
              <Grid className="itemRowMargin" container>
                <Grid className="itemValue" item xs={12}>{tableRecord && tableRecord.consignmentId}</Grid>
              </Grid>
              {deliveryInformation && deliveryInformation.deliveryInstructions ? (
              <>
                <Grid container>
                  <Grid className="itemlabel" item xs={12}>Delivery Instructions</Grid>
                </Grid>
                <Grid className="itemRowMargin" container>
                  <Grid className="itemValue" item xs={12}>{deliveryInformation && deliveryInformation.deliveryInstructions}</Grid>
                </Grid>
              </>
              ) : null
              }
            </Grid>
          </Grid>
        </Grid>

        <Grid className="consignmentHistory" item>
          <Accordion>
            <AccordionSummary
              expandIcon={<SvgIcon component={downArrowIcon} htmlColor={COLORS.COPY} />}
              aria-controls="consignment-history-content"
              id="consignment-history-header"
              className={classes.accordionSummary}
            >
              <Typography className="header">Return Consignment History</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="div" className="content">
                <Timeline className={classes.timeline} align="left">
                  {getHistoryTimelineItems()}
                </Timeline>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid className="itemsSection" item>
          <Accordion>
            <AccordionSummary
              expandIcon={<SvgIcon component={downArrowIcon} htmlColor={COLORS.COPY} />}
              aria-controls="consignment-history-content"
              id="consignment-history-header"
              className={classes.accordionSummary}
            >
              <Typography className="header">Items Summary ({items && items.length})</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="div" className="content">
                <Grid className="itemsContainer" container direction="column">
                  <Grid className="item" item>
                    <Grid className="itemContainer header" container>
                      <Grid className="itemColumn item1" item xs={5}>
                        <Typography>Product Name</Typography>
                      </Grid>
                      <Grid className="itemColumn item2" item xs={4}>
                        <Typography>Shipped/Ordered Qty</Typography>
                      </Grid>
                      <Grid className="itemColumn item3" item xs={3}>
                        <Typography>Unit Price</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {getItems()}
                </Grid>
                <Grid className="paymentContainer" container direction="column">
                  <Grid className="paymentItem" item>
                    <Grid container>
                      <Grid className="paymentItemColumn" item>
                        <Typography>Payment Method</Typography>
                      </Grid>
                      <Grid className="paymentItemColumn right" item>
                        <Typography>{tableRecord ? tableRecord.paymentType || "" : ""}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid className="paymentItem" item>
                    <Grid container>
                      <Grid className="paymentItemColumn" item>
                        <Typography>Delivery Charges</Typography>
                      </Grid>
                      <Grid className="paymentItemColumn right" item>
                        <Typography>{deliveryInformation && metadata ? `${deliveryInformation.deliveryCost || ""} ${metadata.currency || ""}` : ""}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                                    {
                    tableRecord  && isPostPaidOrder(tableRecord?.paymentType)  ? (
                      <Grid className="paymentItem" item>
                        <Grid container>
                          <Grid className="paymentItemColumn" item>
                            <Typography>{tableRecord?.paymentType} Amount</Typography>
                          </Grid>
                          <Grid className="paymentItemColumn right" item>
                            <Typography>{metadata ? `${metadata.amountToCollect || ""} ${metadata.currency || ""}` : ""}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : null
                  }
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>
          {
          tableRecord && hasRepushAccess(tableRecord.displayStatus) && 
          <Grid className="repushButton">
            <Button variant="contained" className="button primary" onClick={handleRepushReturn}>REPUSH ORDER</Button>
          </Grid>
          }
        </Grid>
      </Grid>
    )
  }

  const handleDialogClose = () => {
    closePopup();
  }

  return (
    <CustomDialog
      open={open}
      TransitionComponent={Transition}
      PaperProps={dialogPaperProps}
      title={getTitleContent()}
      content={getDetailsContent()}
      actions={null}
      handleClose={handleDialogClose}
    ></CustomDialog>
  )
}

export default ConsignmentDetails;