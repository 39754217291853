import { Button, Collapse, debounce, Divider, Grid, SvgIcon, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ReactComponent as downArrowIcon } from './../../assets/icons/down-arrow.svg';
import Filter from './Filter';
import { useStyles } from './GridWrapper.styles';
import AppConstants from '../../constants';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { AppState } from "../../config/redux/reducers";
import { SelectItemProps } from '../SelectBox';
import CustomHeaderComponent from './CustomHeader';
import MultiSearchComponent from '../MultiSearchComponent';
import DataTable, { ComponentProps, RowParams, SelectionChangeParams, SortModel } from './DataTable';
import { resetTableSelection, resetFilterOrderBySearch } from "../../orderList/redux/orderListSlice";
import { resetFilterReturnsBySearch } from "../../returnsList/redux/returnsListSlice";
import { resetFilterUserBySearch } from "../../Users/redux/usersSlice";

const switchCasesCallbacks: any = {};
interface GridWrapperProps {
  headerData: any;
  isColumnSettingsDisabled?: boolean | undefined;
  columnSettingsColList?: any;
  favoriteViews?: any;
  rowData: Array<any>;
  loading: boolean | undefined;
  exceptions?: boolean | undefined;
  searchTypes: Array<SelectItemProps>;
  filterForm: Array<any>;
  defaultFilter: any;
  loadMore: any;
  title: string;
  headerButtons?: Array<any>;
  sortModel?: SortModel;
  handleFilterChange?: (fieldName: string, value: any) => void;
  onRowClick?: ((params: RowParams) => void) | undefined;
  onSelectionChange?: (params: SelectionChangeParams) => void | undefined;
  onSort?: ((params: SortModel) => void) | undefined;
  headerBtnClick?: (params: any) => void | undefined;
  onChangeFavoriteView?: (params: any) => void | undefined;
  saveColumnSettingsActions?: (params: any) => void;
  searchCallback: (data: any, filters: any, isLoadMore: boolean) => void;
  filterGrid: (filters: any, isLoadMore?: boolean) => void;
  fetchAllFilters?: () => void;
  resetFilter?: () => void;
}

const GridWrapper = (props: GridWrapperProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const filterRef = useRef<HTMLDivElement>(null);

  const { headerData, rowData, isColumnSettingsDisabled, columnSettingsColList, favoriteViews, loading, exceptions, searchTypes, filterForm, defaultFilter, loadMore, headerButtons, title, sortModel, onSelectionChange, handleFilterChange, headerBtnClick, onRowClick, onSort, searchCallback, fetchAllFilters, filterGrid, onChangeFavoriteView, saveColumnSettingsActions } = props;

  const [toggleFilters, setToggleFilters] = useState(true);

  const [tableStyle, setTableStyle] = useState({ height: `calc(100vh - 195px)` });

  const [searchType, setSearchType] = useState(searchTypes.length ? searchTypes[0].value : "");
  const [searchTextboxValue, setSearchTextboxValue] = useState("");
  const [placeholderText, setPlaceholderText] = useState("");
  const [filters, setFilters] = useState(defaultFilter);
  const [resetFilterFlag, setResetFilterFlag] = useState(false);

  const { countryCode } = useSelector(
    (state: AppState) => state.common
  );

  const { repushOrderFlag, reassignCarrierFlag, filterOrderBySearch } = useSelector(
    (state: AppState) => state.orderList
  );

  const { repushReturnFlag, filterReturnsBySearch } = useSelector(
    (state: AppState) => state.returnsList
  );

  const { filterUserBySearch } = useSelector(
    (state: AppState) => state.users
  );

  const createPlaceHoldersSwitch = () => {
    if (searchTypes.length) {
      const addCase = (_case: any, fn: any) => {
        switchCasesCallbacks[_case] = switchCasesCallbacks[_case] || [];
        switchCasesCallbacks[_case].push(fn);
      }
      searchTypes.forEach(type => {
        addCase(type.value, () => {
          setPlaceholderText(type.name || "");
        });
      });
    }
  }
  const getPlaceHolderText = useCallback(
    () => {
      if (switchCasesCallbacks[searchType]) {
        switchCasesCallbacks[searchType].forEach((fn: any) => {
          fn();
        })
      }
    },
    [searchType],
  );

  const updateFilter = useCallback(
    (filter: any) => {
      handleFilterChange && handleFilterChange(filter.key, filter.value);
      setFilters({ ...filters, [filter.key]: filter.value });
      setResetFilterFlag(false);
    },
    [filters, handleFilterChange]
  );

  const resetFilters = (isException?: boolean) => {
    const resetFilters = { ...defaultFilter };
    if(isException){
      resetFilters.resetExceptionFilters = true;
    }
    setFilters(resetFilters);
    setResetFilterFlag(true);
    setSearchTextboxValue("");
    resetTableData();
    filterGrid(resetFilters);
  }

  const resetTableData = useCallback(
    (isLoadMore?: boolean) => {
      const element = document.querySelector('.data-table-scrollable-area');
      if(!isLoadMore){
        element?.scrollTo(0, 0);
      }
      dispatch(
        resetTableSelection({tableSelection: true})
      );
    },
    [dispatch]
  );

  const handleSearchTypeChange = useCallback(
    (value: any) => {
      setSearchType(value);
      setSearchTextboxValue("");
    },
    []
  );

  const handleSearchBoxTextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSearchTextboxValue(event.target.value);
    },
    []
  );

  const search = useCallback(
    () => {
      if (searchTextboxValue) {
        resetTableData();
        searchCallback({ [searchType]: searchTextboxValue }, filters, false)
      }
    },
    [searchTextboxValue, searchType, filters, resetTableData, searchCallback],
  )

  const handleKeyDownChange = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (searchTextboxValue && event.keyCode === 13) {
        search();
      }
    },
    [searchTextboxValue, search]
  )

  const filterGridData = useCallback((isLoadMore?: boolean) => {
    resetTableData(isLoadMore);
    filterGrid(filters, isLoadMore);
  }, [filters, filterGrid, resetTableData]);

  const handleHeaderBtnClick = useCallback(
    (buttonObj: any) => {
      let buttonParams: any = {
        type: buttonObj.name
      }
      if (buttonObj.name === 'excel' || buttonObj.name === 'repush') {
        buttonParams.params = filters;
      }
      headerBtnClick && headerBtnClick(buttonParams);
    },
    [filters, headerBtnClick]
  );

  const handleFilters = useCallback(
    () => {
      if (searchTextboxValue) {
        search();
      } else {
        filterGridData();
      }
    },
    [searchTextboxValue, search, filterGridData]
  );

  const loadMoreCallback = debounce((event: any) => {
    const target = event.target;
    if (target) {
      if (loading || loadMore.disabled || loadMore.rowCount <= loadMore.pageSize) return;
      let offsetheightCust = exceptions ? target.offsetHeight + 5 : target.offsetHeight
      if (offsetheightCust + target.scrollTop >= target.scrollHeight) {
        if (searchTextboxValue && (filterOrderBySearch || filterReturnsBySearch || filterUserBySearch)) {
          searchCallback({ [searchType]: searchTextboxValue }, filters, true)
        }else{
          filterGridData(true);
        }
      }
    } else {
      return;
    }
  }, 100);

  const enableLoadMore = useCallback(
    (disable?: boolean) => {
      const element = document.querySelector('.data-table-scrollable-area');
      if (element) {
        if (!disable) {
          element.addEventListener('scroll', loadMoreCallback);
        } else {
          element.removeEventListener('scroll', loadMoreCallback);
        }
      }
    },
    [loadMoreCallback]
  );

  useEffect(() => {
    createPlaceHoldersSwitch();
    getPlaceHolderText();
    fetchAllFilters && fetchAllFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    enableLoadMore();
    return () => {
      enableLoadMore(true);
    }
  }, [loadMore, enableLoadMore])

  useEffect(() => {
    getPlaceHolderText();
  }, [searchType, getPlaceHolderText]);

  useEffect(() => {
    if (toggleFilters && filterRef.current) {
      const filterSectionHeight = filterRef.current.offsetHeight;
      setTableStyle({ height: `calc(100vh - ${122 + filterSectionHeight}px)` });
    } else {
      setTableStyle({ height: `calc(100vh - 122px)` });
    }
  }, [toggleFilters])

  useEffect(() => {
    resetFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode])

  useEffect(() => {
    if(repushOrderFlag || repushReturnFlag || reassignCarrierFlag){
      resetFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repushOrderFlag, repushReturnFlag, reassignCarrierFlag])

  useEffect(() => {
    if(history.location.pathname !== AppConstants.ROUTES.USER_MANAGEMENT){
      dispatch(
        resetFilterUserBySearch()
      );
    }
    if(history.location.pathname !== AppConstants.ROUTES.CONSIGNMENTS){
      dispatch(
        resetFilterOrderBySearch()
      );
    }
    if(history.location.pathname !== AppConstants.ROUTES.CONSIGNMENTS_RETURNS){
      dispatch(
        resetFilterReturnsBySearch()
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOrderBySearch, filterUserBySearch, filterReturnsBySearch])

  const handleColumnSettingsActions = useCallback(
    (params: any) => {
      saveColumnSettingsActions && saveColumnSettingsActions(params)
    },
    [saveColumnSettingsActions]
  )

  const handleOnChangeFavoriteView = useCallback(
    (params: any) => {
      onChangeFavoriteView && onChangeFavoriteView(params)
    },
    [onChangeFavoriteView]
  )

  return (
    <div className={classes.root}>
      <Grid container direction="column">
        <Grid className={`${ exceptions ? classes.exceptionsFilterSection : classes.filterSection }`} item>
          <Grid container direction="column">
            <Grid className="filterHeading" item>
              <Grid container className="headingContainer" alignItems="center" onClick={() => setToggleFilters(!toggleFilters)}>
                <SvgIcon className={["logo", toggleFilters ? "open" : ""].join(" ")} component={downArrowIcon} />
                <Typography className="heading" variant="h5" component="h5">FILTER</Typography>
              </Grid>
            </Grid>
            <Collapse in={toggleFilters}>
              <Grid ref={filterRef} className="filtersContent" container>
                <Grid className="search" item>
                  <MultiSearchComponent
                    searchTypes={searchTypes}
                    searchType={searchType}
                    searchValue={searchTextboxValue}
                    placeholderText={placeholderText}
                    handleSearchTypeChange={handleSearchTypeChange}
                    handleSearchBoxTextChange={handleSearchBoxTextChange}
                    handleSearchBoxKeyDownChange={handleKeyDownChange}
                    handleIconClick={search}
                  />
                </Grid>
                {filterForm.length ? filterForm.map((filter: any) => (<Filter key={filter.key} filter={filter} updateFilter={updateFilter} defaultValues={defaultFilter} reset={resetFilterFlag} initialValues={{name:`Select ${filter.label}`, value:AppConstants.SELECT_NONE.value}} />)) : null}
                <Grid className="buttons" item>
                  <Button variant="contained" className={[classes.button, 'primary'].join(' ')} onClick={handleFilters}>{AppConstants.BUTTONS.SEARCH}</Button>
                  <Button variant="contained" className={[classes.button, 'secondary', 'reset-btn'].join(' ')} onClick={() => resetFilters(exceptions ? true : false)}>{AppConstants.BUTTONS.RESET}</Button>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
        <Grid className={classes.tableSection} style={tableStyle} item>
          <Divider />
          <DataTable
            loading={loading}
            exceptions={exceptions}
            rows={rowData}
            columns={headerData}
            isColumnSettingsDisabled={isColumnSettingsDisabled} 
            rowHeight={48}
            headerHeight={48}
            checkboxSelection
            disableSelectionOnClick
            sortModel={sortModel}
            onRowClick={onRowClick}
            onSelectionChange={onSelectionChange}
            onSort={onSort}
            rowCount={loadMore.rowCount}
            components={{
              header: (props: ComponentProps) => (
              <CustomHeaderComponent {...props} 
                exceptions={exceptions}
                isColumnSettingsDisabled={isColumnSettingsDisabled} 
                columnSettingsColList={columnSettingsColList} 
                favoriteViews={favoriteViews} 
                handleColumnSettingsActions={handleColumnSettingsActions} 
                onChangeFavoriteView={handleOnChangeFavoriteView} 
                title={title} 
                buttons={headerButtons} 
                btnClickHandler={handleHeaderBtnClick} 
              />)
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default GridWrapper;