import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .hasLabel': {
        '& .MuiOutlinedInput-input': {
          paddingTop: theme.spacing(2),
          paddingBottom: 5
        }
      }
    }
  })
);