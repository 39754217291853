import { Grid, IconButton, InputAdornment, SvgIcon } from '@material-ui/core';
import React from 'react';
import SelectBox, { SelectItemProps } from '../SelectBox';
import TextBox from '../TextBox';
import { useStyles } from './MultiSearchComponent.styles';
import { ReactComponent as searchIcon } from './../../assets/icons/search.svg';

export interface MultiSearchComponentProps {
  searchTypes: Array<SelectItemProps>;
  searchType: string;
  searchValue: string;
  placeholderText: string;
  handleSearchTypeChange: (value: any) => void;
  handleSearchBoxTextChange: ((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void) | undefined;
  handleSearchBoxKeyDownChange: ((event: React.KeyboardEvent<HTMLDivElement>) => void) | undefined;
  handleIconClick: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
};

const MultiSearchComponent = (props: MultiSearchComponentProps) => {
  const classes = useStyles();

  const { searchTypes, searchType, searchValue, placeholderText, handleIconClick, handleSearchTypeChange, handleSearchBoxTextChange, handleSearchBoxKeyDownChange } = props;

  return (
    <Grid className={classes.root} container>
      <Grid className={classes.searchSelect} item>
        <SelectBox
          handleChange={handleSearchTypeChange}
          value={searchType}
          hideIconComponent
          menuPaperProps={classes.searchSelectPaperProps}
          id="searchTypeSelect"
          items={searchTypes}
        ></SelectBox>
      </Grid>
      <Grid className={classes.searchTextbox} item>
        <TextBox
          variant="outlined"
          handleChange={handleSearchBoxTextChange}
          handleKeyDownChange={handleSearchBoxKeyDownChange}
          value={searchValue}
          label="Search"
          textBoxId="searchTextBox"
          type="text"
          placeholderText={placeholderText}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                edge="end"
                size="small"
                onClick={handleIconClick}
              >
                <SvgIcon component={searchIcon} />
              </IconButton>
            </InputAdornment>
          )}
        ></TextBox>
      </Grid>
    </Grid>
  );
}

export default MultiSearchComponent;