const ReturnConstants = {
  RETURN_CONSTANTS: {
    TABLE_WRAPPER_DATA: {
      title: "Consignments",
      HeaderData: [
        {
          field: 'id',
          headerName: 'Id',
          hide: true
        },
        {
          type: "number",
          field: 'returnId',
          headerName: 'Return ID',
          width: 150
        },
        {
          type: "number",
          field: 'consignmentId',
          headerName: 'Original Consignment',
          width: 200
        },
        {
          field: 'hubName',
          headerName: 'Hub',
          width: 150
        },
        {
          field: 'hubCode',
          headerName: 'Hub Code',
          width: 150
        },
        {
          type: "number",
          field: 'trackingId',
          headerName: 'Tracking ID',
          width: 150
        },
        {
          type: "date",
          field: 'pickupDate',
          headerName: 'Pickup Date',
          width: 150
        },
        {
          field: 'pickupSlotTime',
          headerName: 'Pickup Slot',
          width: 130
        },
        {
          field: 'displayStatus',
          headerName: 'Return Status',
          width: 150
        },
        {
          field: 'deliveryTime',
          headerName: 'Status Update Time',
          width: 170
        },
        {
          type: "number",
          field: 'orderId',
          headerName: 'Order ID',
          width: 120
        },
        {
          field: 'customerName',
          headerName: 'Customer Name',
          width: 150
        },
        {
          field: 'pickupCity',
          headerName: 'Pickup City',
          width: 150
        },
        {
          type: "number",
          field: 'pickupZone',
          headerName: 'Pickup Zone',
          width: 150
        },
        {
          field: 'pickupArea',
          headerName: 'Pickup Area',
          width: 150
        },
        {
          field: 'pickupStreetAddress',
          headerName: 'Pickup Street Address',
          width: 200
        },
        {
          type: "number",
          field: 'customerMobileNumber',
          headerName: 'Customer Mobile Number',
          width: 210
        },
        {
          type: "date",
          field: 'transactionDate',
          headerName: 'Transaction Date',
          width: 150
        },
        {
          field: 'paymentType',
          headerName: 'Payment Type',
          width: 140
        },
        {
          type: "number",
          field: 'totalAmount',
          headerName: 'Total Amount',
          width: 140
        },
        {
          type: "number",
          field: 'codAmount',
          headerName: 'COD Amount',
          width: 130
        },
        {
          field: 'carrier',
          headerName: 'Carrier',
          width: 100
        },
        {
          field: 'vehicleType',
          headerName: 'Vehicle Type',
          width: 130
        },
        {
          field: 'deliveryProposition',
          headerName: 'Delivery Proposition',
          width: 180
        },
        {
          field: 'sellerName',
          headerName: 'Seller Name',
          width: 130
        },
        {
          field: 'omsStatus',
          headerName: 'OMS Status',
          width: 120
        },
        {
          field: 'statusErrors',
          headerName: 'Errors',
          width: 150
        },
        {
          field: 'deliveryStatus',
          headerName: 'Carrier Status',
          width: 150
        },
        {
          type: "date",
          field: 'deliveryDate',
          headerName: 'Delivery Date',
          width: 150
        },
        {
          field: 'startEndTime',
          headerName: 'Delivery Slot',
          width: 130
        },
      ],
      FilterFormData: [
        {
          type: "selectBox",
          fieldName: "hub",
          multiple: true,
          key: `selectBox-hub-${Math.floor(Math.random() * 1000)}`,
          label: "Hub",
          items: []
        },
        {
          type: "dateRange",
          fieldName: "dateRange",
          key: `dateRange-filter-${Math.floor(Math.random() * 1000)}`,
          placeholderText: 'Pickup Date',
          label: 'Pickup Date',
          autoUpdateInput: false,
          autoApply: true,
          showDropdowns: true,
          linkedCalendars: true,
          maxSpan: {
            day: "6"
          },
          locale: {}
        },
        {
          type: "selectBox",
          fieldName: "deliveryStatus",
          multiple: true,
          key: `selectBox-delStatus-${Math.floor(Math.random() * 1000)}`,
          label: "Return Status",
          items: []
        },
      ],
      headerButtons: [
        {
          name: "print",
          displayName: "PRINT SHIPPING LABEL",
          disabled: false
        },
        {
          name: "excel",
          displayName: "EXPORT TO EXCEL",
          disabled: false
        }
      ],
      loadMore: {
        name: 'loadMore',
        disabled: false,
        pageSize: 20
      },
      defaultFiltersObj: {
        hub: ["all"],
        deliveryStatus: ["all"],
        proposition: ["all"],
        page: 0,
        dateRange: {
          startDate: new Date(),
          endDate: new Date()
        }
      },
      SEARCH_TYPES: [
        {
          name: "Return Id",
          value: "consignment"
        },
        {
          name: "Tracking Id",
          value: "tracking"
        },
        {
          name: "Order Id",
          value: "order"
        },
        {
          name: "Phone",
          value: "phone"
        }
      ],
      sortModel: {
        field: "deliveryDate",
        direction: "asc"
      }
    },
    REPUSH_STATUSES:["Return PickUp Creation Failed", "Return Creation Failed"],
    FAILED_STATUSES:["PICKUP_CREATION_FAILED", "CREATION_FAILED"],
    STATUSES: {
      "new": {
        color: "yellow",
        hintText: "When request is received in CaMS from OMS"
      },
      "carrier_assigned": {
        color: "yellow",
        hintText: "Carrier identified for delivey based on carrier selection rules"
      },
      "export_success": {
        color: "yellow",
        hintText: "Request to 3PL/LM system successful"
      },
      "driver_assigned": {
        color: "yellow",
        hintText: "Driver assigned for Pickup"
      },
      "arrived": {
        color: "yellow",
        hintText: "3PL/ Driver arrived at the pickup location"
      },
      "picked_up": {
        color: "yellow",
        hintText: "3PL/ Driver collected the order for delivery. Can be used in Ready for Dispatch also"
      },
      "not_delivered_reattempt": {
        color: "yellow",
        hintText: "Order not delivered but needs to be reattempted/ rescheduled for delivery"
      },
      "carrier_reassigned": {
        color: "blue",
        hintText: "New carrier assigned within CaMS"
      },
      "driver_reassigned": {
        color: "blue",
        hintText: "Driver reassigned for Pickup"
      },
      "dispatched": {
        color: "blue",
        hintText: "Out for delivery"
      },
      "partially_delivered": {
        color: "green",
        hintText: "Not all items have been delivered to the customer"
      },
      "delivered": {
        color: "green",
        hintText: "All items of an order delivered to customer"
      },
      "export_failed": {
        color: "red",
        hintText: "Request to 3PL (Carrier system) failed"
      },
      "pickup_failed": {
        color: "red",
        hintText: "3PL/ Driver unable to collect the order due to Technical or operational issue"
      },
      "cancelled_lm": {
        color: "red",
        hintText: "Last mile has cancelled the delivery for tech/ops issues"
      },
      "cancelled_customer": {
        color: "red",
        hintText: "Delivery is cancelled by the customer and if the order has been dispatched then it needs to be returned"
      },
      "lost_in_transit_fwd": {
        color: "red",
        hintText: "All or some items lost by LM/ 3PL before delivery to customer"
      },
      "not_delivered_rto": {
        color: "red",
        hintText: "Order not delivered and needs to be returned to shipper"
      },
      "return_to_origin": {
        color: "red",
        hintText: "Return initiated due to non-delivery of the order"
      },
      "lost_in_transit_rto": {
        color: "red",
        hintText: "All or some items lost by LM/ 3PL after initiating RTO but before delivering back to the Shipper"
      },
      "delivered_rto": {
        color: "red",
        hintText: "RTO order successfully delivered back to the Shipper"
      }
    },
    EXCEL_FILE_NAME: "returns-consignments.xlsx",
    RESPONSE_CONSTANTS: {
      ERROR_CODES: {
        SESSION_TIMEOUT: "UM100",
        UNAUTHORISED: "UM101",
      }
    },
    MORE_ERRORS: "...more errors"
  },
}

export default ReturnConstants;