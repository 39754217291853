import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginLeft: 24,
      marginRight: 24,
      height: "100%",
    },
    container: {
      flexDirection: "column",
      height: "100%",
      '& .planningItem': {
        width: '100%',
        '& .MuiTab-root': {
          marginLeft: theme.spacing(2),
          maxWidth: '130px !important'
        },
        '&.heading': {
          paddingBottom: theme.spacing(2.5),
          paddingTop: theme.spacing(2),
          '& p': {
            fontSize: 24,
            color: COLORS.BLACK,
            fontWeight: 600
          }
        },
        '&.content': {
          backgroundColor: COLORS.WHITE,
          boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
          borderRadius: theme.spacing(1),
          height: "calc(100vh - 172px)",
          '& .exceptionsRoutes':{
            paddingTop: theme.spacing(1),
            '& .MuiTabs-fixed':{
              '& .MuiTabs-indicator': {
                width: '110px !important'
              },
              '& .MuiTab-wrapper': {
                alignItems: 'start',
                paddingBottom: theme.spacing(1)
              }
            }
          }
        }
      }
    },
    tabPanelContainer: {
      flexDirection: "column",
      height: "100%",
      '& .panelItem': {
        width: "100%",
        '&.chipFilter': {
          overflowY: 'auto',
          padding: theme.spacing(3, 2),
          '&::-webkit-scrollbar': {
            height: '0'
          },
          '& .chipsContainer': {
            justifyContent: 'start',
            minWidth: 1430,
            '& .chipDisabled':{
              backgroundColor: COLORS.DOVE_GRAY_LIGHT,
            },
            '& .chipFilterItem': {
              height: 50,
              minWidth: "13.2%",
              borderRadius: theme.spacing(1),
              marginRight: theme.spacing(2),
              border: `1px solid ${COLORS.TRANSPARENT_BLUE_BORDER}`,
              cursor: "pointer",
              '&:last-child': {
                marginRight: 0
              },
              '&.active': {
                backgroundColor: theme.palette.primary.main,
                border: "none",
                boxShadow: "none",
                '& .chipItem p': {
                  color: COLORS.WHITE
                }
              },
              '& .chipContainer': {
                alignItems: "center",
                height: "100%",
                '& .chipItem': {
                  padding: theme.spacing(0, 1.5),
                  '& p': {
                    fontWeight: 500,
                    color: COLORS.MINE_SHAFT,
                  },
                  '&.name': {
                    '& p': {
                      fontSize: 14
                    }
                  },
                  '&.count': {
                    marginLeft: "auto",
                    '& p': {
                      fontSize: 24
                    }
                  },
                  '& .notApplicable':{
                    fontSize: '16px !important'
                  }
                }
              }
            }
          }
        },
        '&.gridWrapper': {
          height: "calc(100% - 98px)",
          '& .idLink': {
            cursor: "pointer",
            color: theme.palette.primary.main,
            fontWeight: 600,
            fontSize: 12
          }
        }
      }
    },
    statusHeader: {
      "& .statusMsgMore": {
        fontWeight:"bold"
      }
    }
  }),
);
