import React from 'react';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import NotFound from './common/NotFoundPage';
import AppConstants from './constants';
import UserLogin from './Login';
import UserRegister from './Login/register';
import SuccessStatus from './Login/successStatus';
import OrderList from './orderList';
import ReturnsList from './returnsList';
import Users from './Users';
import Dashboard from './Dashboard';
import ExceptionsDelivery from './ExceptionsDelivery';
import ExceptionsReturns from './ExceptionsReturns';
import ComingSoon from './common/ComingSoonPage';

interface RoutesProps {
  isLoggedIn: boolean;
  isAdmin: boolean;
};

interface GuardedRouteProps extends RouteProps {
  routeGuards?: RouteGuards;
}

interface RouteGuards {
  [key: string]: boolean;
}

const RouteGuards = (guards: RouteGuards | undefined) => {
  let isActive = false;
  if (guards) {
    isActive = true;
    for (let key in guards) {
      if (!guards[key]) {
        isActive = false;
        break;
      }
    }
  }
  return isActive;
}

const AuthenticatedRoute = (props: GuardedRouteProps) => {
  const { component: Component, routeGuards, ...rest } = props;

  return (
    <Route
      {...rest}
      render={props => (RouteGuards(routeGuards) === true ? Component ? <Component {...props} /> : <Redirect to={{ pathname: AppConstants.ROUTES.HOME }} /> : <Redirect to={{ pathname: AppConstants.ROUTES.LOGIN }} />)}
    />
  )
}

const UnauthenticatedRoute = (props: GuardedRouteProps) => {
  const { component: Component, routeGuards, ...rest } = props;

  return (
    <Route
      {...rest}
      render={props => (RouteGuards(routeGuards) === false ? Component ? <Component {...props} /> : <Redirect to={{ pathname: AppConstants.ROUTES.LOGIN }} /> : <Redirect to={{ pathname: AppConstants.ROUTES.HOME }} />)}
    />
  )
}

const Routes = (props: RoutesProps) => {

  const { isLoggedIn, isAdmin } = props;
  return (
    <Switch>
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn
        }}
        path={[AppConstants.ROUTES.DASHBOARD, AppConstants.ROUTES.CAMS_DASHBOARD]}
        component={Dashboard}
      />
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn
        }}
        path={[AppConstants.ROUTES.CONSIGNMENTS, AppConstants.ROUTES.CAMS_CONSIGNMENT]}
        component={OrderList}
      />
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn
        }}
        path={[AppConstants.ROUTES.CONSIGNMENTS_RETURNS, AppConstants.ROUTES.CAMS_CONSIGNMENTS_RETURNS]}
        component={ReturnsList}
      />
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn,
          isAdmin: isAdmin
        }}
        path={AppConstants.ROUTES.USER_MANAGEMENT}
        component={Users}
      />
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn
        }}
        path={AppConstants.ROUTES.ADMINISTRATION}
        component={ComingSoon}
      />
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn
        }}
        path={[AppConstants.ROUTES.EXCEPTIONS_DELIVERIES, AppConstants.ROUTES.CAMS_EXCEPTIONS_DELIVERIES]}
        component={ExceptionsDelivery}
      />
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn
        }}
        path={AppConstants.ROUTES.EXCEPTIONS_RETURNS}
        component={ExceptionsReturns}
      />
      <UnauthenticatedRoute
        routeGuards={{
          auth: isLoggedIn
        }}
        path={AppConstants.ROUTES.RESET_PWD}
        component={() => (<UserLogin resetPwd={true} />)}
      />
      <UnauthenticatedRoute
        routeGuards={{
          auth: isLoggedIn
        }}
        path={AppConstants.ROUTES.LOGIN}
        component={UserLogin}
      />
      <UnauthenticatedRoute
        routeGuards={{
          auth: isLoggedIn
        }}
        path={AppConstants.ROUTES.REGISTER}
        component={UserRegister}
      />
      <UnauthenticatedRoute
        routeGuards={{
          auth: isLoggedIn
        }}
        path={AppConstants.ROUTES.STATUS}
        component={SuccessStatus}
      />
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn
        }}
        path={AppConstants.ROUTES.INDEX}
      />
      <Route>
        <NotFound />
      </Route>
    </Switch>
  )
}

export default Routes;