import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

export const COLORS = {
  PRIMARY_MAIN: "#0E5AA7",
  PRIMARY_LIGHT: "rgba(14, 90, 167, 0.2)",
  PRIMARY_DARK: "#0E5A80",
  SECONDARY_MAIN: "#F5F5F5",
  SECONDARY_LIGHT: "#EEEEEE",
  SECONDARY_DARK: "#E6E6E6",
  TEXT_PRIMARY: "#252525",
  TRANSPARENT_BLUE: "rgba(10, 67, 124, 0.05)",
  WHITE: "#FFFFFF",
  BLACK_SHADOW: "rgba(0,0,0,0.05)",
  BLACK_INPUT_BORDER: "rgba(0, 0, 0, 0.23)",
  BLACK_INPUT_LABEL: "rgba(0, 0, 0, 0.54)",
  BLACK_BORDER: "#B2B2B2",
  BLACK: "#000000",
  GOLDEN_YELLOW: "#E3870F",
  AZURE_BLUE: "#0A437C",
  PERSIAN_GREEN: "#009688",
  DARK_RED: "#F34041",
  DOVE_GRAY: "#666666",
  DOVE_GRAY_LIGHT: "rgba(102,102,102,0.6)",
  SILVER: "#C4C4C4",
  COPY: "#4D4D4D",
  TRANSPARENT: "transparent",
  LOADER_BACKGROUND: "rgba(255, 255, 255, 0.38)",
  STATUS_YELLOW: "#F79E1B",
  TABLE_ROW_SELECTED: "#F3F6F8",
  ROUTE_CONTAINER_BACKGROUND: '#FAFAFA',
  TRANSPARENT_BLUE_BORDER: "rgba(10, 67, 124, 0.2)",
  BOX_SHADOW_PRIMARY: "rgba(14, 90, 167, 0.2)",
  GRADIENT_BLUE: "#91AECC",
  MINE_SHAFT: "#2D2D2D",
  AMBER: "#E79832",
  LIGHT_WHITE: "rgba(0, 0, 0, 0.06)",
};

const checkboxDefaultIcon = 'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMS41IiB5PSIxLjUiIHdpZHRoPSIyMSIgaGVpZ2h0PSIyMSIgcng9IjIuNSIgZmlsbD0id2hpdGUiIHN0cm9rZT0iI0IyQjJCMiIvPgo8L3N2Zz4K)';
const checkboxCheckedIcon = 'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMS41IiB5PSIxLjUiIHdpZHRoPSIyMSIgaGVpZ2h0PSIyMSIgcng9IjIuNSIgZmlsbD0iIzBFNUFBNyIgZmlsbC1vcGFjaXR5PSIwLjEiIHN0cm9rZT0iIzBFNUFBNyIvPgo8cGF0aCBkPSJNNiAxMS44NTVMMTAuMDk2IDE2TDE4IDcuNzg3TDE3LjI0MyA3TDEwLjA4NSAxNC40MzdMNi43NDcgMTEuMDU3TDYgMTEuODU1WiIgZmlsbD0iIzBFNUFBNyIvPgo8L3N2Zz4K)';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Montserrat',
      'Roboto',
      'Helvetica',
      'sans-serif'
    ].join(','),
    fontWeightRegular: 500
  },
  palette: {
    type: 'light',
    primary: {
      main: COLORS.PRIMARY_MAIN,
      light: COLORS.PRIMARY_LIGHT,
      dark: COLORS.PRIMARY_DARK,
    },
    secondary: {
      main: COLORS.SECONDARY_MAIN,
      light: COLORS.SECONDARY_LIGHT,
      dark: COLORS.SECONDARY_DARK,
    },
    error: {
      main: red.A400,
    },
    background: {
      default: COLORS.WHITE,
    },
    text: {
      primary: COLORS.TEXT_PRIMARY
    }
  },
  overrides: {
    MuiTabs: {
      root: {
        flex: "auto",
        minHeight: 40,
        alignItems: "center"
      },
      indicator: {
        backgroundColor: COLORS.PRIMARY_MAIN
      }
    },
    MuiTab: {
      root: {
        flexGrow: 1,
        minHeight: 24,
        padding: "6px 0px 2px",
        marginLeft: 40,
        minWidth: "100px !important",
        maxWidth: "160px !important",
        '&$selected': {
          color: COLORS.PRIMARY_MAIN,
          fontWeight: 700
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 8,
        height: 40,
        minWidth: 200,
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: COLORS.BLACK_INPUT_BORDER,
          borderWidth: 1
        },
        '&:hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: COLORS.BLACK_INPUT_BORDER
          }
        },
        '&.hasLabel': {
          '& .MuiSelect-root': {
            paddingTop: 16,
            paddingBottom: 5
          }
        }
      },
      adornedEnd: {
        paddingRight: 8,
        '& .MuiSvgIcon-root': {
          padding: 4
        }
      },
      input: {
        paddingTop: 10.5,
        paddingBottom: 10.5,
        fontSize: 12,
        height: "auto"
      }
    },
    MuiSelect: {
      root: {
        paddingTop: 10.5,
        paddingBottom: 10.5,
        fontWeight: 600
      },
      icon: {
        padding: 4
      },
      select: {
        '&:focus': {
          backgroundColor: "inherit"
        }
      }
    },
    MuiInputLabel: {
      outlined: {
        marginTop: 13,
        fontWeight: 400,
        fontSize: 12,
        '&.Mui-focused': {
          color: COLORS.BLACK_INPUT_LABEL
        }
      }
    },
    MuiButton: {
      root: {
        padding: "7px 16px",
        fontSize: 14,
        fontWeight: 600,
        borderRadius: 8,
        textTransform: "none",
        '&.primary': {
          backgroundColor: COLORS.PRIMARY_MAIN,
          color: COLORS.WHITE,
          border: `1px solid ${COLORS.TRANSPARENT}`,
          '&$disabled': {
            backgroundColor: COLORS.PRIMARY_LIGHT,
            color: COLORS.WHITE
          },
          '&:hover': {
            backgroundColor: COLORS.PRIMARY_MAIN
          }
        },
        '&.secondary': {
          backgroundColor: COLORS.WHITE,
          color: COLORS.PRIMARY_MAIN,
          border: `1px solid ${COLORS.PRIMARY_MAIN}`,
          '&$disabled': {
            color: COLORS.PRIMARY_MAIN,
          },
          '&:hover': {
            backgroundColor: COLORS.WHITE
          }
        }
      },
      contained: {
        boxShadow: "none",
        '&:hover': {
          boxShadow: "none"
        }
      }
    },
    MuiAccordion: {
      root: {
        width: "100%",
        borderRadius: "8px !important",
        boxShadow: "0px 1px 4px 0px rgba(0,0,0,0.20)"
      }
    },
    MuiAccordionSummary: {
      root: {
        minHeight: 64,
        borderBottom: `1px solid ${COLORS.SECONDARY_DARK}`,
        '&$expanded': {
          minHeight: 64
        }
      }
    },
    MuiAccordionDetails: {
      root: {
        padding: 16
      }
    },
    MuiListItemText: {
      root: {
        margin: "0 !important"
      },
      primary: {
        fontSize: 12
      }
    },
    MuiCheckbox: {
      root: {
        padding: 0,
        borderRadius: 0,
        background: checkboxDefaultIcon,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "20px 20px",
        '& svg path': {
          display: "none"
        }
      },
      colorPrimary: {
        '&.Mui-checked': {
          backgroundImage: checkboxCheckedIcon,
        }
      },
      indeterminate: {
        backgroundImage: `${checkboxDefaultIcon} !important`
      },
    },
    MuiTableCell: {
      root: {
        fontSize: 12,
        padding: "14px 10px 13px",
        '&:first-child': {
          paddingLeft: 16
        }
      },
      head: {
        lineHeight: 0,
        fontWeight: 600
      },
      stickyHeader: {
        backgroundColor: COLORS.SECONDARY_MAIN
      }
    },
    MuiTableRow: {
      root: {
       '& .idLink': {
          color: COLORS.PRIMARY_MAIN
        },
        '&.Mui-selected,&.Mui-selected:hover,&:hover': {
          backgroundColor: COLORS.TABLE_ROW_SELECTED
        }
      },
      head: {
        backgroundColor: COLORS.SECONDARY_MAIN
      }
    },
    MuiTableSortLabel: {
      root: {
        '&:hover,&:focus': {
          color: "inherit"
        }
      }
    }
  }
});

export default theme;