import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from './ComingSoon.styles';

const ComingSoon = () => {
    const classes = useStyles();
    return (
        <Grid className={classes.root} container>
            <Grid className={classes.comingSoonMain}>
                <Typography variant="h3" component="h3">COMING SOON</Typography>
            </Grid>
        </Grid>
    );
}

export default ComingSoon;