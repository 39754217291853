import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../config/theme/baseTheme";
  
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    titleContainer: {
      '& .headingContainer': {
        flexDirection: "column",
        '& .titleHeading': {
          fontSize: 18,
          fontWeight: 500,
          '& .content': {
            fontWeight: 600
          }
        },
        '& .titleContent': {
          fontSize: 12,
          fontWeight: 600,
          '&.link': {
            cursor: "pointer"
          }
        }
      },
      '& .tagContainer': {
        marginLeft: 8,
        '& .tag': {
          color: COLORS.WHITE,
          borderRadius: 100,
          padding: theme.spacing(0.5, 1),
          backgroundColor: COLORS.BLACK,
          fontWeight: 700,
          textTransform: "uppercase",
          fontSize: 12,
          '&.yellow': {
            backgroundColor: COLORS.GOLDEN_YELLOW
          },
          '&.blue': {
            backgroundColor: COLORS.AZURE_BLUE
          },
          '&.green': {
            backgroundColor: COLORS.PERSIAN_GREEN
          },
          '&.red': {
            backgroundColor: COLORS.DARK_RED
          },
          '&.black': {
            backgroundColor: COLORS.BLACK
          }
        }
      }
    },
    contentContainer: {
      flexDirection: "column",
      flexWrap: "nowrap",
      '& .customerEditDetails':{
        marginBottom: theme.spacing(2),
      },
      '& .sellerEditDetails':{

      },
      '& .MuiAccordionDetails-root': {
        //height: 40
        '& .content': {
          width: "100%",
          '& .itemsContainer': {
            flexWrap: "nowrap",
            '& .customError':{
              textAlign: "center",
              paddingTop: 16,
              color: COLORS.DARK_RED
            },
            '& .itemDivider': {
              backgroundColor: "#E6E6E6",
              margin: "0"
            },
            '& .item': {
              '& .itemContainer': {
                //padding: theme.spacing(2,2),
                '& p': {
                  fontSize: 14,
                  lineHeight: "17px",
                  fontWeight: 600
                },
                '&.header p': {
                  color: COLORS.DOVE_GRAY,
                  fontWeight: 400,
                  '&.Mui-error':{
                    color: COLORS.DARK_RED,
                  },
                  '&.title': {
                    color: COLORS.BLACK,
                    fontWeight: 600
                  },
                },
                '& .itemColumn': {
                  padding: theme.spacing(1, 0, 1, 0),
                  '& .itemLabel':{
                    paddingBottom: 4
                  },
                  '& .itemInput': {
                    '& .Mui-disabled':{
                      backgroundColor: theme.palette.secondary.main
                    }
                    
                  },
                  
                }
              }
            }
          }
        }
      }
    },
    accordionSummary: {
      '& .header': {
        fontSize: 14,
        lineHeight: "17px",
        fontWeight: 600
      },
      '& .content': {
        flexGrow: 1
      }
    },
    buttons: {
      width: "100%",
      '& .buttonsContainer': {
        '& .userBtn': {
          '&.secondary': {
            marginRight: theme.spacing(2)
          },
          flexGrow: 1
        }
      }
    },
    dialogPaperPropsRoot: {
      margin: 0,
      marginLeft: "auto",
      maxHeight: "none",
      height: "100vh",
      width: 580
    }
  }),
);