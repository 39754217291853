import React, { forwardRef, ReactElement, Ref, useCallback, useEffect, useState } from 'react';
import CustomDialog from '../common/Dialog';
import * as yup from 'yup';
import { useStyles } from './UserAddOrEditDetails.styles';
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import { Button, Grid, Typography } from '@material-ui/core';
import AppConstants from '../constants/index';
import { useFormik } from 'formik';
import TextBox from '../common/TextBox';
import SelectBox from '../common/SelectBox';
import { useSelector } from 'react-redux';
import { AppState } from '../config/redux/reducers';

interface UserAddOrEditDetailsProps {
  open: boolean;
  user: any;
  isEdit: boolean;
  closePopup: () => void;
  handleDialogAction?: (params: any) => void;
}

const inputValidationSchema = yup.object({
  firstName: yup
    .string()
    .required(AppConstants.USERS_CONSTANTS.VALIDATIONS.FIRSTNAME.REQUIRED.msg),
  lastName: yup
    .string()
    .required(AppConstants.USERS_CONSTANTS.VALIDATIONS.LASTNAME.REQUIRED.msg),
  email: yup
    .string()
    .required(AppConstants.VALIDATIONS.EMAIL.REQUIRED.msg)
    .email(AppConstants.VALIDATIONS.EMAIL.VALID_EMAIL.msg),
  password: yup
    .string()
    .matches(new RegExp(AppConstants.VALIDATIONS.PASSWORD.STRONG_PWD.value), AppConstants.VALIDATIONS.PASSWORD.STRONG_PWD.msg)
    .required(AppConstants.VALIDATIONS.PASSWORD.REQUIRED.msg),
  confirmPassword: yup
    .string()
    .matches(new RegExp(AppConstants.VALIDATIONS.PASSWORD.STRONG_PWD.value), AppConstants.VALIDATIONS.PASSWORD.STRONG_PWD.msg)
    .required(AppConstants.VALIDATIONS.PASSWORD.REQUIRED.msg)
    .oneOf([yup.ref('password')], AppConstants.VALIDATIONS.PASSWORD.ONE_OF.msg),
  phone: yup
    .string()
    .required(AppConstants.USERS_CONSTANTS.VALIDATIONS.PHONE.REQUIRED.msg)
    .matches(new RegExp(AppConstants.USERS_CONSTANTS.VALIDATIONS.PHONE.VALIDITY.value), AppConstants.USERS_CONSTANTS.VALIDATIONS.PHONE.VALIDITY.msg),
  status: yup
    .string()
    .required(AppConstants.USERS_CONSTANTS.VALIDATIONS.STATUS.REQUIRED.msg)
    .matches(new RegExp(AppConstants.USERS_CONSTANTS.VALIDATIONS.STATUS.REQUIRED.value), AppConstants.USERS_CONSTANTS.VALIDATIONS.STATUS.REQUIRED.msg),
  userRole: yup
    .string()
    .required(AppConstants.USERS_CONSTANTS.VALIDATIONS.ROLE.REQUIRED.msg)
    .matches(new RegExp(AppConstants.USERS_CONSTANTS.VALIDATIONS.ROLE.REQUIRED.value), AppConstants.USERS_CONSTANTS.VALIDATIONS.ROLE.REQUIRED.msg),
  userType: yup
    .string()
    .required(AppConstants.USERS_CONSTANTS.VALIDATIONS.TYPE.REQUIRED.msg)
    .matches(new RegExp(AppConstants.USERS_CONSTANTS.VALIDATIONS.TYPE.REQUIRED.value), AppConstants.USERS_CONSTANTS.VALIDATIONS.TYPE.REQUIRED.msg),
  country: yup
    .string()
    .required(AppConstants.USERS_CONSTANTS.VALIDATIONS.COUNTRY.REQUIRED.msg)
    .matches(new RegExp(AppConstants.USERS_CONSTANTS.VALIDATIONS.COUNTRY.REQUIRED.value), AppConstants.USERS_CONSTANTS.VALIDATIONS.COUNTRY.REQUIRED.msg),
  hub: yup
    .string()
    .required(AppConstants.USERS_CONSTANTS.VALIDATIONS.HUB.REQUIRED.msg)
    .matches(new RegExp(AppConstants.USERS_CONSTANTS.VALIDATIONS.HUB.REQUIRED.value), AppConstants.USERS_CONSTANTS.VALIDATIONS.HUB.REQUIRED.msg)
});

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const UserAddOrEditDetails = (props: UserAddOrEditDetailsProps) => {

  const classes = useStyles();
  const { open, user, isEdit, closePopup, handleDialogAction } = props;
  const [isPasswordEntered, setIsPasswordEntered] = useState(false);

  const { addUserDropdowns } = useSelector(
    (state: AppState) => state.users
  );

  const { countryCode } = useSelector(
    (state: AppState) => state.common
  );

  const [resetFormFlag, setResetFormFlag] = useState(false);

  let tableRecord: any = {};
  let hubDetails: any = {};
  let checkCountry: any = {};
  let statusList: any = {};
  let selectedRole: any = "";
  let countryCodeValue = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode
  if (user) {
    tableRecord = user.tableRecord;
    if (user.countryDetails && user.countryDetails[countryCodeValue]) {
      hubDetails = user.countryDetails[countryCodeValue].filter((hub: any) => hub.hubName === tableRecord.hubName);
      checkCountry = user.countryDetails[countryCodeValue].filter((country: any) => country.countryName === tableRecord.country);
    }
    if (tableRecord && tableRecord.role) {
      const currentRoleIndex = AppConstants.USERS_CONSTANTS.USER_ROLES.findIndex(role => role.value === tableRecord.role);
      const accessibleRoles = AppConstants.USERS_CONSTANTS.USER_ROLES.slice(currentRoleIndex);
      selectedRole = accessibleRoles.length ? accessibleRoles[0].code : "n/a";
    }

  }

  //show only ACTIVE & INACTIVE in add & edit user form
  if(addUserDropdowns.statusList){
    statusList = addUserDropdowns.statusList.filter((status: any) => status.name === "ACTIVE"  || status.name === "INACTIVE");
  }

  const dialogPaperProps = {
    classes: {
      root: classes.dialogPaperPropsRoot
    },
    square: true
  }

  const getInitialValues = () => {
    return isEdit ? {
      firstName: user.firstname ? user.firstname : '',
      lastName: user.lastname ? user.lastname : '',
      email: user.email ? user.email : '',
      userName: user.username ? user.username : '',
      phone: user.phone ? user.phone : '',
      password: '',
      confirmPassword: '',
      status: user.status ? user.status : AppConstants.SELECT_NONE.value,
      userRole: selectedRole ? selectedRole : AppConstants.SELECT_NONE.value,
      userType: user.userType ? user.userType : AppConstants.SELECT_NONE.value,
      country: checkCountry.length ? countryCodeValue : (user.tableRecord.country  ? user.tableRecord.country :  AppConstants.SELECT_NONE.value),
      hub: hubDetails.length ? hubDetails[0].hubCode : (user.tableRecord.hubName  ? user.tableRecord.hubName :  AppConstants.SELECT_NONE.value)
    } : {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
        status: AppConstants.SELECT_NONE.value,
        userRole: AppConstants.SELECT_NONE.value,
        userType: AppConstants.SELECT_NONE.value,
        country: AppConstants.SELECT_NONE.value,
        hub: AppConstants.SELECT_NONE.value
      }
  }

  const handleKeyUpChange = (event: any) => {
      if(event.target.value){
        setIsPasswordEntered(true)
      }else{
        setIsPasswordEntered(false)
      }
  }

  const inputFormik = useFormik({
    initialValues: getInitialValues(),
    validationSchema: isEdit ? (isPasswordEntered ? inputValidationSchema : inputValidationSchema.omit(['password', 'confirmPassword'])) : inputValidationSchema,
    validate: () => {
      setResetFormFlag(false);
    },
    onSubmit: (values) => {
      setIsPasswordEntered(false);
      dialogActionHandler('submit', values);
    }
  });

  

  const getTitleContent = () => {
    return (
      <Grid className={classes.titleContainer} container>
        <Grid item>
          <Grid className="headingContainer" container>
            <Grid item>
              <Grid container>
                <Grid item>
                  <Typography className="titleHeading">
                    <span className="label">{isEdit ? 'Edit User' : 'Add User'}</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid >
    )
  }

  const getDetailsContent = () => {
    return (
      <form onSubmit={inputFormik.handleSubmit}>
        <Grid className={classes.contentContainer} container>
          <Grid className="contentItem" item>
            <Grid className="itemContainer" container>
              <Grid className="itemLabel" item>
                <Typography>First Name</Typography>
              </Grid>
              <Grid className="itemInput" item>
                <TextBox
                  fullWidth
                  variant="outlined"
                  handleChange={inputFormik.handleChange}
                  value={inputFormik.values.firstName}
                  error={inputFormik.touched.firstName && Boolean(inputFormik.errors.firstName)}
                  helperText={inputFormik.touched.firstName && inputFormik.errors.firstName}
                  textBoxId="AddUserFirstnameTextbox"
                  name="firstName"
                  type="text"
                  placeholderText="First Name"
                ></TextBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="contentItem" item>
            <Grid className="itemContainer" container>
              <Grid className="itemLabel" item>
                <Typography>Last Name</Typography>
              </Grid>
              <Grid className="itemInput" item>
                <TextBox
                  fullWidth
                  variant="outlined"
                  handleChange={inputFormik.handleChange}
                  value={inputFormik.values.lastName}
                  error={inputFormik.touched.lastName && Boolean(inputFormik.errors.lastName)}
                  helperText={inputFormik.touched.lastName && inputFormik.errors.lastName}
                  textBoxId="AddUserLastnameTextbox"
                  name="lastName"
                  type="text"
                  placeholderText="Last Name"
                ></TextBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="contentItem" item>
            <Grid className="itemContainer" container>
              <Grid className="itemLabel" item>
                <Typography>Email Id</Typography>
              </Grid>
              <Grid className="itemInput" item>
                <TextBox
                  fullWidth
                  variant="outlined"
                  handleChange={inputFormik.handleChange}
                  value={inputFormik.values.email}
                  error={inputFormik.touched.email && Boolean(inputFormik.errors.email)}
                  helperText={inputFormik.touched.email && inputFormik.errors.email}
                  disabled={isEdit}
                  textBoxId="AddUserEmailTextbox"
                  name="email"
                  type="text"
                  placeholderText="Email Id"
                ></TextBox>
              </Grid>
            </Grid>
          </Grid>
          {
            <Grid className="contentItem" item>
              <Grid className="itemContainer" container>
                <Grid className="itemLabel" item>
                  <Typography>Password</Typography>
                </Grid>
                <Grid className="itemInput" item>
                  <TextBox
                    fullWidth
                    variant="outlined"
                    handleKeyUpChange={handleKeyUpChange}
                    handleChange={inputFormik.handleChange}
                    value={inputFormik.values.password}
                    error={inputFormik.touched.password && Boolean(inputFormik.errors.password)}
                    helperText={inputFormik.touched.password && inputFormik.errors.password}
                    textBoxId="AddUserPasswordTextbox"
                    name="password"
                    type="password"
                    placeholderText="Password"
                  ></TextBox>
                </Grid>
              </Grid>
            </Grid>
          }
          {
            <Grid className="contentItem" item>
              <Grid className="itemContainer" container>
                <Grid className="itemLabel" item>
                  <Typography>Confirm Password</Typography>
                </Grid>
                <Grid className="itemInput" item>
                  <TextBox
                    fullWidth
                    variant="outlined"
                    handleChange={inputFormik.handleChange}
                    value={inputFormik.values.confirmPassword}
                    error={inputFormik.touched.confirmPassword && Boolean(inputFormik.errors.confirmPassword)}
                    helperText={inputFormik.touched.confirmPassword && inputFormik.errors.confirmPassword}
                    textBoxId="AddUserConfirmPasswordTextbox"
                    name="confirmPassword"
                    type="password"
                    placeholderText="Confirm Password"
                  ></TextBox>
                </Grid>
              </Grid>
            </Grid>
          }
          <Grid className="contentItem" item>
            <Grid className="itemContainer" container>
              <Grid className="itemLabel" item>
                <Typography>Phone No.</Typography>
              </Grid>
              <Grid className="itemInput" item>
                <TextBox
                  fullWidth
                  variant="outlined"
                  handleChange={inputFormik.handleChange}
                  value={inputFormik.values.phone}
                  error={inputFormik.touched.phone && Boolean(inputFormik.errors.phone)}
                  helperText={inputFormik.touched.phone && inputFormik.errors.phone}
                  textBoxId="AddUserPhoneTextbox"
                  name="phone"
                  type="text"
                  placeholderText="Phone No."
                ></TextBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="contentItem" item>
            <Grid className="itemContainer" container>
              <Grid className="itemLabel" item>
                <Typography>Status</Typography>
              </Grid>
              <Grid className="itemInput" item>
                <SelectBox
                  fullWidth
                  handleFormikChange={inputFormik.handleChange}
                  value={inputFormik.values.status}
                  error={inputFormik.touched.status && Boolean(inputFormik.errors.status)}
                  helperText={inputFormik.touched.status && inputFormik.errors.status}
                  reset={resetFormFlag}
                  initialValues={{name:"Select Status", value:AppConstants.SELECT_NONE.value}}
                  id="AddUserStatusSelectBox"
                  inputProps={{
                    name: "status",
                    id: "AddUserStatusSelectBox"
                  }}
                  items={statusList}
                ></SelectBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="contentItem" item>
            <Grid className="itemContainer" container>
              <Grid className="itemLabel" item>
                <Typography>Role</Typography>
              </Grid>
              <Grid className="itemInput" item>
                <SelectBox
                  fullWidth
                  handleFormikChange={inputFormik.handleChange}
                  value={inputFormik.values.userRole}
                  error={inputFormik.touched.userRole && Boolean(inputFormik.errors.userRole)}
                  helperText={inputFormik.touched.userRole && inputFormik.errors.userRole}
                  reset={resetFormFlag}
                  initialValues={{name:"Select Role", value:AppConstants.SELECT_NONE.value}}
                  id="AddUserRoleSelectBox"
                  inputProps={{
                    name: "userRole",
                    id: "AddUserRoleSelectBox"
                  }}
                  items={addUserDropdowns.rolesList}
                ></SelectBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="contentItem" item>
            <Grid className="itemContainer" container>
              <Grid className="itemLabel" item>
                <Typography>Country</Typography>
              </Grid>
              <Grid className="itemInput" item>
                <SelectBox
                  fullWidth
                  handleFormikChange={inputFormik.handleChange}
                  value={inputFormik.values.country}
                  error={inputFormik.touched.country && Boolean(inputFormik.errors.country)}
                  helperText={inputFormik.touched.country && inputFormik.errors.country}
                  reset={resetFormFlag}
                  initialValues={{name:"Select Country", value:AppConstants.SELECT_NONE.value}}
                  id="AddUserCountrySelectBox"
                  inputProps={{
                    name: "country",
                    id: "AddUserCountrySelectBox"
                  }}
                  items={addUserDropdowns.countriesList[inputFormik.values.userRole] || []}
                ></SelectBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="contentItem" item>
            <Grid className="itemContainer" container>
              <Grid className="itemLabel" item>
                <Typography>Hub</Typography>
              </Grid>
              <Grid className="itemInput" item>
                <SelectBox
                  fullWidth
                  handleFormikChange={inputFormik.handleChange}
                  value={inputFormik.values.hub}
                  error={inputFormik.touched.hub && Boolean(inputFormik.errors.hub)}
                  helperText={inputFormik.touched.hub && inputFormik.errors.hub}
                  reset={resetFormFlag}
                  initialValues={{name:"Select Hub", value:AppConstants.SELECT_NONE.value}}
                  id="AddUserHubSelectBox"
                  inputProps={{
                    name: "hub",
                    id: "AddUserHubSelectBox"
                  }}
                  items={addUserDropdowns.hubList[inputFormik.values.country] || []}
                ></SelectBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="contentItem" item>
            <Grid className="itemContainer" container>
              <Grid className="itemLabel" item>
                <Typography>Type</Typography>
              </Grid>
              <Grid className="itemInput" item>
                <SelectBox
                  fullWidth
                  handleFormikChange={inputFormik.handleChange}
                  value={inputFormik.values.userType}
                  error={inputFormik.touched.userType && Boolean(inputFormik.errors.userType)}
                  helperText={inputFormik.touched.userType && inputFormik.errors.userType}
                  reset={resetFormFlag}
                  initialValues={{name:"Select Type", value:AppConstants.SELECT_NONE.value}}
                  id="AddUserTypeSelectBox"
                  inputProps={{
                    name: "userType",
                    id: "AddUserTypeSelectBox"
                  }}
                  items={addUserDropdowns.userTypeList}
                ></SelectBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    )
  }

  const handleDialogClose = useCallback(
    () => {
      setIsPasswordEntered(false);
      closePopup();
    },
    [closePopup],
  );

  const dialogActionHandler = useCallback(
    (type: string, values?: any) => {
      setIsPasswordEntered(false);
      handleDialogAction && handleDialogAction({
        type: type,
        values: values,
        isEdit: isEdit
      });
    },
    [handleDialogAction, isEdit],
  )

  const getDialogActions = () => {
    return (
      <form className={classes.buttons} onSubmit={inputFormik.handleSubmit}>
        <Grid className="buttonsContainer" container>
          <Button className="userBtn secondary" variant="contained" onClick={() => { dialogActionHandler('cancel') }}>{AppConstants.BUTTONS.CANCEL}</Button>
          <Button className="userBtn primary" variant="contained" type="submit">{isEdit ? AppConstants.BUTTONS.UPDATE : AppConstants.BUTTONS.ADD_USER}</Button>
        </Grid>
      </form>
    );
  };

  useEffect(() => {
    if (open) {
      inputFormik.resetForm();
      setResetFormFlag(true);
      if (isEdit) {
        inputFormik.setValues(getInitialValues());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <CustomDialog
      open={open}
      TransitionComponent={Transition}
      PaperProps={dialogPaperProps}
      title={getTitleContent()}
      content={getDetailsContent()}
      actions={getDialogActions()}
      handleClose={handleDialogClose}
    ></CustomDialog>
  )
}

export default UserAddOrEditDetails;