import React, { forwardRef, ReactElement, Ref, useCallback } from 'react';
import CustomDialog from '../common/Dialog';
import mobileLogoIcon from './../assets/icons/mobileLogo.svg';
import editIcon from './../assets/icons/edit-icon.svg';
import emailLogoIcon from './../assets/icons/emailLogo.svg';
import { useStyles } from './UserDetails.styles';
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import { Button, Grid, Typography } from '@material-ui/core';
import AppConstants from '../constants/index';
import { checkUserRoleAccess, getStatusField } from '../mocks/users/response.transforms';

interface UserDetailsProps {
  open: boolean;
  user: any;
  closePopup: () => void;
  handleDialogAction?: (params: any) => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const UserDetails = (props: UserDetailsProps) => {

  const classes = useStyles();
  const { open, user, closePopup, handleDialogAction } = props;

  let tableRecord: any = {};
  if (user) {
    tableRecord = user.tableRecord;
  }
  const dialogPaperProps = {
    classes: {
      root: classes.dialogPaperPropsRoot
    },
    square: true
  }

  const dialogActionHandler = useCallback(
    (type: string, status?: string) => {
      let params = {
        values: {
          status: status || '',
          userName: user.username,
          userRole: tableRecord.roleKey
        },
        type: type,
        isStatusUpdate: user.status === 'AWAITING_APPROVAL'
      }
      handleDialogAction && handleDialogAction(params);
    },
    [handleDialogAction, user, tableRecord],
  )

  const handleDialogClose = () => {
    closePopup();
  }

  const fetchUserRoleAccess = () => {
    let canAccess = false;
    const currentRole = sessionStorage.getItem('role') ? JSON.parse(sessionStorage.getItem('role') || "[]")[0] : undefined;
    const selectedRole = tableRecord ? AppConstants.USERS_CONSTANTS.USER_ROLES.filter(role => role.value === tableRecord.role)[0] : undefined;
    if (currentRole && selectedRole) {
      canAccess = checkUserRoleAccess(currentRole, selectedRole);
    }
    if(user && user.status === "AWAITING_EMAIL_VERIFICATION"){
      canAccess = false;
    }
    return canAccess;
  }

  const showEditIcon = () => {
    let canShow = false;
    if(user &&  user.status === "AWAITING_APPROVAL"){
      canShow = false;
    }else{
      canShow = true;
    }
    return canShow;
  }

  const showBottomButtons = () => {
    let canShowButtons = false;
    if(user && user.status === "AWAITING_APPROVAL"){
      canShowButtons = true;
    }
    return canShowButtons;
  }

  const getTitleContent = () => {
    return (
      <Grid className={classes.titleContainer} container>
        <Grid item>
          <Grid className="headingContainer" container>
            <Grid item>
              <Grid container>
                <Grid item>
                  <Typography className="titleHeading">
                    <span className="label">{`${user.firstname} ${user.lastname}`}</span>
                  </Typography>
                </Grid>
                {
                  fetchUserRoleAccess() &&  showEditIcon() &&
                  <Grid className="tagContainer" onClick={() => dialogActionHandler('update')} item>
                    <img className="logo" alt="Edit" src={editIcon} />
                  </Grid>
                }
              </Grid>
            </Grid>
            {
              user.status &&
              <Grid item>
                <Grid className="statusContainer" container alignItems="center">
                  <Grid item className={`statusIcon ${getStatusField('color', user.status)}`}></Grid>
                  <Grid item className="statusMsg">
                    <Typography className="status">{getStatusField('name', user.status)}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            }
          </Grid>
        </Grid>
      </Grid >
    )
  }

  const getDetailsContent = () => {
    return (
      <Grid className={classes.contentContainer} container>
        <Grid className="userDetails" item>
          <Grid className="detailsHeader" container>
            <Grid className="detailsHeaderItem" item>
              <Typography>User Details</Typography>
            </Grid>
            <Grid className="detailsHeaderItem" item>
              <Typography className="name">{`${user.firstname} ${user.lastname}`}</Typography>
            </Grid>
            <Grid className="detailsHeaderItem" item>
              <Grid container alignItems="center">
                <Grid className="contactMobile" item>
                  <Grid container alignItems="center">
                    <img className="logo" alt="Mobile" src={mobileLogoIcon} />
                    <span className="contact mobile">{user.phone ? <a href={`tel:${user.phone}`}>{user.phone}</a> : ""}</span>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container alignItems="center">
                    <img className="logo" alt="Email" src={emailLogoIcon} />
                    <span className="contact mobile">{user.email ? <a href={`mailto:${user.email}`}>{user.email}</a> : ""}</span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid className="detailsContent" container>
            <Grid className="contentRow" item>
              <Grid className="contentRowContainer" container>
                <Grid className="contentColumn" item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className="label">Role</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className="content">{tableRecord && tableRecord.role}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className="contentColumn" item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className="label">Country</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className="content">{tableRecord && tableRecord.country}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className="contentColumn" item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className="label">Hub</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className="content">{tableRecord && tableRecord.hubName}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="contentRow" item>
              <Grid className="contentRowContainer" container>
                <Grid className="contentColumn" item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className="label">Type</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className="content">{tableRecord && tableRecord.type}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const getDialogActions = () => {
    return (
      <>
        {
          fetchUserRoleAccess() && showBottomButtons() &&
          <Grid className={classes.buttons} container>
            <Button className="userBtn secondary" variant="contained" onClick={() => { dialogActionHandler('cancel', 'REJECTED') }}>{user.status === 'AWAITING_APPROVAL' ? AppConstants.BUTTONS.REJECT : AppConstants.BUTTONS.CANCEL}</Button>
            <Button className="userBtn primary" variant="contained" onClick={() => { dialogActionHandler('update', 'ACTIVE') }}>{user.status === 'AWAITING_APPROVAL' ? AppConstants.BUTTONS.APPROVE : AppConstants.BUTTONS.UPDATE}</Button>
          </Grid>
        }
      </>
    );
  };

  return (
    <CustomDialog
      open={open}
      TransitionComponent={Transition}
      PaperProps={dialogPaperProps}
      title={getTitleContent()}
      content={getDetailsContent()}
      actions={getDialogActions()}
      handleClose={handleDialogClose}
    ></CustomDialog>
  )
}

export default UserDetails;