import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const customHeaderUseStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        padding: theme.spacing(1, 0)
      },
      exceptionsRoot: {
        padding: theme.spacing(1, 1)
      },
      total: {
        alignSelf: "flex-end",
        '& span': {
          verticalAlign: "middle",
          fontSize: 14,
          fontWeight: 400,
          '&.separator': {
            padding: theme.spacing(0, 0.75)
          },
          '&.count': {
            fontWeight: 600,
            fontSize: 20,
          }
        }

      },
      buttonContainer: {
        marginLeft: "auto",
        "& .subMenuPoper":{
          top: "35px !important",
          left: "-206px !important",
          width: "177px",
          '& ul':{
            maxHeight: 300,
            overflowY: 'scroll',
            '& li':{
              fontSize: 14,
              paddingLeft: 10,
            },
            '& li:hover': {
              backgroundColor: "#E7EFF6"
            }
          }
        },
        "& .menuPoper":{
          top: "-5px !important",
          left: "-28px !important",
          width: "177px",
          '& li':{
            fontSize: 14,
          },
          '& li:hover': {
            backgroundColor: "#E7EFF6"
          }
        },
        '& .selectedView':{
          paddingTop: 10,
          color: "#252525",
        }
      },
      button: {
        margin: theme.spacing(0.5),
        '&:last-child': {
          marginRight: theme.spacing(0)
        }
      },
      savedView:{
        marginRight: 50
      },
      activeView: {
        backgroundColor: "#E7EFF6"
      },
      isActiveViewDiv:{
        width: "15%"
      },
      viewNameOnly:{
        width: "15%"
      },
      viewName:{
        width: "85%",
        whiteSpace: "normal"
      },
      isActiveView:{
        marginRight: 10
      },
      colSettings: {
        float: "right",
        marginTop: -3
      },
      customTooltip:{
        backgroundColor: "#000",
        padding: 6
      },
      customArrow:{
        color: "#000"
      },
      
    })
);