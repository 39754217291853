const ConsignmentConstants = {
  CONSIGNMENT_CONSTANTS: {
    TABLE_WRAPPER_DATA: {
      title: "Consignments",
      HeaderData: [
        {
          type: "string",
          field: 'hubName',
          headerName: 'Hub',
          width: 100,
          hide: false
        },
        {
          type: "string",
          field: 'hubCode',
          headerName: 'Hub Code',
          width: 150,
          hide: false
        },
        {
          type: "number",
          field: 'consignmentId',
          headerName: 'Consignment ID',
          width: 150,
          hide: false
        },
        {
          type: "number",
          field: 'trackingId',
          headerName: 'Tracking ID',
          width: 150,
          hide: false
        },
        {
          type: "date",
          field: 'deliveryDate',
          headerName: 'Delivery Date',
          width: 150,
          hide: false
        },
        {
          type: "string",
          field: 'startEndTime',
          headerName: 'Delivery Slot',
          width: 130,
          hide: false
        },
        {
          type: "date",
          field: 'carrierDeliveryDate',
          headerName: 'Carrier Delivery Date',
          width: 150,
          hide: false
        },
        {
          type: "string",
          field: 'carrierDeliverySlot',
          headerName: 'Carrier Delivery Slot',
          width: 150,
          hide: false
        },
        {
          type: "string",
          field: 'displayStatus',
          headerName: 'Delivery Status',
          width: 150,
          hide: false
        },
        {
          type: "string",
          field: 'deliveryTime',
          headerName: 'Status Update Time',
          width: 170,
          hide: false
        },
        {
          type: "number",
          field: 'orderId',
          headerName: 'Order ID',
          width: 120,
          hide: false
        },
        {
          type: "string",
          field: 'customerName',
          headerName: 'Customer Name',
          width: 150,
          hide: false
        },
        {
          type: "number",
          field: 'deliveryZone',
          headerName: 'Delivery Zone',
          width: 150,
          hide: false
        },
        {
          type: "string",
          field: 'deliveryArea',
          headerName: 'Delivery Area',
          width: 150,
          hide: false
        },
        {
          type: "string",
          field: 'deliveryCity',
          headerName: 'Delivery City',
          width: 150,
          hide: false
        },
        {
          type: "string",
          field: 'deliveryStreetAddress',
          headerName: 'Delivery Street Address',
          width: 200,
          hide: false
        },
        {
          type: "number",
          field: 'customerMobileNumber',
          headerName: 'Customer Mobile Number',
          width: 210,
          hide: false
        },
        {
          type: "number",
          field: 'customerEmail',
          headerName: 'Customer Email',
          width: 210,
          hide: false
        },
        {
          type: "date",
          field: 'transactionDate',
          headerName: 'Transaction Date',
          width: 150,
          hide: false
        },
        {
          type: "string",
          field: 'paymentType',
          headerName: 'Payment Type',
          width: 140,
          hide: false
        },
        {
          type: "number",
          field: 'totalAmount',
          headerName: 'Total Amount',
          width: 140,
          hide: false
        },
        {
          type: "number",
          field: 'codAmount',
          headerName: 'COD Amount',
          width: 130,
          hide: false
        },
        {
          type: "number",
          field: 'totalWeight',
          headerName: 'Total Weight (In KG)',
          width: 150,
          hide: false
        },
        {
          type: "string",
          field: 'carrier',
          headerName: 'Carrier',
          width: 100,
          hide: false
        },
        {
          type: "string",
          field: 'vehicleType',
          headerName: 'Vehicle Type',
          width: 130,
          hide: false
        },
        {
          type: "string",
          field: 'deliveryProposition',
          headerName: 'Delivery Proposition',
          width: 180,
          hide: false
        },
        {
          type: "string",
          field: 'sellerName',
          headerName: 'Seller Name',
          width: 130,
          hide: false
        },
        {
          type: "string",
          field: 'sellerLat',
          headerName: 'Seller Latitude',
          width: 150,
          hide: false
        },
        {
          type: "string",
          field: 'sellerLong',
          headerName: 'Seller Longitude',
          width: 150,
          hide: false
        },
        {
          type: "string",
          field: 'omsStatus',
          headerName: 'OMS Status',
          width: 120,
          hide: false
        },
        {
          type: "string",
          field: 'statusErrors',
          headerName: 'Errors',
          width: 150,
          hide: false
        },
        {
          type: "string",
          field: 'deliveryStatus',
          headerName: 'Carrier Status',
          width: 150,
          hide: false
        },
        {
          type: "string",
          field: 'pickupRefID',
          headerName: 'Pickup RefID',
          width: 150,
          hide: false
        },
        {
          type: "string",
          field: 'pickupGUID',
          headerName: 'Pickup GUID',
          width: 150,
          hide: false
        },
        
        {
          type: "date",
          field: 'pickUpDate',
          headerName: 'OMS Pickup Date',
          width: 150,
          hide: false
        },
        {
          type: "string",
          field: 'pickUpSlot',
          headerName: 'OMS Pickup Slot',
          width: 140,
          hide: false
        },
        {
          type: "date",
          field: 'carrierPickUpDate',
          headerName: 'Expected Pickup Date',
          width: 160,
          hide: false
        },
        {
          type: "string",
          field: 'carrierPickUpSlot',
          headerName: 'Expected Pickup Slot',
          width: 160,
          hide: false
        },
        {
          type: "string",
          field: 'driverName',
          headerName: 'Driver Name',
          width: 150,
          hide: false
        },
        {
          type: "string",
          field: 'driverPhone',
          headerName: 'Driver Phone',
          width: 150,
          hide: false
        },
        {
          type: "string",
          field: 'customerLat',
          headerName: 'Customer Latitude',
          width: 150,
          hide: false
        },
        {
          type: "string",
          field: 'customerLng',
          headerName: 'Customer Longitude',
          width: 150,
          hide: false
        },
        {
          type: "string",
          field: 'driverLat',
          headerName: 'Executed Latitude',
          width: 150,
          hide: false
        },
        {
          type: "string",
          field: 'driverLng',
          headerName: 'Executed Longitude',
          width: 150,
          hide: false
        }
      ],
      FilterFormData: [
        {
          type: "selectBox",
          fieldName: "hub",
          multiple: true,
          key: `selectBox-hub-${Math.floor(Math.random() * 1000)}`,
          label: "Hub",
          items: []
        },
        {
          type: "selectBox",
          fieldName: "carriers",
          multiple: true,
          key: `selectBox-carriers-${Math.floor(Math.random() * 1000)}`,
          label: "Carrier",
          items: []
        },
        {
          type: "dateRange",
          fieldName: "dateRange",
          key: `dateRange-filter-${Math.floor(Math.random() * 1000)}`,
          placeholderText: 'Delivery Date',
          label: 'Delivery Date',
          autoUpdateInput: false,
          autoApply: true,
          showDropdowns: true,
          linkedCalendars: true,
          maxSpan: {
            day: "6"
          },
          locale: {}
        },
        {
          type: "selectBox",
          fieldName: "proposition",
          multiple: true,
          key: `selectBox-proposition-${Math.floor(Math.random() * 1000)}`,
          label: "Delivery Proposition",
          items: []
        },
        {
          type: "selectBox",
          fieldName: "deliveryStatus",
          multiple: true,
          key: `selectBox-delStatus-${Math.floor(Math.random() * 1000)}`,
          label: "Delivery Status",
          items: []
        },
      ],
      headerButtons: [
        {
          name: "print",
          displayName: "PRINT SHIPPING LABEL",
          disabled: false
        },
        {
          name: "excel",
          displayName: "EXPORT TO EXCEL",
          disabled: false
        },
        {
          name: "colsettings",
          displayName: "Column Settings",
          disabled: false
        }
      ],
      loadMore: {
        name: 'loadMore',
        disabled: false,
        pageSize: 20
      },
      defaultFiltersObj: {
        hub: ["all"],
        carriers: ["all"],
        deliveryStatus: ["all"],
        proposition: ["all"],
        page: 0,
        dateRange: {
          startDate: new Date(),
          endDate: new Date()
        }
      },
      SEARCH_TYPES: [
        {
          name: "Consignment Id",
          value: "consignment"
        },
        {
          name: "Tracking Id",
          value: "tracking"
        },
        {
          name: "Order Id",
          value: "order"
        },
        {
          name: "Phone",
          value: "phone"
        }
      ],
      sortModel: {
        field: "deliveryDate",
        direction: "asc"
      }
    },
    DISABLE_EXPORT_EXCEL: 100000,
    DISABLE_EXPORT_EXCEL_MSG: "Exporting more than 100000 (1 Lac) records is restricted",
    REPUSH_STATUSES:["PickUp Creation Failed", "Creation Failed", "Updation Failed", "UPDATION_FAILED"],
    FAILED_STATUSES:["PICKUP_CREATION_FAILED", "CREATION_FAILED", "UPDATION_FAILED"],
    REPUSH_ACESSS_USERS:[
      "asaboor@mafcarrefour.com", 
      "rchaturvedi@mafcarrefour.com", 
      "sfarhad@mafcarrefour.com", 
      "mshaheer@mafcarrefour.com", 
      "mbhargava@mafcarrefour.com", 
      "adansari@mafcarrefour.com", 
      "sosingh@mafcarrefour.com", 
      "mbhogal@mafcarrefour.com", 
      "rbhatia@mafcarrefour.com",
      "kchakraborty@mafcarrefour.com",
      "rsingh@mafcarrefour.com",
      "sdixit@mafcarrefour.com"
    ],
    REASSIGN_CARRIER_MSG: "Consignment has been reassigned to carrier successfully",
    REASSIGN_CARRIER_NOT_FOUND_MSG: "Order not found to reasign carrier",
    ORDER_REPUSHED_MSG: "Order has been repushed successfully",
    OPEN_HATCH_SUCCESS_MSG: "Hatch is opened successfully",
    OPEN_HATCH_ERROR_MSG: "Hatch cannot be opened at this moment. Please try again after sometime.",
    REPUSHED_ORDER_CONTENT_NOT_FOUND: "Consignment content not found",
    OPEN_HATCH_STATUSES: ["rover ready", "at customer address", 'rover return'],
    REASSIGN_CARRIER_STATUSES: ["dispatched", "at customer address", "delivered"],
    STATUSES: {
      "new": {
        color: "yellow",
        hintText: "When request is received in CaMS from OMS"
      },
      "carrier_assigned": {
        color: "yellow",
        hintText: "Carrier identified for delivey based on carrier selection rules"
      },
      "export_success": {
        color: "yellow",
        hintText: "Request to 3PL/LM system successful"
      },
      "driver_assigned": {
        color: "yellow",
        hintText: "Driver assigned for Pickup"
      },
      "arrived": {
        color: "yellow",
        hintText: "3PL/ Driver arrived at the pickup location"
      },
      "picked_up": {
        color: "yellow",
        hintText: "3PL/ Driver collected the order for delivery. Can be used in Ready for Dispatch also"
      },
      "not_delivered_reattempt": {
        color: "yellow",
        hintText: "Order not delivered but needs to be reattempted/ rescheduled for delivery"
      },
      "carrier_reassigned": {
        color: "blue",
        hintText: "New carrier assigned within CaMS"
      },
      "driver_reassigned": {
        color: "blue",
        hintText: "Driver reassigned for Pickup"
      },
      "dispatched": {
        color: "blue",
        hintText: "Out for delivery"
      },
      "partially_delivered": {
        color: "green",
        hintText: "Not all items have been delivered to the customer"
      },
      "delivered": {
        color: "green",
        hintText: "All items of an order delivered to customer"
      },
      "export_failed": {
        color: "red",
        hintText: "Request to 3PL (Carrier system) failed"
      },
      "pickup_failed": {
        color: "red",
        hintText: "3PL/ Driver unable to collect the order due to Technical or operational issue"
      },
      "cancelled_lm": {
        color: "red",
        hintText: "Last mile has cancelled the delivery for tech/ops issues"
      },
      "cancelled_customer": {
        color: "red",
        hintText: "Delivery is cancelled by the customer and if the order has been dispatched then it needs to be returned"
      },
      "lost_in_transit_fwd": {
        color: "red",
        hintText: "All or some items lost by LM/ 3PL before delivery to customer"
      },
      "not_delivered_rto": {
        color: "red",
        hintText: "Order not delivered and needs to be returned to shipper"
      },
      "return_to_origin": {
        color: "red",
        hintText: "Return initiated due to non-delivery of the order"
      },
      "lost_in_transit_rto": {
        color: "red",
        hintText: "All or some items lost by LM/ 3PL after initiating RTO but before delivering back to the Shipper"
      },
      "delivered_rto": {
        color: "red",
        hintText: "RTO order successfully delivered back to the Shipper"
      }
    },
    CARRIER_FOR_COURIERATTRIBUTE:['jnt'],
    DISPLAY_NAME_JNT:'J&T Express',
    CONSIGNMENT_STATUSES: {
      ORDER_PLACED: "ORDER_PLACED",
      ORDER_BEING_PREPARED: "ORDER_BEING_PREPARED",
      ORDER_ON_THE_WAY: "ORDER_ON_THE_WAY",
      ORDER_DELIVERED: "ORDER_DELIVERED",
      UNABLE_TO_DELIVER: "UNABLE_TO_DELIVER",
      ORDER_CANCELLATION: "ORDER_CANCELLATION",
      ORDER_NOT_DELIVERED: "ORDER_NOT_DELIVERED",
    },
    VALIDATIONS: {
      CARRIER_NAME: {
        REQUIRED: {
          msg: "Carrier name is required",
          value: "\\b(?![n\\/a]\\b)\\w+"
        }
      },
      VEHICLE_TYPE: {
        REQUIRED: {
          msg: "Vehicle type is required",
          value: "\\b(?![n\\/a]\\b)\\w+"
        }
      },
    },
    EXCEL_FILE_NAME: "consignments.xlsx",
    RESPONSE_CONSTANTS: {
      ERROR_CODES: {
        SESSION_TIMEOUT: "UM100",
        UNAUTHORISED: "UM101",
      }
    },
    MORE_ERRORS: "...more errors",
    DISPATCHED: "dispatched",
    I_MILE: "imile",
    EDIT_CONSIGNMENT_DELIVERY: {
      DEFAULT_DELIVERY_EDIT_SELECTION: "UNDELIVERED",
      STATUS: 'Status',
      UPDATE_STATUS: "Update Status",
      REASON: "Reason",
      COMMENTS: "Comments",
      ENTER_COMMENTS: "Enter a comment",
      NO_LONGER_DELIVERED: "This consignment will no longer be delivered.",
      PLEASE_SELECT_REASON: 'Please select reason.',
      OTHER_REASON_CODE: 'DR3',
      PLEASE_PROVIDE_COMMENTS: 'Please provide comments',
      STATUS_UPDATED_SUCCESSFULLY: "Status updated successfully",
    },
  },
}

export default ConsignmentConstants;