import { TableCell, TableCellProps } from '@material-ui/core';
import React, { useCallback } from 'react';
import { ColDef } from '.';
import { useStyles } from './DataTableCellElement.styles';

export interface DataTableCellElementProps extends TableCellProps {
  column: ColDef;
  value: any;
  cellClickCallback?: (column: ColDef, value: any) => void;
}

const DataTableCellElement = (props: DataTableCellElementProps) => {
  const classes = useStyles();
  const { column, value, cellClickCallback, ...rest } = props;

  const handleCellClick = useCallback(
    () => {
      cellClickCallback && cellClickCallback(column, value);
    },
    [cellClickCallback, column, value],
  );

  return (
    !column.hide
      ? <TableCell
        {...rest}
        align={column.type === 'number' ? 'left' : 'left'}
        className={classes.root}
        onClick={handleCellClick}
      >
        {
          column.renderCell
            ? <column.renderCell field={column.field} value={value} />
            : value
        }
      </TableCell>
      : <></>
  )
}

export default DataTableCellElement;