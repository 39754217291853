import AppConstants from "../../constants";

export interface formDetail { }

export interface Permission {
  assignedRole: string;
  formDetails: formDetail[];
}

export interface Hub {
  hubCode: string;
  hubName: string;
  countryName: string;
  permissions: Permission[];
}

export interface Country {
  [key: string]: Hub[];
}
export interface Content {
  username: string;
  email: string;
  firstname: string;
  lastname: string;
  phone: number;
  enabled: boolean;
  createdBy: string;
  lastModifiedBy: string;
  userType: string;
  countryDetails: Country;
  status: string;
}

export interface Sort {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export interface Pageable {
  sort: Sort;
  offset: number;
  requestContinuation: string;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;
}

export interface Sort2 {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export interface UserDataResponse {
  content: Content[];
  pageable: Pageable;
  totalElements: number;
  totalPages: number;
  last: boolean;
  sort: Sort2;
  first: boolean;
  numberOfElements: number;
  size: number;
  number: number;
  empty: boolean;
}

const lastMileUserDataTransform = (response: UserDataResponse, countryCode: string): any[] => {
  const transformedArray: any[] = [];
  response.content.forEach((content: Content) => {
    const {
      email,
      firstname,
      lastname,
      phone,
      userType,
      countryDetails,
      status,
    } = content;
    // TODO: map/add actual objects too for drilling down content or store in state
    const hubDetails: Hub[] = countryDetails[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode] || [];
    const currentHubDetails: Hub = hubDetails.length ? hubDetails[0] : countryDetails[AppConstants.COUNTRY_ALL] ? countryDetails[AppConstants.COUNTRY_ALL][0] : {
      hubCode: "N/A",
      hubName: "N/A",
      countryName: "N/A",
      permissions: []
    };
    //const role = currentHubDetails.permissions.length ? currentHubDetails.permissions[0].assignedRole : "N/A";
    const currentRole = currentHubDetails.permissions.length ? currentHubDetails.permissions[0].assignedRole : "N/A";
    const currentRoleIndex = AppConstants.USERS_CONSTANTS.USER_ROLES.findIndex(role => role.code === currentRole);
    const accessibleRoles = AppConstants.USERS_CONSTANTS.USER_ROLES.slice(currentRoleIndex);
    const  role = accessibleRoles.length ? accessibleRoles[0].value : "N/A";
    transformedArray.push({
      id: email,
      email: email,
      firstname: firstname,
      lastname: lastname,
      hubName: currentHubDetails.hubName || currentHubDetails.hubCode,
      role: role,
      roleKey: currentRole,
      type: userType,
      country: currentHubDetails.countryName,
      status: status,
      phone: phone
    });
  });
  return [...transformedArray];
};

export { lastMileUserDataTransform };

export const createFilterPayload = (payload: any, data?: any, isLoadMore?: boolean) => {
  if (payload.hub) {
    const selectAllIdx = payload.hub.indexOf(AppConstants.SELECT_ALL.value);
    const selectNoneIdx = payload.hub.indexOf(AppConstants.SELECT_NONE.value);
    if (selectAllIdx > -1) {
      payload.hub = [];
    }
    if (selectNoneIdx > -1) {
      payload.hub.splice(selectNoneIdx, 1);
    }
  }
  if (payload.role) {
    const selectAllIdx = payload.role.indexOf(AppConstants.SELECT_ALL.value);
    const selectNoneIdx = payload.role.indexOf(AppConstants.SELECT_NONE.value);
    if (selectAllIdx > -1) {
      payload.role = [];
    }
    if (selectNoneIdx > -1) {
      payload.role.splice(selectNoneIdx, 1);
    }
  }
  if (payload.status) {
    const selectAllIdx = payload.status.indexOf(AppConstants.SELECT_ALL.value);
    const selectNoneIdx = payload.status.indexOf(AppConstants.SELECT_NONE.value);
    if (selectAllIdx > -1) {
      payload.status = [];
    }
    if (selectNoneIdx > -1) {
      payload.status.splice(selectNoneIdx, 1);
    }
  }
  if (payload.type) {
    const selectAllIdx = payload.type.indexOf(AppConstants.SELECT_ALL.value);
    const selectNoneIdx = payload.type.indexOf(AppConstants.SELECT_NONE.value);
    if (selectAllIdx > -1) {
      payload.type = [];
    }
    if (selectNoneIdx > -1) {
      payload.type.splice(selectNoneIdx, 1);
    }
  }
  if (isLoadMore && data) {
    const paginationObj = data.pageable;
    if (paginationObj) {
      payload.page = paginationObj.pageNumber + 1 < data.totalPages ? paginationObj.pageNumber + 1 : payload.page;
    }
  }
  // if (isExcel) {
  //   delete payload.page;
  // }
  return payload;
};

export const createAddUserPayload = (payload: any, dropdowns: any) => {
  let countryDetails = dropdowns.countriesList[payload.userRole] ? dropdowns.countriesList[payload.userRole].filter((country: any) => country.value === payload.country) : [];
  let hubDetails = dropdowns.hubList[payload.country] ? dropdowns.hubList[payload.country].filter((hub: any) => hub.value === payload.hub) : []
  if (countryDetails.length) {
    payload.countryDetails = countryDetails.map((country: any) => {
      return {
        code: country.value,
        value: country.name
      };
    })[0];
  }
  if (hubDetails.length) {
    payload.hubDetails = hubDetails.map((hub: any) => {
      return {
        code: hub.value,
        value: hub.name
      };
    });
  }
  payload.userName = payload.email;
  delete payload.confirmPassword;
  delete payload.country;
  delete payload.hub;
  return payload;
}

export const createEditUserPayload = (values: any, dropdowns: any) => {
  let countryDetails = dropdowns.countriesList[values.userRole] ? dropdowns.countriesList[values.userRole].filter((country: any) => country.value === values.country) : [];
  let hubDetails = dropdowns.hubList[values.country] ? dropdowns.hubList[values.country].filter((hub: any) => hub.value === values.hub) : []
  if (countryDetails.length) {
    values.countryDetails = countryDetails.map((country: any) => {
      return {
        countryCode: country.value,
        countryName: country.name
      };
    })[0];
  }
  if (hubDetails.length) {
    values.hubDetails = hubDetails.map((hub: any) => {
      return {
        hubCode: hub.value,
        hubName: hub.name
      };
    });
  }
  values.firstname = values.firstName;
  values.lastname = values.lastName;
  values.role = values.userRole;
  values.type = values.userType;
  let payload = {
    userName: values.userName,
    data: values
  }
  delete payload.data.firstName;
  delete payload.data.lastName;
  delete payload.data.userRole;
  delete payload.data.userType;
  if(payload.data && !payload.data.password){
    delete payload.data.password;
  }
  delete payload.data.confirmPassword;
  delete payload.data.country;
  delete payload.data.hub;
  delete payload.data.email;
  delete payload.data.userName;
  return payload;
}

export const getStatusField = (fieldName: string, statusCode: string) => {
  const statuses: any = AppConstants.USERS_CONSTANTS.STATUSES;
  return statusCode && statuses[statusCode] ? statuses[statusCode][fieldName] : "";
}

export const checkUserAsAdmin = (currentRoles: any) => {
  let isAdmin = false;
  for (let adminRole of AppConstants.USERS_CONSTANTS.ADMIN_ROLES) {
    for (let currentRole of currentRoles) {
      if (currentRole.code === adminRole.code) {
        isAdmin = true;
        break;
      }
    }
    if (isAdmin) {
      break;
    }
  }
  return isAdmin;
}

export const checkUserAsSuperAdmin = (currentRoles: any) => {
  let isSuperAdmin = false;
  for (let adminRole of AppConstants.USERS_CONSTANTS.SUPER_ADMIN_ROLES) {
    for (let currentRole of currentRoles) {
      if (currentRole.code === adminRole.code) {
        isSuperAdmin = true;
        break;
      }
    }
    if (isSuperAdmin) {
      break;
    }
  }
  return isSuperAdmin;
}

export const checkUserRoleAccess = (currentRole: any, selectedRole: any) => {
  let canAccess = false;
  const currentRoleIndex = AppConstants.USERS_CONSTANTS.USER_ROLES.findIndex(role => role.code === currentRole.code);
  let accessibleRoles = AppConstants.USERS_CONSTANTS.USER_ROLES.slice(currentRoleIndex);
  if (currentRole.code === 'ROLE_COUNTRY_ADMIN') {
    accessibleRoles = accessibleRoles.filter(role => role.code !== 'ROLE_COUNTRY_ADMIN');
  }
  if (currentRole.code === 'ROLE_APP_ADMIN') {
    accessibleRoles = accessibleRoles.filter(role => role.code !== 'ROLE_APP_ADMIN');
  }
  for (let userRole of accessibleRoles) {
    if (selectedRole.code === userRole.code) {
      canAccess = true;
      break;
    }
  }
  return canAccess;
}
