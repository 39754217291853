const AppConstants = {
  COUNTRY: 'country',
  COUNTRY_CODE: 'countryCode',
  COUNTRY_ALL: 'ALL',
  DEFAULT_COUNTRY_CODE: 'AE',
  SEARCH_BOX_LIMIT: 3,
  SEARCH_TEXT_LIMIT: 'Search box must have at least 3 characters',
  HUBNAME: 'hubName',
  DELIVERY_STATUS: 'deliveryStatus',
  DELIVERY_DATE: 'deliveryDate',
  CARREFOUR: '(carrefour|market)',
  DATE_FORMAT: 'DD/MM/YYYY',
  DATE_FORMAT_UI: 'DD MMM YY',
  DATE_TIME_FORMAT_UI: 'DD MMM YY HH:mm',
  DATE_FORMAT_BACKEND: "YYYY-MM-DDTHH:mm:ss",
  POST_PAID_ARRAY:["Card on delivery", "COD"],
  COUNTRY_OBJ: {
    value: "AE",
    name: "United Arab Emirates"
  },
  SELECT_ALL: {
    name: "Select All",
    value: "all"
  },
  SELECT_NONE: {
    name: "Please Select",
    value: "n/a"
  },
  BUTTONS: {
    SEARCH: 'SEARCH',
    RESET: 'RESET',
    LOAD_MORE_LOADING: "LOADING MORE DATA",
    LOGIN: 'LOGIN',
    FORGOT_PWD: 'Forgot Password?',
    REGISTER_USER: 'New User Registration',
    SUBMIT: 'SUBMIT',
    APPROVE: 'APPROVE',
    UPDATE: 'UPDATE',
    REJECT: 'REJECT',
    CANCEL: "CANCEL",
    ADD_USER: "ADD USER",
    FILTER: "FILTER",
    REPUSH: "REPUSH",
    OPEN_THE_HATCH: "Open the Hatch",
    REASSIGN_CARRIER: "Reassign Carrier",
    SAVE: 'Save'
  },
  SNACKBAR: {
    TYPES: {
      SUCCESS: "success",
      ERROR: "error"
    },
    AUTO_HIDE_TIMEOUT: 3000,
    AUTO_HIDE_STATUS_TIMEOUT: 8000
  },
  RESPONSE_CONSTANTS: {
    ERROR_CODES: {
      DEFAULT_API_FAILED: "BR100",
    },
    DEFAULT_API_FAILED_ERROR_MSG: "Something went wrong, Please try again later",
  },
  VALIDATIONS: {
    EMAIL: {
      REQUIRED: {
        msg: "Email is required"
      },
      VALID_EMAIL: {
        msg: "Please enter a valid email"
      }
    },
    PASSWORD: {
      REQUIRED: {
        msg: "Password is required"
      },
      STRONG_PWD: {
        msg: "Password should be of minimum 8 characters length and should contain at least one Lowercase character, one Uppercase character, one Number and one Special character",
        value: "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$"
      },
      ONE_OF: {
        msg: "Passwords should be same"
      }
    }
  }
};

export default AppConstants;