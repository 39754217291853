import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from './ShippingLabel.styles';
import logoIcon from './../assets/icons/carrefour-white.svg';
import oldPhoneIcon from './../assets/icons/old-phone-icon.svg';
import { useBarcode } from 'react-barcodes';
import { useSelector } from 'react-redux';
import { AppState } from '../config/redux/reducers';
import moment from 'moment';
import { isPostPaidOrder } from "../utils/helpers.utils";




interface ShippingLabelProps {
  record: any;
}

const ShippingLabel = (props: ShippingLabelProps) => {
  const classes = useStyles();
  const { record } = props;

  const { data } = useSelector(
    (state: AppState) => state.returnsList
  );

  let selectedConsignment: any = null;
  let deliveryInfo: any = {
    address: {}
  };
  let custInfo: any = {
    address: {}
  };
  let totalWeight: any = 0;
  let deliveryRemarks: any = "";
  let currency: any = "AED";
  let custAddress1: any = "";
  let custAddress2: any = "";
  let deliveryAddress1: any = "";
  let deliveryAddress2: any = "";

  if (data && data.content) {
    selectedConsignment = data.content.filter((consignment: any) => consignment.rmaNumber === record.returnId);
    selectedConsignment = selectedConsignment.length ? selectedConsignment[0] : null;
    if (selectedConsignment) {
      deliveryInfo = selectedConsignment.deliveryInformation.customerContactInfo;
      custInfo = selectedConsignment.pickUpInformation.pickUpContactInfo;
      
      custAddress1 = custInfo.address ? `${custInfo.address.building}, ${custInfo.address.apartment}, ${custInfo.address.streetName}, ${custInfo.address.streetNumber}`:"";
      custAddress2 = custInfo.address ? `${custInfo.address.area} - ${custInfo.address.city} ${custInfo.address.zoneNumber} ${custInfo.address.postalCode}` : "";
      let custAddressConcat = custInfo.address ? `${custInfo.address.building}, ${custInfo.address.apartment}, ${custInfo.address.streetName}, ${custInfo.address.streetNumber}`:"";
      if(custAddressConcat && custAddressConcat.length > 115){
        custAddress1 = custAddressConcat.substring(0,115);
      }
      if(custInfo.address && custInfo.address.area && custAddressConcat.length > 0 && custAddressConcat.length < 35){
        custAddress2 = `${custInfo.address.area.substring(0,100)} - ${custInfo.address.city} ${custInfo.address.zoneNumber} ${custInfo.address.postalCode}`;
      } else if(custInfo.address && custInfo.address.area && custAddressConcat.length > 35 && custAddressConcat.length < 70){
        custAddress2 = `${custInfo.address.area.substring(0,60)} - ${custInfo.address.city} ${custInfo.address.zoneNumber} ${custInfo.address.postalCode}`;
      } else if(custInfo.address && custInfo.address.area && custAddressConcat.length > 70){
        custAddress2 = `${custInfo.address.area.substring(0,20)} - ${custInfo.address.city} ${custInfo.address.zoneNumber} ${custInfo.address.postalCode}`;
      }


      deliveryAddress1 = deliveryInfo.address ? `${deliveryInfo.address.building}, ${deliveryInfo.address.apartment}, ${deliveryInfo.address.streetName}, ${deliveryInfo.address.streetNumber}`:"";
      deliveryAddress2 = deliveryInfo.address ? `${deliveryInfo.address.area} - ${deliveryInfo.address.city} ${deliveryInfo.address.zoneNumber} ${deliveryInfo.address.postalCode}` : "";
      let deliveryAddressConcat = deliveryInfo.address ? `${deliveryInfo.address.building}, ${deliveryInfo.address.apartment}, ${deliveryInfo.address.streetName}, ${deliveryInfo.address.streetNumber}`:"";
      if(deliveryAddressConcat && deliveryAddressConcat.length > 165){
        deliveryAddress1 = deliveryAddressConcat.substring(0,165);
      }
      if(deliveryInfo.address && deliveryInfo.address.area && deliveryAddressConcat.length > 0 && deliveryAddressConcat.length < 55){
        deliveryAddress2 = `${deliveryInfo.address.area.substring(0,110)} - ${deliveryInfo.address.city} ${deliveryInfo.address.zoneNumber} ${deliveryInfo.address.postalCode}`;
      } else if(deliveryInfo.address && deliveryInfo.address.area && custAddressConcat.length > 55 && custAddressConcat.length < 110){
        deliveryAddress2 = `${deliveryInfo.address.area.substring(0,75)} - ${deliveryInfo.address.city} ${deliveryInfo.address.zoneNumber} ${deliveryInfo.address.postalCode}`;
      } else if(deliveryInfo.address && deliveryInfo.address.area && custAddressConcat.length > 110){
        deliveryAddress2 = `${deliveryInfo.address.area.substring(0,20)} - ${deliveryInfo.address.city} ${deliveryInfo.address.zoneNumber} ${deliveryInfo.address.postalCode}`;
      }

      totalWeight = selectedConsignment.deliveryInformation && selectedConsignment.deliveryInformation.hasOwnProperty('totalWeight') ? selectedConsignment.deliveryInformation.totalWeight : 0;
      deliveryRemarks = selectedConsignment.deliveryInformation.deliveryInstructions;
      currency = selectedConsignment.metadata.currency;
    }
  }

  const { inputRef: consignCdBarCdRef } = useBarcode({
    value: record.returnId || "N/A",
    options: {
      width: 3,
      height: 75,
      fontOptions: "bold"
    }
  });

  const { inputRef: trackingNoBarCdRef } = useBarcode({
    value: record.trackingId || "N/A",
    options: {
      height: 50
    }
  });

  return (
    <Grid className={[classes.root, 'shippingLabelContainer'].join(" ")} container>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={9}>
            <Grid container>
              <Grid className={classes.logoSection} item xs={12}>
                <img className="logo" alt="Shipping Label" src={logoIcon} />
              </Grid>
              <Grid className={classes.fromSection} item xs={12}>
                <Typography className={classes.heading} component="div">FROM (SHIPPER)</Typography>
                <Typography className="custName" component="div">{custInfo.name ? custInfo.name.substring(0,30) : custInfo.name}</Typography>
                <Grid className={classes.address}>
                  <Typography className="addHeading" component="div">{custAddress1}</Typography>
                  <Typography className="addContent" component="div">{custAddress2}</Typography>
                  <Typography className="addFooter" component="div">{custInfo.address ? custInfo.address.countryName : ""}</Typography>
                </Grid>
                <Grid className={classes.phnContainer} container>
                  <img alt="Phone" src={oldPhoneIcon} />
                  <span>{custInfo.phone}</span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.barcode1Section} item xs={3}>
            <img alt="Consignment Code" ref={consignCdBarCdRef}></img>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.toSection} item xs={12}>
        <Typography className={classes.heading} component="div">TO</Typography>
        <Typography className="custName" component="div">{deliveryInfo.companyName ? deliveryInfo.companyName.substring(0,45) : deliveryInfo.name.substring(0,45)}</Typography>
        <Grid className={classes.address}>
          <Typography className="addHeading" component="div">{deliveryAddress1}</Typography>
          <Typography className="addContent" component="div">{deliveryAddress2}</Typography>
          <Typography className="addFooter" component="div">{deliveryInfo.address ? deliveryInfo.address.countryName : ""}</Typography>
        </Grid>
        <Grid className={classes.phnContainer} container>
          <img alt="Phone" src={oldPhoneIcon} />
          <span>{deliveryInfo.phone}</span>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid className={classes.descSection} item xs={8}>
            <Grid className="descItem">
              <span className="descLabel">Description of Goods:</span>
              <span className="descContent">N/A</span>
            </Grid>
            <Grid className="descItem" container>
              <Grid item>
                <span className="descLabel">Ship Date:</span>
                <span className="descContent">{moment().format('DD-MM-YYYY')}</span>
              </Grid>
              <Grid className="item" item>
                <span className="descLabel">Wt:</span>
                <span className="descContent">{`${totalWeight} Kg`}</span>
              </Grid>
              <Grid item>
                <span className="descLabel">Pcs:</span>
                <span className="descContent">1</span>
              </Grid>
            </Grid>
            <Grid className="descItem">
              <span className="descLabel">Order No:</span>
              <span className="descContent">{record.orderId}</span>
            </Grid>
            <Grid className="descItem" container>
              <Grid item xs={6}>
                <span className="descLabel">Origin:</span>
              </Grid>
              <Grid item xs={6}>
                <span className="descLabel">Dest:</span>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.modeSection} item xs={4}>
            <Grid className="mode">
              {record?.paymentType && isPostPaidOrder(record?.paymentType)  ?
                (
                  <Grid className="codContainer">
                    <Typography className="modeText" component="div">{record?.paymentType}</Typography>
                    <Typography className="amtContainer" component="div">
                      <span className="descLabel">{currency}:</span>
                      <span className="descContent">{record.codAmount}</span>
                    </Typography>
                  </Grid>
                ) : (
                  <Typography className="modeText" component="div">Prepaid</Typography>
                )
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.barcode2Section} item xs={12}>
        <img alt="Tracking No." ref={trackingNoBarCdRef}></img>
        <Typography className="carrierContainer" component="div">
          <span className="descLabel">Shipped via:</span>
          <span className="descContent">{record.carrier}</span>
        </Typography>
        <Typography className="remarksContainer" component="div">
          <span className="descLabel">Notes:</span>
          <span className="descContent">{deliveryRemarks}</span>
        </Typography>
      </Grid>
    </Grid>
  )
}

export default ShippingLabel;
