import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginLeft: 24,
      marginRight: 24
    },
    comingSoonMain:{
      height: 16,
      flexGrow: 1,
      alignItems: "center",
      justifyContent: "center",
      marginTop: "20%",
      '& h3':{
        color: "#4D4D4D",
        textAlign: "center"
      }
    }
  })
);