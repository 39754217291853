import { Checkbox, TableCell, TableHead, TableHeadProps, TableRow, TableSortLabel } from '@material-ui/core';
import React from 'react';
import { ColDef, SortDirection, RowData } from '.';
import { useStyles } from './DataTableHeader.styles';

export interface DataTableHeaderProps extends TableHeadProps {
  checkboxSelection: boolean;
  order: SortDirection;
  orderBy: keyof RowData;
  columns: ColDef[];
  rowCount: number;
  selected: number;
  headerHeight: number;
  onSort: (event: React.MouseEvent<any>, property: string) => void;
  onSelectAll: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
}

const DataTableHeader = (props: DataTableHeaderProps) => {
  const classes = useStyles();
  const { checkboxSelection, order, orderBy, selected, rowCount, columns, headerHeight, onSelectAll, onSort, ...rest } = props;

  const createSortHandler = (property: string) => (
    event: React.MouseEvent<any>
  ) => {
    onSort(event, property);
  };

  return (
    <TableHead className={classes.root} {...rest}>
      <TableRow>
        <TableCell style={{ width: 20, height: headerHeight, minHeight: headerHeight, maxHeight: headerHeight }}>
          {
            checkboxSelection &&
            <Checkbox
              indeterminate={selected > 0 && selected < rowCount}
              checked={rowCount > 0 && selected === rowCount}
              size="small"
              color="primary"
              onChange={onSelectAll}
            />
          }
        </TableCell>
        {columns.map((col: ColDef, index: number) => (
          !col.hide &&  
          <TableCell
            key={col.field}
            align={col.type === 'number' ? 'left' : 'left'}
            sortDirection={orderBy === col.field && order !== 'default' ? order : false}
            style={{ height: headerHeight, minHeight: headerHeight, maxHeight: headerHeight, width: col.width, minWidth: col.width, maxWidth: col.width }}
          >
            <TableSortLabel
              active={false}//{orderBy === col.field && order !== 'default'}
              direction={orderBy === col.field && order !== 'default' ? order : 'asc'}
              hideSortIcon={true}//{orderBy === col.field && order === 'default'}
              onClick={createSortHandler(col.field)}
            >
              {
                col.renderHeader
                  ? <col.renderHeader field={col.field} colDef={col} colIndex={index}></col.renderHeader>
                  : col.headerName
              }
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default DataTableHeader;