import { FormControl, InputLabel, OutlinedTextFieldProps, TextField, InputAdornment, IconButton } from '@material-ui/core';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React, { useState } from 'react';
import { useStyles } from './TextBox.styles';

interface TextBoxProps extends OutlinedTextFieldProps {
  handleChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleKeyDownChange?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  handleKeyUpChange?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  textBoxId: string;
  label?: string;
  type: string;
  placeholderText?: string;
  readOnly?: boolean;
  endAdornment?: React.ReactNode;
}

const TextBox = (props: TextBoxProps) => {
  const classes = useStyles();
  let { handleChange, handleKeyDownChange, handleKeyUpChange, fullWidth, variant, value, label, name, textBoxId, error, helperText, type, placeholderText, readOnly, className, endAdornment, ...rest } = props;

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  if(type === "password"){
    type = showPassword ? "text" : "password";
    endAdornment = (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    )
  }

  return (
    <>
      <FormControl className={classes.root} variant={variant} fullWidth={fullWidth}>
        {label && <InputLabel htmlFor={textBoxId} shrink>{label}</InputLabel>}
        <TextField
          {...rest}
          className={`${className || ''} ${label ? 'hasLabel ' : ''}`}
          variant={variant}
          fullWidth={fullWidth}
          id={textBoxId}
          placeholder={placeholderText}
          type={type}
          name={name}
          value={value}
          InputProps={{
            readOnly: readOnly,
            autoComplete: "new-password",
            endAdornment: endAdornment
          }}
          error={error}
          helperText={helperText}
          onKeyDown={handleKeyDownChange}
          onKeyUp={handleKeyUpChange}
          onChange={handleChange}
        />
      </FormControl>
    </>
  );
}

export default TextBox;