const UsersConstants = {
  USERS_CONSTANTS: {
    TABLE_WRAPPER_DATA: {
      title: "Users",
      HeaderData: [
        {
          field: 'id',
          headerName: 'Id',
          hide: true
        },
        {
          field: 'email',
          headerName: 'Email Id',
          width: 180
        },
        {
          field: 'firstname',
          headerName: 'First Name',
          width: 150
        },
        {
          field: 'lastname',
          headerName: 'Last Name',
          width: 150
        },
        {
          field: 'hubName',
          headerName: 'Hub',
          width: 150
        },
        {
          field: 'role',
          headerName: 'Role',
          width: 150
        },
        {
          field: 'type',
          headerName: 'Type',
          width: 150
        },
        {
          field: 'country',
          headerName: 'Country',
          width: 150
        },
        {
          field: 'status',
          headerName: 'Status',
          width: 210
        },
        {
          field: 'phone',
          headerName: 'Phone No',
          width: 150
        },
      ],
      FilterFormData: [
        {
          type: "selectBox",
          fieldName: "hub",
          multiple: true,
          key: `selectBox-hub-${Math.floor(Math.random() * 1000)}`,
          label: "Hub",
          items: []
        },
        {
          type: "selectBox",
          fieldName: "role",
          multiple: true,
          key: `selectBox-role-${Math.floor(Math.random() * 1000)}`,
          label: "Role",
          items: []
        },
        {
          type: "selectBox",
          fieldName: "status",
          multiple: true,
          key: `selectBox-status-${Math.floor(Math.random() * 1000)}`,
          label: "Status",
          items: []
        },
        {
          type: "selectBox",
          fieldName: "type",
          multiple: true,
          key: `selectBox-type-${Math.floor(Math.random() * 1000)}`,
          label: "Type",
          items: []
        }
      ],
      headerButtons: [
        {
          name: "addUser",
          displayName: "ADD NEW USER",
          disabled: false
        }
      ],
      loadMore: {
        name: 'loadMore',
        disabled: false,
        pageSize: 20
      },
      defaultFiltersObj: {
        hub: ["all"],
        role: ["all"],
        status: ["all"],
        type: ["all"],
        page: 0
      },
      SEARCH_TYPES: [
        {
          name: "First Name",
          value: "firstname"
        },
        {
          name: "Last Name",
          value: "lastname"
        },
        {
          name: "Email Id",
          value: "email"
        }
      ],
      sortModel: {
        field: "email",
        direction: "asc"
      }
    },
    VALIDATIONS: {
      FIRSTNAME: {
        REQUIRED: {
          msg: "Firstname is required"
        }
      },
      LASTNAME: {
        REQUIRED: {
          msg: "Lastname is required"
        }
      },
      PHONE: {
        REQUIRED: {
          msg: "Phone Number is required"
        },
        VALIDITY: {
          msg: "Please enter a valid Phone number",
          value: "^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$"
        }
      },
      STATUS: {
        REQUIRED: {
          msg: "Status is required",
          value: "\\b(?![n\\/a]\\b)\\w+"
        }
      },
      ROLE: {
        REQUIRED: {
          msg: "Role is required",
          value: "\\b(?![n\\/a]\\b)\\w+"
        }
      },
      TYPE: {
        REQUIRED: {
          msg: "Type is required",
          value: "\\b(?![n\\/a]\\b)\\w+"
        }
      },
      COUNTRY: {
        REQUIRED: {
          msg: "Country is required",
          value: "\\b(?![n\\/a]\\b)\\w+"
        }
      },
      HUB: {
        REQUIRED: {
          msg: "Hub is required",
          value: "\\b(?![n\\/a]\\b)\\w+"
        }
      }
    },
    STATUSES: {
      "CREATED": {
        "color": "black",
        "name": "Created",
        "tooltip": "User created by admin"
      },
      "ACTIVE": {
        "color": "green",
        "name": "Active",
        "tooltip": "User approved by admin"
      },
      "INACTIVE": {
        "color": "red",
        "name": "Inactive",
        "tooltip": "User is deactivated by admin"
      },
      "REJECTED": {
        "color": "gray",
        "name": "Rejected",
        "tooltip": "User request is rejected by admin"
      },
      "AWAITING_APPROVAL": {
        "color": "yellow",
        "name": "Pending Approval",
        "tooltip": "User approval is pending at admin end"
      },
      "AWAITING_EMAIL_VERIFICATION": {
        "color": "yellow",
        "name": "Pending Email Verification",
        "tooltip": "User email verification is pending at user end"
      }
    },
    USER_ROLES: [
      {
        code: "ROLE_SUPER_ADMIN",
        value: "Super Admin"
      },
      {
        code: "ROLE_APP_ADMIN",
        value: "Application Admin"
      },
      {
        code: "ROLE_COUNTRY_ADMIN",
        value: "Country Admin"
      },
      {
        code: "ROLE_LAST_MILE_DISPATCHER",
        value: "Last Mile Dispatcher"
      },
      {
        code: "ROLE_LAST_MILE_OPERATIONS_MANAGER",
        value: "Last Mile Operations Manager"
      },
      {
        code: "ROLE_CUSTOMER_SERVICE",
        value: "Customer Service"
      }
    ],
    ADMIN_ROLES: [
      {
        code: "ROLE_SUPER_ADMIN",
        value: "Super Admin"
      },
      {
        code: "ROLE_APP_ADMIN",
        value: "Application Admin"
      },
      {
        code: "ROLE_COUNTRY_ADMIN",
        value: "Country Admin"
      }
    ],
    SUPER_ADMIN_ROLES: [
      {
        code: "ROLE_SUPER_ADMIN",
        value: "Super Admin"
      }
    ],
    SELF_REGISTER_ROLES: [
      {
        code: "ROLE_LAST_MILE_DISPATCHER",
        value: "Last Mile Dispatcher"
      },
      {
        code: "ROLE_LAST_MILE_OPERATIONS_MANAGER",
        value: "Last Mile Operations Manager"
      },
      {
        code: "ROLE_CUSTOMER_SERVICE",
        value: "Customer Service"
      }
    ],
    RESPONSE_CONSTANTS: {
      ERROR_CODES: {
        SESSION_TIMEOUT: "UM100",
        UNAUTHORISED: "UM101",
      },
      USER_UPDATE_SUCCESS_MSG: "has been approved successfully!",
      USER_UPDATE_REJECT_MSG: "has been rejected."
    }
  },
}

export default UsersConstants;