import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginLeft: 24,
      marginRight: 24
    },
    pageTitle:{
      padding:"16px 16px 0 0",
      fontSize:20
    },
    statusHeader: {
      "& .statusIcon": {
        width: 12,
        height: 12,
        borderRadius: "50%",
        '&.yellow': {
          backgroundColor: COLORS.STATUS_YELLOW
        },
        '&.black': {
          backgroundColor: COLORS.TEXT_PRIMARY
        },
        '&.green': {
          backgroundColor: COLORS.PERSIAN_GREEN
        },
        '&.red': {
          backgroundColor: COLORS.DARK_RED
        },
        '&.gray': {
          backgroundColor: COLORS.BLACK_BORDER
        },
      },
      '& .statusMsg': {
        paddingLeft: theme.spacing(1),
        '& .status': {
          fontSize: 12
        }
      }
    },
    customTooltip:{
      backgroundColor: "#000",
      padding: 6
    },
    customArrow:{
      color: "#000"
    },
  }),
);
