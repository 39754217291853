import { Button, Grid, MenuItem, Typography, MenuList, Paper, Popper, ClickAwayListener, Tooltip, Link } from '@material-ui/core';
import React, { useState, useRef , useEffect} from 'react';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { customHeaderUseStyles } from './CustomHeader.styles';
import { ComponentProps } from './DataTable';
import ColumnSettings from "./ColumnSettings";
import _ from "lodash";

interface CustomHeaderComponentProps extends ComponentProps {
  buttons?: Array<any>;
  columnSettingsColList?: any;
  favoriteViews?: any;
  title: string;
  isColumnSettingsDisabled?: boolean | undefined;
  exceptions?: boolean | undefined;
  btnClickHandler?: (params: any) => void;
  onChangeFavoriteView?: (params: any) => void;
  handleColumnSettingsActions?: (params: any) => void;
}

const CustomHeaderComponent = (props: CustomHeaderComponentProps) => {
  const classes = customHeaderUseStyles();
  const { options, buttons, isColumnSettingsDisabled, exceptions, columnSettingsColList, favoriteViews, title, btnClickHandler, onChangeFavoriteView, handleColumnSettingsActions } = props;
  const [openColumnSettingsPopup, setOpenColumnSettingsPopup] = useState(false);
  const selectedView = favoriteViews ? _.find(favoriteViews, (n) => { return (n.isActive === true); }): '';
  const [open, setOpen] = useState(false);
  const [openSub, setOpenSub] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const prevOpen = useRef(open);
  const prevOpenSub = useRef(openSub);

  const handleToggle = () => {
    if(openSub){
      setOpenSub((prevOpenSub) => !prevOpenSub);
    }
    setOpen((prevOpen) => !prevOpen);
  };

  const handleToggleSub = () => {
    setOpenSub((prevOpenSub) => !prevOpenSub);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
    setOpenSub(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
      setOpenSub(false);
    }
  }

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (prevOpenSub.current === true && openSub === false) {
      anchorRef.current!.focus();
    }
    prevOpenSub.current = openSub;
  }, [openSub]);

  const handleItemClick = (value: any) => {
    const activeView = favoriteViews ? _.find(favoriteViews, (n) => { return (n.isActive === true); }): '';
    if(activeView.viewName !== value){
      onChangeFavoriteView && onChangeFavoriteView(value)
    }
  };

  const openColumnSettingsDialog = () => {
    setOpenColumnSettingsPopup(true);
  }

  const closeColumnSettingsPopup = () => {
    setOpenColumnSettingsPopup(false);
  }

  const handleDialogSaveAction = (params: any) => {
      handleColumnSettingsActions && handleColumnSettingsActions(params)
      setOpenColumnSettingsPopup(false);
  };

  return (
    <Grid className={`${ exceptions ? classes.exceptionsRoot : classes.root }`} container>
      <Grid className={classes.total} item>
        {options.selected ? (
          <Typography><span>{`Selected ${title}`}</span><span className="separator">:</span><span className="count">{options.selected}</span></Typography>
        ) : (
            <Typography><span>{`No. of ${title}`}</span><span className="separator">:</span><span className="count">{options.rowCount}</span></Typography>
          )}
      </Grid>
      {
        buttons && buttons.length ? (
          <Grid item className={classes.buttonContainer}>
            {
              !isColumnSettingsDisabled && selectedView ?
              <Tooltip arrow placement="top" classes={{tooltip: classes.customTooltip, arrow: classes.customArrow}} title={`Selected View : ${selectedView?.viewName}`}>
                <Link component="button" className="selectedView" underline="none">{`${selectedView?.viewName}`} &nbsp; <br/><VisibilityOutlinedIcon /></Link>
              </Tooltip>
              :null
            }
            {
              buttons.map((buttonObj, index) => (
                buttonObj && buttonObj.name !== "colsettings" ?
                <Button key={`${buttonObj.name}-${index}`} disabled={buttonObj.disabled} className={[classes.button, "primary"].join(" ")} variant="contained" onClick={() => btnClickHandler && btnClickHandler(buttonObj)}>{buttonObj.displayName}</Button>
                :
                !isColumnSettingsDisabled ?
                <React.Fragment key={`${buttonObj.name}-${index}`}>
                  <Button className={classes.colSettings} ref={anchorRef} aria-controls={open ? 'menu-list-grow' : undefined} aria-haspopup="true" onClick={handleToggle}>           
                    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="42" height="42" fill="#E5E5E5"/>
                      <rect width="1440" height="1200" transform="translate(-1379 -210)" fill="white"/>
                      <rect x="0.5" y="0.5" width="41" height="41" rx="8.5" fill="white" stroke="#D2D2D2"/>
                      <circle cx="21" cy="15" r="2" fill="#4D4D4D"/>
                      <circle cx="21" cy="27" r="2" fill="#4D4D4D"/>
                      <circle cx="21" cy="21" r="2" fill="#4D4D4D"/>
                    </svg>
                  </Button>
                  <Popper className="menuPoper" open={open} anchorEl={anchorRef.current} role={undefined} style={{zIndex: 1000}} transition disablePortal>
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                          <MenuItem onClick={() => openColumnSettingsDialog()}>Column Settings</MenuItem>
                          {favoriteViews.length ? 
                          <MenuItem aria-controls={openSub ? 'menu-list-grow1' : undefined} aria-haspopup="true" onClick={handleToggleSub}>
                            <Grid className={classes.savedView} component="span">Saved Views</Grid>
                            <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M6.75 6.99055L0.48735 0.25L0 0.721825L5.82525 6.99122L0.00675016 13.2789L0.4941 13.75L6.75 6.99055Z" fill="#4D4D4D"/>
                            </svg>
                          </MenuItem>
                          :null
                          }
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Popper>
                  <Popper className="subMenuPoper" open={openSub} anchorEl={anchorRef.current} style={{zIndex: 1000}} transition disablePortal>
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList autoFocusItem={openSub} id="menu-list-grow1" onKeyDown={handleListKeyDown}>
                          {
                            favoriteViews.map((view: any, index: any) => (
                              <MenuItem key={`${view.viewName}-${index}`} className={view.isActive ? classes.activeView: ''} onClick={() => handleItemClick(view.viewName)}>
                                {view.isActive ? 
                                <>
                                  <Grid className={classes.isActiveViewDiv}>
                                    <svg className={classes.isActiveView} width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path fillRule="evenodd" clipRule="evenodd" d="M5.54975 11.25L0.5 6.32475L1.03025 5.7795L5.54225 10.1805L14.9623 0.75L15.5 1.28775L5.54975 11.25Z" fill="#4D4D4D"/>
                                    </svg>
                                  </Grid>
                                  <Grid className={classes.viewName}>
                                    {view.viewName}
                                  </Grid>
                                </>
                                :
                                <>
                                <Grid className={classes.viewNameOnly}>&nbsp;</Grid>
                                  <Grid className={classes.viewName}>
                                  {view.viewName}
                                </Grid>
                                </>
                              }
                              </MenuItem>
                            ))
                          }
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Popper>
                </React.Fragment>
                :null
              ))
            }
          </Grid>
        ) : null
      }
      {
      !isColumnSettingsDisabled && columnSettingsColList && 
      <ColumnSettings open={openColumnSettingsPopup} columnList={columnSettingsColList} savedViews={favoriteViews} handleDialogAction={handleDialogSaveAction} closePopup={closeColumnSettingsPopup}></ColumnSettings>
      }
    </Grid>
  )
}

export default CustomHeaderComponent;