const RouteConstants = {
  ROUTES: {
    LOGIN: "/login",
    CONSIGNMENTS: "/consignments",
    CONSIGNMENTS_RETURNS: "/consignments-returns",
    USER_MANAGEMENT: "/user-management",
    RESET_PWD: "/reset-pwd",
    INDEX: "/",
    HOME: "/dashboard",
    REGISTER: "/register",
    DASHBOARD: "/dashboard",
    STATUS: "/status",
    ADMINISTRATION: "/administration",
    EXCEPTIONS_DELIVERIES: "/exceptions-deliveries",
    EXCEPTIONS_RETURNS: "/exceptions-returns",
    CAMS_CONSIGNMENT:"/cams/consignments",
    CAMS_CONSIGNMENTS_RETURNS: "/cams/consignments-returns",
    CAMS_DASHBOARD: "/cams/dashboard",
    CAMS_EXCEPTIONS_DELIVERIES:"/cams/exceptions-deliveries",
  }
};

export default RouteConstants;