import { Button, Grid, SvgIcon, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import TextBox from '../common/TextBox';
import { ReactComponent as carrefourLogo } from './../assets/icons/logo-with-title.svg';
import { useStyles } from './Login.styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../config/redux/reducers';
import Loader from '../common/Loader';
import { useHistory } from 'react-router';
import AppConstants from '../constants';
import CustomStatusBar from '../common/CustomStatusBar';
import SelectBox from '../common/SelectBox';
import { fetchFiltersDropdownValues } from '../orderList/redux/orderListSlice';
import _ from 'lodash';
import { resetUserError, userRegister } from './redux/loginSlice';
import { transformSelectValues } from '../utils/helpers.utils';

export interface UserRegisterProps { }

const registerValidationSchema = yup.object({
  firstname: yup
    .string()
    .required(AppConstants.USERS_CONSTANTS.VALIDATIONS.FIRSTNAME.REQUIRED.msg),
  lastname: yup
    .string()
    .required(AppConstants.USERS_CONSTANTS.VALIDATIONS.LASTNAME.REQUIRED.msg),
  email: yup
    .string()
    .required(AppConstants.VALIDATIONS.EMAIL.REQUIRED.msg)
    .email(AppConstants.VALIDATIONS.EMAIL.VALID_EMAIL.msg),
  password: yup
    .string()
    .matches(new RegExp(AppConstants.VALIDATIONS.PASSWORD.STRONG_PWD.value), AppConstants.VALIDATIONS.PASSWORD.STRONG_PWD.msg)
    .required(AppConstants.VALIDATIONS.PASSWORD.REQUIRED.msg),
  confirmPassword: yup
    .string()
    .matches(new RegExp(AppConstants.VALIDATIONS.PASSWORD.STRONG_PWD.value), AppConstants.VALIDATIONS.PASSWORD.STRONG_PWD.msg)
    .required(AppConstants.VALIDATIONS.PASSWORD.REQUIRED.msg)
    .oneOf([yup.ref('password')], AppConstants.VALIDATIONS.PASSWORD.ONE_OF.msg),
  phone: yup
    .string()
    .required(AppConstants.USERS_CONSTANTS.VALIDATIONS.PHONE.REQUIRED.msg)
    .matches(new RegExp(AppConstants.USERS_CONSTANTS.VALIDATIONS.PHONE.VALIDITY.value), AppConstants.USERS_CONSTANTS.VALIDATIONS.PHONE.VALIDITY.msg),
  role: yup
    .string()
    .required(AppConstants.USERS_CONSTANTS.VALIDATIONS.ROLE.REQUIRED.msg)
    .matches(new RegExp(AppConstants.USERS_CONSTANTS.VALIDATIONS.ROLE.REQUIRED.value), AppConstants.USERS_CONSTANTS.VALIDATIONS.ROLE.REQUIRED.msg),
  type: yup
    .string()
    .required(AppConstants.USERS_CONSTANTS.VALIDATIONS.TYPE.REQUIRED.msg)
    .matches(new RegExp(AppConstants.USERS_CONSTANTS.VALIDATIONS.TYPE.REQUIRED.value), AppConstants.USERS_CONSTANTS.VALIDATIONS.TYPE.REQUIRED.msg),
  country: yup
    .string()
    .required(AppConstants.USERS_CONSTANTS.VALIDATIONS.COUNTRY.REQUIRED.msg)
    .matches(new RegExp(AppConstants.USERS_CONSTANTS.VALIDATIONS.COUNTRY.REQUIRED.value), AppConstants.USERS_CONSTANTS.VALIDATIONS.COUNTRY.REQUIRED.msg),
  hub: yup
    .string()
    .required(AppConstants.USERS_CONSTANTS.VALIDATIONS.HUB.REQUIRED.msg)
    .matches(new RegExp(AppConstants.USERS_CONSTANTS.VALIDATIONS.HUB.REQUIRED.value), AppConstants.USERS_CONSTANTS.VALIDATIONS.HUB.REQUIRED.msg),
});

const UserRegister = (props: UserRegisterProps) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { loading, error, userCreationSuccess } = useSelector(
    (state: AppState) => state.userLogin
  );
  const { filtersDropdowns } = useSelector(
    (state: AppState) => state.orderList
  );

  const classes = useStyles();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [resetFormFlag, setResetFormFlag] = useState(false);

  const snackbarType = useRef(AppConstants.SNACKBAR.TYPES.SUCCESS);
  const snackbarMessage = useRef("");

  const registerFormik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      confirmPassword: '',
      phone: '',
      role: AppConstants.SELECT_NONE.value,
      type: AppConstants.SELECT_NONE.value,
      country: AppConstants.SELECT_NONE.value,
      hub: AppConstants.SELECT_NONE.value
    },
    validationSchema: registerValidationSchema,
    validate: () => {
      setResetFormFlag(false);
    },
    onSubmit: (values) => {
      register(values);
    }
  });

  const register = (values: any) => {
    if (values && filtersDropdowns.countriesList.length) {
      let payload = _.cloneDeep(values);
      let countryDetails = filtersDropdowns.countriesList.filter((country: any) => country.value === payload.country);
      let hubDetails = filtersDropdowns.hubList[payload.country] ? filtersDropdowns.hubList[payload.country].filter((hub: any) => hub.value === payload.hub) : []
      if (countryDetails.length) {
        payload.countryDetails = countryDetails.map((country: any) => {
          return {
            countryCode: country.value,
            countryName: country.name
          };
        })[0];
      }
      if (hubDetails.length) {
        payload.hubDetails = hubDetails.map((hub: any) => {
          return {
            hubCode: hub.value,
            hubName: hub.name
          };
        })[0];
      }
      delete payload.confirmPassword;
      delete payload.country;
      delete payload.hub;
      dispatch(
        userRegister({
          userdata: payload
        })
      );
    }
  }

  const openSnackbarPopup = (msg: string, type: string) => {
    snackbarMessage.current = msg;
    snackbarType.current = type;
    setOpenSnackbar(true);
  }

  const fetchHubsByCountry = useCallback(
    () => {
      dispatch(
        fetchFiltersDropdownValues()
      )
    },
    [dispatch],
  )
  const fetchDropdownValues = useCallback(
    () => {
      fetchHubsByCountry();
    },
    [fetchHubsByCountry],
  )

  const handleSnackbarAfterExited = useCallback(
    () => {
      dispatch(
        resetUserError()
      )
    },
    [dispatch],
  )

  const handleSnackbarClose = useCallback(
    () => {
      setOpenSnackbar(false);
      handleSnackbarAfterExited();
    },
    [handleSnackbarAfterExited],
  )

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpenSnackbar(false);
      handleSnackbarAfterExited();
    }, AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT);
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    registerFormik.setFieldValue('hub', AppConstants.SELECT_NONE.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerFormik.values.country])

  useEffect(() => {
    if (userCreationSuccess) {
      let  redirectUrl  = `${AppConstants.ROUTES.STATUS}?type=success&message=${AppConstants.LOGIN_CONSTANTS.USER_REQUEST_SUBMITTED}&moreinfo=${AppConstants.LOGIN_CONSTANTS.USER_CREATION_SUCCESS_MSG}`;
      history.push(redirectUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCreationSuccess])

  useEffect(() => {
    registerFormik.resetForm();
    setResetFormFlag(true);
    if (error) {
      openSnackbarPopup(error, AppConstants.SNACKBAR.TYPES.ERROR);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    fetchDropdownValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid className={classes.root} container>
      {loading && <Loader></Loader>}
      <Grid className="mainItem" item>
        <Grid container className={classes.loginContainer} direction="column">
          <Grid className="registerItem" item>
            <Grid container className="logoContainer" direction="column">
              <Grid item>
                <SvgIcon className="logo" viewBox="0 0 285 50" component={carrefourLogo} />
              </Grid>
            </Grid>
          </Grid>
          <CustomStatusBar 
            open={openSnackbar}
            mainClassName={classes.statusMessageMain} 
            iconClassName={classes.statusMessageIcon} 
            textClassName={classes.statusMessageText} 
            closeClassName={classes.statusMessageClose} 
            handleClose={handleSnackbarClose} 
            message={snackbarMessage.current} 
            type={snackbarType.current} 
          />
          <form onSubmit={registerFormik.handleSubmit}>
            <Grid className="registerItem" item>
              <Grid container className="formContainer" direction="column">
                <Grid className="formItemGroup" item>
                  <Grid container className="formItemContainer">
                    <Grid className="formItem" item>
                      <Typography className="formTitle">New User Registration</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className="formItemGroup" item>
                  <Grid container className="formItemContainer">
                    <Grid className="formItem left" item>
                      <TextBox
                        fullWidth
                        variant="outlined"
                        handleChange={registerFormik.handleChange}
                        value={registerFormik.values.firstname}
                        error={registerFormik.touched.firstname && Boolean(registerFormik.errors.firstname)}
                        helperText={registerFormik.touched.firstname && registerFormik.errors.firstname}
                        textBoxId="firstnameTextbox"
                        name="firstname"
                        type="text"
                        placeholderText="First Name"
                      ></TextBox>
                    </Grid>
                    <Grid className="formItem right" item>
                      <TextBox
                        fullWidth
                        variant="outlined"
                        handleChange={registerFormik.handleChange}
                        value={registerFormik.values.lastname}
                        error={registerFormik.touched.lastname && Boolean(registerFormik.errors.lastname)}
                        helperText={registerFormik.touched.lastname && registerFormik.errors.lastname}
                        textBoxId="lastnameTextbox"
                        name="lastname"
                        type="text"
                        placeholderText="Last Name"
                      ></TextBox>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className="formItemGroup" item>
                  <Grid container className="formItemContainer">
                    <Grid className="formItem left" item>
                      <TextBox
                        fullWidth
                        variant="outlined"
                        handleChange={registerFormik.handleChange}
                        value={registerFormik.values.email}
                        error={registerFormik.touched.email && Boolean(registerFormik.errors.email)}
                        helperText={registerFormik.touched.email && registerFormik.errors.email}
                        textBoxId="emailTextbox"
                        name="email"
                        type="text"
                        placeholderText="Email Id"
                      ></TextBox>
                    </Grid>
                    <Grid className="formItem right" item>
                      <TextBox
                        fullWidth
                        variant="outlined"
                        handleChange={registerFormik.handleChange}
                        value={registerFormik.values.phone}
                        error={registerFormik.touched.phone && Boolean(registerFormik.errors.phone)}
                        helperText={registerFormik.touched.phone && registerFormik.errors.phone}
                        textBoxId="phoneTextbox"
                        name="phone"
                        type="text"
                        placeholderText="Phone Number"
                      ></TextBox>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className="formItemGroup" item>
                  <Grid container className="formItemContainer">
                    <Grid className="formItem left" item>
                      <TextBox
                        fullWidth
                        variant="outlined"
                        handleChange={registerFormik.handleChange}
                        value={registerFormik.values.password}
                        error={registerFormik.touched.password && Boolean(registerFormik.errors.password)}
                        helperText={registerFormik.touched.password && registerFormik.errors.password}
                        textBoxId="passwordTextbox"
                        type="password"
                        name="password"
                        placeholderText="Password"
                      ></TextBox>
                    </Grid>
                    <Grid className="formItem right" item>
                      <TextBox
                        fullWidth
                        variant="outlined"
                        handleChange={registerFormik.handleChange}
                        value={registerFormik.values.confirmPassword}
                        error={registerFormik.touched.confirmPassword && Boolean(registerFormik.errors.confirmPassword)}
                        helperText={registerFormik.touched.confirmPassword && registerFormik.errors.confirmPassword}
                        textBoxId="confirmPasswordTextbox"
                        type="password"
                        name="confirmPassword"
                        placeholderText="Confirm Password"
                      ></TextBox>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className="formItemGroup" item>
                  <Grid container className="formItemContainer">
                    <Grid className="formItem left" item>
                      <SelectBox
                        handleFormikChange={registerFormik.handleChange}
                        value={registerFormik.values.country}
                        error={registerFormik.touched.country && Boolean(registerFormik.errors.country)}
                        helperText={registerFormik.touched.country && registerFormik.errors.country}
                        reset={resetFormFlag}
                        initialValues={{name:"Country", value:AppConstants.SELECT_NONE.value}}
                        id="countrySelectBox"
                        inputProps={{
                          name: "country",
                          id: "countrySelectBox"
                        }}
                        items={filtersDropdowns.countriesList}
                      ></SelectBox>
                    </Grid>
                    <Grid className="formItem right" item>
                      <SelectBox
                        handleFormikChange={registerFormik.handleChange}
                        value={registerFormik.values.hub}
                        error={registerFormik.touched.hub && Boolean(registerFormik.errors.hub)}
                        helperText={registerFormik.touched.hub && registerFormik.errors.hub}
                        reset={resetFormFlag}
                        initialValues={{name:"Hub", value:AppConstants.SELECT_NONE.value}}
                        id="hubSelectBox"
                        inputProps={{
                          name: "hub",
                          id: "hubSelectBox"
                        }}
                        items={filtersDropdowns.hubList[registerFormik.values.country] || []}
                      ></SelectBox>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className="formItemGroup" item>
                  <Grid container className="formItemContainer">
                    <Grid className="formItem left" item>
                      <SelectBox
                        handleFormikChange={registerFormik.handleChange}
                        value={registerFormik.values.role}
                        error={registerFormik.touched.role && Boolean(registerFormik.errors.role)}
                        helperText={registerFormik.touched.role && registerFormik.errors.role}
                        reset={resetFormFlag}
                        initialValues={{name:"Role", value:AppConstants.SELECT_NONE.value}}
                        id="roleSelectBox"
                        inputProps={{
                          name: "role",
                          id: "roleSelectBox"
                        }}
                        items={transformSelectValues(AppConstants.USERS_CONSTANTS.SELF_REGISTER_ROLES)}
                      ></SelectBox>
                    </Grid>
                    <Grid className="formItem right" item>
                      <SelectBox
                        handleFormikChange={registerFormik.handleChange}
                        value={registerFormik.values.type}
                        error={registerFormik.touched.type && Boolean(registerFormik.errors.type)}
                        helperText={registerFormik.touched.type && registerFormik.errors.type}
                        reset={resetFormFlag}
                        initialValues={{name:"Type", value:AppConstants.SELECT_NONE.value}}
                        id="typeSelectBox"
                        inputProps={{
                          name: "type",
                          id: "typeSelectBox"
                        }}
                        items={AppConstants.LOGIN_CONSTANTS.TYPES}
                      ></SelectBox>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="registerItem" item>
              <Grid container className="buttonsContainer" direction="column">
                <Grid className="btnItem" item>
                  <Button variant="contained" className="loginBtn primary" type="submit">{AppConstants.BUTTONS.SUBMIT}</Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      {/* <CustomSnackbar open={openSnackbar} handleClose={handleSnackbarClose} onExited={handleSnackbarAfterExited} autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT} message={snackbarMessage.current} type={snackbarType.current} /> */}
    </Grid >
  )
}

export default UserRegister;