import React, { useEffect, useRef } from 'react';
import { useStyles } from './DatePickerComponent.styles';
import DateRangePicker, { Props, EventHandler } from 'react-bootstrap-daterangepicker';
import { FormControl, InputLabel } from '@material-ui/core';

interface DatePickerComponentProps extends Props {
  id: string;
  datePickerValue: string;
  placeholderText?: string;
  label: string;
  onChange: EventHandler;
}

const DatePickerComponent = (props: DatePickerComponentProps) => {

  const classes = useStyles();

  const dateRangeRef = useRef<DateRangePicker>(null);

  const { id, initialSettings, placeholderText, label, datePickerValue, onChange } = props;

  useEffect(() => {
    if (dateRangeRef.current) {
      dateRangeRef.current.setStartDate(initialSettings.startDate);
      dateRangeRef.current.setEndDate(initialSettings.endDate);
    }
  }, [initialSettings]);

  return (
    <FormControl className={classes.formControl} variant="outlined">
      <InputLabel htmlFor={id} shrink>{label}</InputLabel>
      <DateRangePicker
        initialSettings={initialSettings}
        ref={dateRangeRef}
        onApply={onChange}
        onEvent={onChange}
      >
        <input
          className="MuiOutlinedInput-root MuiOutlinedInput-input MuiOutlinedInput-notchedOutline"
          type="text"
          id={id}
          readOnly
          value={datePickerValue}
          placeholder={placeholderText}
        />
      </DateRangePicker>
    </FormControl>
  )

};

export default DatePickerComponent;