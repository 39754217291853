import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // width: "100%"
      '& .MuiTableSortLabel-root':{
        marginTop: 8
      }
    },
    headFilterIcon: {
      float: "right"
    }
  })
);
