import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    titleContainer: {
      '& .headingContainer': {
        flexDirection: "column",
        '& .titleHeading': {
          fontSize: 18,
          fontWeight: 500,
          '& .content': {
            fontWeight: 600
          }
        },
        '& .titleContent': {
          fontSize: 12,
          fontWeight: 600,
          '&.link': {
            cursor: "pointer"
          }
        }
      },
    },
    contentContainer: {
      flexDirection: "column",
      flexWrap: "nowrap",
      '& .detailsContent': {
        flexDirection: "column",
        flexWrap: "nowrap",
        margin: "auto",
        '& ul':{
          display: "inline",
          listStyle: "none",
          paddingInlineStart: 0,
          '& li': {
            width: "100%",
            backgroundColor: "#F5F5F5",
            borderRadius: 8,
            //marginBottom: 5
          }
        }
      },
    },
    sortableItem:{
      listStyle: "none",
      height: 40,
      backgroundColor: "#F5F5F5",
      zIndex: 2000,
      borderRadius: 10,
      marginBottom: 10,
      '& .item2': {
        paddingLeft: 12
      },
      '& .item3': {
        textAlign: "right"
      }
    },
    menuIcon:{
      margin: "6px 8px 0px 8px",
      height: 16
    },
    buttons: {
      width: "100%",
      '& .buttonsContainer': {
        '& .userBtn': {
          '&.secondary': {
            marginRight: theme.spacing(2)
          },
          flexGrow: 1
        }
      }
    },
    dialogPaperPropsRoot: {
      margin: 0,
      marginLeft: "auto",
      maxHeight: "none",
      height: "100vh",
      width: 380,
      '& .MuiDialogContent-root': {
        paddingTop: 0,
        paddingBottom: 0
      }
    },
    customDialog: {
      '& .MuiDialogActions-root': {
        padding: "20px",
        marginTop: 20,
        marginBottom: 10,
        '& .customBtn':{
          minWidth: 134,
          height: 40
        }
      },
      '& .MuiDialog-paper':{
        minWidth: 460
      },
      '& .MuiDialogTitle-root': {
        '& .MuiTypography-root':{
          fontSize: 18,
          fontWeight: 600,
          color: "#252525",
        }
      },
      '& .MuiDialogContentText-root': {
        fontSize: 18,
        fontWeight: 600,
        color: "#252525",
      },
      
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    }
  }),
);