import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import networkHandler from "../../network/networkHandler";
import { EApiMiddlewareMethods } from "../../network/networkHandler.types";
import { repushOrderAuthToken } from "../../config/Api.config";
import { saveFile, transformSelectValues, transformSelectValuesWithCode } from "../../utils/helpers.utils";
import { ReturnsListState } from "./returnsListSlice.types";
import AppConstants from "../../constants";

export const fetchOrderListReturn = createAsyncThunk(
  "ReturnsList/FetchByCountry",
  async (
    {
      payload,
      page,
      searchBy,
      searchValue,
    }: {
      payload?: any;
      page: number;
      searchBy: string;
      searchValue: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `orders/returns/${searchBy}`,
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      const { data } = await networkHandler(request, false, true);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchFiltersDropdownValues = createAsyncThunk(
  "ReturnsList/FetchFiltersDropdownValues",
  async (params, { rejectWithValue }) => {
    try {
      const request = {
        url: `filters/filter/all/RETURN`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, true);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchOrderListByFilterReturn = createAsyncThunk(
  "ReturnsList/FetchByFilter",
  async (
    {
      filters
    }: {
      filters: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'orders/returns/order/filter',
        method: EApiMiddlewareMethods.POST,
        data: filters
      } as any;
      const { data } = await networkHandler(request, false, true);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateExportToExcelPathReturn = createAsyncThunk(
  "ReturnsList/ExportToExcel",
  async (
    {
      payload,
    }: {
      payload: object;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'export/returns/excel',
        method: EApiMiddlewareMethods.POST,
        data: payload,
        responseType: "blob"
      } as any;
      const { data } = await networkHandler(request, false, true);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const clearListRetturn = createAsyncThunk("ReturnsList/ClearContents", () => {
  return [];
});

export const resetOrderListErrorReturn = createAsyncThunk("ReturnsList/ResetError", () => {
  return "";
});

export const resetReturnOrder = createAsyncThunk("ReturnsList/resetReturnOrder", () => {
  return "";
});

export const repushReturnOrder = createAsyncThunk(
  "ReturnsList/repushReturnOrder",
  async (
    {
      returnId
    }: {
      returnId: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `repush/returns/consignment/${returnId}`,
        method: EApiMiddlewareMethods.POST,
      } as any;
      request.headers = {
        'Authorization': repushOrderAuthToken,
      }
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const resetFilterReturnsBySearch = createAsyncThunk("ReturnsList/resetFilterReturnsBySearch", () => {
  return false;
});

const initialState: ReturnsListState = {
  loading: true,
  loader: false,
  tableLoading: true,
  filtersDropdowns: {
    countriesList: [],
    hubList: [],
    statusList: [],
    delPropositionList: []
  },
  exportToExcelPath: "",
  data: {
    content: [],
    pageNumber: 0,
    totalElements: 0
  },
  error: "",
  repushReturnFlag: false,
  repushReturnMsg: "",
  filterReturnsBySearch: false
};

const returnsListSlice = createSlice({
  name: "ReturnsList",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(fetchOrderListReturn.pending, (state) => {
        state.tableLoading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchOrderListReturn.fulfilled, (state, action) => {
        const {
          payload,
          meta: {
            arg: { page, searchValue, searchBy },
          },
        } = action;
        state.tableLoading = false;
        state.filterReturnsBySearch = true;
        state.errorCode = "";
        state.error = "";
        state.data = {
          ...payload,
          totalElements: payload.totalElements,
          content:
            page === 0
              ? [...payload.elements]
              : [...state.data.content, ...payload.elements],
        };
        state.prevCountry =
          searchBy === "country" ? searchValue : state.prevCountry;
      })
      .addCase(fetchOrderListReturn.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.tableLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchFiltersDropdownValues.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchFiltersDropdownValues.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        let hubList: any = {};
        let statusList: any = {};
        let delPropositionList: any = {};
        if (payload.hubMap) {
          Object.keys(payload.hubMap).filter((country: any) => {
            hubList[country] = transformSelectValuesWithCode(payload.hubMap[country]);
            return true;
          });
        }
        if (payload.statusMap) {
          Object.keys(payload.statusMap).filter((country: any) => {
            statusList[country] = transformSelectValues(payload.statusMap[country]);
            return true;
          });
        }
        if (payload.deliveryPropositionMap) {
          Object.keys(payload.deliveryPropositionMap).filter((country: any) => {
            delPropositionList[country] = transformSelectValues(payload.deliveryPropositionMap[country]);
            return true;
          });
        }
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.filtersDropdowns = {
          countriesList: transformSelectValues(payload.countries),
          hubList: hubList,
          statusList: statusList,
          delPropositionList: delPropositionList
        };
      })
      .addCase(fetchFiltersDropdownValues.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(clearListRetturn.fulfilled, (state, action) => {
        const { payload } = action;
        state.tableLoading = false;
        state.errorCode = "";
        state.error = "";
        state.data = {
          ...payload,
          content: action.payload,
        };
      })
      .addCase(resetOrderListErrorReturn.fulfilled, (state, action) => {
        const { payload } = action;
        state.errorCode = payload;
        state.error = payload;
      })
      .addCase(fetchOrderListByFilterReturn.pending, (state) => {
        state.tableLoading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchOrderListByFilterReturn.fulfilled, (state, action) => {
        const {
          payload,
          meta: {
            arg: { filters }
          }
        } = action;
        state.tableLoading = false;
        state.filterReturnsBySearch = false;
        state.errorCode = "";
        state.error = "";
        state.data = {
          ...payload,
          totalElements: payload.totalElements,
          content:
            filters.page === 0
              ? [...payload.elements]
              : [...state.data.content, ...payload.elements],
        };
      })
      .addCase(fetchOrderListByFilterReturn.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.tableLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(updateExportToExcelPathReturn.pending, (state) => {
        state.loader = true;
        state.error = "";
      })
      .addCase(updateExportToExcelPathReturn.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loader = false;
        state.errorCode = "";
        state.error = "";
        if (payload) {
          saveFile(payload, AppConstants.RETURN_CONSTANTS.EXCEL_FILE_NAME);
        }
      })
      .addCase(updateExportToExcelPathReturn.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loader = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(repushReturnOrder.pending, (state) => {
        state.repushReturnFlag = false;
        state.repushReturnMsg = "";
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(repushReturnOrder.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        if (!payload) {
          state.errorCode = "";
          state.error = AppConstants.CONSIGNMENT_CONSTANTS.REPUSHED_ORDER_CONTENT_NOT_FOUND;
        }else if (payload.error) {
          state.errorCode = payload.error || AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
          state.error = payload
            ? payload.message
            : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        } else {
          state.errorCode = "";
          state.error = "";
          state.repushReturnFlag = true;
          state.repushReturnMsg = AppConstants.CONSIGNMENT_CONSTANTS.ORDER_REPUSHED_MSG;
        }
      })
      .addCase(repushReturnOrder.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.repushReturnFlag = false;
        state.repushReturnMsg = "";
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(resetReturnOrder.fulfilled, (state, action) => {
        state.repushReturnFlag = false;
        state.repushReturnMsg = "";
      })
      .addCase(resetFilterReturnsBySearch.fulfilled, (state, action) => {
        const { payload } = action;
        state.filterReturnsBySearch = payload;
      });
  },
});

export default returnsListSlice.reducer;
