import { makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../../config/theme/baseTheme";


export const useStyles = makeStyles((theme: Theme) => ({
  arrow: {
    color: COLORS.BLACK,
  },
  tooltip: {
    backgroundColor: COLORS.BLACK,
  },
}));