import React, { useCallback, useEffect, forwardRef, ReactElement, Ref, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as yup from "yup";
import { useFormik } from "formik";

import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContent, DialogTitle, IconButton, Slide, Grid, Typography, Accordion, AccordionSummary, AccordionDetails, SvgIcon, Collapse, Link, Button, Divider } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from "@material-ui/lab";

import errorIcon from "./../../src/assets/icons/error.png";
import mobileLogoIcon from "./../assets/icons/mobileLogo.svg";
import emailLogoIcon from "./../assets/icons/emailLogo.svg";
import { ReactComponent as activeTimelineDot } from "./../assets/icons/active-timeline-dot.svg";
import { ReactComponent as errorTimelineDot } from "./../assets/icons/timeline-dot-error.svg";
import { ReactComponent as timelineDot } from "./../assets/icons/timeline-dot.svg";
import { ReactComponent as downArrowIcon } from "./../assets/icons/down-arrow.svg";
import { ReactComponent as pencilIcon } from "./../assets/icons/pencilIcon.svg";
import { ReactComponent as successIcon } from "./../../src/assets/icons/success.svg";

import { useStyles } from "./ConsignmentDetails.styles";
import { COLORS } from "../config/theme/baseTheme";
import {
  repushOrder,
  openTheHatch,
  resetOpenHatch,
  reassignCarrier,
  fetchDeliveryReasons,
  fetchOrderDeliveryStatuses,
  resetReasonsDropdownList,
  resetOrderDeliveryStatuses,
  updateOrderStatus,
} from "./redux/orderListSlice";
import { resetRepushEnabledFlag } from "../ExceptionsDelivery/redux/exceptionsDeliverySlice";
import { AppState } from "../config/redux/reducers";
import { getDateStringFromUTC, getTimeStringFromUTC, getDayAndMonthName, isPostPaidOrder, isUndefined, isNull, isNonEmptyObject } from "../utils/helpers.utils";
import { ConsignmentDetailsStateInterface } from "./redux/orderListSlice.types";

import CustomTooltip from "../common/Tooltip";
import MapContainer from "../common/GoogleMapContainer";
import SelectBox from "../common/SelectBox";
import AppConstants from "../constants";
import CustomDialog from "../common/Dialog";
import RenderConsignmentEdit from "./RenderConsignmentEdit";
import CustomAlertbar from "../common/CustomAlertbar";

interface ConsignmentDetailsProps {
  pageType: string;
  open: boolean;
  consignmentData: any;
  closePopup: (consignment?: any) => void;
  handleEditAction?: () => void;
}

const Transition = forwardRef(function Transition(props: TransitionProps & { children?: ReactElement<any, any> }, ref: Ref<unknown>) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ConsignmentDetails = (props: ConsignmentDetailsProps) => {
  const { pageType, open, consignmentData, closePopup, handleEditAction } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const { loading, openHatchSuccess, openHatchError, openHatchMsg, reasonsDropdownList, orderDeliveryStatuses, deliveryReasons, deliveryError } = useSelector((state: AppState) => state.orderList);
  const { repushEnabled } = useSelector((state: AppState) => state.exceptionsDelivery);
  const { isAdmin } = useSelector((state: AppState) => state.users);

  const dialogPaperProps = { classes: { root: classes.dialogPaperPropsRoot }, square: true };

  const [toggleCustMap, setToggleCustMap] = useState(false);
  const [toggleSellerMap, setToggleSellerMap] = useState(false);
  const [openCarrierReassign, setOpenCarrierReassign] = useState(false);
  const [resetFormFlag, setResetFormFlag] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [toggleOrderPlaced, setToggleOrderPlaced] = useState(true);
  const [toggleOrderPrepared, setToggleOrderPrepared] = useState(false);
  const [toggleOrderOnTheWay, setToggleOrderOnTheWay] = useState(false);
  const [toggleOrderDelivered, setToggleOrderDelivered] = useState(false);
  const [toggleOrderDeliveryFailed, setToggleOrderDeliveryFailed] = useState(false);
  const [toggleOrderCancelled, setToggleOrderCancelled] = useState(false);
  const [orderPlacedItemStyle, setOrderPlacedItemStyle] = useState({ display: "none" });
  const [orderPreparedItemStyle, setOrderPreparedItemStyle] = useState({ display: "none" });
  const [orderOnTheWayItemStyle, setOrderOnTheWayItemStyle] = useState({ display: "none" });
  const [orderDeliveredItemStyle, setOrderDeliveredItemStyle] = useState({ display: "none" });
  const [orderDeliveryFailedItemStyle, setOrderDeliveryFailedItemStyle] = useState({ display: "none" });
  const [orderCancelledItemStyle, setOrderCancelledItemStyle] = useState({ display: "none" });
  const [viewMoreOrderPlaced, setViewMoreOrderPlaced] = useState(false);
  const [viewMoreOrderPrepared, setViewMoreOrderPrepared] = useState(false);
  const [viewMoreOrderOnTheWay, setViewMoreOrderOnTheWay] = useState(false);
  const [viewMoreOrderDelivered, setViewMoreOrderDelivered] = useState(false);
  const [viewMoreOrderDeliveryFailed, setViewMoreOrderDeliveryFailed] = useState(false);
  const [viewMoreOrderCancelled, setViewMoreOrderCancelled] = useState(false);
  const [consignmentDetailsState, setConsignmentDetailsState] = useState<ConsignmentDetailsStateInterface>({
    consignment: {},
    custGeoLocation: { name: "", location: { lat: 0, lng: 0 } },
    sellerGeoLocation: { name: "", location: { lat: 0, lng: 0 } },
    lastStatusUpdate: "",
    trackingStatus: {},
    //
    openConsignmentEditPopup: false,
    selectedStatus: AppConstants.CONSIGNMENT_CONSTANTS.EDIT_CONSIGNMENT_DELIVERY.DEFAULT_DELIVERY_EDIT_SELECTION,
    selectedReason: AppConstants.SELECT_NONE.value,
    selectedReasonError: "",
    commentsValue: "",
    commentsValueError: "",
    openSnackbar: false,
    snackbarMessage: "",
    snackbarType: AppConstants.SNACKBAR.TYPES.SUCCESS,
  });

  const checkIfOneclickOrWings = (carrierName: string = "") => {
    let carrier = carrierName ? carrierName : consignmentDetailsState?.consignment?.deliveryInformation?.carrier ? consignmentDetailsState.consignment.deliveryInformation.carrier : "";
    const statusName = carrier && carrier.toLowerCase();
    if (statusName === "oneclick" || statusName === "wings") {
      return true;
    }
    return false;
  };

  const handleTrackingLink = () => {
    let carrierName = consignmentDetailsState?.consignment?.deliveryInformation?.carrier ? consignmentDetailsState.consignment.deliveryInformation.carrier : "";
    if (checkIfOneclickOrWings(carrierName)) {
      window.open(consignmentDetailsState?.consignment?.deliveryInformation?.carrierTrackingUrl, "_blank");
    } else {
      setOpenDialog(true);
    }
  };

  const handleOldCarrierTracking = () => {
    if (consignmentDetailsState?.consignment?.deliveryInformation?.logisticTrackingUrl) {
      window.open(consignmentDetailsState?.consignment?.deliveryInformation?.logisticTrackingUrl, "_blank");
    }
  };

  const handleRepushOrder = () => {
    let payloadData = { repushType: "SINGLE", repushData: [{ consignmentCode: consignmentDetailsState?.consignment?.consignmentCode }] };
    dispatch(repushOrder({ payload: payloadData }));
  };

  const handleOpenTheHatch = () => {
    dispatch(
      openTheHatch({
        localizedLang: "en",
        consignmentCode: consignmentDetailsState?.consignment?.consignmentCode ? consignmentDetailsState.consignment.consignmentCode : "",
        vehicleId: consignmentDetailsState?.consignment?.deliveryInformation?.vehicleId ? consignmentDetailsState.consignment.deliveryInformation.vehicleId : "",
      })
    );
    const timer = setTimeout(() => {
      dispatch(resetOpenHatch());
    }, AppConstants.SNACKBAR.AUTO_HIDE_STATUS_TIMEOUT);
    return () => clearTimeout(timer);
  };

  const carrierArr = [{ name: "oneClick", value: "oneclick" }];
  const vehicleTypeArr = [
    { name: "Bike", value: "BIKE" },
    { name: "Car", value: "CAR" },
  ];

  const getInitialValues = () => {
    return { carrierName: "", vehicleType: "" };
  };

  const inputValidationSchema = yup.object({
    carrierName: yup.string().required(AppConstants.CONSIGNMENT_CONSTANTS.VALIDATIONS.CARRIER_NAME.REQUIRED.msg),
    vehicleType: yup.string().required(AppConstants.CONSIGNMENT_CONSTANTS.VALIDATIONS.VEHICLE_TYPE.REQUIRED.msg),
  });

  const inputFormik = useFormik({
    initialValues: getInitialValues(),
    validationSchema: inputValidationSchema,
    validate: () => {
      setResetFormFlag(false);
    },
    onSubmit: (values) => {
      dialogActionHandler("submit", values);
    },
  });

  const dialogActionHandler = (type: string, values?: any) => {
    dispatch(reassignCarrier({ toCarrier: values.carrierName, consignmentCode: consignmentDetailsState?.consignment?.consignmentCode, vehicleType: values.vehicleType }));
    const timer = setTimeout(() => {
      dispatch(resetOpenHatch());
    }, AppConstants.SNACKBAR.AUTO_HIDE_STATUS_TIMEOUT);
    return () => clearTimeout(timer);
  };

  const openReassignCarrier = useCallback(() => {
    setOpenCarrierReassign(true);
    setResetFormFlag(true);
    inputFormik.resetForm();
  }, [inputFormik]);

  const handleCloseReassign = () => {
    setOpenCarrierReassign(false);
  };

  const canOpenTheHatch = (statusCode: string) => {
    const openHatchStatuses: any = AppConstants.CONSIGNMENT_CONSTANTS.OPEN_HATCH_STATUSES;
    const statusName = statusCode && statusCode.toLowerCase();
    let canAcces = openHatchStatuses.includes(statusName);
    return canAcces;
  };

  const canReassignCarrier = (statusCode: string) => {
    const reassignCarrierStatuses: any = AppConstants.CONSIGNMENT_CONSTANTS.REASSIGN_CARRIER_STATUSES;
    const statusName = statusCode && statusCode.toLowerCase();
    let canAcces = reassignCarrierStatuses.includes(statusName);
    return canAcces;
  };

  const getStatusField = (fieldName: string, statusCode: string) => {
    const statuses: any = AppConstants.CONSIGNMENT_CONSTANTS.STATUSES;
    const statusName = statusCode && statusCode.toLowerCase().replace(/\s/gm, "_");
    return statusCode && statuses[statusName] ? statuses[statusName][fieldName] : "";
  };

  const hasRepushAccess = (statusCode: string) => {
    const statuses: any = AppConstants.CONSIGNMENT_CONSTANTS.REPUSH_STATUSES;
    //const usersHasAccess: any = AppConstants.CONSIGNMENT_CONSTANTS.REPUSH_ACESSS_USERS;
    let isStatusExists = statuses.includes(statusCode);
    //let loggedInUser = sessionStorage.getItem('username') ? sessionStorage.getItem('username') : '';
    //let isLoggedInUserHasAccess = usersHasAccess.includes(loggedInUser);
    if (isStatusExists) {
      return true;
    }
    return false;
  };

  const isFailedStatus = (statusCode: string) => {
    const statuses: any = AppConstants.CONSIGNMENT_CONSTANTS.FAILED_STATUSES;
    let isStatusExists = statuses.includes(statusCode);
    if (isStatusExists) {
      return true;
    }
    return false;
  };

  const getStatusType = (errors: any, statusCode: string, index: number) => {
    if (errors && errors.length && isFailedStatus(statusCode)) {
      return errorTimelineDot;
    } else if (index === 0) {
      return activeTimelineDot;
    } else {
      return timelineDot;
    }
  };

  const getTimelineViewBox = (errors: any, statusCode: string, index: number) => {
    if (errors && errors.length && isFailedStatus(statusCode)) {
      return "0 0 18 18";
    } else if (index === 0) {
      return "0 0 18 18";
    } else {
      return "0 0 8 8";
    }
  };

  const showEditIconToMarkDeliveryFailed = () => {
    if (
      isAdmin &&
      consignmentDetailsState?.consignment?.terminalStatusReached === false &&
      consignmentDetailsState?.consignment?.statuses?.length > 0 &&
      consignmentDetailsState?.consignment?.statuses?.findIndex((obj: any) => obj?.statusName.toLowerCase() === AppConstants.CONSIGNMENT_CONSTANTS.DISPATCHED) !== -1 &&
      consignmentDetailsState?.consignment?.deliveryInformation?.carrier.toLowerCase() === AppConstants.CONSIGNMENT_CONSTANTS.I_MILE
    ) {
      return true;
    }
    return false;
  };

  const handleDeliveryEditPopUp = () => {
    setConsignmentDetailsState({
      ...consignmentDetailsState,
      openConsignmentEditPopup: true,
      selectedStatus: AppConstants.CONSIGNMENT_CONSTANTS.EDIT_CONSIGNMENT_DELIVERY.DEFAULT_DELIVERY_EDIT_SELECTION,
      selectedReason: AppConstants.SELECT_NONE.value,
      selectedReasonError: "",
      commentsValue: "",
      commentsValueError: "",
    });
  };

  const closeDeliveryEditPopUp = () => {
    dispatch(resetOrderDeliveryStatuses());
    dispatch(resetReasonsDropdownList());
    setConsignmentDetailsState({ ...consignmentDetailsState, openConsignmentEditPopup: false });
  };

  const handleStatusChange = (value: any) => {
    setConsignmentDetailsState({
      ...consignmentDetailsState,
      selectedStatus: value,
      selectedReason: AppConstants.SELECT_NONE.value,
      selectedReasonError: "",
      commentsValue: "",
      commentsValueError: "",
    });
  };

  const handleReasonChange = (value: any) => {
    setConsignmentDetailsState({ ...consignmentDetailsState, selectedReason: value, selectedReasonError: "", commentsValue: "", commentsValueError: "" });
  };

  const handleCommentsTextChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!isUndefined(event?.target?.value)) {
      setConsignmentDetailsState({ ...consignmentDetailsState, commentsValue: event?.target?.value, commentsValueError: "" });
    }
  };

  const validateDeliveryStatus = () => {
    let newErrors = {};
    if (isNull(consignmentDetailsState.selectedReason)) {
      newErrors = { ...newErrors, selectedReasonError: AppConstants.CONSIGNMENT_CONSTANTS.EDIT_CONSIGNMENT_DELIVERY.PLEASE_SELECT_REASON };
    } else if (consignmentDetailsState.selectedReason === AppConstants.SELECT_NONE.value) {
      newErrors = { ...newErrors, selectedReasonError: AppConstants.CONSIGNMENT_CONSTANTS.EDIT_CONSIGNMENT_DELIVERY.PLEASE_SELECT_REASON };
    }

    if (!isNull(consignmentDetailsState.selectedReason) && consignmentDetailsState.selectedReason === AppConstants.CONSIGNMENT_CONSTANTS.EDIT_CONSIGNMENT_DELIVERY.OTHER_REASON_CODE) {
      if (isNull(consignmentDetailsState.commentsValue)) {
        newErrors = { ...newErrors, commentsValueError: AppConstants.CONSIGNMENT_CONSTANTS.EDIT_CONSIGNMENT_DELIVERY.PLEASE_PROVIDE_COMMENTS };
      }
    }

    setConsignmentDetailsState({ ...consignmentDetailsState, ...newErrors });
    return Object.keys(newErrors).length;
  };

  const dialogDeliveryFailedActionHandler = async () => {
    if (validateDeliveryStatus() === 0) {
      const payload = {
        consignmentCode: consignmentDetailsState?.consignment?.consignmentCode,
        status: consignmentDetailsState.selectedStatus,
        reason: { comment: consignmentDetailsState.commentsValue, reasonCode: consignmentDetailsState.selectedReason, reasonDescription: "" },
      };
      const deliveryReason = deliveryReasons.filter((reason: any) => reason.reasonCode === consignmentDetailsState.selectedReason);
      if (deliveryReason?.length > 0 && !isUndefined(deliveryReason[0]?.reasonDescription) && !isNull(deliveryReason[0]?.reasonDescription)) {
        payload.reason.reasonDescription = deliveryReason[0].reasonDescription;
      }
      const response: any = await dispatch(updateOrderStatus({ params: payload }));
      if (response?.payload?.orderCode) {
        setConsignmentDetailsState({
          ...consignmentDetailsState,
          consignment: response?.payload,
          custGeoLocation: getCustGeoLocation(response?.payload),
          sellerGeoLocation: getSellerGeoLocation(response?.payload),
          lastStatusUpdate: getLastStatusUpdate(response?.payload),
          trackingStatus: getTrackingStatus(response?.payload),
          openConsignmentEditPopup: false,
          openSnackbar: true,
          snackbarMessage: AppConstants.CONSIGNMENT_CONSTANTS.EDIT_CONSIGNMENT_DELIVERY.STATUS_UPDATED_SUCCESSFULLY,
          snackbarType: AppConstants.SNACKBAR.TYPES.SUCCESS,
        });
      }
    }
  };

  const getDeliveryDetailsContent = () => {
    return (
      <RenderConsignmentEdit
        classes={classes}
        loading={loading}
        consignmentDetailsState={consignmentDetailsState}
        orderDeliveryStatuses={orderDeliveryStatuses}
        reasonsDropdownList={reasonsDropdownList}
        handleStatusChange={handleStatusChange}
        handleReasonChange={handleReasonChange}
        handleCommentsTextChange={handleCommentsTextChange}
      />
    );
  };

  const getItems = () => {
    return consignmentDetailsState?.consignment?.products?.length > 0 ? (
      consignmentDetailsState?.consignment?.products?.map((item: any, index: number) => (
        <React.Fragment key={`${item?.name?.en} - ${index}`}>
          <Grid key={`${item?.name?.en} - ${index}`} className={`item ${item?.substitutedProducts?.length ? "substituteItem" : ""}`} item>
            {item?.substitutedProducts?.length > 0 ? (
              <>
                <Grid className="itemContainer substitutedLine" container>
                  <Grid className="itemColumn item1" item xs={3}>
                    <Typography>{item?.name?.en && item?.name?.en.toLowerCase()}</Typography>
                  </Grid>
                  <Grid className="itemColumn item2" item xs={2}>
                    <Typography>
                      <span>
                        {item?.quantity || "-"}
                        {item?.uom && item.uom === "Grams" ? <br /> : null}
                        {`${item?.quantity && item.quantity !== "0" && item?.uom && item.uom === "Grams" ? item.uom : ""}`}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid className="itemColumn item3" item xs={2}>
                    <Typography>
                      <span className="green">-</span>
                    </Typography>
                  </Grid>
                  <Grid className="itemColumn item4" item xs={2}>
                    <Typography>
                      <span className="red">-</span>
                    </Typography>
                  </Grid>
                  <Grid className="itemColumn item5" item xs={3}>
                    <Typography>{`${item.unitPrice} ${consignmentDetailsState?.consignment?.metadata?.currency}`}</Typography>
                  </Grid>
                </Grid>
                {item?.substitutedProducts?.length > 0 ? (
                  <Grid className="substitutedContent">
                    <Typography className="substitutedTxt">Substituted</Typography>
                    {item.substitutedProducts.map((subItem: any, subIndex: number) => (
                      <Grid key={`${subItem?.name?.en} - ${subIndex}`} className="itemContainer" container>
                        <Grid className="itemColumn item1" item xs={3}>
                          <Typography>{subItem?.name?.en && subItem?.name?.en.toLowerCase()}</Typography>
                        </Grid>
                        <Grid className="itemColumn item2" item xs={2}>
                          <Typography>
                            <span>-</span>
                          </Typography>
                        </Grid>
                        <Grid className="itemColumn item3" item xs={2}>
                          <Typography>
                            <span className="green">
                              {subItem?.shippedQty || "-"}
                              {item?.uom && item.uom === "Grams" ? <br /> : null}
                              {`${subItem?.shippedQty && subItem.shippedQty !== "0" && item?.uom && item.uom === "Grams" ? item.uom : ""}`}
                            </span>
                          </Typography>
                        </Grid>
                        <Grid className="itemColumn item4" item xs={2}>
                          <Typography>
                            <span className="red">
                              {subItem?.rejectedQty || "-"}
                              {item?.uom && item.uom === "Grams" ? <br /> : null}
                              {`${subItem?.rejectedQty && subItem.rejectedQty !== "0" && item?.uom && item.uom === "Grams" ? item.uom : ""}`}
                            </span>
                          </Typography>
                        </Grid>
                        <Grid className="itemColumn item5" item xs={3}>
                          <Typography>{`${subItem.unitPrice || "-"} ${consignmentDetailsState?.consignment?.metadata?.currency}`}</Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                ) : null}
              </>
            ) : (
              <Grid className="itemContainer" container>
                <Grid className="itemColumn item1" item xs={3}>
                  <Typography>{item?.name?.en && item?.name?.en.toLowerCase()}</Typography>
                </Grid>
                <Grid className="itemColumn item2" item xs={2}>
                  <Typography>
                    <span>
                      {item.quantity || "-"}
                      {item.uom && item.uom === "Grams" ? <br /> : null}
                      {`${item.quantity && item.quantity !== "0" && item.uom && item.uom === "Grams" ? item.uom : ""}`}
                    </span>
                  </Typography>
                </Grid>
                <Grid className="itemColumn item3" item xs={2}>
                  <Typography>
                    <span className="green">
                      {item.shippedQty || "-"}
                      {item.uom && item.uom === "Grams" ? <br /> : null}
                      {`${item.shippedQty && item.shippedQty !== "0" && item.uom && item.uom === "Grams" ? item.uom : ""}`}
                    </span>
                  </Typography>
                </Grid>
                <Grid className="itemColumn item4" item xs={2}>
                  <Typography>
                    <span className="red">
                      {item.rejectedQty || "-"}
                      {item.uom && item.uom === "Grams" ? <br /> : null}
                      {`${item.rejectedQty && item.rejectedQty !== "0" && item.uom && item.uom === "Grams" ? item.uom : ""}`}
                    </span>
                  </Typography>
                </Grid>
                <Grid className="itemColumn item5" item xs={3}>
                  <Typography>{`${item.unitPrice} ${consignmentDetailsState?.consignment?.metadata?.currency}`}</Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
          {consignmentDetailsState?.consignment?.products?.length - 1 !== index ? <Divider className="itemDivider"></Divider> : null}
        </React.Fragment>
      ))
    ) : (
      <Typography>No Items to display</Typography>
    );
  };

  const getHistoryTimelineItems = () => {
    return (
      <>
        {consignmentDetailsState?.consignment?.statuses?.length > 0 ? (
          consignmentDetailsState?.consignment?.statuses?.map((item: any, index: number) => (
            <TimelineItem key={`${item.statusCode} - ${index}`} className="timelineItem">
              <TimelineSeparator>
                {index === 0 ? (
                  <TimelineDot className="timelineDot">
                    <SvgIcon className="dotIcon active" component={getStatusType(consignmentDetailsState?.consignment?.errors, item.statusCode, index)} viewBox="0 0 18 18" />
                  </TimelineDot>
                ) : (
                  <TimelineDot className="timelineDot">
                    <SvgIcon
                      className="dotIcon"
                      component={getStatusType(consignmentDetailsState?.consignment?.errors, item.statusCode, index)}
                      viewBox={getTimelineViewBox(consignmentDetailsState?.consignment?.errors, item.statusCode, index)}
                    />
                  </TimelineDot>
                )}
                {index !== consignmentDetailsState?.consignment?.statuses?.length - 1 && <TimelineConnector className="primaryConnector" />}
              </TimelineSeparator>
              {isFailedStatus(item.statusCode) ? (
                <TimelineContent className="timelineContent">
                  <Typography className="heading error active">
                    <span className="status">{`${item.statusCode} - ${item.statusName} (${consignmentDetailsState?.consignment?.errors?.length})`}</span>
                    <span className="dateTime">{`(${getDateStringFromUTC(item.statusDate, consignmentDetailsState?.consignment?.metadata?.timeZone)} at ${getTimeStringFromUTC(
                      item.statusDate,
                      consignmentDetailsState?.consignment?.metadata?.timeZone
                    )})`}</span>
                  </Typography>
                  {consignmentDetailsState?.consignment?.errors?.map((errorItem: any, index: number) => (
                    <Typography key={`errorMetadata-${index}`} component="div" className="metadataContainer">
                      <Typography className="title">{`${errorItem.errorCode} - ${errorItem.message}`}</Typography>
                      <Typography className="description">{`By System on (${getDateStringFromUTC(errorItem.date, consignmentDetailsState?.consignment?.metadata?.timeZone)} at ${getTimeStringFromUTC(
                        errorItem.date,
                        consignmentDetailsState?.consignment?.metadata?.timeZone
                      )})`}</Typography>
                    </Typography>
                  ))}
                  {item?.reason && (
                    <Typography component="div" className="metadataContainer">
                      <Typography className="title">{`${item?.reason?.reasonCode ? item.reason.reasonCode + " - " : ""} ${item?.reason?.reasonDescription || ""} ${!isUndefined(item?.reason?.comment) && !isNull(item?.reason?.comment) ? " - " : ""} ${item?.reason?.comment || ""}`}</Typography>
                    </Typography>
                  )}
                </TimelineContent>
              ) : (
                <TimelineContent className="timelineContent">
                  <Typography className={["heading", index === 0 ? "active" : ""].join(" ")}>
                    <span className="status">
                      {item.statusCode} - {item.statusName}
                    </span>
                    <span className="dateTime">{`(${getDateStringFromUTC(item.statusDate, consignmentDetailsState?.consignment?.metadata?.timeZone)} at ${getTimeStringFromUTC(
                      item.statusDate,
                      consignmentDetailsState?.consignment?.metadata?.timeZone
                    )})`}</span>
                  </Typography>
                  {item?.reason && (
                    <Typography component="div" className="metadataContainer">
                      <Typography className="title">{`${item?.reason?.reasonCode ? item.reason.reasonCode + " - " : ""} ${item?.reason?.reasonDescription || ""} ${!isUndefined(item?.reason?.comment) && !isNull(item?.reason?.comment) ? " - " : ""} ${item?.reason?.comment || ""}`}</Typography>
                    </Typography>
                  )}
                </TimelineContent>
              )}
            </TimelineItem>
          ))
        ) : (
          <Typography>No Items to display</Typography>
        )}
      </>
    );
  };

  const getTitleContent = () => {
    return (
      <Grid className={classes.titleContainer} container>
        <Grid item>
          <Grid className="headingContainer" container>
            <Grid item>
              <Grid container className={classes.titleDivGridContainer}>
                <Grid item className={classes.titleHeadingGridContainer}>
                  <Typography className="titleHeading">
                    <span className="label"> {`${consignmentDetailsState.openConsignmentEditPopup ? "EDIT CONSIGNMENT ID : " : "CONSIGNMENT ID : "} `} </span>
                    <span className="content"> {consignmentDetailsState?.consignment?.consignmentCode || ""} </span>
                  </Typography>
                </Grid>
                <CustomTooltip title={getStatusField("hintText", consignmentDetailsState?.consignment?.metadata?.displayStatus)}>
                  <Grid className="tagContainer" item>
                    <Typography className={["tag", getStatusField("color", consignmentDetailsState?.consignment?.metadata?.displayStatus)].join(" ")}>
                      {consignmentDetailsState?.consignment?.metadata
                        ? consignmentDetailsState?.consignment?.metadata?.displayStatus
                          ? consignmentDetailsState.consignment.metadata.displayStatus
                          : consignmentDetailsState?.consignment?.metadata?.status || ""
                        : ""}
                    </Typography>
                  </Grid>
                </CustomTooltip>
                {!consignmentDetailsState.openConsignmentEditPopup && showEditIconToMarkDeliveryFailed() && (
                  <SvgIcon className="pencilIconStyle" viewBox="0 0 24 24" component={pencilIcon} onClick={handleDeliveryEditPopUp} />
                )}
              </Grid>
            </Grid>
            {!consignmentDetailsState.openConsignmentEditPopup && (
              <>
                {consignmentDetailsState?.consignment?.deliveryInformation?.carrierTrackingId || consignmentDetailsState?.consignment?.orderReference ? (
                  <Grid item>
                    {consignmentDetailsState?.consignment?.deliveryInformation?.carrierTrackingUrl ? (
                      <Link className="titleContent link" onClick={handleTrackingLink}>{`Track Consignment (#${
                        consignmentDetailsState?.consignment?.deliveryInformation?.carrierTrackingId
                          ? consignmentDetailsState.consignment.deliveryInformation.carrierTrackingId
                          : consignmentDetailsState?.consignment?.orderReference
                          ? consignmentDetailsState.consignment.orderReference
                          : ""
                      })`}</Link>
                    ) : consignmentDetailsState?.consignment?.deliveryInformation && !checkIfOneclickOrWings() ? (
                      <Link className="titleContent link" onClick={handleTrackingLink}>{`Track Consignment (#${
                        consignmentDetailsState?.consignment?.deliveryInformation?.carrierTrackingId
                          ? consignmentDetailsState.consignment.deliveryInformation.carrierTrackingId
                          : consignmentDetailsState?.consignment?.orderReference
                          ? consignmentDetailsState.consignment.orderReference
                          : ""
                      })`}</Link>
                    ) : (
                      <Typography className="titleContent">{`Track Consignment (#${
                        consignmentDetailsState?.consignment?.deliveryInformation?.carrierTrackingId
                          ? consignmentDetailsState.consignment.deliveryInformation.carrierTrackingId
                          : consignmentDetailsState?.consignment?.orderReference
                          ? consignmentDetailsState.consignment.orderReference
                          : ""
                      })`}</Typography>
                    )}
                  </Grid>
                ) : (
                  ""
                )}
                {!checkIfOneclickOrWings() && consignmentDetailsState?.consignment?.deliveryInformation?.logisticTrackingUrl && (
                  <Grid item>
                    <Link className="titleContent link" onClick={handleOldCarrierTracking}>{`Carrier Tracking (#${
                      consignmentDetailsState?.consignment?.deliveryInformation?.carrierTrackingId
                        ? consignmentDetailsState.consignment.deliveryInformation.carrierTrackingId
                        : consignmentDetailsState?.consignment?.orderReference
                        ? consignmentDetailsState.consignment.orderReference
                        : ""
                    })`}</Link>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getDetailsContent = () => {
    return (
      <Grid className={classes.contentContainer} container>
        {(openHatchSuccess || openHatchError) && (
          <Grid item>
            <Grid className="snackBarContainer" container direction="column">
              {openHatchError && (
                <Grid className="snackBarErrorMessage" item>
                  <Grid className={`${classes.statusMessageImg}`}>
                    <img alt="errorIcon" src={errorIcon} />
                  </Grid>
                  <Grid className="errorTag">{openHatchMsg}</Grid>
                </Grid>
              )}
              {openHatchSuccess && (
                <Grid className="snackBarSuccessMessage" item>
                  <Grid className={`${classes.statusMessageIcon}`}>
                    <SvgIcon className={`${classes.iconStyle}`} viewBox="0 0 285 50" component={successIcon} />
                  </Grid>
                  <Grid className="successTag">{openHatchMsg}</Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
        {!repushEnabled && pageType === "exceptions" && (
          <Grid item>
            <Grid className="snackBarContainer" container direction="column">
              <Grid className="snackBarDetails" item>
                <Grid className="tag">You have to update few details to repush the order</Grid>
                <Grid className="action" onClick={() => handleEditAction && handleEditAction()}>
                  Edit
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid className="customerDetails" item>
          <Grid className="detailsHeader detailsHeaderLeftSection" container>
            <Grid className="addressContainer" container>
              <Grid className="detailsHeaderItem" item>
                <Typography className="addressTitle">Customer Details {consignmentDetailsState?.consignment?.addressGeoCoded ? <span className="geocoded">(GeoCoded)</span> : ""}</Typography>
              </Grid>
              <Grid className="detailsHeaderItem" item>
                <Typography className="name">{consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.name}</Typography>
                <Typography>
                  <span className="addressinfo">Flat/Villa no</span>: {consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address?.apartment || ""}
                </Typography>
                <Typography>
                  <span className="addressinfo">Building name/no</span>: {consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address?.building || ""}
                </Typography>
                <Typography>
                  <span className="addressinfo">Street Name/no</span>:
                  {consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address?.streetName
                    ? `${consignmentDetailsState.consignment.deliveryInformation.customerContactInfo.address.streetName} ${
                        consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address?.streetNumber
                          ? `/ ${consignmentDetailsState.consignment.deliveryInformation.customerContactInfo.address.streetNumber}`
                          : ""
                      }`
                    : ""}
                </Typography>
                {!consignmentDetailsState?.consignment?.addressGeoCoded ? (
                  <>
                    <Typography>
                      <span className="addressinfo">Area</span>:{consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address?.area || ""}
                    </Typography>
                    <Typography>
                      <span className="addressinfo">City</span>:{consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address?.city || ""}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography className="geocoded">
                      <span className="addressinfo">Area</span>:{consignmentDetailsState?.consignment?.geocodedAddress?.geocodedArea || ""}
                    </Typography>
                    <Typography className="geocoded">
                      <span className="addressinfo">City</span>: {consignmentDetailsState?.consignment?.geocodedAddress?.geocodedCity || ""}
                    </Typography>
                  </>
                )}
                <Typography>
                  <span className="addressinfo">Landmark</span>: {consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address?.landmark || ""}
                </Typography>
                <Typography>
                  <span className="addressinfo">Country</span>: {consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address?.countryName || ""}
                </Typography>
                <Typography>
                  <span className="addressinfo">Lat/Long</span>:{" "}
                  {consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address?.latitude
                    ? ` ${consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address.latitude + ", " || ""}${
                        consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address?.longitude || ""
                      }`
                    : ""}
                </Typography>
              </Grid>
              <Grid className="detailsHeaderItem" item>
                <Grid container alignItems="center">
                  <Grid className="contactMobile" item>
                    <Grid container alignItems="center">
                      <img className="logo" alt="Mobile" src={mobileLogoIcon} />
                      <span className="contact mobile">
                        {consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.phone && (
                          <a href={`tel: ${consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.phone}`}>
                            {consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.phone || ""}
                          </a>
                        )}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container alignItems="center">
                      <img className="logo" alt="Email" src={emailLogoIcon} />
                      <span className="contact email">
                        {consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.email && (
                          <a href={`mailto: ${consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.email}`}>
                            {consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.email || ""}
                          </a>
                        )}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className="detailsHeaderItem geocodedAddress" item>
                <Grid container direction="column">
                  <Grid className="addressColItem" item>
                    <Grid container className="geoLocAddressContainer" alignItems="center" onClick={() => setToggleCustMap(!toggleCustMap)}>
                      <span className="address">View Map</span>
                      <SvgIcon className={["addressLogo", toggleCustMap ? "open" : ""].join(" ")} component={downArrowIcon} />
                    </Grid>
                  </Grid>
                  <Collapse in={toggleCustMap}>
                    <Grid className="addressMapContainer" item>
                      {consignmentDetailsState.custGeoLocation ? (
                        <MapContainer center={consignmentDetailsState.custGeoLocation.location} zoom={12} markers={[consignmentDetailsState.custGeoLocation]}></MapContainer>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Collapse>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {consignmentDetailsState?.consignment?.metadata?.category?.toLowerCase() === "mkp" ? (
            // TODO understand waht is happening here !!!!
            <Grid className="detailsHeader detailsHeaderRightSection" container>
              <Grid className="addressContainer" container>
                <Grid className="detailsHeaderItem" item>
                  <Typography className="addressTitle">Pickup Details</Typography>
                </Grid>
                <Grid className="detailsHeaderItem" item>
                  <Typography className="name">{`${
                    consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo?.companyName
                      ? consignmentDetailsState.consignment.pickUpInformation.pickUpContactInfo.companyName
                      : consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo?.name
                      ? consignmentDetailsState.consignment.pickUpInformation.pickUpContactInfo.name
                      : ""
                  }`}</Typography>
                  <Typography>
                    <span className="addressinfo">Flat/Villa no</span>:
                    {consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo?.address?.apartment
                      ? consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo?.address?.apartment
                      : consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address?.apartment || ""}
                  </Typography>
                  <Typography>
                    <span className="addressinfo">Building name/no</span>:{" "}
                    {consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo?.address
                      ? `${
                          consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo?.address?.building
                            ? consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address.building
                            : ""
                        }`
                      : ""}
                  </Typography>
                  <Typography>
                    <span className="addressinfo">Street Name/no</span>:
                    {consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo?.address
                      ? `${
                          consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo?.address?.streetName
                            ? consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo?.address?.streetNumber
                              ? consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo?.address?.streetName + "/"
                              : consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo?.address?.streetName
                            : ""
                        }${
                          consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo?.address?.streetNumber
                            ? consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo?.address?.streetNumber
                            : ""
                        }`
                      : ""}
                  </Typography>
                  <Typography>
                    <span className="addressinfo">Area</span>:{" "}
                    {consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo?.address
                      ? `${
                          consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo?.address.area
                            ? consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo?.address?.area
                            : ""
                        }`
                      : ""}
                  </Typography>
                  <Typography>
                    <span className="addressinfo">City</span>:{" "}
                    {consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo.address
                      ? `${
                          consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo.address.city
                            ? consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo.address.city
                            : ""
                        }`
                      : ""}
                  </Typography>
                  <Typography>
                    <span className="addressinfo">Landmark</span>:{" "}
                    {consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo.address
                      ? `${
                          consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo.address.landmark
                            ? consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo.address.landmark
                            : ""
                        }`
                      : ""}
                  </Typography>
                  <Typography>
                    <span className="addressinfo">Country</span>:{" "}
                    {consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo.address
                      ? `${
                          consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo.address.countryName
                            ? consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo.address.countryName
                            : ""
                        }`
                      : ""}
                  </Typography>
                  <Typography>
                    <span className="addressinfo">Lat/Long</span>:{" "}
                    {consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo.address
                      ? ` ${
                          consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo.address.latitude
                            ? consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo.address.latitude + ", "
                            : ""
                        }${
                          consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo.address.longitude
                            ? consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo.address.longitude
                            : ""
                        }`
                      : ""}
                  </Typography>
                </Grid>
                <Grid className="detailsHeaderItem" item>
                  <Grid container alignItems="center">
                    <Grid className="contactMobile" item>
                      <Grid container alignItems="center">
                        <img className="logo" alt="Mobile" src={mobileLogoIcon} />
                        <span className="contact mobile">
                          {consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo ? (
                            <a href={`tel: ${consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo.phone}`}>
                              {consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo.phone || ""}
                            </a>
                          ) : (
                            ""
                          )}
                        </span>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems="center">
                        <img className="logo" alt="Email" src={emailLogoIcon} />
                        <span className="contact email">
                          {consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo ? (
                            <a href={`mailto: ${consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo.email}`}>
                              {consignmentDetailsState?.consignment?.pickUpInformation?.pickUpContactInfo.email || ""}
                            </a>
                          ) : (
                            ""
                          )}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className="detailsHeaderItem geocodedAddress" item>
                  <Grid container direction="column">
                    <Grid className="addressColItem" item>
                      <Grid container className="geoLocAddressContainer" alignItems="center" onClick={() => setToggleSellerMap(!toggleSellerMap)}>
                        <span className="address">View Map</span>
                        <SvgIcon className={["addressLogo", toggleSellerMap ? "open" : ""].join(" ")} component={downArrowIcon} />
                      </Grid>
                    </Grid>
                    <Collapse in={toggleSellerMap}>
                      <Grid className="addressMapContainer" item>
                        {consignmentDetailsState.sellerGeoLocation ? (
                          <MapContainer center={consignmentDetailsState.sellerGeoLocation.location} zoom={12} markers={[consignmentDetailsState.sellerGeoLocation]}></MapContainer>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Collapse>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid className="detailsHeader detailsHeaderRightSection" container>
              <Grid className="addressContainer" container>
                <Grid className="detailsHeaderItem" item>
                  <Typography className="addressTitle">Pickup Details</Typography>
                </Grid>
                <Grid className="detailsHeaderItem" item>
                  <Typography className="name">{consignmentDetailsState?.consignment?.tableRecord && consignmentDetailsState?.consignment?.tableRecord.hubName}</Typography>
                  <Typography>
                    <span className="addressinfo">Flat/Villa no</span>:{" "}
                    {consignmentDetailsState?.consignment?.posInformation?.contactInfo.address
                      ? `${
                          consignmentDetailsState?.consignment?.posInformation?.contactInfo.address.apartment
                            ? consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address.apartment
                            : ""
                        }`
                      : ""}
                  </Typography>
                  <Typography>
                    <span className="addressinfo">Building name/no</span>:{" "}
                    {consignmentDetailsState?.consignment?.posInformation?.contactInfo.address
                      ? `${
                          consignmentDetailsState?.consignment?.posInformation?.contactInfo.address.building
                            ? consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address.building
                            : ""
                        }`
                      : ""}
                  </Typography>
                  <Typography>
                    <span className="addressinfo">Street Name/no</span>:{" "}
                    {consignmentDetailsState?.consignment?.posInformation?.contactInfo && consignmentDetailsState?.consignment?.posInformation?.contactInfo.address
                      ? `${
                          consignmentDetailsState?.consignment?.posInformation?.contactInfo.address.streetName
                            ? consignmentDetailsState?.consignment?.posInformation?.contactInfo.address.streetNumber
                              ? consignmentDetailsState?.consignment?.posInformation?.contactInfo.address.streetName + "/"
                              : consignmentDetailsState?.consignment?.posInformation?.contactInfo.address.streetName
                            : ""
                        }${
                          consignmentDetailsState?.consignment?.posInformation?.contactInfo.address.streetNumber
                            ? consignmentDetailsState?.consignment?.posInformation?.contactInfo.address.streetNumber
                            : ""
                        }`
                      : ""}
                  </Typography>
                  <Typography>
                    <span className="addressinfo">Area</span>:{" "}
                    {consignmentDetailsState?.consignment?.posInformation?.contactInfo && consignmentDetailsState?.consignment?.posInformation?.contactInfo.address
                      ? `${consignmentDetailsState?.consignment?.posInformation?.contactInfo.address.area ? consignmentDetailsState?.consignment?.posInformation?.contactInfo.address.area : ""}`
                      : ""}
                  </Typography>
                  <Typography>
                    <span className="addressinfo">City</span>:{" "}
                    {consignmentDetailsState?.consignment?.posInformation?.contactInfo && consignmentDetailsState?.consignment?.posInformation?.contactInfo.address
                      ? `${consignmentDetailsState?.consignment?.posInformation?.contactInfo.address.city ? consignmentDetailsState?.consignment?.posInformation?.contactInfo.address.city : ""}`
                      : ""}
                  </Typography>
                  <Typography>
                    <span className="addressinfo">Landmark</span>:{" "}
                    {consignmentDetailsState?.consignment?.posInformation?.contactInfo && consignmentDetailsState?.consignment?.posInformation?.contactInfo.address
                      ? `${
                          consignmentDetailsState?.consignment?.posInformation?.contactInfo.address.landmark ? consignmentDetailsState?.consignment?.posInformation?.contactInfo.address.landmark : ""
                        }`
                      : ""}
                  </Typography>
                  <Typography>
                    <span className="addressinfo">Country</span>:{" "}
                    {consignmentDetailsState?.consignment?.posInformation?.contactInfo && consignmentDetailsState?.consignment?.posInformation?.contactInfo.address
                      ? `${
                          consignmentDetailsState?.consignment?.posInformation?.contactInfo.address.countryName
                            ? consignmentDetailsState?.consignment?.posInformation?.contactInfo.address.countryName
                            : ""
                        }`
                      : ""}
                  </Typography>
                  <Typography>
                    <span className="addressinfo">Lat/Long</span>:{" "}
                    {consignmentDetailsState?.consignment?.posInformation?.contactInfo && consignmentDetailsState?.consignment?.posInformation?.contactInfo.address
                      ? ` ${
                          consignmentDetailsState?.consignment?.posInformation?.contactInfo.address.latitude
                            ? consignmentDetailsState?.consignment?.posInformation?.contactInfo.address.latitude + ", "
                            : ""
                        }${consignmentDetailsState?.consignment?.posInformation?.contactInfo?.address?.longitude || ""}`
                      : ""}
                  </Typography>
                </Grid>
                <Grid className="detailsHeaderItem" item>
                  <Grid container alignItems="center">
                    <Grid className="contactMobile" item>
                      <Grid container alignItems="center">
                        <img className="logo" alt="Mobile" src={mobileLogoIcon} />
                        <span className="contact mobile">
                          {consignmentDetailsState?.consignment?.posInformation?.contactInfo ? (
                            <a href={`tel: ${consignmentDetailsState?.consignment?.posInformation?.contactInfo.phone}`}>
                              {consignmentDetailsState?.consignment?.posInformation?.contactInfo.phone || ""}
                            </a>
                          ) : (
                            ""
                          )}
                        </span>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems="center">
                        <img className="logo" alt="Email" src={emailLogoIcon} />
                        <span className="contact email">
                          {consignmentDetailsState?.consignment?.posInformation?.contactInfo ? (
                            <a href={`mailto: ${consignmentDetailsState?.consignment?.posInformation?.contactInfo.email}`}>
                              {consignmentDetailsState?.consignment?.posInformation?.contactInfo.email || ""}
                            </a>
                          ) : (
                            ""
                          )}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className="detailsHeaderItem geocodedAddress" item>
                  <Grid container direction="column">
                    <Grid className="addressColItem" item>
                      <Grid container className="geoLocAddressContainer" alignItems="center" onClick={() => setToggleSellerMap(!toggleSellerMap)}>
                        <span className="address">View Map</span>
                        <SvgIcon className={["addressLogo", toggleSellerMap ? "open" : ""].join(" ")} component={downArrowIcon} />
                      </Grid>
                    </Grid>
                    <Collapse in={toggleSellerMap}>
                      <Grid className="addressMapContainer" item>
                        {consignmentDetailsState.sellerGeoLocation ? (
                          <MapContainer center={consignmentDetailsState.sellerGeoLocation.location} zoom={12} markers={[consignmentDetailsState.sellerGeoLocation]}></MapContainer>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Collapse>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        {!openCarrierReassign ? (
          <>
            {consignmentDetailsState?.consignment?.addressGeoCoded ? (
              <Grid className="customerAddress customerAddressTop" item>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<SvgIcon component={downArrowIcon} htmlColor={COLORS.COPY} />}
                    aria-controls="customer-address-content"
                    id="customer-address-header"
                    className={classes.accordionSummary}
                  >
                    <Typography className="header">Address entered by customer</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography component="div" className="content">
                      <Timeline className={classes.timeline} align="left">
                        <Grid className="detailsHeaderItem" item>
                          <Typography className="name">
                            {consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo && consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.name}
                          </Typography>
                          <Typography>
                            <span className="addressinfo">Flat/Villa no</span>:{" "}
                            {consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address
                              ? `${
                                  consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address.apartment
                                    ? consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address.apartment + ", "
                                    : ""
                                }`
                              : ""}
                            <span className="addressinfo">Building name/no</span>:{" "}
                            {consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address
                              ? `${
                                  consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address.building
                                    ? consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address.building
                                    : ""
                                }`
                              : ""}
                          </Typography>
                          <Typography>
                            <span className="addressinfo">Street Name/no</span>:{" "}
                            {consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address
                              ? `${
                                  consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address.streetName
                                    ? consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address.streetNumber
                                      ? consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address.streetName + "/"
                                      : consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address.streetName + ", "
                                    : ""
                                }${
                                  consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address.streetNumber
                                    ? consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address.streetNumber + ", "
                                    : ""
                                }`
                              : ""}
                            <span className="addressinfo">Area</span>:{" "}
                            {consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address
                              ? `${
                                  consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address.area
                                    ? consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address.area
                                    : ""
                                }`
                              : ""}
                          </Typography>
                          <Typography>
                            <span className="addressinfo">City</span>:{" "}
                            {consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address
                              ? `${
                                  consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address.city
                                    ? consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address.city + ", "
                                    : ""
                                }`
                              : ""}
                            <span className="addressinfo">Landmark</span>:{" "}
                            {consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address
                              ? `${
                                  consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address.landmark
                                    ? consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address.landmark
                                    : ""
                                }`
                              : ""}
                          </Typography>
                          <Typography>
                            <span className="addressinfo">Country</span>:{" "}
                            {consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address
                              ? `${
                                  consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address.countryName
                                    ? consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address.countryName
                                    : ""
                                }`
                              : ""}{" "}
                          </Typography>
                          <Typography>
                            <span className="addressinfo">Lat/Long</span>:{" "}
                            {consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address
                              ? ` ${
                                  consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address.latitude
                                    ? consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address.latitude + ", "
                                    : ""
                                }${
                                  consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address.longitude
                                    ? consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address.longitude
                                    : ""
                                }`
                              : ""}
                          </Typography>
                        </Grid>
                      </Timeline>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ) : null}
            <Grid item>
              <Grid className="detailsContent" container>
                <Grid className="item" item>
                  <Grid container>
                    <Grid className="itemlabel" item xs={4}>
                      Carrier Name
                    </Grid>
                    <Grid className="itemlabel" item xs={4}>
                      Delivery Date
                    </Grid>
                    <Grid className="itemlabel itemPaddingLeft" item xs={4}>
                      Delivery Slot
                    </Grid>
                  </Grid>
                  <Grid className="itemRowMargin" container>
                    <Grid className="itemValue" item xs={4}>
                      {consignmentDetailsState?.consignment?.deliveryInformation?.carrier}
                    </Grid>
                    <Grid className="itemValue" item xs={4}>
                      {consignmentDetailsState?.consignment?.tableRecord?.deliveryDate}
                    </Grid>
                    <Grid className="itemValue itemPaddingLeft" item xs={4}>
                      {consignmentDetailsState?.consignment?.tableRecord?.startEndTime}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid className="itemlabel" item xs={4}>
                      Delivery Proposition
                    </Grid>
                    <Grid className="itemlabel" item xs={4}>
                      Expected Pick Up Date
                    </Grid>
                    <Grid className="itemlabel itemPaddingLeft" item xs={4}>
                      Expected Pick Up Slot
                    </Grid>
                  </Grid>
                  <Grid className="itemRowMargin" container>
                    <Grid className="itemValue" item xs={4}>
                      {consignmentDetailsState?.consignment?.tableRecord?.deliveryProposition}
                    </Grid>
                    <Grid className="itemValue" item xs={4}>
                      {consignmentDetailsState?.consignment?.tableRecord?.carrierPickUpDate}
                    </Grid>
                    <Grid className="itemValue itemPaddingLeft" item xs={4}>
                      {consignmentDetailsState?.consignment?.tableRecord?.carrierPickUpSlot}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid className="itemlabel" item xs={4}>
                      Vehicle Type
                    </Grid>
                    <Grid className="itemlabel" item xs={4}>
                      OMS Pick Up Date
                    </Grid>
                    <Grid className="itemlabel itemPaddingLeft" item xs={4}>
                      OMS Pick Up Slot
                    </Grid>
                  </Grid>
                  <Grid className="itemRowMargin" container>
                    <Grid className="itemValue" item xs={4}>
                      {consignmentDetailsState?.consignment?.tableRecord?.vehicleType}
                    </Grid>
                    <Grid className="itemValue" item xs={4}>
                      {consignmentDetailsState?.consignment?.tableRecord?.pickUpDate}
                    </Grid>
                    <Grid className="itemValue itemPaddingLeft" item xs={4}>
                      {consignmentDetailsState?.consignment?.tableRecord?.pickUpSlot}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid className="itemlabel" item xs={12}>
                      Payment Status
                    </Grid>
                  </Grid>
                  <Grid className="itemRowMargin" container>
                    <Grid className="itemValue" item xs={12}>
                      {consignmentDetailsState?.consignment?.paymentAccepted ? "Payment Accepted" : "-"}
                    </Grid>
                  </Grid>
                  {consignmentDetailsState?.consignment?.deliveryInformation?.vehicleId && (
                    <>
                      <Grid container>
                        <Grid className="itemlabel" item xs={12}>
                          Vehicle ID
                        </Grid>
                      </Grid>
                      <Grid className="itemRowMargin" container>
                        <Grid className="itemValue" item xs={12}>
                          {consignmentDetailsState?.consignment?.deliveryInformation?.vehicleId}
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {consignmentDetailsState?.consignment?.deliveryInformation?.deliveryInstructions && (
                    <>
                      <Grid container>
                        <Grid className="itemlabel" item xs={12}>
                          Delivery Instructions
                        </Grid>
                      </Grid>
                      <Grid className="itemRowMargin" container>
                        <Grid className="itemValue" item xs={12}>
                          {consignmentDetailsState?.consignment?.deliveryInformation?.deliveryInstructions}
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid className="consignmentHistory" item>
              <Accordion>
                <AccordionSummary
                  expandIcon={<SvgIcon component={downArrowIcon} htmlColor={COLORS.COPY} />}
                  aria-controls="consignment-history-content"
                  id="consignment-history-header"
                  className={classes.accordionSummary}
                >
                  <Typography className="header">Consignment History</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography component="div" className="content">
                    <Timeline className={classes.timeline} align="left">
                      {getHistoryTimelineItems()}
                    </Timeline>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid className="itemsSection itemSummary" item>
              <Accordion>
                <AccordionSummary
                  expandIcon={<SvgIcon component={downArrowIcon} htmlColor={COLORS.COPY} />}
                  aria-controls="consignment-history-content"
                  id="consignment-history-header"
                  className={classes.accordionSummary}
                >
                  <Typography className="header">Items Summary ({consignmentDetailsState?.consignment?.products && consignmentDetailsState?.consignment?.products.length})</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography component="div" className="content">
                    <Grid className="itemsContainer" container direction="column">
                      <Grid className="item" item>
                        <Grid className="itemContainer header" container>
                          <Grid className="itemColumn item1" item xs={3}>
                            <Typography>Product Name</Typography>
                          </Grid>
                          <Grid className="itemColumn item2" item xs={2}>
                            <Typography>Ordered</Typography>
                          </Grid>
                          <Grid className="itemColumn item3" item xs={2}>
                            <Typography>Shipped</Typography>
                          </Grid>
                          <Grid className="itemColumn item4" item xs={2}>
                            <Typography>Rejected</Typography>
                          </Grid>
                          <Grid className="itemColumn item5" item xs={3}>
                            <Typography>Unit Price</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      {getItems()}
                    </Grid>
                    <Grid className="paymentContainer" container direction="column">
                      <Grid className="paymentItem" item>
                        <Grid container>
                          <Grid className="paymentItemColumn" item>
                            <Typography>Payment Method</Typography>
                          </Grid>
                          <Grid className="paymentItemColumn right" item>
                            <Typography>{consignmentDetailsState?.consignment?.tableRecord?.paymentType || ""}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid className="paymentItem" item>
                        <Grid container>
                          <Grid className="paymentItemColumn" item>
                            <Typography>Delivery Charges</Typography>
                          </Grid>
                          <Grid className="paymentItemColumn right" item>
                            <Typography>
                              {consignmentDetailsState?.consignment?.deliveryInformation?.deliveryCost
                                ? `${consignmentDetailsState?.consignment?.deliveryInformation.deliveryCost || ""} ${consignmentDetailsState?.consignment?.metadata?.currency || ""}`
                                : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      {consignmentDetailsState?.consignment?.tableRecord && isPostPaidOrder(consignmentDetailsState?.consignment?.tableRecord?.paymentType) ? (
                        <Grid className="paymentItem" item>
                          <Grid container>
                            <Grid className="paymentItemColumn" item>
                              <Typography>{consignmentDetailsState?.consignment?.tableRecord?.paymentType}Amount</Typography>
                            </Grid>
                            <Grid className="paymentItemColumn right" item>
                              <Typography>
                                {consignmentDetailsState?.consignment?.metadata?.amountToCollect
                                  ? `${consignmentDetailsState?.consignment?.metadata?.amountToCollect || ""} ${consignmentDetailsState?.consignment?.metadata?.currency || ""}`
                                  : ""}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : null}
                      {/* <Grid className="paymentItem" item>
                    <Grid container>
                      <Grid className="paymentItemColumn" item>
                        <Typography>Sub Total</Typography>
                      </Grid>
                      <Grid className="paymentItemColumn right" item>
                        <Typography>{tableRecord && metadata ? `${tableRecord.subTotal || ""} ${metadata.currency || ""}` : ""}</Typography>
                      </Grid>
                    </Grid>
                  </Grid> */}
                      {/* {
                    metadata && metadata.share ?
                      <Grid className="paymentItem" item>
                        <Grid container>
                          <Grid className="paymentItemColumn" item>
                            <Typography>Paid by SHARE points</Typography>
                          </Grid>
                          <Grid className="paymentItemColumn right" item>
                            <Typography>-20.00 AED</Typography>
                          </Grid>
                        </Grid>
                      </Grid> : ""
                  } */}
                      {/* <Divider className="totalDivider"></Divider> */}
                      {/* <Grid className="paymentItem" item>
                    <Grid container>
                      <Grid className="paymentItemColumn" item>
                        <Typography>Grand Total<span className="vat">(inclusive of VAT)</span></Typography>
                      </Grid>
                      <Grid className="paymentItemColumn right" item>
                        <Typography>{tableRecord && metadata ? `${tableRecord.totalAmount || ""} ${metadata.currency || ""}` : ""}</Typography>
                      </Grid>
                    </Grid>
                  </Grid> */}
                    </Grid>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              {consignmentDetailsState?.consignment?.tableRecord && pageType === "order" && hasRepushAccess(consignmentDetailsState?.consignment?.tableRecord?.displayStatus) && (
                <Grid className="repushButton">
                  <Button variant="contained" className="button primary" onClick={handleRepushOrder}>
                    REPUSH ORDER
                  </Button>
                </Grid>
              )}
            </Grid>
          </>
        ) : (
          <>
            <form onSubmit={inputFormik.handleSubmit}>
              <Grid className={classes.contentContainer} container>
                <Grid className="contentItem" item>
                  &nbsp;
                </Grid>
                <Grid className="contentItem" item>
                  <Grid className="itemContainer" container>
                    <Grid className="itemLabel" item>
                      <Typography>Carrier</Typography>
                    </Grid>
                  </Grid>
                  <Grid className="itemContainer" container>
                    <Grid className="itemInput" item>
                      <SelectBox
                        fullWidth
                        handleFormikChange={inputFormik.handleChange}
                        value={inputFormik.values.carrierName}
                        error={inputFormik.touched.carrierName && Boolean(inputFormik.errors.carrierName)}
                        helperText={inputFormik.touched.carrierName && inputFormik.errors.carrierName}
                        initialValues={{ name: "Carrier", value: AppConstants.SELECT_NONE.value }}
                        reset={resetFormFlag}
                        id="ReassignCarrierNameSelect"
                        inputProps={{ name: "carrierName", id: "AddUserStatusSelectBox" }}
                        items={carrierArr}
                      ></SelectBox>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className="contentItem" item>
                  &nbsp;
                </Grid>
                <Grid className="contentItem" item>
                  <Grid className="itemContainer" container>
                    <Grid className="itemLabel" item>
                      <Typography>Vehicle Type</Typography>
                    </Grid>
                  </Grid>
                  <Grid className="itemContainer" container>
                    <Grid className="itemInput" item>
                      <SelectBox
                        fullWidth
                        handleFormikChange={inputFormik.handleChange}
                        value={inputFormik.values.vehicleType}
                        error={inputFormik.touched.vehicleType && Boolean(inputFormik.errors.vehicleType)}
                        helperText={inputFormik.touched.vehicleType && inputFormik.errors.vehicleType}
                        initialValues={{ name: "Vehicle Type", value: AppConstants.SELECT_NONE.value }}
                        reset={resetFormFlag}
                        id="ReassignVehicleSelect"
                        inputProps={{ name: "vehicleType", id: "ReassignVehicleSelect" }}
                        items={vehicleTypeArr}
                      ></SelectBox>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </>
        )}
      </Grid>
    );
  };

  const getDialogActions = () => {
    return pageType === "exceptions" ? (
      consignmentDetailsState?.consignment?.tableRecord && hasRepushAccess(consignmentDetailsState?.consignment?.tableRecord?.displayStatus) && (
        <Grid className="buttons">
          <Grid className="buttonsContainer" container>
            <Button
              disabled={!repushEnabled}
              className="userBtn primary"
              variant="contained"
              onClick={() => {
                handleRepushOrder();
              }}
            >
              {AppConstants.BUTTONS.REPUSH}
            </Button>
          </Grid>
        </Grid>
      )
    ) : consignmentDetailsState?.consignment?.metadata?.displayStatus && consignmentDetailsState?.consignment?.deliveryInformation?.carrier?.toLowerCase() === "yandex" ? (
      !openCarrierReassign ? (
        <Grid className={classes.buttons}>
          <Grid className="buttonsContainer" container>
            <Button
              className="userBtn secondary"
              variant="contained"
              disabled={!canOpenTheHatch(consignmentDetailsState?.consignment?.metadata?.displayStatus) ? true : false}
              onClick={() => {
                handleOpenTheHatch();
              }}
            >
              {AppConstants.BUTTONS.OPEN_THE_HATCH}
            </Button>
            <Button
              className="userBtn primary"
              variant="contained"
              disabled={canReassignCarrier(consignmentDetailsState?.consignment?.metadata?.displayStatus) ? true : false}
              onClick={() => {
                openReassignCarrier();
              }}
            >
              {AppConstants.BUTTONS.REASSIGN_CARRIER}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <form className={classes.buttons} onSubmit={inputFormik.handleSubmit}>
          <Grid className="buttonsContainer" container>
            <Button
              className="userBtn secondary"
              variant="contained"
              onClick={() => {
                handleCloseReassign();
              }}
            >
              {AppConstants.BUTTONS.CANCEL}
            </Button>
            <Button className="userBtn primary" variant="contained" type="submit">
              {AppConstants.BUTTONS.UPDATE}
            </Button>
          </Grid>
        </form>
      )
    ) : null;
  };

  const handleDialogClose = () => {
    setOpenCarrierReassign(false);
    if (consignmentData?.statuses?.length !== consignmentDetailsState?.consignment?.statuses?.length) {
      closePopup(consignmentDetailsState?.consignment);
    } else {
      closePopup();
    }
  };

  const fetchAllDeliveryReasons = () => {
    dispatch(fetchDeliveryReasons());
    dispatch(fetchOrderDeliveryStatuses());
  };

  const handleSnackbarClose = () => {
    setConsignmentDetailsState({ ...consignmentDetailsState, openSnackbar: false, snackbarMessage: "", snackbarType: AppConstants.SNACKBAR.TYPES.SUCCESS });
  };

  useEffect(() => {
    if (consignmentDetailsState.openConsignmentEditPopup && reasonsDropdownList?.length === 0) {
      fetchAllDeliveryReasons();
    }
  }, [consignmentDetailsState.openConsignmentEditPopup]);

  useEffect(() => {
    !isNull(deliveryError) && setConsignmentDetailsState({ ...consignmentDetailsState, openSnackbar: true, snackbarMessage: deliveryError, snackbarType: AppConstants.SNACKBAR.TYPES.ERROR });
  }, [deliveryError]);

  const CloseTrackingDialog = () => {
    setOpenDialog(false);
  };

  const getTrackingTimelineViewBox = (index: any) => {
    if (index === "ORDER_PLACED") {
      return "0 0 18 18";
    } else {
      return "0 0 10 10";
    }
  };

  const getGroupStatusInfo = (index: any) => {
    if (index === AppConstants.CONSIGNMENT_CONSTANTS.CONSIGNMENT_STATUSES.ORDER_PLACED) {
      return toggleOrderPlaced;
    } else if (index === AppConstants.CONSIGNMENT_CONSTANTS.CONSIGNMENT_STATUSES.ORDER_BEING_PREPARED) {
      return toggleOrderPrepared;
    } else if (index === AppConstants.CONSIGNMENT_CONSTANTS.CONSIGNMENT_STATUSES.ORDER_ON_THE_WAY) {
      return toggleOrderOnTheWay;
    } else if (index === AppConstants.CONSIGNMENT_CONSTANTS.CONSIGNMENT_STATUSES.ORDER_DELIVERED) {
      return toggleOrderDelivered;
    } else if (index === AppConstants.CONSIGNMENT_CONSTANTS.CONSIGNMENT_STATUSES.UNABLE_TO_DELIVER) {
      return toggleOrderDeliveryFailed;
    } else if (index === AppConstants.CONSIGNMENT_CONSTANTS.CONSIGNMENT_STATUSES.ORDER_CANCELLATION) {
      return toggleOrderCancelled;
    } else if (index === AppConstants.CONSIGNMENT_CONSTANTS.CONSIGNMENT_STATUSES.ORDER_NOT_DELIVERED) {
      return toggleOrderCancelled;
    }
  };

  const toggleTrackingStatus = (index: any) => {
    if (index === AppConstants.CONSIGNMENT_CONSTANTS.CONSIGNMENT_STATUSES.ORDER_PLACED) {
      setToggleOrderPlaced(!toggleOrderPlaced);
    } else if (index === AppConstants.CONSIGNMENT_CONSTANTS.CONSIGNMENT_STATUSES.ORDER_BEING_PREPARED) {
      setToggleOrderPrepared(!toggleOrderPrepared);
    } else if (index === AppConstants.CONSIGNMENT_CONSTANTS.CONSIGNMENT_STATUSES.ORDER_ON_THE_WAY) {
      setToggleOrderOnTheWay(!toggleOrderOnTheWay);
    } else if (index === AppConstants.CONSIGNMENT_CONSTANTS.CONSIGNMENT_STATUSES.ORDER_DELIVERED) {
      setToggleOrderDelivered(!toggleOrderDelivered);
    } else if (index === AppConstants.CONSIGNMENT_CONSTANTS.CONSIGNMENT_STATUSES.UNABLE_TO_DELIVER) {
      setToggleOrderDeliveryFailed(!toggleOrderDeliveryFailed);
    } else if (index === AppConstants.CONSIGNMENT_CONSTANTS.CONSIGNMENT_STATUSES.ORDER_CANCELLATION) {
      setToggleOrderCancelled(!toggleOrderCancelled);
    } else if (index === AppConstants.CONSIGNMENT_CONSTANTS.CONSIGNMENT_STATUSES.ORDER_NOT_DELIVERED) {
      setToggleOrderCancelled(!toggleOrderCancelled);
    }
  };

  const checkIfNotLastTimeLine = (index: any) => {
    if (consignmentDetailsState.lastStatusUpdate && index !== consignmentDetailsState?.lastStatusUpdate?.statusGroupCode) {
      return true;
    } else {
      return false;
    }
  };

  const getItemStyle = (index: any) => {
    if (index === "ORDER_PLACED") {
      return orderPlacedItemStyle;
    } else if (index === "ORDER_BEING_PREPARED") {
      return orderPreparedItemStyle;
    } else if (index === "ORDER_ON_THE_WAY") {
      return orderOnTheWayItemStyle;
    } else if (index === "ORDER_DELIVERED") {
      return orderDeliveredItemStyle;
    } else if (index === "UNABLE_TO_DELIVER") {
      return orderDeliveryFailedItemStyle;
    } else if (index === "ORDER_CANCELLATION") {
      return orderCancelledItemStyle;
    }
  };

  const getGroupValueViewMoreFlag = (index: any) => {
    if (index === "ORDER_PLACED") {
      return viewMoreOrderPlaced;
    } else if (index === "ORDER_BEING_PREPARED") {
      return viewMoreOrderPrepared;
    } else if (index === "ORDER_ON_THE_WAY") {
      return viewMoreOrderOnTheWay;
    } else if (index === "ORDER_DELIVERED") {
      return viewMoreOrderDelivered;
    } else if (index === "UNABLE_TO_DELIVER") {
      return viewMoreOrderDeliveryFailed;
    } else if (index === "ORDER_CANCELLATION") {
      return viewMoreOrderCancelled;
    }
  };

  const viewMoreDetails = (index: any) => {
    if (index === "ORDER_PLACED") {
      setViewMoreOrderPlaced(true);
      setOrderPlacedItemStyle({ display: "block" });
    } else if (index === "ORDER_BEING_PREPARED") {
      setViewMoreOrderPrepared(true);
      setOrderPreparedItemStyle({ display: "block" });
    } else if (index === "ORDER_ON_THE_WAY") {
      setViewMoreOrderOnTheWay(true);
      setOrderOnTheWayItemStyle({ display: "block" });
    } else if (index === "ORDER_DELIVERED") {
      setViewMoreOrderDelivered(true);
      setOrderDeliveredItemStyle({ display: "block" });
    } else if (index === "UNABLE_TO_DELIVER") {
      setViewMoreOrderDeliveryFailed(true);
      setOrderDeliveryFailedItemStyle({ display: "block" });
    } else if (index === "ORDER_CANCELLATION") {
      setViewMoreOrderCancelled(true);
      setOrderCancelledItemStyle({ display: "block" });
    }
  };

  const viewLessDetails = (index: any) => {
    if (index === "ORDER_PLACED") {
      setViewMoreOrderPlaced(false);
      setOrderPlacedItemStyle({ display: "none" });
    } else if (index === "ORDER_BEING_PREPARED") {
      setViewMoreOrderPrepared(false);
      setOrderPreparedItemStyle({ display: "none" });
    } else if (index === "ORDER_ON_THE_WAY") {
      setViewMoreOrderOnTheWay(false);
      setOrderOnTheWayItemStyle({ display: "none" });
    } else if (index === "ORDER_DELIVERED") {
      setViewMoreOrderDelivered(false);
      setOrderDeliveredItemStyle({ display: "none" });
    } else if (index === "UNABLE_TO_DELIVER") {
      setViewMoreOrderDeliveryFailed(false);
      setOrderDeliveryFailedItemStyle({ display: "none" });
    } else if (index === "ORDER_CANCELLATION") {
      setViewMoreOrderCancelled(false);
      setOrderCancelledItemStyle({ display: "none" });
    }
  };

  const getTrackingStatusInfo = () => {
    return (
      <>
        {consignmentDetailsState?.trackingStatus && Object.keys(consignmentDetailsState?.trackingStatus).length ? (
          Object.keys(consignmentDetailsState.trackingStatus).map((index: any, itemInfo: number) => (
            <TimelineItem key={`${index}`} className="timelineItem">
              <Typography className={["timer", index === "ORDER_PLACED" ? "first" : "notFirst"].join(" ")}>&nbsp;</Typography>
              <TimelineSeparator className={index !== "ORDER_PLACED" ? "marginLeft" : ""}>
                <TimelineDot className="timelineDot">
                  <SvgIcon
                    className={["dotIcon", index === "ORDER_PLACED" ? "active" : ""].join(" ")}
                    component={index === "ORDER_PLACED" ? activeTimelineDot : timelineDot}
                    viewBox={getTrackingTimelineViewBox(index)}
                  />
                </TimelineDot>
                {checkIfNotLastTimeLine(index) ? (
                  <TimelineConnector className="primaryConnector" />
                ) : getGroupStatusInfo(index) ? (
                  <TimelineConnector className="primaryConnector lastConnector" />
                ) : null}
              </TimelineSeparator>
              <TimelineContent className="timelineContent">
                <Typography className="heading active" onClick={() => toggleTrackingStatus(index)}>
                  <span className="arrow">
                    <SvgIcon className={["addressLogo", getGroupStatusInfo(index) ? "open" : ""].join(" ")} component={downArrowIcon} />
                  </span>
                  <span className="status">{consignmentDetailsState.trackingStatus[index][0].statusGroupName}</span>
                  <span className="dateTime">&nbsp; | &nbsp;</span>
                  <span className="dateTime">
                    {consignmentDetailsState.trackingStatus[index][0]?.statusDate
                      ? getDayAndMonthName(consignmentDetailsState.trackingStatus[index][0]?.statusDate, consignmentDetailsState?.consignment?.metadata?.timeZone)
                      : " | --"}
                  </span>
                </Typography>
                <Collapse in={getGroupStatusInfo(index)}>
                  {consignmentDetailsState.trackingStatus[index]?.length > 0 &&
                    consignmentDetailsState.trackingStatus[index].map((itemInfo: any, indexInner: any) => (
                      <Typography key={indexInner} component="div" className="metadataContainer" style={indexInner > 5 ? getItemStyle(index) : {}}>
                        {itemInfo.statusError ? (
                          <Typography component="div" className="border">
                            <Typography className="title failed">{itemInfo?.statusDescription}</Typography>
                            <Typography className="description">{`${getDayAndMonthName(itemInfo.statusDate, consignmentDetailsState?.consignment?.metadata?.timeZone)} at ${getTimeStringFromUTC(
                              itemInfo.statusDate,
                              consignmentDetailsState?.consignment?.metadata?.timeZone,
                              true
                            )}`}</Typography>
                          </Typography>
                        ) : (
                          <>
                            <Typography className="title">{itemInfo?.statusDescription}</Typography>
                            <Typography className="description">{`${
                              itemInfo.statusDate ? getDayAndMonthName(itemInfo.statusDate, consignmentDetailsState?.consignment?.metadata?.timeZone) : "--"
                            } at ${itemInfo.statusDate ? getTimeStringFromUTC(itemInfo.statusDate, consignmentDetailsState?.consignment?.metadata?.timeZone, true) : "--"}`}</Typography>
                          </>
                        )}
                      </Typography>
                    ))}
                  {consignmentDetailsState.trackingStatus[index]?.length > 6 && (
                    <Typography key={`view-more-${index}`} component="div" className="metadataContainer viewMore">
                      <Typography className="title viewMore" onClick={() => (getGroupValueViewMoreFlag(index) ? viewLessDetails(index) : viewMoreDetails(index))}>
                        {getGroupValueViewMoreFlag(index) ? `view less` : `view more`}
                      </Typography>
                    </Typography>
                  )}
                </Collapse>
              </TimelineContent>
            </TimelineItem>
          ))
        ) : (
          <Typography className="notFound">Shipment details currently not available</Typography>
        )}
      </>
    );
  };

  const getDeliveryEditDialogActions = () => {
    return (
      <Grid className={classes.buttons}>
        <Grid className="buttonsContainer" container>
          <Button className="userBtn secondary" variant="contained" disabled={loading} onClick={closeDeliveryEditPopUp}>
            {AppConstants.BUTTONS.CANCEL}
          </Button>
          <Button className="userBtn primary" variant="contained" disabled={loading} onClick={() => dialogDeliveryFailedActionHandler()}>
            {AppConstants.BUTTONS.SAVE}
          </Button>
        </Grid>
      </Grid>
    );
  };

  const getCustGeoLocation = (data: any) => {
    const custGeoLocation = { name: "", location: { lat: 0, lng: 0 } };
    custGeoLocation.name = data?.deliveryInformation?.customerContactInfo?.name;
    custGeoLocation.location.lat = parseFloat(data?.deliveryInformation?.customerContactInfo?.address.latitude) || 0;
    custGeoLocation.location.lng = parseFloat(data?.deliveryInformation?.customerContactInfo?.address.longitude) || 0;
    return custGeoLocation;
  };

  const getSellerGeoLocation = (data: any) => {
    const sellerGeoLocation = { name: "", location: { lat: 0, lng: 0 } };
    sellerGeoLocation.name = data?.pickUpInformation?.pickUpContactInfo.name;
    sellerGeoLocation.location.lat = parseFloat(data?.pickUpInformation?.pickUpContactInfo.address.latitude) || 0;
    sellerGeoLocation.location.lng = parseFloat(data?.pickUpInformation?.pickUpContactInfo.address.longitude) || 0;
    return sellerGeoLocation;
  };

  const getLastStatusUpdate = (data: any) => {
    let trackingStatusList = data?.statuses?.length > 0 ? data?.statuses.slice() : [];
    trackingStatusList = trackingStatusList?.reverse();
    return trackingStatusList?.length ? trackingStatusList[trackingStatusList.length - 1] : "";
  };

  const getTrackingStatus = (data: any) => {
    let trackingStatusList = data?.statuses?.length > 0 ? data?.statuses.slice() : [];
    trackingStatusList = trackingStatusList.reverse();
    let trackingStatus = trackingStatusList.reduce((acc: any, obj: any) => {
      const key = obj["statusGroupCode"];
      if (
        key &&
        (key === AppConstants.CONSIGNMENT_CONSTANTS.CONSIGNMENT_STATUSES.ORDER_PLACED ||
          key === AppConstants.CONSIGNMENT_CONSTANTS.CONSIGNMENT_STATUSES.ORDER_BEING_PREPARED ||
          key === AppConstants.CONSIGNMENT_CONSTANTS.CONSIGNMENT_STATUSES.ORDER_ON_THE_WAY ||
          key === AppConstants.CONSIGNMENT_CONSTANTS.CONSIGNMENT_STATUSES.ORDER_DELIVERED ||
          key === AppConstants.CONSIGNMENT_CONSTANTS.CONSIGNMENT_STATUSES.UNABLE_TO_DELIVER ||
          key === AppConstants.CONSIGNMENT_CONSTANTS.CONSIGNMENT_STATUSES.ORDER_CANCELLATION ||
          key === AppConstants.CONSIGNMENT_CONSTANTS.CONSIGNMENT_STATUSES.ORDER_NOT_DELIVERED)
      ) {
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
      }
      return acc;
    }, {});
    return trackingStatus;
  };

  useEffect(() => {
    if (isNonEmptyObject(consignmentData)) {
      setConsignmentDetailsState({
        ...consignmentDetailsState,
        consignment: consignmentData,
        custGeoLocation: getCustGeoLocation(consignmentData),
        sellerGeoLocation: getSellerGeoLocation(consignmentData),
        lastStatusUpdate: getLastStatusUpdate(consignmentData),
        trackingStatus: getTrackingStatus(consignmentData),
      });
      if (consignmentDetailsState.consignment) {
        let deliveryInformation = consignmentDetailsState.consignment?.deliveryInformation;
        let custInfo = deliveryInformation?.customerContactInfo;
        if (custInfo && !consignmentDetailsState?.consignment?.deliveryInformation?.customerContactInfo?.address.landmark) {
          dispatch(resetRepushEnabledFlag(true));
        } else {
          dispatch(resetRepushEnabledFlag(true));
        }
      }
    }
  }, []);

  return (
    <>
      {open && (
        <CustomDialog
          open={open}
          TransitionComponent={Transition}
          PaperProps={dialogPaperProps}
          title={getTitleContent()}
          content={consignmentDetailsState.consignment && isNonEmptyObject(consignmentDetailsState.consignment) ? getDetailsContent() : <></>}
          actions={getDialogActions()}
          handleClose={handleDialogClose}
        ></CustomDialog>
      )}
      {openDialog && (
        <Dialog className={classes.customDialog} open={openDialog} onClose={CloseTrackingDialog} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            <IconButton aria-label="close" className={classes.closeButton} onClick={CloseTrackingDialog}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid container>
                <Grid className="titleStatus trackingStatus" item xs={12}>
                  Shipment Details
                </Grid>
              </Grid>
              <Grid container>
                <Grid className="orderNumber orderLabel" item xs={12}>
                  Order Number : <span className="orderValue">{consignmentDetailsState?.consignment?.orderCode}</span> &nbsp; | &nbsp; Delivery By :
                  <span>{consignmentDetailsState?.consignment?.deliveryInformation?.carrier}</span>
                </Grid>
              </Grid>
              <Grid container className="StatusProgressMain">
                <Timeline className={classes.timeline} align="left">
                  {getTrackingStatusInfo()}
                </Timeline>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
      {consignmentDetailsState.openSnackbar && (
        <CustomAlertbar
          open={consignmentDetailsState.openSnackbar}
          handleClose={handleSnackbarClose}
          autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT}
          message={consignmentDetailsState.snackbarMessage}
          type={consignmentDetailsState.snackbarType}
        />
      )}
      {consignmentDetailsState.openConsignmentEditPopup && (
        <CustomDialog
          open={consignmentDetailsState.openConsignmentEditPopup}
          TransitionComponent={Transition}
          PaperProps={dialogPaperProps}
          title={getTitleContent()}
          content={getDeliveryDetailsContent()}
          actions={getDeliveryEditDialogActions()}
          handleClose={closeDeliveryEditPopUp}
        />
      )}
    </>
  );
};

export default ConsignmentDetails;
