// Local
// export const basicAuthorization = process.env.REACT_APP_AUTHORIZATION;
// export const reportBaseURL = `${process.env.REACT_APP_REPORT_BASE_URL}/lastmile`;
// export const userBaseURL = `${process.env.REACT_APP_USER_BASE_URL}/lastmile`;
// export const repushOrderBaseURL = `${process.env.REACT_APP_REPUSH_ORDER_URL}/lastmile`;
// export const repushOrderAuthToken = process.env.REACT_APP_REPUSH_ORDER_AUTH_TOKEN;
// export const googleApiCode = process.env.REACT_APP_GOOGLE_API_CODE;
// export const basicGeocodeAuthorization = process.env.REACT_APP_GEOCODED_AUTH_TOKEN;
// export const geoCodedAddressUrl = `${process.env.REACT_APP_GEOCODED_ADDRESS_URL}/lastmile`;
// export const yandexApiUrl = `${process.env.REACT_APP_YANDEX_URL}/lastmile`;
// export const yandexAuthToken = process.env.REACT_APP_YANDEX_AUTH_TOKEN;
// export const iMileApiUrl = `${process.env.REACT_APP_I_MILE_BASE_URL}/lastmile`;
// export const iMileAuthorization = process.env.REACT_APP_I_MILE_REACT_APP_AUTHORIZATION;
// export const wingsPortalUrl = process.env.REACT_APP_WINGS_PORTAL_URL;

// Deployemnt
export const basicAuthorization = window._env_.REACT_APP_AUTHORIZATION;
export const reportBaseURL = `${window._env_.REACT_APP_REPORT_BASE_URL}/lastmile`;
export const userBaseURL = `${window._env_.REACT_APP_USER_BASE_URL}/lastmile`;
export const repushOrderBaseURL = `${window._env_.REACT_APP_REPUSH_ORDER_URL}/lastmile`;
export const repushOrderAuthToken = window._env_.REACT_APP_REPUSH_ORDER_AUTH_TOKEN;
export const googleApiCode = window._env_.REACT_APP_GOOGLE_API_CODE;
export const basicGeocodeAuthorization = window._env_.REACT_APP_GEOCODED_AUTH_TOKEN;
export const geoCodedAddressUrl = `${window._env_.REACT_APP_GEOCODED_ADDRESS_URL}/lastmile`;
export const yandexApiUrl = `${window._env_.REACT_APP_YANDEX_URL}/lastmile`;
export const yandexAuthToken = window._env_.REACT_APP_YANDEX_AUTH_TOKEN;
export const iMileApiUrl = `${window._env_.REACT_APP_I_MILE_BASE_URL}/lastmile`;
export const iMileAuthorization = window._env_.REACT_APP_I_MILE_REACT_APP_AUTHORIZATION;
export const wingsPortalUrl = window._env_.REACT_APP_WINGS_PORTAL_URL;