import React from 'react';
import { mapStyles } from './GoogleMapContainer.styles';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { googleApiCode } from '../../config/Api.config';

interface GoogleMapContainerProps {
  center: {
    lat: number,
    lng: number
  },
  zoom?: number,
  markers?: Array<any>
}

const MapContainer = (props: GoogleMapContainerProps) => {

  const { center, zoom, markers } = props;
  return (
    <LoadScript googleMapsApiKey={googleApiCode || ""}>
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={zoom || 8}
        center={center}
      >
        {
          markers && markers.length ? (
            markers.map(item => {
              return (
                <Marker key={item.name} position={item.location} />
              )
            })
          ) : null
        }
      </GoogleMap>
    </LoadScript>
  );
}

export default MapContainer;
